import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { useRestaurantAttributes } from '../../../models/CustomerAttributes'
import {
  birthdayTagName,
  confirmationTagName,
} from '../../../models/ReservationTag'
import { useRestaurantRequestReservations } from '../../../models/RestaurantRequestReservation'
import { useRestaurantReservations } from '../../../models/RestaurantReservation'
import { hasCustomerAttribute } from '../Chart/utils'

type NeedConfirmReservationsParams = {
  restaurantId: number
  date: dayjs.Dayjs
}
export type UseNeedConfirmReservations = {
  requestReservationAmount: number
  smartPaymentAmount: number
  unallocatedSeatAmount: number
  vipAmount: number
  cautionAmount: number
  birthdayAmount: number
  confirmationAmount: number
  isUseAccordion: boolean
  amount: number
}
export function useNeedConfirmReservations({
  restaurantId,
  date,
}: NeedConfirmReservationsParams): UseNeedConfirmReservations {
  const { restaurantReservations } = useRestaurantReservations(restaurantId, {
    date: date.format('YYYY-MM-DD'),
  })
  const { requestReservations } = useRestaurantRequestReservations(
    restaurantId,
    {
      date,
    }
  )
  const { data: customerAttributes = [] } =
    useRestaurantAttributes(restaurantId)
  const requestReservationAmount = requestReservations?.length ?? 0
  const smartPaymentAmount = restaurantReservations.filter(
    (r) => r.smart_payment != null
  ).length
  const unallocatedSeatAmount = restaurantReservations.filter(
    (r) => r.table_seats.length === 0
  ).length
  const vipAmount = restaurantReservations.filter(
    (r) =>
      r.customers.filter((customer) =>
        hasCustomerAttribute(customer, customerAttributes, t('VIP'))
      ).length > 0
  ).length
  const cautionAmount = restaurantReservations.filter(
    (r) =>
      r.customers.filter((customer) =>
        hasCustomerAttribute(customer, customerAttributes, t('要注意'))
      ).length > 0
  ).length
  const birthdayAmount = restaurantReservations.filter(
    (r) =>
      (r.reservation_tags?.filter((tag) => tag.name === birthdayTagName)
        .length ?? 0) > 0
  ).length
  const confirmationAmount = restaurantReservations.filter(
    (r) =>
      (r.reservation_tags?.filter((tag) => tag.name === confirmationTagName)
        .length ?? 0) > 0
  ).length

  const isUseAccordion =
    [
      requestReservationAmount > 0,
      smartPaymentAmount > 0,
      unallocatedSeatAmount > 0,
      vipAmount > 0,
      cautionAmount > 0,
      birthdayAmount > 0,
      confirmationAmount > 0,
    ].filter(Boolean).length > 1

  return {
    requestReservationAmount,
    smartPaymentAmount,
    unallocatedSeatAmount,
    isUseAccordion,
    vipAmount,
    cautionAmount,
    birthdayAmount,
    confirmationAmount,
    amount:
      requestReservationAmount +
      smartPaymentAmount +
      unallocatedSeatAmount +
      vipAmount +
      cautionAmount +
      birthdayAmount +
      confirmationAmount,
  }
}
