import React, { useCallback } from 'react'

import { Document, Font, Page, StyleSheet, View } from '@react-pdf/renderer'
import { inRange } from 'lodash'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

import { PrintWrap } from '../PrintWrap'

import { HeaderCell } from './HeaderCell'
import { HourCellList } from './HourCellList'
import { LeftContent } from './LeftContent'
import { Reservation } from './Reservation'
import { calculateDisplayHours, createRowChunks, toSeconds } from './model'

import type { ChartViewProps } from './types'

import NotoSansJPBold from 'assets/fonts/NotoSansJP-Bold.ttf'
import NotoSansJPRegular from 'assets/fonts/NotoSansJP-Regular.ttf'

Font.register({ family: 'NotoSansJPRegular', src: NotoSansJPRegular })
Font.register({ family: 'NotoSansJPBold', src: NotoSansJPBold })

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  content: {
    flexDirection: 'column',
    marginTop: 12,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },

  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    position: 'relative',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    // width: 'fit-content',
  },

  reservationContainer: {
    position: 'absolute',
    borderRadius: 4,
    borderWidth: 1,
    backgroundColor: Colors.white,
  },
})

export type ChartViewPDFProps = ChartViewProps & {
  restaurantId: number
  reservationDate: dayjs.Dayjs
  restaurantName: string
  latestUpdatedAt: string
}
export function ChartViewPDF({
  tableSeats,
  businessTimes,
  displayDate,
  isRestaurantSmartPaymentAvailable,
  unifiedData,
  restaurantName,
  reservationDate,
  latestUpdatedAt,
}: ChartViewPDFProps) {
  const today = dayjs(displayDate)
  const isRestaurantOpenAt = useCallback(
    (hour: number, minute: number): boolean => {
      return (
        // 営業時間の配列から、指定時刻が含まれる時間枠を探す
        businessTimes?.some(({ open, startAt, endAt }) => {
          // 営業していない、または開始/終了時刻が未設定の場合はスキップ
          if (!open || !startAt || !endAt) return false

          /**
           * 日付を跨ぐ営業時間に対応するためのヘルパー関数
           * 例: 18:00-26:00（翌2:00）のような営業時間
           *
           * @param day - 対象の日時
           * @returns
           *   - 同日の場合: その時刻の秒数（0-86399）
           *   - 翌日の場合: 86400(1日の秒数)を加算した秒数
           */
          const getAdjustedSeconds = (day: dayjs.Dayjs) =>
            day.isSame(today, 'day')
              ? toSeconds(day.hour(), day.minute()) // 同日なら通常の秒数
              : 86400 + toSeconds(day.hour(), day.minute()) // 翌日なら1日分(86400秒)を加算

          // 営業開始時刻を秒数に変換（日跨ぎ対応）
          const startSeconds = getAdjustedSeconds(dayjs.unix(startAt.seconds))
          // 営業終了時刻を秒数に変換（日跨ぎ対応）
          const endSeconds = getAdjustedSeconds(dayjs.unix(endAt.seconds))

          // 指定された時刻（hour:minute）が営業時間内かチェック
          return inRange(toSeconds(hour, minute), startSeconds, endSeconds)
        }) ?? false // businessTimesがnullの場合はfalse
      )
    },
    [businessTimes, today]
  )

  // 表示時間の計算
  const { displayStartHour, displayEndHour } = calculateDisplayHours(
    businessTimes,
    dayjs(displayDate),
    unifiedData
  )

  // チャンクを作成
  const totalChunks = createRowChunks(
    unifiedData,
    tableSeats,
    displayStartHour,
    displayEndHour,
    today
  )

  const totalPages = totalChunks.reduce(
    (acc, timeChunk) => acc + timeChunk.chunkedRows.length,
    0
  )

  // 現在のページインデックスを計算
  const getCurrentPageIndex = (timeIndex: number, rowIndex: number) => {
    // timeIndexまでのchunkedRowsの合計を計算
    const previousPagesCount = totalChunks
      .slice(0, timeIndex)
      .map((c) => c.chunkedRows.length)
      .reduce((a, b) => a + b, 0)

    // 現在のtimeChunkの行数を加算
    return previousPagesCount + rowIndex + 1
  }

  return (
    <Document>
      {totalChunks.map((timeChunk, timeIndex) =>
        timeChunk.chunkedRows.map((rowChunk, rowIndex) => (
          <Page
            key={`page-${rowChunk.startIndex}`}
            size="A4"
            orientation="landscape"
            style={styles.page}
          >
            <PrintWrap
              orientation="landscape"
              currentPage={getCurrentPageIndex(timeIndex, rowIndex)}
              totalPages={totalPages}
              lastUpdatedAt={latestUpdatedAt}
              restaurantName={restaurantName}
              reservationDate={reservationDate}
            >
              <View style={styles.container}>
                <View>
                  <HeaderCell text={t('席')} isFirst />
                  <LeftContent key={JSON.stringify(rowChunk)} {...rowChunk} />
                </View>

                {/* メインコンテンツ */}
                <View style={styles.mainContainer}>
                  {/* 時間軸ヘッダー */}
                  <View style={styles.rowContainer}>
                    {Array.from(
                      { length: timeChunk.end - timeChunk.start },
                      (_, i) => timeChunk.start + i
                    ).map((hour: number, index: number) => (
                      <HeaderCell
                        key={hour}
                        text={`${hour}:00`}
                        isFirst={index === 0}
                      />
                    ))}
                  </View>

                  {/* 時間枠のグリッド */}
                  <HourCellList
                    timeChunk={timeChunk}
                    rowChunk={rowChunk}
                    isRestaurantOpenAt={isRestaurantOpenAt}
                  />

                  {rowChunk.rows.map((row) => {
                    // すでに表示された連続席の予約IDを記録するセット
                    const shownReservationIds = new Set<string>()

                    return row.reservations
                      .filter((reservation) => {
                        // 連続席情報を持たない場合は表示
                        if (
                          reservation.consecutiveCount === undefined ||
                          reservation.consecutiveCount <= 1
                        ) {
                          return true
                        }

                        // 既に表示された予約の場合はスキップ
                        if (shownReservationIds.has(reservation.id)) {
                          return false
                        }

                        // 表示する予約としてIDを記録
                        shownReservationIds.add(reservation.id)
                        return true
                      })
                      .map((reservation) => (
                        <Reservation
                          key={JSON.stringify(reservation)}
                          index={row.index}
                          restaurantReservation={reservation}
                          date={dayjs(displayDate)}
                          isRestaurantSmartPaymentAvailable={
                            isRestaurantSmartPaymentAvailable
                          }
                          displayStartHour={timeChunk.start}
                          displayEndHour={timeChunk.end}
                        />
                      ))
                  })}

                  {/* メインコンテンツ done */}
                </View>
              </View>
            </PrintWrap>
          </Page>
        ))
      )}
    </Document>
  )
}
