import React, { useState } from 'react'

import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard'
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown'
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons/faAngleUp'
import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair'
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons/faCircleQuestion'
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons/faExternalLinkAlt'
import { faFrown } from '@fortawesome/pro-solid-svg-icons/faFrown'
import { faHistory } from '@fortawesome/pro-solid-svg-icons/faHistory'
import { faMemo } from '@fortawesome/pro-solid-svg-icons/faMemo'
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons/faPhoneAlt'
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag'
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends'
import { faUtensils } from '@fortawesome/pro-solid-svg-icons/faUtensils'
import { View, useWindowDimensions, Platform } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Popover } from '@hello-ai/ar_shared/src/components/Popover'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import {
  createResponsiveSheet,
  useResponsive,
  useResponsiveStyle,
} from '@hello-ai/ar_shared/src/modules/useResponsive'
import {
  RestaurantReservation,
  RestaurantReservationVisitStatus,
} from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'
import {
  RevisionSmartPaymentButton,
  SmartPaymentStatus,
} from '@hello-ai/for_r_app/src/components/Reservations/SmartPayment/SmartPaymentStatusButton'
import { RestaurantCourseResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_course/restaurant_course_resource'

import { labelColors } from '../../components/Customers/Attributes'
import { Customer as CustomerModel } from '../../models/Customer'
import { Attribute } from '../../models/CustomerAttributes'
import { Restaurant, useRestaurant } from '../../models/Restaurant'
import {
  RestaurantReservationStatus,
  updateRestaurantReservation,
  useRestaurantReservation,
} from '../../models/RestaurantReservation'
import { TableSeat, useTableSeatsBulk } from '../../models/TableSeat'
import { openURL } from '../../modules/openURL'
import { mutate, swrKey } from '../../modules/swr'
import { getFormatTime } from '../../modules/time'
import { useRestaurantCurrency } from '../../modules/useRestaurantCurrency'
import { getCustomerDisplayName } from '../Customers/Customer'
import { ReservationData } from '../Restaurant/Reservation/ListView/Content'
import { VisitStatusTag } from '../Restaurant/Reservation/ListView/VisitStatusTag'
import { displayToastSuccess } from '../Shared/Toast'

import { CustomerOrderHistory } from './CustomerOrderHistory'
import { CustomerOrderHistorySmall } from './CustomerOrderHistorySmall'
import Row from './Row'
import { getRestaurantCourseText } from './common'

const fontSize = 18
const rowMargin = 8
const HELP_PAGE_URL =
  'https://help-for-restaurants.autoreserve.com/hc/ja/articles/27393677576985-%E3%82%B9%E3%83%9E%E3%83%BC%E3%83%88%E6%B1%BA%E6%B8%88%E6%A9%9F%E8%83%BD%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6'

function isReservedByAR(
  reservation: ReservationData | null | undefined
): boolean {
  return (
    reservation?.source === 'autoreserve' && reservation?.source_by === 'auto'
  )
}

function isCanceledBeforePayment(
  reservation: ReservationData | null | undefined
): boolean {
  return (
    reservation?.status === 'canceled' &&
    reservation?.smart_payment?.status === 'not_authorized'
  )
}

function hasNoSmartPayment(
  reservation: ReservationData | null | undefined
): boolean {
  return reservation?.smart_payment == null && !isReservedByAR(reservation)
}

function smartPaymentAvailable(restaurant?: Restaurant): boolean {
  return !!restaurant?.table_restaurant_setting?.smart_payment_available
}

function shouldShowRevisionButton(
  restaurantReservation: ReservationData,
  restaurant?: Restaurant
): boolean {
  return (
    smartPaymentAvailable(restaurant) &&
    restaurantReservation.smart_payment?.status === 'paid'
  )
}

function shouldNotShowSmartPaymentTag(
  restaurantReservation: ReservationData | null | undefined,
  restaurant?: Restaurant
): boolean {
  if (!smartPaymentAvailable(restaurant)) {
    return true
  }

  return (
    isCanceledBeforePayment(restaurantReservation) ||
    hasNoSmartPayment(restaurantReservation)
  )
}

export function ReservationDetails({
  restaurantId,
  date,
  startTime,
  endTime,
  reservationChildDetails,
  adultPartySize,
  childPartySize,
  tableSeatIds,
  restaurantReservationId,
  reservationStatus,
  visitStatus,
}: {
  restaurantId: number
  date: dayjs.Dayjs
  startTime: number
  endTime: number
  adultPartySize: number
  childPartySize: number
  reservationChildDetails: RestaurantReservation['reservation_child_details']
  // TODO: Can't we just pass in the tableSeats array directly?
  tableSeatIds: Array<TableSeat['id']>
  // 予約変更の場合のみ予約ID、予約ステータス、来店ステータスが必要。
  restaurantReservationId?: string
  reservationStatus?: RestaurantReservationStatus
  visitStatus?: RestaurantReservationVisitStatus
}) {
  const { width, sm } = useResponsive()
  const token = useToken()
  const { tableSeats } = useTableSeatsBulk(restaurantId, {
    ids: tableSeatIds,
  })
  const { restaurantReservation, mutate: mutateReservation } =
    useRestaurantReservation(restaurantId, restaurantReservationId)
  const { data: restaurant } = useRestaurant(restaurantId)

  // 予約変更時、この画面内でステータスを更新したら、画面上も更新する
  const onUpdateVisitStatus = async (
    newVisitStatus: RestaurantReservationVisitStatus | undefined
  ) => {
    if (restaurantReservationId == null) return
    const { error } = await updateRestaurantReservation(
      token,
      restaurantId,
      restaurantReservationId,
      {
        visit_status: newVisitStatus,
      }
    )
    if (error !== undefined) return
    mutate(
      swrKey(
        token,
        `/reservation_book/restaurants/${restaurantId}/reservations`,
        {
          date: date.format('YYYY-MM-DD'),
        }
      )
    )
    displayToastSuccess(t('来店ステータスを更新しました'))
  }

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap-reverse',
      }}
    >
      <View style={{ flex: 1 }}>
        <Row icon={faCalendarAlt}>
          <Text style={[{ fontWeight: '600' }]}>
            {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
            {date.format('YYYY年M月DD日(ddd)')}
          </Text>
        </Row>
        <Row
          style={{
            marginTop: rowMargin,
          }}
          icon={faClock}
        >
          <Text style={{ fontWeight: '600' }}>
            {getFormatTime(startTime)}~{getFormatTime(endTime)}
          </Text>
        </Row>
        <Row
          style={{
            marginTop: rowMargin,
          }}
          icon={faUserFriends}
        >
          {(reservationChildDetails?.length ?? 0) > 0 ? (
            <Text style={{ fontSize, fontWeight: '600', flex: 1 }}>
              {t('{{count}}名様', {
                count: adultPartySize + childPartySize,
              })}
              {`(${t('お子様{{count}}名', {
                count: reservationChildDetails?.length,
              })}：${reservationChildDetails
                ?.map((child) => t('{{count}}歳', { count: child.age }))
                .join('/')}) `}
            </Text>
          ) : (
            <Text style={{ fontSize, fontWeight: '600' }}>
              {t(
                '{{party_size}} ({{adult_party_size}}、{{child_party_size}})',
                {
                  party_size: t('{{count}}名様', {
                    count: adultPartySize + childPartySize,
                  }),
                  adult_party_size: t('大人{{count}}', {
                    count: adultPartySize,
                  }),
                  child_party_size: t('お子様{{count}}', {
                    count: childPartySize,
                  }),
                }
              )}
            </Text>
          )}
        </Row>
        <Row
          style={{
            marginTop: rowMargin,
          }}
          icon={faChair}
        >
          <Text style={{ fontSize }}>
            {(tableSeats &&
              tableSeats.map((tableSeat) => tableSeat.name).join('、')) ||
              t('なし')}
          </Text>
        </Row>
      </View>
      <View
        style={[
          {
            gap: 8,
          },
          width >= sm
            ? { flexDirection: 'row' }
            : {
                alignItems: 'flex-end',
                paddingBottom: 16,
              },
        ]}
      >
        <View
          style={[
            {
              flexDirection: 'row',
              columnGap: 8,
            },
            width < sm && {
              marginLeft: 4,
            },
          ]}
        >
          {reservationStatus !== undefined && (
            <VisitStatusTag
              style={[
                {
                  height: 36,
                  marginRight:
                    width < sm &&
                    shouldNotShowSmartPaymentTag(
                      restaurantReservation,
                      restaurant
                    )
                      ? -16
                      : 0,
                },
              ]}
              xOffset={
                width < sm &&
                !shouldNotShowSmartPaymentTag(restaurantReservation, restaurant)
                  ? 36
                  : 0
              }
              reservationStatus={reservationStatus!}
              visitStatus={visitStatus ?? 'all_visited'}
              onUpdateVisitStatus={onUpdateVisitStatus}
            />
          )}
          {restaurantReservation != null &&
            smartPaymentAvailable(restaurant) && (
              <SmartPaymentStatusTag
                restaurantReservation={restaurantReservation}
                restaurantId={restaurantId}
                onIssueSmartPaymentReceipt={() => mutateReservation()}
              />
            )}
          {restaurantReservation != null &&
            shouldShowRevisionButton(restaurantReservation, restaurant) && (
              <RevisionSmartPaymentButton
                restaurantId={restaurantId}
                restaurantReservation={restaurantReservation}
              />
            )}
        </View>
      </View>
    </View>
  )
}

function useResponsiveSmartPaymentStyle() {
  const { width: windowWidth } = useWindowDimensions()
  const responsiveStyle = createResponsiveSheet(({ width, sm }) => ({
    button: {
      width: width > sm ? 200 : windowWidth - 178,
      height: 36,
      marginLeft: 2,
    },
    text: {
      color: Colors.white,
      fontSize: 14,
      fontWeight: '600',
    },
  }))
  return useResponsiveStyle(responsiveStyle)
}

function SmartPaymentStatusTag({
  restaurantReservation,
  restaurantId,
  onIssueSmartPaymentReceipt,
}: {
  restaurantReservation: ReservationData
  restaurantId: number
  onIssueSmartPaymentReceipt: () => void
}) {
  const style = useResponsiveSmartPaymentStyle()
  const [isHelpOpen, setIsHelpOpen] = useState(false)

  if (isCanceledBeforePayment(restaurantReservation)) {
    return null
  }

  if (restaurantReservation.smart_payment == null) {
    if (isReservedByAR(restaurantReservation)) {
      return (
        <Button disabled style={style.button}>
          <Text style={style.text}>{t('スマート決済なし')}</Text>
        </Button>
      )
    }
    return null
  }

  switch (restaurantReservation.smart_payment.status) {
    case 'paid':
    case 'canceled':
    case 'failed':
    case 'require_three_d_secure':
      return (
        <SmartPaymentStatus
          restaurantReservation={restaurantReservation}
          restaurantId={restaurantId}
          buttonStyle={style.button}
          onIssueReceipt={onIssueSmartPaymentReceipt}
        />
      )
    case 'authorized':
    case 'not_authorized':
      return (
        <Button disabled style={[style.button, { opacity: 1, gap: 4 }]}>
          <Text style={style.text}>{t('スマート決済あり')}</Text>
          <Popover
            isOpen={isHelpOpen}
            showArrowElement={false}
            placement={'bottomEnd'}
            offsetX={Platform.OS === 'web' ? 0 : 8}
            offsetY={-28}
            anchor={
              <TouchableOpacity onPress={() => setIsHelpOpen(!isHelpOpen)}>
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  color={'white'}
                  size={16}
                />
              </TouchableOpacity>
            }
            onClose={() => setIsHelpOpen(false)}
          >
            <View style={{ padding: 24, gap: 16, width: 305 }}>
              <Text style={{ fontSize: 14, color: Colors.black }}>
                {t(
                  'お客様が予約時に登録されたクレジットカードで決済が行われます。'
                )}
              </Text>
              <Text style={{ fontSize: 14, color: Colors.black }}>
                {t(
                  'お食事完了後に「スマート決済で会計する」ボタンから会計を行なってください。'
                )}
              </Text>

              <TouchableOpacity
                style={[{ flexDirection: 'row', gap: 4, alignItems: 'center' }]}
                onPress={async () => await openURL(HELP_PAGE_URL)}
              >
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  color={Colors.primary}
                  size={16}
                />
                <Text style={{ fontSize: 14, color: Colors.primary }}>
                  {t('スマート決済でのお会計方法について')}
                </Text>
              </TouchableOpacity>
            </View>
          </Popover>
        </Button>
      )
  }
}

const useResponsiveCustomerStyle = (hasRight: boolean) => {
  const { width: windowWidth } = useWindowDimensions()
  const responsiveStyle = createResponsiveSheet(({ width, sm }) => ({
    // right（顧客情報を見る）が存在する場合、spだとnameが被る可能性があるため調整
    name: {
      ...(hasRight &&
        width <= sm && {
          maxWidth: windowWidth - 240,
        }),
    },
    // right（顧客情報を見る）が存在する場合、spだと各行が中途半端な位置で改行されるため調整
    row: {
      marginTop: rowMargin,
      ...(hasRight &&
        width <= sm && {
          flexWrap: 'wrap',
          width: windowWidth - 70,
        }),
    },
  }))
  return useResponsiveStyle(responsiveStyle)
}

export function Customer({
  customer,
  displayName,
  attributes,
  right,
  restaurant,
}: {
  customer: CustomerModel
  displayName?: string
  attributes?: Attribute[]
  right?: React.ReactNode
  restaurant?: Pick<Restaurant, 'price_unit'>
}) {
  const { width, sm } = useResponsive()
  const style = useResponsiveCustomerStyle(right !== undefined)
  const attributeIds =
    customer.restaurant_profile?.custom_attributes?.split(',') ?? []
  const selectedAttributes = attributes?.filter((i) =>
    attributeIds.includes(i.id)
  )
  const customerNote = customer.restaurant_customer_notes?.reduce(
    (previousValue, note) => previousValue + note.content,
    ''
  )

  const [isOpenPreviousOrder, setIsOpenPreviousOrder] = useState(true)
  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <Row icon={faAddressCard}>
            <Text
              style={[
                {
                  fontWeight: '600',
                  flex: 1,
                },
                style.name,
              ]}
            >
              {displayName ?? getCustomerDisplayName(customer, t('様'))}
            </Text>
          </Row>
          <Row
            style={{
              marginTop: rowMargin,
            }}
            icon={faPhoneAlt}
          >
            <Text
              style={{
                fontWeight: '600',
              }}
            >
              {customer.phone_number_format ?? t('未登録')}
            </Text>
          </Row>
          <Row style={style.row} icon={faEnvelope}>
            <Text>{customer.email || t('なし')}</Text>
          </Row>
          <Row style={style.row} icon={faFrown}>
            <Text>
              {customer.allergy != null ? customer.allergy : t('特になし')}
            </Text>
          </Row>
          {selectedAttributes != null && selectedAttributes.length !== 0 && (
            <Row style={[{ gap: 8 }, style.row]} icon={faTag}>
              {selectedAttributes.map((attribute) => (
                <Text
                  key={attribute.id}
                  style={{
                    borderRadius: 2,
                    backgroundColor:
                      labelColors[attribute.label_color].backgroundColor,
                    color: labelColors[attribute.label_color].text,
                    paddingHorizontal: 8,
                    paddingVertical: 3,
                    fontSize: 12,
                    fontWeight: '600',
                  }}
                >
                  {attribute.name}
                </Text>
              ))}
            </Row>
          )}
          {customerNote != null && customerNote.length !== 0 && (
            <Row
              style={{
                marginTop: rowMargin,
                marginRight: 32,
              }}
              icon={faMemo}
            >
              <Text>{customerNote}</Text>
            </Row>
          )}
        </View>
        {right}
      </View>
      {customer.previous_table_order && (
        <View style={{ marginTop: 8 }}>
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 4,
              paddingVertical: 8,
            }}
            onPress={() => setIsOpenPreviousOrder(!isOpenPreviousOrder)}
          >
            <FontAwesomeIcon
              icon={faHistory}
              size={20}
              color={Colors.black60}
            />
            <Text style={{ fontSize: 18, color: Colors.black }}>
              {t('この顧客の前回の注文')}
            </Text>
            <FontAwesomeIcon
              icon={isOpenPreviousOrder ? faAngleUp : faAngleDown}
              size={20}
              color={Colors.black60}
            />
          </TouchableOpacity>
          {isOpenPreviousOrder && (
            <View
              style={{
                marginTop: 4,
                backgroundColor: Colors.bgBlack,
                padding: 24,
              }}
            >
              <Text style={{ fontWeight: '600', fontSize: 14 }}>
                {dayjs(customer.previous_table_order.started_at).format('ll')}
              </Text>
              {width < sm ? (
                <CustomerOrderHistorySmall
                  tableOrder={customer.previous_table_order}
                  restaurant={restaurant}
                />
              ) : (
                <CustomerOrderHistory
                  tableOrder={customer.previous_table_order}
                  restaurant={restaurant}
                />
              )}
            </View>
          )}
        </View>
      )}
    </>
  )
}

function getTextMarginTop({
  fontSize,
  lineHeight,
}: {
  fontSize: number
  lineHeight: number
}) {
  return -(lineHeight - fontSize) / 2
}

export function ReservationCoursesAndMemo({
  restaurantId,
  reservationCourses,
  allergy,
  translatedAllergy,
  memo,
  translatedMemo,
}: {
  restaurantId: number
  reservationCourses: Array<{
    partySize: number
    restaurantCourse:
      | (Pick<RestaurantCourseResource, 'id' | 'title'> & {
          price: number | null
        })
      | undefined
    restaurantCourseQuestionAnswers: Array<{
      text: string | null
      restaurantCourseQuestion: {
        description: string
      }
      restaurantCourseQuestionSelections: Array<{
        description: string
      }>
      translatedText?: string | null
    }>
  }>
  allergy: string | null
  translatedAllergy?: string | null
  memo: string | null
  translatedMemo?: string | null
}) {
  const { data: restaurant } = useRestaurant(restaurantId)
  const { currencyWithDelimiter } = useRestaurantCurrency(restaurant)
  const { width, sm } = useResponsive()
  return (
    <View style={{ gap: 4 }}>
      <Row mode="inline-expanded" icon={faUtensils}>
        <View style={{ flex: 1 }}>
          {reservationCourses.length === 0 && (
            <Text
              style={{
                fontSize: 18,
                lineHeight: 18 * 1.5,
                marginTop: getTextMarginTop({
                  fontSize: 18,
                  lineHeight: 18 * 1.5,
                }),
              }}
            >
              {t('コースなし')}
            </Text>
          )}
          {reservationCourses.map(
            (
              { partySize, restaurantCourse, restaurantCourseQuestionAnswers },
              index
            ) => (
              <View
                key={`${index}`}
                style={{
                  marginTop: index === 0 ? 0 : 8,
                }}
              >
                <Text
                  style={{
                    fontWeight: '600',
                    fontSize: 18,
                    lineHeight: 18 * 1.5,
                    marginTop: getTextMarginTop({
                      fontSize: 18,
                      lineHeight: 18 * 1.5,
                    }),
                  }}
                >
                  {restaurantCourse == null
                    ? t('コースが見つかりません')
                    : getRestaurantCourseText({
                        title: restaurantCourse.title,
                        price: currencyWithDelimiter(restaurantCourse.price),
                        partySize,
                      })}
                </Text>
                {restaurantCourseQuestionAnswers.map(
                  (restaurantCourseQuestionAnswer, index) => {
                    return (
                      <View
                        key={`${index}`}
                        style={{
                          marginTop: 16,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.black60,
                              fontWeight: '600',
                            }}
                          >
                            {t('質問')}
                          </Text>
                          <Text
                            style={{
                              marginLeft: 8,
                              flex: 1,
                            }}
                          >
                            {
                              restaurantCourseQuestionAnswer
                                .restaurantCourseQuestion.description
                            }
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: 8,
                            flexDirection: 'row',
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.black60,
                              fontWeight: '600',
                            }}
                          >
                            {t('回答')}
                          </Text>
                          <Text
                            style={{
                              marginLeft: 8,
                              fontWeight: '600',
                            }}
                            selectable
                          >
                            {(restaurantCourseQuestionAnswer.text ?? '') !== ''
                              ? `${(restaurantCourseQuestionAnswer.translatedText ?? '') !== '' ? `${restaurantCourseQuestionAnswer.translatedText}\n` : ''}${restaurantCourseQuestionAnswer.text}`
                              : restaurantCourseQuestionAnswer.restaurantCourseQuestionSelections
                                  .map((selection) => selection.description)
                                  .join(', ')}
                          </Text>
                        </View>
                      </View>
                    )
                  }
                )}
              </View>
            )
          )}
        </View>
      </Row>
      <Row
        style={{
          marginTop: width > sm ? 24 : 12,
        }}
        icon={faFrown}
        mode={'inline-expanded'}
      >
        <View style={{ flex: 1 }}>
          <Text
            selectable
            style={{
              marginTop: getTextMarginTop({
                fontSize: 18,
                lineHeight: 18 * 1.5,
              }),
            }}
          >
            {(allergy ?? '') !== ''
              ? `${(translatedAllergy ?? '') !== '' ? `${translatedAllergy}\n` : ''}${allergy}`
              : t('未記入')}
          </Text>
        </View>
      </Row>
      <Row
        style={{
          marginTop: width > sm ? 24 : 12,
        }}
        icon={faEdit}
        mode={'inline-expanded'}
      >
        <View style={{ flex: 1 }}>
          <Text
            selectable
            style={{
              marginTop: getTextMarginTop({
                fontSize: 18,
                lineHeight: 18 * 1.5,
              }),
            }}
          >
            {(memo ?? '') !== ''
              ? `${(translatedMemo ?? '') !== '' ? `${translatedMemo}\n` : ''}${memo}`
              : t('未記入')}
          </Text>
        </View>
      </Row>
    </View>
  )
}
