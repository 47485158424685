import { inRange } from 'lodash'

import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { RestaurantRequestReservation } from '@hello-ai/ar_shared/src/types/ForR/RestaurantRequestReservation'
import {
  RestaurantReservation,
  isSiteControllerReservation,
} from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'
import type { TableSeat } from '@hello-ai/ar_shared/src/types/ForR/TableSeat'
import type { GetCalendarResponse_BusinessTimeResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_business_time/restaurant_business_time_service'
import type { ListPeriodResponse_RestaurantReservationBlockPeriod } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation_block/restaurant_reservation_block_service'

import { Attribute } from '../../../../models/CustomerAttributes'

import {
  ChunkedReservationRow,
  NO_SEAT_ASSIGNED_ID,
  NO_SEAT_ASSIGNED_NAME,
  REQUEST_ID,
  REQUEST_NAME,
  RowChunk,
  TimeChunk,
  UnifiedReservationData,
} from './types'

import type {
  BusinessTimeView,
  ChartViewProps,
  CustomerAttribute,
  TableSeatView,
} from './types'

// 時間計算ユーティリティ
export function toSeconds(hours: number, mins: number) {
  return hours * 3600 + mins * 60
}

export function getFormatTime(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const mins = Math.floor((seconds % 3600) / 60)
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`
}

// レストランがお客さんの名前を見て呼ぶことがあるので、表示系は基本的に予約名などを使う必要がある
export function getCustomerDisplayName(
  customer: UnifiedReservationData['customers'][number] | null,
  suffix?: string
) {
  if (
    (customer?.first_name ?? '') !== '' ||
    (customer?.last_name ?? '') !== ''
  ) {
    return (
      [customer?.last_name, customer?.first_name]
        .filter((value) => value != null)
        .join(' ') + (suffix ?? '')
    )
  }

  const joinedName = [
    customer?.reservation_last_name,
    customer?.reservation_first_name,
  ]
    .filter((value) => value != null)
    .join(' ')

  return joinedName ? `${joinedName}${suffix ?? ''}` : t('未設定')
}

// 予約表示用ユーティリティ
export function getReservationText(
  restaurantReservation: Pick<UnifiedReservationData, 'customers' | 'kind'>
) {
  if (restaurantReservation.kind === 'walkin') {
    return t('ウォークイン')
  }
  if (restaurantReservation.kind === 'block') {
    return t('ブロック')
  }
  if (restaurantReservation.customers.length === 0) {
    return t('未設定')
  }
  return restaurantReservation.customers
    .map((customer) => getCustomerDisplayName(customer, t('様')))
    .join(', ')
}

export function getPartySize2(
  restaurantReservation: Pick<
    UnifiedReservationData,
    'partySize' | 'adultPartySize' | 'childPartySize'
  >
) {
  const { partySize, adultPartySize, childPartySize } = restaurantReservation
  if (adultPartySize != null && childPartySize != null) {
    return `${adultPartySize}/${childPartySize}`
  }
  return partySize.toString()
}

// アイコン表示用ユーティリティ
export function calculateIconMaxWidth(
  hours: number,
  iconCount: number
): number {
  if (hours < 1) {
    return 100
  }
  if (hours < 1.5) {
    return iconCount > 2 ? 50 : 100
  }
  return 100
}

export function shouldDisplayEllipsis(
  hours: number,
  iconCount: number
): boolean {
  if (hours < 1) {
    return iconCount > 2
  }
  if (hours < 1.5) {
    return iconCount > 3
  }
  return false
}

export function calculateIconCount(props: {
  isVip: boolean
  hasSmartPayment: boolean
  hasMemo: boolean
  isAutoReserve: boolean
  hasCourse: boolean
  hasHistory: boolean
  hasWarning: boolean
  hasBirthdayTag: boolean
  hasDrinkTag: boolean
  hasAllergyTag: boolean
  hasTableTag: boolean
  hasPhoneTag: boolean
}): number {
  // booleanプロパティの配列をフィルタリングしてtrueの数をカウント
  return [
    props.isVip,
    props.hasSmartPayment,
    props.hasMemo,
    props.isAutoReserve,
    props.hasCourse,
    props.hasHistory,
    props.hasWarning,
    props.hasBirthdayTag,
    props.hasDrinkTag,
    props.hasAllergyTag,
    props.hasTableTag,
    props.hasPhoneTag,
  ].filter((x) => x === true).length
}

/**
 * TableSeatからTableSeatViewへの変換
 */
export function createTableSeatView(tableSeat: TableSeat): TableSeatView {
  return {
    id: tableSeat.id,
    name: tableSeat.name,
    maxPartySize: tableSeat.max_party_size ?? 0,
    minPartySize: tableSeat.min_party_size ?? 0,
    isNoSeatAssigned:
      Array.isArray(tableSeat.table_seats) &&
      tableSeat.table_seats.length === 0,
  }
}

/**
 * 座席指定なし用の行を生成
 */
export const createNoSeatAssignedRow = (): TableSeatView => ({
  id: NO_SEAT_ASSIGNED_ID,
  name: NO_SEAT_ASSIGNED_NAME,
  maxPartySize: 0,
  minPartySize: 0,
  isNoSeatAssigned: true,
})

export const createRequestRow = (): TableSeatView => ({
  id: REQUEST_ID,
  name: REQUEST_NAME,
  maxPartySize: 0,
  minPartySize: 0,
  isNoSeatAssigned: true,
})

/**
 * GetCalendarResponse_BusinessTimeResourceからBusinessTimeViewへの変換
 */
export const toBusinessTimeView = (
  businessTime: GetCalendarResponse_BusinessTimeResource
): BusinessTimeView => {
  return {
    startAt: businessTime?.startAt ?? null,
    endAt: businessTime?.endAt ?? null,
    open: businessTime?.open ?? false,
  }
}

/**
 * 営業時間と予約時間の配列から表示範囲を計算する
 */
export const calculateDisplayHours = (
  businessTimes:
    | BusinessTimeView[]
    | GetCalendarResponse_BusinessTimeResource[],
  today: dayjs.Dayjs,
  unifiedData: UnifiedReservationData[]
): { displayStartHour: number; displayEndHour: number } => {
  // 予約時間の範囲を計算
  const reservationHours = unifiedData.reduce<{
    min: number
    max: number
  } | null>((acc, data) => {
    const startHour = dayjs(data.startAt).hour()
    const endHour = dayjs(data.endAt).hour()
    // 日付をまたぐ場合の調整
    const dayDiff = dayjs(data.startAt).diff(today, 'day')
    const endDayDiff = dayjs(data.endAt).diff(today, 'day')
    const adjustedStartHour = startHour + dayDiff * 24
    const adjustedEndHour = endHour + endDayDiff * 24

    if (!acc) {
      return { min: adjustedStartHour, max: adjustedEndHour }
    }
    return {
      min: Math.min(acc.min, adjustedStartHour),
      max: Math.max(acc.max, adjustedEndHour),
    }
  }, null)

  // 営業時間の範囲を計算（既存のロジックを関数化）
  const calculateBusinessHours = () => {
    if (businessTimes == null || businessTimes.length === 0) {
      return { min: 10, max: 22 } // デフォルト値
    }

    const hours = Array.from({ length: 30 }, (_, i) => i)
    const isOpen = (hour: number, minute: number): boolean => {
      return businessTimes.some(({ open, startAt, endAt }) => {
        if (!open || !startAt || !endAt) return false

        const getAdjustedSeconds = (day: dayjs.Dayjs) =>
          day.isSame(today, 'day')
            ? toSeconds(day.hour(), day.minute())
            : 86400 + toSeconds(day.hour(), day.minute())

        const startSeconds = getAdjustedSeconds(dayjs.unix(startAt.seconds))
        const endSeconds = getAdjustedSeconds(dayjs.unix(endAt.seconds))

        return inRange(toSeconds(hour, minute), startSeconds, endSeconds)
      })
    }

    const openHours = hours.filter((hour) => isOpen(hour, 0))
    if (openHours.length === 0) {
      return { min: 10, max: 22 }
    }

    return {
      min: openHours[0],
      max: openHours[openHours.length - 1] + 1,
    }
  }

  const businessHours = calculateBusinessHours()

  // 予約データが空の場合は営業時間のみを考慮
  if (!reservationHours) {
    return {
      displayStartHour: Math.max(0, businessHours.min - 1),
      displayEndHour: Math.min(29, businessHours.max + 1),
    }
  }

  // 予約時間と営業時間の両方を考慮して表示範囲を決定
  return {
    displayStartHour: Math.max(
      0,
      Math.min(reservationHours.min - 1, businessHours.min - 1)
    ),
    displayEndHour: Math.min(
      29,
      Math.max(reservationHours.max + 1, businessHours.max + 1)
    ),
  }
}

export const hasCustomerAttribute = (
  customer: RestaurantReservation['customers'][number],
  customerAttributes: CustomerAttribute[],
  name: string
) => {
  return customer.profiles
    ?.slice(-1)[0]
    ?.custom_attributes?.split(',')
    .some(
      (customAttributeId) =>
        customerAttributes.find(
          (customerAttribute) => customerAttribute.id === customAttributeId
        )?.name === name
    )
}

// 定数定義（共通チャンクサイズ）
export const DEFAULT_CHUNK_SIZE = 12

export const groupConsecutiveReservationSeats = (
  tableSeats: TableSeatView[],
  reservationSeats: Pick<TableSeatView, 'id' | 'name'>[],
  chunkSize: number
): Pick<TableSeatView, 'id' | 'name'>[][] => {
  const seatIndexMap = new Map<string, number>()
  tableSeats.forEach((seat, idx) => {
    seatIndexMap.set(seat.id, idx)
  })

  const sortedReservationSeats = reservationSeats
    .filter((rSeat) => seatIndexMap.has(rSeat.id))
    .sort(
      (a, b) =>
        (seatIndexMap.get(a.id) ?? Number.MAX_SAFE_INTEGER) -
        (seatIndexMap.get(b.id) ?? Number.MAX_SAFE_INTEGER)
    )

  // 予約が席を持っていない場合など、空配列ならすぐ返す
  if (sortedReservationSeats.length === 0) {
    return []
  }

  // ソート済みの予約テーブルを「連番かどうか」で区切ってグループ化する
  const result: Array<Array<Pick<TableSeatView, 'id' | 'name'>>> = []
  let currentGroup: Array<Pick<TableSeatView, 'id' | 'name'>> = []
  let prevIndex = seatIndexMap.get(sortedReservationSeats[0].id)!
  currentGroup.push(sortedReservationSeats[0])

  for (let i = 1; i < sortedReservationSeats.length; i++) {
    const currentSeat = sortedReservationSeats[i]
    const currentSeatIndex = seatIndexMap.get(currentSeat.id)!
    const prevChunk = Math.floor(prevIndex / chunkSize)
    const currentChunk = Math.floor(currentSeatIndex / chunkSize)

    if (currentSeatIndex === prevIndex + 1 && prevChunk === currentChunk) {
      // 前の席と「連番」で、同じチャンク内なので、同じグループに入れる
      currentGroup.push(currentSeat)
    } else {
      // 連番が途切れた、またはチャンクが変わったので、新しいグループを開始する
      result.push(currentGroup)
      currentGroup = [currentSeat]
    }
    prevIndex = currentSeatIndex
  }

  if (currentGroup.length > 0) {
    result.push(currentGroup)
  }

  return result
}

/**
 * 変換後の予約データに連続席情報を追加する
 */
export const addConsecutiveCountInfo = (
  unifiedData: UnifiedReservationData[],
  tableSeats: TableSeatView[]
): UnifiedReservationData[] => {
  // 予約IDごとにグループ化
  const reservationGroups = new Map<string, UnifiedReservationData[]>()

  // 座席指定なしと承認待ち予約の数をカウント
  const noSeatReservationsCount = unifiedData.filter(
    (d) => d.isNoSeatAssigned && d.type === 'no-seat'
  ).length
  const requestReservationsCount = unifiedData.filter(
    (d) => d.type === 'request'
  ).length

  // 特殊予約の数
  const specialReservationsCount =
    noSeatReservationsCount + requestReservationsCount

  // 動的なチャンクサイズの計算（デフォルトからページ内の特殊予約数を引く）
  const tableSeatsPerChunk = Math.max(
    1,
    DEFAULT_CHUNK_SIZE - specialReservationsCount
  )

  unifiedData.forEach((data) => {
    if (!reservationGroups.has(data.id)) {
      reservationGroups.set(data.id, [])
    }
    reservationGroups.get(data.id)!.push(data)
  })

  // 結果を格納する配列
  const result: UnifiedReservationData[] = []

  // 各予約グループに対して処理
  reservationGroups.forEach((reservationGroup) => {
    // 空席指定や1席のみの予約はスキップ
    if (reservationGroup.length <= 1 || reservationGroup[0].isNoSeatAssigned) {
      result.push(...reservationGroup)
      return
    }

    // テーブル席情報を抽出
    const reservationSeats = reservationGroup.map((r) => ({
      id: r.tableSeatId,
      name: r.tableSeatName,
    }))

    // 連続席をグループ化（動的チャンクサイズを渡す）
    const seatGroups = groupConsecutiveReservationSeats(
      tableSeats,
      reservationSeats,
      tableSeatsPerChunk
    )

    // チャンクサイズに基づいて連続席グループを分割
    const chunkedSeatGroups: Pick<TableSeatView, 'id' | 'name'>[][] = []

    seatGroups.forEach((group) => {
      // グループのインデックスを取得
      const seatIndices = group
        .map((seat) => {
          const index = tableSeats.findIndex((ts) => ts.id === seat.id)
          return index
        })
        .filter((idx) => idx !== -1)

      if (seatIndices.length === 0) return

      // チャンク番号ごとにグループを分割
      const groupsByChunk = new Map<
        number,
        Pick<TableSeatView, 'id' | 'name'>[]
      >()

      group.forEach((seat) => {
        const seatIndex = tableSeats.findIndex((ts) => ts.id === seat.id)
        if (seatIndex === -1) return

        const chunkNumber = Math.floor(seatIndex / tableSeatsPerChunk)
        if (!groupsByChunk.has(chunkNumber)) {
          groupsByChunk.set(chunkNumber, [])
        }
        groupsByChunk.get(chunkNumber)!.push(seat)
      })

      // 分割されたグループを追加
      groupsByChunk.forEach((chunkedGroup) => {
        if (chunkedGroup.length > 0) {
          chunkedSeatGroups.push(chunkedGroup)
        }
      })
    })

    // 各グループに対して処理
    chunkedSeatGroups.forEach((seatGroup) => {
      if (seatGroup.length === 0) return

      // グループの最初の席を代表として使用
      const firstSeatId = seatGroup[0].id
      const representativeReservation = reservationGroup.find(
        (r) => r.tableSeatId === firstSeatId
      )

      if (!representativeReservation) return

      // 連続席カウントを設定
      result.push({
        ...representativeReservation,
        consecutiveCount: seatGroup.length,
      })
    })
  })

  return result
}

/**
 * チャートビュープロップスを生成する
 */
export const createChartViewProps = (params: {
  restaurantName: string
  tableSeats: TableSeat[]
  reservations: RestaurantReservation[]
  businessTimes: GetCalendarResponse_BusinessTimeResource[]
  blockPeriods: ListPeriodResponse_RestaurantReservationBlockPeriod[]
  displayDate: string
  isRestaurantSmartPaymentAvailable: boolean
  otherReservations: RestaurantReservation[]
  requestReservations: RestaurantRequestReservation[]
  customerAttributes: Attribute[] | undefined
}): ChartViewProps => {
  const latestUpdatedAt = params.reservations.reduce((acc, r) => {
    return dayjs(r.created_at).isAfter(acc) ? dayjs(r.created_at) : dayjs(acc)
  }, dayjs(params.reservations?.[0]?.created_at))
  // 通常の座席リストを変換
  // 座席指定なしの予約がある場合、専用の行を追加
  const allTableSeats = params.tableSeats.map(createTableSeatView)

  // 表示時間の計算

  const unifiedData = [
    ...params.otherReservations.flatMap((r) =>
      toUnifiedReservation(
        r,
        'no-seat',
        allTableSeats,
        params.customerAttributes ?? []
      )
    ),
    ...params.requestReservations.flatMap((r) =>
      toUnifiedReservation(
        r,
        'request',
        allTableSeats,
        params.customerAttributes ?? []
      )
    ),
    ...params.reservations.flatMap((r) =>
      toUnifiedReservation(
        r,
        'reservation',
        allTableSeats,
        params.customerAttributes ?? []
      )
    ),
    ...params.blockPeriods.flatMap((b) =>
      toUnifiedReservation(
        b,
        'block',
        allTableSeats,
        params.customerAttributes ?? []
      )
    ),
  ]

  const { displayStartHour, displayEndHour } = calculateDisplayHours(
    params.businessTimes,
    dayjs(params.displayDate),
    unifiedData
  )

  // 変換後のデータに対して連続席の計算を行う
  const enhancedUnifiedData = addConsecutiveCountInfo(
    unifiedData,
    allTableSeats
  )

  return {
    restaurantName: params.restaurantName,
    latestUpdatedAt: latestUpdatedAt.format('YYYY/MM/DD HH:mm'),
    tableSeats: allTableSeats,
    businessTimes: params.businessTimes.map((bt) => toBusinessTimeView(bt)),
    displayDate: params.displayDate,
    displayStartHour,
    displayEndHour,
    isRestaurantSmartPaymentAvailable: params.isRestaurantSmartPaymentAvailable,
    unifiedData: enhancedUnifiedData,
  }
}

/**
 * 予約データを統一形式に変換
 */
export const toUnifiedReservation = (
  data:
    | RestaurantReservation
    | RestaurantRequestReservation
    | ListPeriodResponse_RestaurantReservationBlockPeriod,
  type: UnifiedReservationData['type'],
  tableSeats: TableSeatView[],
  customerAttributes: CustomerAttribute[]
): UnifiedReservationData[] => {
  let startDate = ''
  let endDate = ''

  switch (type) {
    case 'reservation':
    case 'no-seat': {
      const typedData = data as RestaurantReservation
      const isVip = typedData.customers.some((customer) =>
        hasCustomerAttribute(customer, customerAttributes, 'VIP')
      )
      const needAttention = typedData.customers.some((customer) =>
        hasCustomerAttribute(customer, customerAttributes, t('要注意'))
      )

      // テーブル席がない場合または座席指定なしの場合
      if (typedData.table_seats.length === 0 || type === 'no-seat') {
        return [
          {
            id: typedData.id,
            type,
            startAt: typedData.start_at,
            endAt: typedData.end_at,
            kind: typedData.kind ?? 'normal',
            customers: typedData.customers.map((customer) => ({
              reservation_first_name: customer.reservation_first_name ?? '',
              reservation_last_name: customer.reservation_last_name ?? '',
              last_name: customer.last_name ?? '',
              first_name: customer.first_name ?? '',
              profiles: customer.profiles ?? [],
            })),
            partySize: typedData.party_size,
            adultPartySize: typedData.adult_party_size ?? 0,
            childPartySize: typedData.child_party_size ?? 0,
            smartPayment: typedData.smart_payment,
            memo: typedData.memo,
            source: typedData.source ?? 'site_controller',
            sourceBy: typedData.source_by ?? 'auto',
            reservationCourses: typedData.reservation_courses ?? null,
            reservationTags: typedData.reservation_tags ?? null,
            isWalkin: typedData.kind === 'walkin',
            isVip,
            needAttention,
            hasPreviousTableOrder: typedData.has_previous_table_order ?? false,
            reservation_site_controller_parsed_course:
              isSiteControllerReservation(typedData)
                ? typedData.reservation_site_controller_parsed_course
                : undefined,
            tableSeatId: '',
            tableSeatName: '',
            maxPartySize: 0,
            minPartySize: 0,
            isNoSeatAssigned: true,
            displayOrder: type === 'no-seat' ? 0 : 2,
          },
        ]
      }

      // テーブル席ごとにUnifiedReservationDataを作成
      return typedData.table_seats.map((tableSeatItem) => {
        const tableSeat =
          tableSeats.find((seat) => seat.id === tableSeatItem.id) ?? null
        return {
          id: typedData.id,
          type,
          startAt: typedData.start_at,
          endAt: typedData.end_at,
          kind: typedData.kind ?? 'normal',
          customers: typedData.customers.map((customer) => ({
            reservation_first_name: customer.reservation_first_name ?? '',
            reservation_last_name: customer.reservation_last_name ?? '',
            last_name: customer.last_name ?? '',
            first_name: customer.first_name ?? '',
            profiles: customer.profiles ?? [],
          })),
          partySize: typedData.party_size,
          adultPartySize: typedData.adult_party_size ?? 0,
          childPartySize: typedData.child_party_size ?? 0,
          smartPayment: typedData.smart_payment,
          memo: typedData.memo,
          source: typedData.source ?? 'site_controller',
          sourceBy: typedData.source_by ?? 'auto',
          reservationCourses: typedData.reservation_courses ?? null,
          reservationTags: typedData.reservation_tags ?? null,
          isWalkin: typedData.kind === 'walkin',
          isVip,
          needAttention,
          hasPreviousTableOrder: typedData.has_previous_table_order ?? false,
          reservation_site_controller_parsed_course:
            isSiteControllerReservation(typedData)
              ? typedData.reservation_site_controller_parsed_course
              : undefined,
          tableSeatId: tableSeat?.id ?? '',
          tableSeatName: tableSeat?.name ?? '',
          maxPartySize: tableSeat?.maxPartySize ?? 0,
          minPartySize: tableSeat?.minPartySize ?? 0,
          isNoSeatAssigned: tableSeat?.isNoSeatAssigned ?? false,
          displayOrder: 2,
        }
      })
    }
    case 'request': {
      const typedData = data as RestaurantRequestReservation
      return [
        {
          id: String(typedData.id),
          type: 'request',
          startAt: typedData.start_at,
          endAt: typedData.end_at,
          kind: 'request',
          customers: [
            {
              reservation_first_name: typedData.name,
              reservation_last_name: '',
              last_name: '',
              first_name: '',
              profiles: typedData.customer?.profiles ?? [],
            },
          ],
          partySize: typedData.party_size,
          adultPartySize: typedData.adult_party_size,
          childPartySize: typedData.child_party_size,
          smartPayment: null,
          memo: typedData.memo ?? null,
          source: 'site_controller',
          sourceBy: 'auto',
          reservationCourses: null,
          reservationTags: null,
          isWalkin: false,
          isVip: false,
          needAttention: false,
          hasPreviousTableOrder: false,

          tableSeatId: '',
          tableSeatName: '',
          maxPartySize: 0,
          minPartySize: 0,
          isNoSeatAssigned: true,
          displayOrder: 1,
          isChangeRequest:
            typedData.reservation?.reservation_change_request != null,
        },
      ]
    }

    case 'block': {
      const typedData =
        data as ListPeriodResponse_RestaurantReservationBlockPeriod
      if (typedData.startAt) {
        startDate = dayjs
          .unix(typedData.startAt.seconds)
          .format('YYYY-MM-DD HH:mm:ss')
      }
      if (typedData.endAt) {
        endDate = dayjs
          .unix(typedData.endAt.seconds)
          .format('YYYY-MM-DD HH:mm:ss')
      }

      // テーブル席がない場合
      if (typedData.tableSeats.length === 0) {
        return [
          {
            id: typedData.id,
            type: 'block',
            startAt: startDate,
            endAt: endDate,
            kind: 'block',
            customers: [],
            partySize: 0,
            adultPartySize: 0,
            childPartySize: 0,
            smartPayment: null,
            memo: null,
            source: '',
            sourceBy: '',
            reservationCourses: null,
            reservationTags: null,
            isWalkin: false,
            isVip: false,
            needAttention: false,
            hasPreviousTableOrder: false,

            tableSeatId: '',
            tableSeatName: '',
            maxPartySize: 0,
            minPartySize: 0,
            isNoSeatAssigned: true,
            displayOrder: 3,
            isRepeat: typedData.isRepeat ?? false,
          },
        ]
      }

      // テーブル席ごとにUnifiedReservationDataを作成
      return typedData.tableSeats.map((tableSeatItem) => {
        return {
          id: typedData.id,
          type: 'block',
          startAt: startDate,
          endAt: endDate,
          kind: 'block',
          customers: [],
          partySize: 0,
          adultPartySize: 0,
          childPartySize: 0,
          smartPayment: null,
          memo: null,
          source: '',
          sourceBy: '',
          reservationCourses: null,
          reservationTags: null,
          isWalkin: false,
          isVip: false,
          needAttention: false,
          hasPreviousTableOrder: false,

          tableSeatId: tableSeatItem?.id ?? '',
          tableSeatName: '', // nameプロパティは存在しないので空文字を設定
          maxPartySize: 0,
          minPartySize: 0,
          isNoSeatAssigned: false,
          displayOrder: 3,
          isRepeat: typedData.isRepeat ?? false,
        }
      })
    }

    default:
      throw new Error('Invalid reservation type')
  }
}

/**
 * 表示行チャンク分割
 *
 * @param data - 予約データ配列
 * @param tableSeats - テーブル席データ配列
 * @param chunkSize - 1チャンクの行数（デフォルト12行）
 * @param startHour
 * @param endHour
 * @param timeChunkSize
 */
export const createRowChunks = (
  data: UnifiedReservationData[],
  tableSeats: TableSeatView[],
  startHour: number,
  endHour: number,
  today: dayjs.Dayjs,
  chunkSize = DEFAULT_CHUNK_SIZE,
  timeChunkSize = 9 // 9時間分
): TimeChunk[] => {
  // 予約データの最大endHourを取得し、引数のendHourと比較して大きい方を採用
  const maxEndHour = endHour

  // 予約データをタイプごとに分類
  const noSeatReservations = data.filter((d) => d.type === 'no-seat')
  const requestReservations = data.filter((d) => d.type === 'request')
  const tableReservations = data.filter(
    (d) => d.type === 'reservation' || d.type === 'block'
  )

  // 特殊予約（座席指定なし・承認待ち予約）の数はチャンク内の有効テーブル席数に影響する
  // ただし、チャンクサイズ自体は固定で使用する
  const effectiveChunkSize = chunkSize

  // テーブル予約をtableSeatIdでグループ化
  const reservationGroups = new Map<string, UnifiedReservationData[]>()
  tableReservations.forEach((reservation) => {
    const key = reservation.tableSeatId
    if (!reservationGroups.has(key)) {
      reservationGroups.set(key, [])
    }
    reservationGroups.get(key)?.push(reservation)
  })

  const rows: ChunkedReservationRow[] = [
    // 座席指定なし予約を追加
    ...Array.from({ length: noSeatReservations.length }).map((_, i) => ({
      index: 0,
      tableSeat: createNoSeatAssignedRow(),
      reservations: [noSeatReservations[i]],
    })),
    // 承認待ち予約を追加
    ...Array.from({ length: requestReservations.length }).map((_, i) => ({
      index: 0,
      tableSeat: createRequestRow(),
      reservations: [requestReservations[i]],
    })),
    // テーブル席ごとに予約を追加
    ...Array.from({ length: tableSeats.length }).map((_, i) => ({
      index: 0,
      tableSeat: tableSeats[i],
      reservations: reservationGroups.get(tableSeats[i].id) ?? [],
    })),
  ]

  const timeChunks: TimeChunk[] = []

  let current = startHour
  while (current < maxEndHour) {
    const chunkEnd = Math.min(current + timeChunkSize, maxEndHour)

    const chunks: RowChunk[] = []
    for (let i = 0; i < rows.length; i += effectiveChunkSize) {
      const chunkedRows: ChunkedReservationRow[] = rows
        .slice(i, i + effectiveChunkSize)
        .map((row) => ({
          ...row,
          reservations: row.reservations.filter((reservation) => {
            // 時間と分を小数点に変換して詳細な時間を考慮する
            const startDateTime = dayjs(reservation.startAt)
            const endDateTime = dayjs(reservation.endAt)
            const startHour = startDateTime.hour() + startDateTime.minute() / 60
            const endHour = endDateTime.hour() + endDateTime.minute() / 60

            // 日付をまたぐ場合の調整
            const dayDiff = startDateTime.diff(today, 'day')
            const endDayDiff = endDateTime.diff(today, 'day')
            const adjustedStartHour = startHour + dayDiff * 24
            const adjustedEndHour = endHour + endDayDiff * 24

            return (
              // 予約開始時刻がチャンク内
              (adjustedStartHour >= current && adjustedStartHour < chunkEnd) ||
              // 予約終了時刻がチャンク内
              (adjustedEndHour > current && adjustedEndHour <= chunkEnd) ||
              // 予約が時間帯を跨ぐ
              (adjustedStartHour <= current && adjustedEndHour >= chunkEnd)
            )
          }),
        }))
        .map((row, index) => ({
          ...row,
          index,
        }))

      chunks.push({
        rows: chunkedRows,
        startIndex: i,
        endIndex: Math.min(i + effectiveChunkSize, rows.length),
      })
    }

    timeChunks.push({
      start: current,
      end: chunkEnd, // 常にchunkEndを使用
      chunkedRows: chunks,
    })

    current = chunkEnd
  }

  return timeChunks
}
