import React, { useCallback, useRef, useState } from 'react'

import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import {
  Dimensions,
  Modal,
  Pressable,
  View,
  Text,
  StyleProp,
  ViewStyle,
  StyleSheet,
} from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { ReservationStatus } from '@hello-ai/ar_shared/src/types/ForR/Reservation'
import {
  ALL_RESTAURANT_RESERVATION_VISIT_STATUS,
  RestaurantReservationVisitStatus,
} from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'

import { FakeInput } from '../../../Shared/FakeInput'

import { ReservationData } from './Content'

type VisitStatusTagMode = 'default' | 'form'

export type PopoverAlignType = 'left' | 'right'

export type VisitStatusChipProps = {
  visitStatus: NonNullable<ReservationData['visit_status']>
  reservationStatus: ReservationData['status']
  style?: StyleProp<ViewStyle>
  onUpdateVisitStatus?: (
    newVisitStatus: ReservationData['visit_status']
  ) => Promise<void> | void
  mode?: VisitStatusTagMode
  xOffset?: number
}

export function VisitStatusTag({
  visitStatus,
  reservationStatus,
  style,
  onUpdateVisitStatus,
  mode = 'default',
  xOffset,
}: VisitStatusChipProps) {
  const componentProps = calculateVisitStatusComponentProps(
    visitStatus,
    reservationStatus
  )
  const { width, sm } = useResponsive()
  const ref = useRef<View>(null)
  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [coordinates, setCoordinates] = useState<{
    x: number
    y: number
  } | null>(null)

  const [tooltipDimensions, setTooltipDimensions] = useState<{
    width: number
    height: number
  }>({
    // 初回は正確な値が取れないので、初期値を設定しておく
    width: width < sm ? 120 : 150,
    height: 160,
  })

  const newVisitStatusCandidates = getNewVisitStatusCandidates()

  const { backgroundColor, textColor, visitStatusLabel } = componentProps

  const measure = useCallback(() => {
    return new Promise<void>((resolve) => {
      ref.current?.measure((x, y, _width, height, pageX, pageY) => {
        setCoordinates(
          getCoordinates({
            x: pageX,
            y: pageY + height,
            width: tooltipDimensions.width,
            height: tooltipDimensions.height,
            isSp: width < sm,
            mode,
            xOffset,
          })
        )
        resolve()
      })
    })
  }, [
    tooltipDimensions.height,
    tooltipDimensions.width,
    width,
    sm,
    mode,
    xOffset,
  ])

  if (componentProps.cancelled) {
    return null
  }

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'center',
        },
        style,
      ]}
      ref={ref}
    >
      <TransformTouchableTag
        visitStatusLabel={visitStatusLabel}
        backgroundColor={backgroundColor}
        textColor={textColor}
        onPress={async () => {
          await measure()
          setIsTooltipVisible(true)
        }}
        mode={mode}
        style={{ width: '100%' }}
      />
      <Modal
        visible={isTooltipVisible}
        transparent
        onRequestClose={() => {
          setIsTooltipVisible(false)
        }}
      >
        <Pressable
          onPress={() => {
            setIsTooltipVisible(false)
          }}
          style={{ flex: 1 }}
        >
          <View
            style={[
              {
                position: 'absolute',
              },
              coordinates != null && {
                top: coordinates.y,
                left: coordinates.x,
              },
              coordinates == null && {
                display: 'none',
              },
            ]}
          >
            <View
              onLayout={(event) => {
                setTooltipDimensions({
                  width:
                    width < sm
                      ? event.nativeEvent.layout.width - 100
                      : event.nativeEvent.layout.width,
                  height: event.nativeEvent.layout.height,
                })
              }}
              style={{
                backgroundColor: 'white',
              }}
            >
              {/* 吹き出し部分 */}
              <View
                style={{
                  width: 0,
                  height: 0,
                  backgroundColor: 'transparent',
                  borderStyle: 'solid',
                  borderLeftWidth: 10,
                  borderRightWidth: 10,
                  borderBottomWidth: 14,
                  borderLeftColor: 'transparent',
                  borderRightColor: 'transparent',
                  borderBottomColor: 'white',
                  position: 'absolute',
                  top: -13,
                  left:
                    mode === 'default' && width < sm
                      ? `${xOffset !== undefined ? 39 : 63}%`
                      : '50%',
                  transform: [{ translateX: -10 }],
                  zIndex: 1,
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: -2,
                  },
                  shadowOpacity: 0.1,
                  shadowRadius: 2,
                  elevation: 2,
                }}
              />
              <View
                style={{
                  borderColor: Colors.border,
                  borderRadius: 8,
                  backgroundColor: 'white',
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.2,
                  shadowRadius: 4,
                  elevation: 1,
                }}
              >
                {newVisitStatusCandidates.map((item, index) => (
                  <TouchableOpacity
                    key={item.visitStatus}
                    onPress={async () => {
                      if (onUpdateVisitStatus) {
                        await onUpdateVisitStatus(item.visitStatus)
                      }
                      setIsTooltipVisible(false)
                    }}
                  >
                    <View
                      style={{
                        padding: 14,
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 28,
                      }}
                    >
                      <Tag
                        visitStatusLabel={item.visitStatusLabel}
                        backgroundColor={item.backgroundColor}
                        textColor={item.textColor}
                      />
                      {newVisitStatusCandidates[index].visitStatus ===
                        visitStatus && (
                        <FontAwesomeIcon
                          icon={faCheck}
                          size={16}
                          color={Colors.primary}
                        />
                      )}
                    </View>
                    {index < newVisitStatusCandidates.length - 1 && (
                      <View style={styles.separator} />
                    )}
                  </TouchableOpacity>
                ))}
              </View>
            </View>
          </View>
        </Pressable>
      </Modal>
    </View>
  )
}

type TransformTouchableTagProps = {
  onPress: () => void
  visitStatusLabel: string
  backgroundColor: string
  textColor: string
  mode: VisitStatusTagMode
  style?: StyleProp<ViewStyle>
}

/**
 * モードに応じて表示形式を切り替えるタグコンポーネント
 * @param props.onPress - タップ時のコールバック関数
 * @param props.visitStatusLabel - 来店ステータスのラベル
 * @param props.backgroundColor - タグの背景色
 * @param props.textColor - タグのテキスト色
 * @param props.mode - 表示モード。'form'の場合はフォームライク(セレクトボックス)、'default'の場合はシンプルなタグとして表示
 * @param props.style - コンポーネントのスタイル
 * @returns モードに応じて表示形式が変わるタグコンポーネント
 */
function TransformTouchableTag({
  onPress,
  visitStatusLabel,
  backgroundColor,
  textColor,
  mode,
  style,
}: TransformTouchableTagProps) {
  if (mode === 'form') {
    return (
      <FakeInput onPress={onPress} style={style} mode="select">
        <Tag
          visitStatusLabel={visitStatusLabel}
          backgroundColor={backgroundColor}
          textColor={textColor}
        />
      </FakeInput>
    )
  }

  return (
    <TouchableTag
      onPress={onPress}
      visitStatusLabel={visitStatusLabel}
      backgroundColor={backgroundColor}
      textColor={textColor}
    />
  )
}

type TouchableTagProps = TagProps & {
  onPress: () => void
}

function TouchableTag({
  onPress,
  visitStatusLabel,
  backgroundColor,
  textColor,
}: TouchableTagProps) {
  return (
    <TouchableOpacity onPress={onPress}>
      <Tag
        visitStatusLabel={visitStatusLabel}
        backgroundColor={backgroundColor}
        textColor={textColor}
      />
    </TouchableOpacity>
  )
}

type TagProps = {
  visitStatusLabel: string
  backgroundColor: string
  textColor: string
}

function Tag({ visitStatusLabel, backgroundColor, textColor }: TagProps) {
  return (
    <View
      style={[
        {
          backgroundColor,
          borderRadius: 4,
          width: 79,
          paddingVertical: 10,
          height: 36,
          alignItems: 'center',
        },
      ]}
    >
      <Text
        style={[
          {
            fontSize: 14,
            fontWeight: '300',
            color: textColor,
            lineHeight: 18,
          },
        ]}
      >
        {visitStatusLabel}
      </Text>
    </View>
  )
}

function getCoordinates({
  x,
  y,
  width,
  height,
  isSp,
  mode,
  xOffset,
}: {
  x: number
  y: number
  width: number
  height: number
  isSp: boolean
  mode: VisitStatusTagMode
  xOffset?: number
}) {
  const coordinatePadding = 10
  const dimensions = Dimensions.get('window')

  const calcXOffset = () => {
    if (mode === 'form') return 0
    const baseOffset = isSp ? -56 : -35
    return xOffset !== undefined ? baseOffset + xOffset : baseOffset
  }

  return {
    x: Math.min(
      Math.max(x, coordinatePadding) + calcXOffset(),
      dimensions.width - width - coordinatePadding
    ),
    y:
      Math.min(
        Math.max(y, coordinatePadding),
        dimensions.height - height - coordinatePadding
      ) + 18,
  }
}

const getNewVisitStatusCandidates = () =>
  ALL_RESTAURANT_RESERVATION_VISIT_STATUS.map((item) => ({
    visitStatus: item,
    ...calculateVisitStatusComponentProps(item),
  })).filter((item) => !item.cancelled)

// ForRestaurantsNative:typecheckは、type guardを考慮していない模様
// そのため、cancelled: true側にもvisitStatusLabelのプロパティを明示的に宣言しないと
// typecheckでTS2339: Property 'visitStatusLabel' does not exist on type が発生する
export type VisitStatusComponentProps =
  | {
      visitStatusLabel: string
      backgroundColor: string
      textColor: string
      cancelled: false
    }
  | {
      visitStatusLabel: never
      backgroundColor: string
      textColor: string
      cancelled: true
    }

export function calculateVisitStatusComponentProps(
  visitStatus: RestaurantReservationVisitStatus,
  reservationStatus?: ReservationStatus
): VisitStatusComponentProps {
  if (visitStatus === 'no_show' || reservationStatus === 'canceled') {
    return {
      visitStatusLabel: '' as never,
      backgroundColor: Colors.caution,
      textColor: 'white',
      cancelled: true,
    }
  }

  switch (visitStatus) {
    case 'reserved':
      return {
        visitStatusLabel: t('予約完了'),
        backgroundColor: Colors.success,
        textColor: 'white',
        cancelled: false,
      }
    case 'some_visited':
      return {
        visitStatusLabel: t('一部来店'),
        backgroundColor: Colors.blue,
        textColor: 'white',
        cancelled: false,
      }
    case 'all_visited':
    case 'eaten':
      return {
        visitStatusLabel: t('ご来店済'),
        backgroundColor: Colors.accentBg40,
        textColor: Colors.black,
        cancelled: false,
      }
    case 'departed':
      return {
        visitStatusLabel: t('退店済'),
        backgroundColor: Colors.primary40,
        textColor: Colors.black,
        cancelled: false,
      }
    case 'paid':
      return {
        visitStatusLabel: t('お会計済'),
        backgroundColor: Colors.primary,
        textColor: 'white',
        cancelled: false,
      }
  }
}

const styles = StyleSheet.create({
  separator: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: Colors.border,
  },
})
