import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { View, ScrollView } from 'react-native'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import {
  Empty,
  AllTable,
  ReservationStatus,
} from '@hello-ai/for_r_app/src/components/Restaurant/RequestReservation/ListView'
import type { PrevStatus } from '@hello-ai/for_r_app/src/components/Restaurant/RequestReservation/ListView/ReservationStatus'
import Loading from '@hello-ai/for_r_app/src/components/Shared/Loading'
import { Pagination } from '@hello-ai/for_r_app/src/components/Shared/Pagination'
import { useAllRequestReservations } from '@hello-ai/for_r_app/src/models/RestaurantRequestReservation'

import { useNavigate } from 'modules/router/useNavigate'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function RequestReservationsPage() {
  const navigate = useNavigate()
  const restaurantId = useRestaurantId()
  const { width, sm } = useResponsive()
  const [page, setPage] = useState(1)
  const { data } = useAllRequestReservations(restaurantId, { page })
  const [searchParams] = useSearchParams()

  // クエリパラメータの取得
  const prev = (searchParams.get('prev') ?? null) as PrevStatus

  return (
    <ScrollView
      style={{ flex: 1, backgroundColor: Colors.bgBlack }}
      contentContainerStyle={[
        {
          paddingHorizontal: width < sm ? 16 : 24,
          paddingBottom: 120,
        },
        width < sm && {
          paddingBottom: 80,
        },
      ]}
    >
      <ReservationStatus restaurantId={restaurantId} prev={prev} />
      <View
        style={[
          {
            marginTop: 24,
            backgroundColor: Colors.white,
            borderRadius: 4,
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.1,
            shadowRadius: 4,
            elevation: 2,
          },
        ]}
      >
        <AllTable
          requestReservations={data?.data}
          onPressRequestRestaurantReservation={(requestReservation) => {
            if (requestReservation.reservation?.reservation_change_request) {
              navigate(
                `/restaurants/${restaurantId}/reservations/change_requests/${requestReservation.reservation.uuid}`
              )
            } else {
              navigate(
                `/restaurants/${restaurantId}/reservations/requests/${requestReservation.id}`
              )
            }
          }}
          onPressCustomer={(id) => {
            navigate(`/restaurants/${restaurantId}/customers/${id}`)
          }}
        />
        {data?.data != null && data.data.length === 0 && (
          <Empty title={t('予約がありません')} />
        )}
        {data === undefined && (
          <View
            style={{
              paddingVertical: 24,
            }}
          >
            <Loading />
          </View>
        )}
        <View
          style={[
            {
              paddingVertical: 24,
              marginBottom: 16,
            },
            width >= sm
              ? {
                  borderTopWidth: 0.5,
                  borderColor: Colors.border,
                }
              : {
                  backgroundColor: Colors.white,
                },
          ]}
        >
          {data?.headerData != null && (
            <Pagination
              currentPage={data.headerData.currentPage}
              totalPage={data.headerData.totalPages}
              setPage={setPage}
              style={{ paddingHorizontal: 16, justifyContent: 'center' }}
            />
          )}
        </View>
      </View>
    </ScrollView>
  )
}
