import { RestaurantReservationBlockClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation_block/restaurant_reservation_block_service.client'

import { createRpcService } from '../modules/rpc'
import { mutate, swrKey } from '../modules/swr'

export * from '@hello-ai/ar_shared/src/types/ForR/Owner'

// 元のサービスを取得
const originalService = createRpcService(RestaurantReservationBlockClient)

// キャッシュを更新する create メソッドをラップしたサービスを作成
export const restaurantReservationBlockService = {
  ...originalService,
  create: async (
    token: string | null,
    params: Parameters<typeof originalService.create>[1]
  ) => {
    const result = await originalService.create(token, params)

    // 作成が成功した場合、関連するリスト取得キャッシュを更新する
    if (result.response) {
      // 印刷ボタンが使用するSWRキャッシュも更新
      await mutate(
        swrKey(token, '/restaurant_reservation_blocks', {
          restaurantId: params.restaurantId,
          startDate: params.startDate,
          endDate: params.endDate,
        })
      )
    }

    return result
  },
}
