import React, { useMemo, useRef } from 'react'

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons/faTrashCan'
import * as Linking from 'expo-linking'
import produce from 'immer'
import { range } from 'lodash'
import { Platform, TextInput as RNTextInput, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import {
  Alert,
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  Checkbox,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import { LegacyButton } from '@hello-ai/ar_shared/src/components/LegacyButton'
import {
  Radio,
  RadioGroup,
  RadioLabel,
} from '@hello-ai/ar_shared/src/components/Radio'
import {
  SelectedImage,
  SelectImage,
} from '@hello-ai/ar_shared/src/components/SelectImage'
import {
  SelectInput,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { SupportedLocale } from '@hello-ai/ar_shared/src/modules/locale'
import { parseNumber } from '@hello-ai/ar_shared/src/modules/number'
import { getFormatTime, toSeconds } from '@hello-ai/ar_shared/src/modules/time'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import { TableMenu as TableMenuModel } from '@hello-ai/ar_shared/src/types/ForR/TableMenu'
import { CreateRequest } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_course_question/restaurant_course_question_service'

import { useRestaurant } from '../../models/Restaurant'
import {
  CreateParams,
  RestaurantCourse as RestaurantCourseModel,
  UpdateParams,
} from '../../models/RestaurantCourse'
import { RestaurantCourseQuestionModal } from '../RestaurantCourseQuestions'
import { displayToastError } from '../Shared/Toast'
import {
  confirmSaveIncompleteChangesAsync,
  getTranslationLocaleLabel,
  hasIncompleteChanges,
  TranslationFormItem,
  TranslationFormList,
} from '../Translation'

type FormCancelPolicy = {
  cancelPercent: string
  cancelTime: number
} & (
  | {
      id?: undefined
      Destroy: false
    }
  | {
      id: string
      Destroy: boolean
    }
)

const parseSeconds = (seconds: number) => {
  const hour = Math.floor(seconds / 3600)
  const minute = Math.floor((seconds % 3600) / 60)
  return {
    hour,
    minute,
  }
}

const UNTIL_DAY_BEFORE_ITEMS: Array<SelectItem<number | undefined>> = [
  {
    label: t('日前'),
    value: undefined,
  },
  ...range(0, 180 + 1).map((value) => {
    return value === 0
      ? { label: t('当日'), value }
      : value === 1
        ? { label: t('前日'), value }
        : {
            label: t('{{text}}日前', {
              text: value,
            }),
            value,
          }
  }),
]

function getTimeBeforeLabel(hours: number, mins: number) {
  let str = ''

  if (hours > 0) {
    str += t('{{text}}時間', {
      text: hours,
    })
  }
  if (mins > 0 || (hours === 0 && mins === 0)) {
    str += t('{{text}}分', {
      text: mins,
    })
  }
  str += t('前')
  return str
}

const UNTIL_TIME_BEFORE_ITEMS: Array<SelectItem<number | undefined>> = [
  {
    label: t('時間前'),
    value: undefined,
  },
  ...range(0, 23 + 1).flatMap((hours) =>
    range(0, 60, 15).map((mins) => ({
      label: getTimeBeforeLabel(hours, mins),
      value: toSeconds(hours, mins),
    }))
  ),
]

const UNTIL_SPECIFIC_TIME_ITEMS: Array<SelectItem<number | undefined>> = [
  {
    label: t('時'),
    value: undefined,
  },
  ...range(0, 23 + 1).flatMap((hours) =>
    range(0, 60, 15).map((mins) => ({
      label: getFormatTime(toSeconds(hours, mins)),
      value: toSeconds(hours, mins),
    }))
  ),
]

type RestaurantCourseFormProps = {
  restaurantId: Restaurant['id']
  restaurantCourseId: RestaurantCourseModel['id'] | undefined
  restaurantCourse: RestaurantCourseModel | undefined
  tableMenus: TableMenuModel[]
  restaurantCourseQuestions: RestaurantCourseModel['questions'] | undefined
  image: RestaurantCourseModel['image_urls'] | undefined
  onCreate: (params: CreateParams) => Promise<void>
  onCreateError: (errors: string[]) => void
  onUpdate: (params: UpdateParams) => Promise<void>
  onUpdateError: (errors: string[]) => void
  onArchive: () => void
  sortedLocales: SupportedLocale[]
  createTranslation: (params: {
    text: string
    source_lang: string
    target_langs: string[]
  }) => Promise<{
    data?: {
      translations: Record<SupportedLocale, string>
    }
    error?: unknown
  }>
  formatCurrencyInput?: (value?: string, useAbsoluteValue?: boolean) => string
  onQuestionCreated: (question: CreateRequest) => Promise<boolean>
}

type LocaleValue = {
  [key in SupportedLocale]: string
}

const formSpace = 40
const cancelPolicyTimeItems = [
  {
    label: t('時間'),
    value: 0,
  },
  ...range(1, 23 + 1).map((hours) => ({
    label: t('{{text}}時間前', {
      text: hours,
    }),
    value: toSeconds(hours, 0),
  })),
  ...range(1, 30 + 1).map((days) => ({
    label: t('{{hours}}時間前（{{days}}日前）', {
      hours: 24 * days,
      days,
    }),
    value: toSeconds(days * 24, 0),
  })),
]

export function validate({
  cancelPolicies,
  price,
  minPartySize,
  maxPartySize,
  stockSetting,
}: {
  cancelPolicies: FormCancelPolicy[]
  price: number
  minPartySize: number | null
  maxPartySize: number | null
  stockSetting: UpdateParams['restaurant_course_stock_setting']
}) {
  const errors: string[] = []

  for (const [index, cancelPolicy] of cancelPolicies.entries()) {
    if (cancelPolicy.Destroy) continue
    if (cancelPolicy.cancelTime === 0) {
      errors.push(
        t('キャンセル料 {{text}}の時間を選択してください', {
          text: index + 1,
        })
      )
      break
    }
    if (cancelPolicy.cancelPercent === '') {
      errors.push(
        t('キャンセル料 {{text}}のパーセンテージを入力してください', {
          text: index + 1,
        })
      )
      break
    }
  }

  if (isNaN(price)) {
    errors.push(t('料金を半角数字で入力してください'))
  }

  if (minPartySize == null && maxPartySize != null) {
    errors.push(t('利用可能な最小人数を入力してください'))
  }
  if (maxPartySize == null && minPartySize != null) {
    errors.push(t('利用可能な最大人数を入力してください'))
  }

  if (minPartySize != null && isNaN(minPartySize)) {
    errors.push(t('利用可能な最小人数を半角数字で入力してください'))
  }
  if (maxPartySize != null && isNaN(maxPartySize)) {
    errors.push(t('利用可能な最大人数を半角数字で入力してください'))
  }

  if (
    minPartySize != null &&
    maxPartySize != null &&
    minPartySize > maxPartySize
  ) {
    errors.push(t('利用可能な最小人数は最大人数以下である必要があります'))
  }
  if (minPartySize != null && maxPartySize != null && minPartySize <= 0) {
    errors.push(t('利用可能な最大人数は1人以上である必要があります'))
  }
  if (minPartySize != null && maxPartySize != null && maxPartySize <= 0) {
    errors.push(t('利用可能な最大人数は1人以上である必要があります'))
  }

  if (
    stockSetting != null &&
    isNaN(stockSetting.quantity) &&
    stockSetting._destroy === false
  ) {
    errors.push(t('在庫数を半角数字で入力してください'))
  }

  return { errors }
}

const getStockType = (
  stockType: NonNullable<
    RestaurantCourseModel['restaurant_course_stock_setting']
  >['stock_type']
): 0 => {
  switch (stockType) {
    case 'daily':
      return 0
  }
}

type RestaurantCourseReservationPeriodPolicy = NonNullable<
  CreateParams['restaurant_course_reservation_period_policy']
>
type SinceTypeKey = NonNullable<
  RestaurantCourseReservationPeriodPolicy['since_type']
>
const SINCE_TYPE: {
  [K in SinceTypeKey]: K
} = {
  each_day: 'each_day',
  bulk: 'bulk',
  business_time_based: 'business_time_based',
}
type UntilTypeKey = NonNullable<
  RestaurantCourseReservationPeriodPolicy['until_type']
>
const UNTIL_TYPE: {
  [K in UntilTypeKey]: K
} = {
  offset: 'offset',
  specific: 'specific',
  business_time_based: 'business_time_based',
}

const INITIAL_RESTAURANT_COURSE_RESERVATION_PERIOD_POLICY: RestaurantCourseReservationPeriodPolicy =
  {
    since_day_before: 0,
    since_month_before: 0,
    since_specific_day: 0,
    since_specific_time: 0,
    since_type: SINCE_TYPE.business_time_based,
    until_enabled: false,
    until_day_before: 0,
    until_specific_time: 0,
    until_time_before: 0,
    until_type: UNTIL_TYPE.business_time_based,
  }

function CardWrap({
  children,
  title,
}: {
  children: React.ReactNode
  title: string
}) {
  return (
    <View style={{ gap: 16 }}>
      <Text style={{ fontSize: 22, fontWeight: '600' }}>{title}</Text>
      {children}
    </View>
  )
}

// キャンセルボタンとお客様への質問ボタンで共通のスタイルを定義
const addButtonStyle = {
  textStyle: {
    color: Colors.primary,
    fontWeight: 'normal' as const,
  },
  buttonStyle: {
    height: 52,
    borderColor: Colors.primary,
    backgroundColor: 'white',
  },
}

export function RestaurantCourseForm({
  restaurantId,
  restaurantCourseId,
  restaurantCourse,
  tableMenus,
  restaurantCourseQuestions,
  image,
  onCreate,
  onCreateError,
  onUpdate,
  onUpdateError,
  onArchive,
  sortedLocales,
  createTranslation,
  formatCurrencyInput,
  onQuestionCreated,
}: RestaurantCourseFormProps) {
  const { width, sm } = useResponsive()
  const insets = useSafeAreaInsets()
  const [isUpdating, setIsUpdating] = useFormState(false)
  const alertRef = useRef<AlertMethods>(null)
  const { data: restaurant } = useRestaurant(restaurantId)
  const isStandardOrHigher =
    // TODO: basic への移行が完了したら削除
    restaurant?.reservation_book_plan_type === 'professional' ||
    // TODO: basic への移行が完了したら削除
    restaurant?.reservation_book_plan_type === 'standard' ||
    restaurant?.reservation_book_plan_type === 'basic' ||
    // TODO: new_free への移行が完了したら削除
    restaurant?.reservation_book_plan_type === 'special' ||
    restaurant?.reservation_book_plan_type === 'new_free'

  const defaultTitle = useMemo(
    () => ({
      ja: restaurantCourse?.title_i18n_ja ?? '',
      en: restaurantCourse?.title_i18n_en ?? '',
      ko: restaurantCourse?.title_i18n_ko ?? '',
      'zh-CN': restaurantCourse?.title_i18n_zh_cn ?? '',
      'zh-TW': restaurantCourse?.title_i18n_zh_tw ?? '',
      'zh-HK': restaurantCourse?.title_i18n_zh_hk ?? '',
      th: restaurantCourse?.title_i18n_th ?? '',
    }),
    [
      restaurantCourse?.title_i18n_en,
      restaurantCourse?.title_i18n_ja,
      restaurantCourse?.title_i18n_ko,
      restaurantCourse?.title_i18n_th,
      restaurantCourse?.title_i18n_zh_cn,
      restaurantCourse?.title_i18n_zh_hk,
      restaurantCourse?.title_i18n_zh_tw,
    ]
  )

  const [title, setTitle] = useFormState<LocaleValue>(defaultTitle)

  const defaultContent = useMemo(
    () => ({
      ja: restaurantCourse?.content_i18n_ja ?? '',
      en: restaurantCourse?.content_i18n_en ?? '',
      ko: restaurantCourse?.content_i18n_ko ?? '',
      'zh-CN': restaurantCourse?.content_i18n_zh_cn ?? '',
      'zh-TW': restaurantCourse?.content_i18n_zh_tw ?? '',
      'zh-HK': restaurantCourse?.content_i18n_zh_hk ?? '',
      th: restaurantCourse?.content_i18n_th ?? '',
    }),
    [
      restaurantCourse?.content_i18n_en,
      restaurantCourse?.content_i18n_ja,
      restaurantCourse?.content_i18n_ko,
      restaurantCourse?.content_i18n_th,
      restaurantCourse?.content_i18n_zh_cn,
      restaurantCourse?.content_i18n_zh_hk,
      restaurantCourse?.content_i18n_zh_tw,
    ]
  )

  const [content, setContent] = useFormState<LocaleValue>(defaultContent)
  const contentRef = useRef<RNTextInput>(null)

  const [price, setPrice] = useFormState(
    restaurantCourse?.price.toString() ?? ''
  )
  const [taxIncluded, setTaxIncluded] = useFormState(
    restaurantCourse?.tax_included ?? true
  )
  const [taxType, setTaxType] = useFormState(
    restaurantCourse?.tax_type ?? 'default'
  )
  const [selectedTableMenuIds, setSelectedTableMenuIds] = useFormState(
    restaurantCourse?.table_menus.map((tableMenu) => tableMenu.id) ?? []
  )
  const [selectedQuestionIds, setSelectedQuestionIds] = useFormState<string[]>(
    restaurantCourse?.questions?.map((question) => question.id) ?? []
  )
  const [selectedImage, setSelectedImage] = useFormState<string | null>(null)
  const [removeImage, setRemoveImage] = useFormState(false)

  const [cancelPolicies, setCancelPolicies] = useFormState<FormCancelPolicy[]>(
    restaurantCourse?.restaurant_course_cancel_policies?.map(
      (cancelPolicy) => ({
        id: cancelPolicy.id,
        cancelPercent: `${cancelPolicy.cancel_percent}`,
        cancelTime: cancelPolicy.cancel_time,
        Destroy: false,
      })
    ) ?? []
  )

  const [stayingTimeSec, setStayingTimeSec] = useFormState(
    restaurantCourse?.staying_time ?? null
  )
  const previousStayingTimeSec = useRef<number | null>(
    restaurantCourse?.staying_time ?? null
  )
  const [customerRegionRestrictionType, setCustomerRegionRestrictionType] =
    useFormState(
      restaurantCourse?.customer_region_restriction_type ?? 'no_restriction'
    )
  const [minPartySize, setMinPartySize] = useFormState(
    restaurantCourse?.min_party_size != null
      ? String(restaurantCourse.min_party_size)
      : null
  )
  const [maxPartySize, setMaxPartySize] = useFormState(
    restaurantCourse?.max_party_size != null
      ? String(restaurantCourse.max_party_size)
      : null
  )
  const [isPartySizeSettingEnabled, setIsPartySizeSettingEnabled] =
    useFormState(
      restaurantCourse?.max_party_size != null &&
        restaurantCourse?.min_party_size != null
    )
  const [stockSetting, setStockSetting] = useFormState<{
    id: string | null
    quantity: string | null
    stock_type: 'daily'
    _destroy: boolean
  } | null>(
    restaurantCourse?.restaurant_course_stock_setting != null
      ? {
          ...restaurantCourse.restaurant_course_stock_setting,
          quantity:
            restaurantCourse.restaurant_course_stock_setting.quantity.toString(),
          _destroy: false,
        }
      : null
  )

  const [
    restaurantCourseReservationPeriodPolicy,
    setRestaurantCourseReservationPeriodPolicy,
  ] = useFormState(
    restaurantCourse?.restaurant_course_reservation_period_policy ??
      INITIAL_RESTAURANT_COURSE_RESERVATION_PERIOD_POLICY
  )

  // 質問作成モーダル表示の状態管理
  const [isQuestionModalVisible, setIsQuestionModalVisible] =
    useFormState(false)

  const onChangeSelectedQuestionId = (questionId: string, checked: boolean) => {
    if (checked) {
      if (!selectedQuestionIds.includes(questionId)) {
        setSelectedQuestionIds([...selectedQuestionIds, questionId])
      }
    } else {
      setSelectedQuestionIds(
        selectedQuestionIds.filter((id) => id !== questionId)
      )
    }
  }

  const onChangeSelectedTableMenuIds = (
    tableMenuId: string,
    value: boolean
  ) => {
    if (value) {
      if (!selectedTableMenuIds.includes(tableMenuId)) {
        setSelectedTableMenuIds([...selectedTableMenuIds, tableMenuId])
      }
    } else {
      setSelectedTableMenuIds(
        selectedTableMenuIds.filter(
          (selectedTableMenuId) => selectedTableMenuId !== tableMenuId
        )
      )
    }
  }

  const onChangeCancelPolicyCancelTime = (
    index: number,
    value: RestaurantCourseModel['restaurant_course_cancel_policies'][number]['cancel_time']
  ) => {
    setCancelPolicies((cancelPolicies) =>
      produce(cancelPolicies, (draft) => {
        draft[index].cancelTime = value
      })
    )
  }

  const onChangeCancelPolicyCancelPercent = (index: number, value: string) => {
    setCancelPolicies((cancelPolicies) =>
      produce(cancelPolicies, (draft) => {
        draft[index].cancelPercent = value
      })
    )
  }

  const onDestroyCancelPolicy = (index: number) => {
    setCancelPolicies((cancelPolicies) =>
      produce(cancelPolicies, (draft) => {
        if (draft[index].id === undefined) {
          draft.splice(index, 1)
        } else {
          draft[index].Destroy = true
        }
      })
    )
  }

  const addCancelPolicyForm = () => {
    setCancelPolicies((cancelPolicies) =>
      produce(cancelPolicies, (draft) => {
        draft.push({
          cancelPercent: '',
          cancelTime: 0,
          Destroy: false,
        })
      })
    )
  }

  const onCreate_ = async () => {
    setIsUpdating(true)
    const restaurantCourseCancelPolicies: CreateParams['restaurant_course_cancel_policies'] =
      cancelPolicies
        .filter(({ Destroy }) => !Destroy)
        .map(({ id, cancelTime, cancelPercent, Destroy }) => ({
          id: id ?? null,
          cancel_percent: Number(cancelPercent),
          cancel_amount: 0,
          cancel_time: cancelTime,
          policy_type: 'type_percent',
          _destroy: Destroy,
        }))

    const parsedPrice = parseFloat(price)
    const parsedMinPartySize =
      minPartySize != null ? Number(minPartySize) : null
    const parsedMaxPartySize =
      maxPartySize != null ? Number(maxPartySize) : null
    const stock: UpdateParams['restaurant_course_stock_setting'] =
      stockSetting != null
        ? {
            ...stockSetting,
            quantity: parseInt(stockSetting.quantity ?? '', 10),
            stock_type: getStockType(stockSetting.stock_type),
          }
        : null

    const { errors } = validate({
      cancelPolicies,
      price: parsedPrice,
      minPartySize: parsedMinPartySize,
      maxPartySize: parsedMaxPartySize,
      stockSetting: stock,
    })

    if (errors.length > 0) {
      setIsUpdating(false)
      return onCreateError(errors)
    }

    const params: CreateParams = {
      restaurant_id: restaurantId,
      title: title.ja,
      title_i18n_ja: title.ja,
      title_i18n_en: title.en,
      title_i18n_ko: title.ko,
      title_i18n_zh_cn: title['zh-CN'],
      title_i18n_zh_hk: title['zh-HK'],
      title_i18n_zh_tw: title['zh-TW'],
      title_i18n_th: title.th,
      content: content.ja,
      content_i18n_ja: content.ja,
      content_i18n_en: content.en,
      content_i18n_ko: content.ko,
      content_i18n_zh_cn: content['zh-CN'],
      content_i18n_zh_hk: content['zh-HK'],
      content_i18n_zh_tw: content['zh-TW'],
      content_i18n_th: content.th,
      price: parsedPrice,
      tax_included: taxIncluded,
      tax_type: taxType === 'default' ? 0 : 1,
      table_menu_ids: selectedTableMenuIds,
      questions: [],
      question_ids: selectedQuestionIds,
      restaurant_course_cancel_policies: restaurantCourseCancelPolicies,
      image: selectedImage ?? '',
      translation_mode: 'for_r_create',
      customer_region_restriction_type: customerRegionRestrictionType,
      restaurant_course_reservation_period_policy:
        restaurantCourseReservationPeriodPolicy,
      staying_time: null,
      max_party_size: parsedMaxPartySize,
      min_party_size: parsedMinPartySize,
      restaurant_course_stock_setting: stock,
    }
    return onCreate(params).finally(() => setIsUpdating(false))
  }

  const onUpdate_ = async () => {
    if (restaurantCourseId === undefined) return
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })
    setIsUpdating(true)

    const restaurantCourseCancelPolicies: UpdateParams['restaurant_course_cancel_policies'] =
      cancelPolicies.map(({ id, Destroy, cancelPercent, cancelTime }) => ({
        id: id ?? null,
        _destroy: Destroy,
        cancel_amount: 0,
        cancel_percent: Number(cancelPercent),
        policy_type: 'type_percent',
        cancel_time: cancelTime,
      }))
    const parsedPrice = parseFloat(price)
    const parsedMinPartySize =
      minPartySize != null ? parseNumber(minPartySize) : null
    const parsedMaxPartySize =
      maxPartySize != null ? parseNumber(maxPartySize) : null

    const stock: UpdateParams['restaurant_course_stock_setting'] =
      stockSetting != null
        ? {
            ...stockSetting,
            quantity: parseNumber(stockSetting.quantity ?? ''),
            stock_type: getStockType(stockSetting.stock_type),
          }
        : null

    const { errors } = validate({
      cancelPolicies,
      price: parsedPrice,
      minPartySize: parsedMinPartySize,
      maxPartySize: parsedMaxPartySize,
      stockSetting: stock,
    })

    if (errors.length > 0) {
      setIsUpdating(false)
      return onUpdateError(errors)
    }

    const params: UpdateParams = {
      restaurant_id: restaurantId,
      id: restaurantCourseId,
      title: title.ja,
      title_i18n_ja: title.ja,
      title_i18n_en: title.en,
      title_i18n_ko: title.ko,
      title_i18n_zh_cn: title['zh-CN'],
      title_i18n_zh_hk: title['zh-HK'],
      title_i18n_zh_tw: title['zh-TW'],
      title_i18n_th: title.th,
      content: content.ja,
      content_i18n_ja: content.ja,
      content_i18n_en: content.en,
      content_i18n_ko: content.ko,
      content_i18n_zh_cn: content['zh-CN'],
      content_i18n_zh_hk: content['zh-HK'],
      content_i18n_zh_tw: content['zh-TW'],
      content_i18n_th: content.th,
      price: parsedPrice,
      tax_included: taxIncluded,
      tax_type: taxType === 'default' ? 0 : 1,
      table_menu_ids: selectedTableMenuIds,
      questions: [],
      question_ids: selectedQuestionIds,
      restaurant_course_cancel_policies: restaurantCourseCancelPolicies,
      image: selectedImage ?? '',
      _destroy: removeImage,
      translation_mode: 'for_r_update',
      staying_time: stayingTimeSec,
      customer_region_restriction_type: customerRegionRestrictionType,
      price_str: restaurantCourse?.price_str ?? '',
      restaurant_course_reservation_period_policy:
        restaurantCourseReservationPeriodPolicy,
      max_party_size: parsedMaxPartySize,
      min_party_size: parsedMinPartySize,
      restaurant_course_stock_setting: stock,
    }

    if (
      hasIncompleteChanges(title, defaultTitle) ||
      hasIncompleteChanges(content, defaultContent)
    ) {
      if (!(await confirmSaveIncompleteChangesAsync(alert))) {
        setIsUpdating(false)
        return
      }
    }

    return onUpdate(params).finally(() => setIsUpdating(false))
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: Platform.OS === 'web' ? Colors.bgBlack : 'transparent',
      }}
    >
      <KeyboardAwareScrollView
        contentContainerStyle={
          width < sm
            ? {
                paddingVertical: 24,
                paddingHorizontal: 24,
              }
            : {
                paddingVertical: 48,
                paddingHorizontal: 32,
              }
        }
        enableResetScrollToCoords={false}
      >
        <View style={{ gap: 32 }}>
          <Text style={{ fontSize: 14, color: Colors.black60 }}>
            {t(
              '予約サービスAutoReserveで使用します。セルフオーダーには反映されません。\n更新内容は、まだ予約されていないコースのみ適用されます。予約済みのコースには反映されません。'
            )}
          </Text>

          <CardWrap title={t('基本情報')}>
            <ShadowBox>
              <TranslationFormList
                formLabel={<FormLabel value={t('タイトル')} required />}
                showsFormDescription
                formDescriptionText={t(
                  '日本語の情報を入力すると、自動で翻訳して他の言語も登録します。ただし編集時には自動翻訳はされません。'
                )}
                sortedLocales={sortedLocales}
                values={title}
                onChangeValues={setTitle}
                createTranslation={(params) => createTranslation(params)}
              >
                {({ locale }) => (
                  <TranslationFormItem
                    key={locale}
                    formLabel={
                      <FormLabel value={getTranslationLocaleLabel(locale)} />
                    }
                  >
                    <TextInput
                      placeholder={
                        locale === 'ja' ? t('例：スペシャルランチコース') : ''
                      }
                      value={title[locale]}
                      onChangeText={(text) =>
                        setTitle((value) => ({
                          ...value,
                          [locale]: text,
                        }))
                      }
                      autoCapitalize="none"
                    />
                  </TranslationFormItem>
                )}
              </TranslationFormList>
              <Divider
                style={{
                  marginVertical: 24,
                }}
              />
              <TranslationFormList
                formLabel={<FormLabel value={t('説明')} />}
                showsFormDescription
                sortedLocales={sortedLocales}
                formDescriptionText={t(
                  '日本語の情報を入力すると、自動で翻訳して他の言語も登録します。ただし編集時には自動翻訳はされません。'
                )}
                values={content}
                onChangeValues={setContent}
                createTranslation={(params) => createTranslation(params)}
              >
                {({ locale }) => (
                  <TranslationFormItem
                    key={locale}
                    formLabel={
                      <FormLabel value={getTranslationLocaleLabel(locale)} />
                    }
                  >
                    <TextInput
                      ref={locale === sortedLocales[0] ? contentRef : undefined}
                      multiline
                      scrollEnabled={false}
                      placeholder={
                        locale === 'ja'
                          ? t('例：ランチ限定のスペシャルなコースです')
                          : ''
                      }
                      value={content[locale]}
                      onChangeText={(text) =>
                        setContent((value) => ({
                          ...value,
                          [locale]: text,
                        }))
                      }
                      autoCapitalize="none"
                    />
                  </TranslationFormItem>
                )}
              </TranslationFormList>
              <Divider style={{ marginVertical: 24 }} />
              <FormGroup
                mode={width < sm ? 'vertical' : 'inline'}
                formLabel={<FormLabel value={t('画像')} />}
              >
                <View
                  style={[
                    { alignSelf: 'flex-start', flex: 1 },
                    width < sm && { marginTop: 16 },
                  ]}
                >
                  <View>
                    {(image && !removeImage) ?? selectedImage != null ? (
                      <View style={{ width: 120, height: 120 }}>
                        <SelectedImage
                          source={{
                            uri: selectedImage ?? image?.sm,
                          }}
                          onRemovePress={() => {
                            setSelectedImage(null)
                            setRemoveImage(true)
                          }}
                        />
                      </View>
                    ) : (
                      <SelectImage
                        onSelectImage={(image) => {
                          setSelectedImage(image)
                          setRemoveImage(false)
                        }}
                      />
                    )}
                  </View>
                </View>
              </FormGroup>
              <Divider style={{ marginVertical: 24 }} />
              <FormGroup
                mode="vertical"
                formLabel={
                  <View style={{ gap: 8 }}>
                    <FormLabel value={t('メニューの選択')} />
                    <Text
                      style={{
                        color: Colors.black60,
                        fontSize: 14,
                        fontWeight: 300,
                      }}
                    >
                      {t(
                        'どのメニューにこのコースを含めるかを選択してください'
                      )}
                    </Text>
                  </View>
                }
              >
                <View style={{ flexDirection: 'column', gap: 16 }}>
                  {tableMenus != null &&
                    tableMenus.map((tableMenu) => {
                      return (
                        <View
                          key={tableMenu.id}
                          style={{
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <Checkbox
                            checked={selectedTableMenuIds.includes(
                              tableMenu.id
                            )}
                            onChange={(value) =>
                              onChangeSelectedTableMenuIds(tableMenu.id, value)
                            }
                            checkboxLabelContainerStyle={{
                              width: '100%',
                            }}
                            checkboxLabel={
                              <View style={{ marginLeft: 12 }}>
                                <CheckboxLabel value={tableMenu.name} />
                                <Text
                                  style={{
                                    fontSize: 14,
                                    fontWeight: 300,
                                    color: Colors.black60,
                                    width: '95%',
                                  }}
                                >
                                  {tableMenu.time_range_description}
                                </Text>
                              </View>
                            }
                          />
                        </View>
                      )
                    })}
                </View>
              </FormGroup>
            </ShadowBox>
          </CardWrap>

          <CardWrap title={t('価格')}>
            <ShadowBox>
              <FormGroup
                mode={width < sm ? 'vertical' : 'inline'}
                formLabel={
                  <View style={{ gap: 8 }}>
                    <FormLabel value={t('料金')} required />
                    <Text style={{ fontSize: 14, color: Colors.black60 }}>
                      {t('半角数字で入力してください')}
                    </Text>
                  </View>
                }
              >
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: Colors.field,
                    borderRadius: 8,
                  }}
                >
                  <View
                    style={{
                      borderRightWidth: 0.5,
                      borderRightColor: 'rgba(68, 68, 68, 0.16)',
                      paddingVertical: 12,
                    }}
                  >
                    <Text
                      style={{
                        paddingLeft: 16,
                        paddingRight: 16,
                        color: Colors.black60,
                        fontSize: 14,
                      }}
                    >
                      JPY
                    </Text>
                  </View>
                  <TextInput
                    keyboardType="numeric"
                    placeholder={t('例：2500')}
                    onChangeText={(text) =>
                      setPrice(
                        formatCurrencyInput ? formatCurrencyInput(text) : text
                      )
                    }
                    value={
                      formatCurrencyInput
                        ? formatCurrencyInput(price)
                        : `${price}`
                    }
                    style={{
                      flex: 1,
                      backgroundColor: 'transparent',
                    }}
                  />
                </View>
              </FormGroup>
              <FormGroup
                mode={width < sm ? 'vertical' : 'inline'}
                formLabel={<FormLabel value={t('税込・税抜設定')} required />}
                style={{ marginTop: formSpace }}
              >
                <RadioGroup
                  mode={'inline'}
                  value={taxIncluded}
                  onChange={(v) => setTaxIncluded(v)}
                  style={{
                    gap: width < sm ? 8 : 16,
                  }}
                  radioContainerStyle={{
                    marginTop: 0,
                    marginLeft: 0,
                  }}
                >
                  <Radio
                    value
                    style={{ flex: 1 }}
                    radioLabel={
                      <RadioLabel
                        style={{ paddingVertical: 8 }}
                        value={t('税込')}
                      />
                    }
                  />
                  <Radio
                    value={false}
                    style={{ flex: 1 }}
                    radioLabel={
                      <RadioLabel
                        style={{ paddingVertical: 8 }}
                        value={t('税抜')}
                      />
                    }
                  />
                </RadioGroup>
              </FormGroup>
              <FormGroup
                mode={width < sm ? 'vertical' : 'inline'}
                formLabel={<FormLabel value={t('税種別')} required />}
                style={{ marginTop: formSpace }}
              >
                <RadioGroup
                  mode={width < sm ? 'vertical' : 'inline'}
                  value={taxType}
                  onChange={(v) => setTaxType(v)}
                  style={{
                    gap: width < sm ? 8 : 16,
                  }}
                  radioContainerStyle={{
                    marginTop: 0,
                    marginLeft: 0,
                  }}
                >
                  <Radio
                    value={'default'}
                    style={{ flex: 1 }}
                    radioLabel={
                      <RadioLabel
                        style={{ paddingVertical: 8 }}
                        value={t('標準税率(10%)')}
                      />
                    }
                  />
                  <Radio
                    value={'reduced'}
                    style={{ flex: 1 }}
                    radioLabel={
                      <RadioLabel
                        style={{ paddingVertical: 8 }}
                        value={t('軽減税率(8%)')}
                      />
                    }
                  />
                </RadioGroup>
              </FormGroup>
            </ShadowBox>
          </CardWrap>

          <CardWrap title={t('予約')}>
            <ShadowBox style={{ padding: 24 }}>
              <Text
                style={{
                  fontWeight: '600',
                  lineHeight: 27,
                }}
              >
                {t('予約受付ルール')}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: '300',
                  lineHeight: 21,
                  marginBottom: 24,
                }}
              >
                {t(
                  '予約可能時間帯/予約受付開始/予約受付終了/お客様の滞在時間を個別に設定しない場合は、営業時間の設定が反映されます。'
                )}
              </Text>
              <View
                style={{
                  rowGap: 24,
                }}
              >
                <FormGroup
                  mode={width < sm ? 'vertical' : 'inline-expanded'}
                  formLabel={
                    <FormLabel
                      labelStyle={{
                        paddingVertical: width < sm ? undefined : 16,
                        fontSize: 14,
                      }}
                      value={t('予約受付終了')}
                    />
                  }
                >
                  <RadioGroup
                    mode={width < sm ? 'vertical' : 'inline'}
                    value={restaurantCourseReservationPeriodPolicy?.until_type}
                    onChange={(value) => {
                      setRestaurantCourseReservationPeriodPolicy({
                        ...restaurantCourseReservationPeriodPolicy,
                        until_type: value,
                      })
                    }}
                    style={{
                      gap: width < sm ? 8 : 16,
                    }}
                    radioContainerStyle={{
                      marginTop: 0,
                      marginLeft: 0,
                    }}
                  >
                    {[
                      {
                        name: t('営業時間に従う'),
                        value: UNTIL_TYPE.business_time_based,
                      },
                      {
                        name: t('個別に設定（〜前）'),
                        value: UNTIL_TYPE.offset,
                      },
                      {
                        name: t('個別に設定(〜日前〜時)'),
                        value: UNTIL_TYPE.specific,
                      },
                    ].map((param, index) => (
                      <Radio
                        style={{ flex: 1 }}
                        key={`${index}`}
                        value={param.value}
                        radioLabel={
                          <RadioLabel
                            style={{
                              paddingVertical: 8,
                            }}
                            value={param.name}
                          />
                        }
                      />
                    ))}
                  </RadioGroup>
                  <View
                    style={{
                      flexDirection: 'row',
                      columnGap: width < sm ? 8 : 4,
                      alignItems: 'center',
                      marginTop: 8,
                      display:
                        restaurantCourseReservationPeriodPolicy?.until_type !==
                        'business_time_based'
                          ? 'flex'
                          : 'none',
                    }}
                  >
                    <SelectInput
                      style={{ height: 64 }}
                      disabled={
                        restaurantCourseReservationPeriodPolicy?.until_type ===
                        UNTIL_TYPE.business_time_based
                      }
                      selectedValue={
                        restaurantCourseReservationPeriodPolicy.until_type ===
                        UNTIL_TYPE.business_time_based
                          ? null
                          : restaurantCourseReservationPeriodPolicy.until_day_before
                      }
                      setValue={(value) => {
                        if (value == null) return
                        setRestaurantCourseReservationPeriodPolicy({
                          ...restaurantCourseReservationPeriodPolicy,
                          until_day_before: value,
                        })
                      }}
                      items={UNTIL_DAY_BEFORE_ITEMS}
                    />
                    <Text style={{ fontSize: 18, fontWeight: '300' }}>:</Text>
                    <SelectInput
                      style={{ height: 64 }}
                      disabled={
                        restaurantCourseReservationPeriodPolicy.until_type ===
                        UNTIL_TYPE.business_time_based
                      }
                      selectedValue={
                        restaurantCourseReservationPeriodPolicy.until_type ===
                        UNTIL_TYPE.business_time_based
                          ? null
                          : restaurantCourseReservationPeriodPolicy.until_type ===
                              UNTIL_TYPE.offset
                            ? restaurantCourseReservationPeriodPolicy.until_time_before
                            : restaurantCourseReservationPeriodPolicy.until_specific_time
                      }
                      setValue={(value) => {
                        if (value == null) return
                        if (
                          restaurantCourseReservationPeriodPolicy.until_type ===
                          UNTIL_TYPE.business_time_based
                        )
                          return
                        if (
                          restaurantCourseReservationPeriodPolicy.until_type ===
                          UNTIL_TYPE.offset
                        ) {
                          setRestaurantCourseReservationPeriodPolicy({
                            ...restaurantCourseReservationPeriodPolicy,
                            until_time_before: value,
                          })
                        } else {
                          setRestaurantCourseReservationPeriodPolicy({
                            ...restaurantCourseReservationPeriodPolicy,
                            until_specific_time: value,
                          })
                        }
                      }}
                      items={
                        restaurantCourseReservationPeriodPolicy.until_type ===
                        UNTIL_TYPE.offset
                          ? UNTIL_TIME_BEFORE_ITEMS
                          : UNTIL_SPECIFIC_TIME_ITEMS
                      }
                    />
                  </View>
                </FormGroup>
                <FormGroup
                  mode={width < sm ? 'vertical' : 'inline-expanded'}
                  formLabel={
                    <FormLabel
                      labelStyle={{
                        paddingVertical: width < sm ? undefined : 16,
                        fontSize: 14,
                      }}
                      value={t('お客様の滞在時間')}
                    />
                  }
                >
                  <RadioGroup
                    mode={width < sm ? 'vertical' : 'inline'}
                    value={stayingTimeSec == null ? 'default' : 'custom'}
                    style={{
                      gap: width < sm ? 8 : 16,
                    }}
                    radioContainerStyle={{
                      marginTop: 0,
                      marginLeft: 0,
                    }}
                    onChange={(value) => {
                      if (value === 'default') {
                        previousStayingTimeSec.current = stayingTimeSec
                        setStayingTimeSec(null)
                      } else {
                        if (previousStayingTimeSec.current != null) {
                          setStayingTimeSec(previousStayingTimeSec.current)
                          return
                        }
                        setStayingTimeSec(0)
                      }
                    }}
                  >
                    {[
                      { name: t('営業時間に従う'), value: 'default' },
                      { name: t('個別に設定'), value: 'custom' },
                    ].map((param, index) => (
                      <Radio
                        style={{ flex: 1 }}
                        key={`${index}`}
                        value={param.value}
                        radioLabel={
                          <RadioLabel
                            style={{
                              paddingVertical: 8,
                            }}
                            value={param.name}
                          />
                        }
                      />
                    ))}
                  </RadioGroup>
                  {stayingTimeSec != null && (
                    <View
                      style={{
                        flexDirection: 'row',
                        columnGap: width < sm ? 8 : 4,
                        alignItems: 'center',
                        marginTop: 8,
                      }}
                    >
                      <SelectInput
                        style={{ height: 64 }}
                        selectedValue={parseSeconds(stayingTimeSec).hour}
                        setValue={(value) => {
                          setStayingTimeSec((prev) => {
                            const { minute } = parseSeconds(prev ?? 0)
                            return value * 3600 + minute * 60
                          })
                        }}
                        items={Array.from({ length: 24 }).map((_, index) => {
                          const hour = index
                          return {
                            label: t('{{hour}}時間', {
                              hour,
                            }),
                            value: hour,
                          }
                        })}
                      />
                      <Text style={{ fontSize: 18, fontWeight: '300' }}>:</Text>
                      <SelectInput
                        style={{ height: 64 }}
                        selectedValue={parseSeconds(stayingTimeSec).minute}
                        setValue={(value) => {
                          setStayingTimeSec((prev) => {
                            const { hour } = parseSeconds(prev ?? 0)
                            return hour * 3600 + value * 60
                          })
                        }}
                        items={Array.from({ length: 4 }).map(
                          (_, coefficient) => {
                            const minute = coefficient * 15
                            return {
                              label: t('{{minute}}分', {
                                minute,
                              }),
                              value: minute,
                            }
                          }
                        )}
                      />
                    </View>
                  )}
                </FormGroup>
                <FormGroup
                  mode={width < sm ? 'vertical' : 'inline-expanded'}
                  formLabel={
                    <FormLabel
                      labelStyle={{
                        paddingVertical: width < sm ? undefined : 16,
                        fontSize: 14,
                      }}
                      value={t('利用可能人数')}
                    />
                  }
                >
                  <RadioGroup
                    mode={'inline'}
                    value={isPartySizeSettingEnabled}
                    style={{
                      gap: 16,
                    }}
                    radioContainerStyle={{
                      marginTop: 0,
                      marginLeft: 0,
                    }}
                    onChange={(value) => {
                      setIsPartySizeSettingEnabled(value)
                      if (value === false) {
                        setMinPartySize(null)
                        setMaxPartySize(null)
                      }
                    }}
                  >
                    {[
                      { name: t('設定しない'), value: false },
                      { name: t('設定する'), value: true },
                    ].map((param, index) => (
                      <Radio
                        style={{ flex: 1 }}
                        key={`${index}`}
                        value={param.value}
                        radioLabel={
                          <RadioLabel
                            style={{
                              paddingVertical: 8,
                            }}
                            value={param.name}
                          />
                        }
                      />
                    ))}
                  </RadioGroup>
                  {isPartySizeSettingEnabled && (
                    <View
                      style={{
                        flexDirection: 'row',
                        columnGap: width < sm ? 8 : 4,
                        alignItems: 'center',
                        marginTop: 8,
                      }}
                    >
                      <TextInput
                        style={{ height: 64, flex: 1 }}
                        value={`${minPartySize ?? ''}`}
                        keyboardType="number-pad"
                        onChangeText={(value) => {
                          setMinPartySize(value)
                        }}
                      />
                      <Text style={{ fontSize: 18, fontWeight: '300' }}>
                        〜
                      </Text>
                      <TextInput
                        style={{ height: 64, flex: 1 }}
                        value={`${maxPartySize ?? ''}`}
                        keyboardType="number-pad"
                        onChangeText={(value) => {
                          setMaxPartySize(value)
                        }}
                      />
                    </View>
                  )}
                </FormGroup>
                <FormGroup
                  mode={width < sm ? 'vertical' : 'inline-expanded'}
                  formLabel={
                    <FormLabel
                      labelStyle={{
                        paddingVertical: width < sm ? undefined : 16,
                        fontSize: 14,
                      }}
                      value={t('在庫設定')}
                    />
                  }
                >
                  <RadioGroup
                    mode={'inline'}
                    value={stockSetting != null && !stockSetting._destroy}
                    style={{
                      gap: 16,
                    }}
                    radioContainerStyle={{
                      marginTop: 0,
                      marginLeft: 0,
                    }}
                    onChange={(value) => {
                      if (value) {
                        if (stockSetting == null || stockSetting.id == null) {
                          // 未設定から設定する場合
                          setStockSetting({
                            id: null,
                            quantity: '',
                            stock_type: 'daily',
                            _destroy: false,
                          })
                        } else {
                          // 削除済みの在庫設定を復元する場合
                          setStockSetting({
                            ...stockSetting,
                            _destroy: false,
                          })
                        }
                      } else {
                        if (stockSetting == null) return
                        if (stockSetting.id == null) {
                          // 未設定の在庫設定を削除する場合
                          setStockSetting(null)
                        } else {
                          // 設定済みの在庫設定を削除する場合
                          setStockSetting({
                            ...stockSetting,
                            _destroy: true,
                          })
                        }
                      }
                    }}
                  >
                    {[
                      { name: t('設定しない'), value: false },
                      { name: t('設定する'), value: true },
                    ].map((param, index) => (
                      <Radio
                        style={{ flex: 1 }}
                        key={`${index}`}
                        value={param.value}
                        radioLabel={
                          <RadioLabel
                            style={{
                              paddingVertical: 8,
                            }}
                            value={param.name}
                          />
                        }
                      />
                    ))}
                  </RadioGroup>
                  {stockSetting != null && !stockSetting._destroy && (
                    <>
                      <View
                        style={{
                          flexDirection: 'row',
                          columnGap: width < sm ? 8 : 4,
                          alignItems: 'center',
                          marginTop: 8,
                        }}
                      >
                        <TextInput
                          style={{ height: 64, flex: 1, maxWidth: 300 }}
                          value={`${stockSetting.quantity ?? ''}`}
                          keyboardType="number-pad"
                          onChangeText={(value) => {
                            setStockSetting({
                              ...stockSetting,
                              quantity: value,
                            })
                          }}
                        />
                      </View>
                      <Text
                        style={{
                          marginTop: 8,
                          color: Colors.black60,
                          fontSize: 14,
                        }}
                      >
                        {t('1日あたりで予約できる客数を設定してください')}
                      </Text>
                    </>
                  )}
                </FormGroup>
              </View>
            </ShadowBox>
          </CardWrap>

          <CardWrap title={t('お客様への質問')}>
            <ShadowBox style={{ gap: 24 }}>
              <View style={{ gap: 8 }}>
                <Text
                  style={{
                    fontWeight: '600',
                    lineHeight: 27,
                  }}
                >
                  {t('お客様への質問')}
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '300',
                    lineHeight: 21,
                  }}
                >
                  {t('このコースで質問する項目を選択してください')}
                </Text>
              </View>
              <View style={{ flexDirection: 'column', gap: 16 }}>
                {restaurantCourseQuestions &&
                  restaurantCourseQuestions.length > 0 &&
                  restaurantCourseQuestions.map((question) => {
                    return (
                      <View
                        key={question.id}
                        style={{
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Checkbox
                          checked={selectedQuestionIds.includes(question.id)}
                          onChange={(value) =>
                            onChangeSelectedQuestionId(question.id, value)
                          }
                          checkboxLabelContainerStyle={{ width: '100%' }}
                          checkboxLabel={
                            <View style={{ marginLeft: 12, width: '100%' }}>
                              <CheckboxLabel value={question.name ?? ''} />
                              {question.description !== undefined &&
                                question.description !== '' && (
                                  <Text
                                    style={{
                                      fontSize: 14,
                                      fontWeight: '300',
                                      color: Colors.black60,
                                      width: '86%',
                                      flexWrap: 'wrap',
                                    }}
                                  >
                                    {question.description}
                                  </Text>
                                )}
                            </View>
                          }
                        />
                      </View>
                    )
                  })}
              </View>

              <LegacyButton
                textStyle={addButtonStyle.textStyle}
                buttonStyle={addButtonStyle.buttonStyle}
                onPress={() => setIsQuestionModalVisible(true)}
                text={t('新しい質問項目を作成する')}
              />
            </ShadowBox>
          </CardWrap>

          <CardWrap title={t('キャンセル料')}>
            <ShadowBox>
              <Text
                style={{
                  fontWeight: '600',
                  lineHeight: 27,
                }}
              >
                {t('キャンセル料を設定')}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: '300',
                  lineHeight: 21,
                  marginBottom: 24,
                }}
              >
                {t('キャンセル料の適用条件と徴収額を設定してください')}
              </Text>
              <View style={{ gap: 40 }}>
                {cancelPolicies.map((cancelPolicy, index) => {
                  if (cancelPolicy.Destroy) return null
                  return (
                    <FormGroup
                      key={`${index}`}
                      mode={'vertical'}
                      formLabel={
                        <FormLabel
                          value={t('キャンセル料 {{text}}', {
                            text: index + 1,
                          })}
                        />
                      }
                    >
                      <View
                        style={{
                          flex: 1,
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <View
                          style={[
                            { flex: 1, gap: 16 },
                            width >= sm
                              ? {
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }
                              : {
                                  flexDirection: 'column',
                                },
                          ]}
                        >
                          <SelectInput
                            style={{
                              flex: 1,
                              height: 64,
                            }}
                            containerStyle={{
                              height: 64,
                            }}
                            items={cancelPolicyTimeItems}
                            selectedValue={cancelPolicy.cancelTime}
                            setValue={(value) => {
                              onChangeCancelPolicyCancelTime(index, value)
                            }}
                            labelStyle={{
                              color:
                                cancelPolicy.cancelTime === 0
                                  ? Colors.disabledBlack
                                  : Colors.black,
                            }}
                          />
                          <View
                            style={{
                              flex: 1,
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}
                          >
                            <TextInput
                              style={{
                                flex: 1,
                                height: 64,
                              }}
                              autoCapitalize="none"
                              keyboardType="number-pad"
                              placeholder=""
                              value={cancelPolicy.cancelPercent}
                              onChangeText={(value) => {
                                onChangeCancelPolicyCancelPercent(index, value)
                              }}
                            />
                            <Text style={{ marginLeft: 8 }}>%</Text>
                          </View>

                          {width < sm ? (
                            <TouchableOpacity
                              onPress={() => onDestroyCancelPolicy(index)}
                            >
                              <View
                                style={{
                                  gap: 4,
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  paddingVertical: 8,
                                  paddingHorizontal: 16,
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashCan}
                                  size={24}
                                  color={Colors.caution}
                                />
                                <Text style={{ color: Colors.caution }}>
                                  {t('削除する')}
                                </Text>
                              </View>
                            </TouchableOpacity>
                          ) : (
                            <TouchableOpacity
                              onPress={() => onDestroyCancelPolicy(index)}
                            >
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                size={24}
                                color={Colors.caution}
                              />
                            </TouchableOpacity>
                          )}
                        </View>
                      </View>
                    </FormGroup>
                  )
                })}
              </View>

              <LegacyButton
                textStyle={addButtonStyle.textStyle}
                buttonStyle={addButtonStyle.buttonStyle}
                onPress={() => addCancelPolicyForm()}
                text={t('キャンセル料を追加する')}
              />
            </ShadowBox>
          </CardWrap>

          <CardWrap title={t('その他')}>
            <ShadowBox>
              <View
                style={{
                  alignItems:
                    width < sm || !isStandardOrHigher ? 'flex-start' : 'center',
                  gap: 24,
                  flexDirection:
                    width < sm || !isStandardOrHigher ? 'column' : 'row',
                }}
              >
                <Text style={{ fontWeight: '600' }}>
                  {t('提供可能なお客様の設定')}
                </Text>
                {isStandardOrHigher ? (
                  <View>
                    <RadioGroup
                      mode={'inline'}
                      value={customerRegionRestrictionType}
                      onChange={(v) => setCustomerRegionRestrictionType(v)}
                      style={{
                        gap: 16,
                      }}
                      radioContainerStyle={{
                        marginTop: 0,
                        marginLeft: 0,
                      }}
                    >
                      <Radio
                        value="no_restriction"
                        style={{ flex: 1 }}
                        radioLabel={
                          <RadioLabel
                            style={{ paddingVertical: 8 }}
                            value={t('すべて')}
                          />
                        }
                      />
                      <Radio
                        value="domestic_only"
                        style={{ flex: 1 }}
                        radioLabel={
                          <RadioLabel
                            style={{ paddingVertical: 8 }}
                            value={t('国内(日本)')}
                          />
                        }
                      />
                      <Radio
                        value="overseas_only"
                        style={{ flex: 1 }}
                        radioLabel={
                          <RadioLabel
                            style={{ paddingVertical: 8 }}
                            value={t('海外')}
                          />
                        }
                      />
                    </RadioGroup>
                  </View>
                ) : (
                  <View
                    style={{
                      flexDirection: width < sm ? 'column' : 'row',
                      padding: 16,
                      backgroundColor: Colors.white,
                      borderRadius: 8,
                      borderColor: Colors.primary,
                      borderWidth: 1,
                      gap: 12,
                      alignItems: width < sm ? 'flex-end' : 'center',
                    }}
                  >
                    <View
                      style={{
                        flex: width < sm ? undefined : 1,
                        flexDirection: 'row',
                        gap: width < sm ? 8 : 12,
                        alignItems: width < sm ? 'flex-start' : 'center',
                      }}
                    >
                      <View style={{ padding: 6 }}>
                        <Image
                          style={{ height: 16, width: 16 }}
                          source={require('../../assets/images/plan-icon.png')}
                        />
                      </View>
                      <Text style={{ flex: 1, color: Colors.black }}>
                        {restaurant?.reservation_book_plan_type === 'entry'
                          ? t(
                              'リクエスト予約機能のみをご利用中の場合、コースを提供するお客様の国を指定する機能（国内のみ、海外のみ、など）は、ご利用はできません。ご利用希望の方はお問い合わせください'
                            )
                          : t(
                              'コースを提供するお客様の国を指定する機能（国内のみ、海外のみ、など）は、スタンダードプラン以上でご利用いただけます。ご利用希望の方はお問い合わせください'
                            )}
                      </Text>
                    </View>
                    <TouchableOpacity
                      style={{
                        flexDirection: 'row',
                        gap: 4,
                        alignItems: 'center',
                      }}
                      onPress={() =>
                        Linking.openURL(
                          'https://autoreserve.com/for_restaurants/contact'
                        )
                      }
                    >
                      <Text style={{ color: Colors.primary }}>
                        {t('お問い合わせへ')}
                      </Text>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        color={Colors.primary}
                      />
                    </TouchableOpacity>
                  </View>
                )}
              </View>
              {isStandardOrHigher &&
                customerRegionRestrictionType !== 'no_restriction' && (
                  <View
                    style={{
                      flexDirection: width < sm ? 'column' : 'row',
                      borderRadius: width < sm ? 4 : 0,
                      paddingHorizontal: width < sm ? 16 : 20,
                      paddingVertical: 16,
                      backgroundColor: '#F4F4F4',
                      gap: 24,
                      alignItems: width < sm ? 'stretch' : 'center',
                      marginTop: 16,
                    }}
                  >
                    <Text style={{ flex: 1 }}>
                      {t(
                        '外国人の方向けに特別な料金などを設定する場合は、コースの説明欄に記載いただくことを推奨します。（記載例：外国の方にも質の高いサービスをご提供するため、外国の方には特別料金を設定させていただいております）'
                      )}
                    </Text>
                    <Button
                      mode="outline"
                      variant="primary"
                      onPress={() => {
                        contentRef.current?.focus()
                      }}
                    >
                      {t('説明を編集する')}
                    </Button>
                  </View>
                )}
            </ShadowBox>
          </CardWrap>
        </View>
      </KeyboardAwareScrollView>
      <View
        style={[
          {
            position: 'sticky',
            bottom: 0,
            paddingBottom:
              Platform.OS === 'web' ? undefined : insets.bottom || 24,
            width: '100%',
            paddingVertical: width < sm ? 16 : 12,
            paddingHorizontal: 24,
            backgroundColor: Colors.white,
            flexDirection: 'row',
            justifyContent: 'center',
            gap: width > sm ? 16 : 12,
            borderTopWidth: 1,
            borderColor: Colors.black10,
          },
        ]}
      >
        {restaurantCourseId != null && (
          <Button
            style={{
              borderColor: Colors.caution,
              backgroundColor: 'transparent',
              maxWidth: 296,
              height: 48,
              flex: 1,
            }}
            textStyle={{
              color: Colors.caution,
              fontWeight: 'normal',
            }}
            onPress={onArchive}
          >
            {t('非表示にする')}
          </Button>
        )}
        <Button
          loading={isUpdating}
          disabled={isUpdating}
          style={[
            {
              height: 48,
              maxWidth: 296,
              flex: 1,
            },
          ]}
          onPress={restaurantCourseId !== undefined ? onUpdate_ : onCreate_}
        >
          {restaurantCourseId != null ? t('更新する') : t('作成する')}
        </Button>
      </View>
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents="box-none"
      >
        <AlertProvider ref={alertRef} />
      </View>

      <RestaurantCourseQuestionModal
        restaurantId={restaurantId}
        isVisible={isQuestionModalVisible}
        onClose={() => setIsQuestionModalVisible(false)}
        onCreate={onQuestionCreated}
        onCreateError={(errors) => {
          displayToastError(errors[0])
        }}
      />
    </View>
  )
}
