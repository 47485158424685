import { useTableSeats } from '../models/TableSeat'

export const calculateMaxPartySize = ({
  tableSeats,
}: {
  tableSeats: NonNullable<ReturnType<typeof useTableSeats>['tableSeats']>
}) => {
  let maxPartySize = 0

  for (const tableSeat of tableSeats) {
    if (tableSeat.max_party_size != null) {
      maxPartySize += tableSeat.max_party_size
    }
  }
  return maxPartySize
}
