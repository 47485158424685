import React from 'react'

import { ScrollView, View } from 'react-native'
import { useNavigate, useParams } from 'react-router'
import invariant from 'tiny-invariant'

import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { RestaurantBusinessTimeForm as BusinessTimeForm } from '@hello-ai/for_r_app/src/components/BusinessTime/RestaurantBusinessTimeForm'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { getSortedLocales } from '@hello-ai/for_r_app/src/components/Translation'

import { useToken } from 'models/Auth'
import { useCurrentOwner } from 'models/Owner'
import { useRestaurant } from 'models/Restaurant'
import {
  createRestaurantBusinessTime,
  destroyRestaurantBusinessTime,
  updateRestaurantBusinessTime,
  useRestaurantBusinessTime,
} from 'models/RestaurantBusinessTime'
import { createTableMenu } from 'models/TableMenu'
import { createTranslation } from 'models/Translation'
import { useRestaurantCountryLanguage } from 'modules/useRestaurantCountryLanguage'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function RestaurantBusinessTimeForm() {
  const token = useToken()
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()

  const { businessTimeId } = useParams<{ businessTimeId?: string }>()
  const { restaurantBusinessTime } = useRestaurantBusinessTime(
    restaurantId,
    businessTimeId === undefined ? undefined : Number(businessTimeId)
  )

  const preferredLocale = useRestaurantCountryLanguage()
  const sortedLocales = getSortedLocales(preferredLocale)

  const onCreateOrUpdate = async (params: any) => {
    if (token === null) return

    const shouldCreateMenu = params._should_create_menu
    const menuName = params._menu_name

    delete params._should_create_menu
    delete params._menu_name

    if (restaurantBusinessTime) {
      const { error } = await updateRestaurantBusinessTime(
        token,
        restaurantId,
        restaurantBusinessTime.id,
        params
      )

      if (error != null) return

      displayToastInfo(t('営業時間を更新しました'))
    } else {
      const result = await createRestaurantBusinessTime(
        token,
        restaurantId,
        params
      )

      if (result.error != null) return

      displayToastInfo(t('営業時間を作成しました'))

      if (
        shouldCreateMenu === true &&
        (menuName ?? '') !== '' &&
        result.restaurantBusinessTime != null
      ) {
        try {
          await createTableMenu({
            token,
            restaurantId,
            params: {
              name: menuName,
              restaurant_business_time_ids: [result.restaurantBusinessTime.id],
            },
          })

          displayToastInfo(t('メニューを作成しました'))
        } catch (error) {
          console.error('Error to create Menu', error)
        }
      }
    }
    navigate(`/restaurants/${restaurantId}/business_times`)
  }

  const destroy = async () => {
    if (token === null) return

    invariant(
      restaurantBusinessTime !== undefined,
      'restaurantBusinessTime cannot be undefined'
    )

    const { error } = await destroyRestaurantBusinessTime(
      token,
      restaurantId,
      restaurantBusinessTime.id
    )

    if (error != null) return

    displayToastInfo(t('営業時間を削除しました'))

    navigate(`/restaurants/${restaurantId}/business_times`)
  }

  const onDestroy = () => {
    Alert.alert(t('本当に削除しますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: () => destroy(),
      },
    ])
  }

  const { owner } = useCurrentOwner()
  const { restaurant } = useRestaurant(restaurantId)

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: Colors.bgBlack }}
    >
      <BusinessTimeForm
        restaurantBusinessTime={restaurantBusinessTime}
        onCreateOrUpdate={onCreateOrUpdate}
        onDestroy={onDestroy}
        sortedLocales={sortedLocales}
        createTranslation={(params) => createTranslation(token!, params)}
      >
        {owner?.autoreserve_admin && (
          <View style={{ marginTop: 20 }}>
            <Text style={{ fontSize: 12, color: Colors.secondaryBlack }}>
              [admin]
              {'\n'}
              {restaurant?.business_hours_str}
              {'\n\n'}
              {t('定休日')}
              {'\n'}
              {restaurant?.regular_holidays_str}
            </Text>
          </View>
        )}
      </BusinessTimeForm>
    </ScrollView>
  )
}
