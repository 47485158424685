import React from 'react'

import { StyleSheet, View, Text } from '@react-pdf/renderer'

import { BORDER_WIDTH, HEADER_CELL_HEIGHT, HEADER_CELL_WIDTH } from './const'

type Props = {
  text: string
  isFirst: boolean
  justifyContent?: 'flex-start' | 'center'
}

const styles = StyleSheet.create({
  container: {
    width: HEADER_CELL_WIDTH,
    height: HEADER_CELL_HEIGHT,
    borderWidth: 1.134,
    borderColor: '#8F8F8F',
    backgroundColor: '#8F8F8F',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: 4,
  },
  text: {
    fontSize: 8,
    fontFamily: 'NotoSansJPBold',
    color: '#FFFFFF',
  },
})

export function HeaderCell({
  text,
  isFirst,
  justifyContent = 'flex-start',
}: Props) {
  return (
    <View
      style={[
        styles.container,
        {
          borderLeftWidth: isFirst ? BORDER_WIDTH : 0,
          justifyContent,
        },
      ]}
    >
      <Text style={styles.text}>{text}</Text>
    </View>
  )
}
