import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import type { ReservationTag } from '@hello-ai/ar_shared/src/types/ForR/ReservationTag'
import { TableSeat } from '@hello-ai/ar_shared/src/types/ForR/TableSeat'
import type { ListPeriodResponse_RestaurantReservationBlockPeriod } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation_block/restaurant_reservation_block_service'
import { Timestamp } from '@hello-ai/proto/src/gen/google/protobuf/timestamp'

import { Customer } from '../../../models/Customer'
import { Attribute } from '../../../models/CustomerAttributes'
import { getTagIcon } from '../../../models/ReservationTag'
import { type RestaurantReservation as RestaurantReservationModel } from '../../../models/RestaurantReservation'
import { getCustomerDisplayName } from '../../Customers/Customer'

import {
  CELL_HEIGHT,
  CELL_PADDING_X,
  CELL_PADDING_Y,
  HOUR_PC_WIDTH,
  HOUR_SP_WIDTH,
  MIN_DURATION,
  SEAT_DURATION_INTERVAL,
  SEAT_PC_HEIGHT,
  SEAT_SP_HEIGHT,
} from './const'

import type { ChartMode, Items } from './types'
import type {
  TableSeat as TableSeatModel,
  useTableSeats,
} from '../../../models/TableSeat'
import type { ViewStyle } from 'react-native'
import type { ReanimatedScrollEvent } from 'react-native-reanimated/lib/typescript/hook/commonTypes'

export function ifScrollToBottom(
  event?: ReanimatedScrollEvent | null
): boolean {
  if (event == null) return false
  const { layoutMeasurement, contentOffset, contentSize } = event
  const paddingToBottom = 20
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  )
}

export function ifScrollToTop(event?: ReanimatedScrollEvent | null): boolean {
  if (event == null) return false
  return event.contentOffset.y <= 0
}

export function ifScrollToStart(event?: ReanimatedScrollEvent | null): boolean {
  if (event == null) return false
  return event.contentOffset.x <= 0
}

export function ifScrollToEnd(event?: ReanimatedScrollEvent | null): boolean {
  if (event == null) return false
  const { layoutMeasurement, contentOffset, contentSize } = event
  return layoutMeasurement.width + contentOffset.x >= contentSize.width - 40
}

/**
 * 省略記号（...）を表示すべきかどうかを判断する関数
 *
 * @param iconCount - 表示するアイコンの数
 * @param isVip - お客様属性がVIPかどうか
 * @param cellWidth - アイコン表示領域の最大幅
 * @returns 省略記号を表示すべき場合はtrue、そうでない場合はfalse
 */
export function shouldDisplayEllipsis(
  iconCount: number,
  isVip: boolean,
  cellWidth: number
): boolean {
  return calculateIconTotalWidth(iconCount, isVip) >= cellWidth
}

/**
 * 全てのアイコンを表示した時の最大幅
 * @param iconCount
 * @param isVip
 */
function calculateIconTotalWidth(iconCount: number, isVip: boolean): number {
  const iconWidth = 16
  const gap = 2

  let totalWidth = iconWidth * iconCount + gap * (iconCount - 1)
  // VIPアイコンは幅が大きいので追加する
  if (isVip) {
    totalWidth += 12
  }
  return totalWidth
}

/**
 * アイコン表示領域の最大幅を計算する関数
 * - 2時間以上の場合は全て表示
 * - 1~1.5時間の場合
 *   - アイコン数が3より大きければ2つ分表示（省略記号の表示スペースを空けるため）
 *   - アイコン数が3以下ならば3つ分表示
 * - 0.5時間の場合は1つ分表示
 * - 0.25時間（15分）以下の場合は0を返す（アイコンを表示しない）
 *
 * @param hours - 予約の滞在時間（時間単位）
 * @param iconCount
 * @param isVip お客様属性がVIPかどうか
 * @param cellWidth - アイコン表示領域の最大幅
 * @returns アイコン表示領域の最大幅
 */
export function calculateIconMaxWidth(
  hours: number,
  iconCount: number,
  isVip: boolean,
  cellWidth: number
): number {
  const iconWidth = 16

  const iconTotalWidth = calculateIconTotalWidth(iconCount, isVip)

  // 15分以下の場合はアイコンを表示しない
  if (hours <= MIN_DURATION) {
    return 0
  }

  // セル幅よりアイコンの幅が大きい場合は省略記号の表示スペースを差し引いて返す
  if (iconTotalWidth >= cellWidth) {
    return cellWidth - iconWidth + 0.5 + 2
  }

  return iconTotalWidth
}

/**
 * アイコンの数を計算する関数
 * cssレイアウトに使用
 *
 * @param restaurantReservation - 予約情報
 * @param isReservedByAR - ARによる予約かどうか
 * @returns アイコンの数
 */
export function calculateIconCount(
  restaurantReservation: Omit<
    RestaurantReservationModel,
    | 'id'
    | 'status'
    | 'cancel_fee'
    | 'table_seats'
    | 'restaurant_crew_member'
    | 'reservation'
  >,
  isVip: boolean,
  needAttention: boolean
): number {
  let iconCount = 0
  if (restaurantReservation.smart_payment !== null) iconCount++
  if (restaurantReservation.memo) iconCount++
  if (isVip) {
    iconCount++
  }
  if (needAttention) {
    iconCount++
  }
  if (
    restaurantReservation.reservation_courses !== undefined &&
    restaurantReservation.reservation_courses.length > 0
  )
    iconCount++
  if (restaurantReservation.reservation_tags !== undefined) {
    iconCount += restaurantReservation.reservation_tags.filter(
      (tag: ReservationTag) => getTagIcon(tag.name) !== undefined
    ).length
  }
  return iconCount
}

export function getCellWidth(hours: number, isSp: boolean) {
  return (isSp ? HOUR_SP_WIDTH : HOUR_PC_WIDTH) * hours - CELL_PADDING_X * 2
}
export function getCellHeight(isSp: boolean) {
  return (isSp ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT) - CELL_PADDING_Y * 2
}

export const getCellX = (x: number, isSp: boolean) => {
  return (isSp ? HOUR_SP_WIDTH : HOUR_PC_WIDTH) * x + CELL_PADDING_X
}

export const getCellY = (y: number, isSp: boolean) => {
  return (
    CELL_HEIGHT + (isSp ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT) * y + CELL_PADDING_Y
  )
}

export function getCellStyle({
  x,
  y,
  isSp,
}: {
  x: number
  y: number
  isSp: boolean
}): ViewStyle {
  return {
    transform: [
      { translateX: getCellX(x, isSp) },
      {
        translateY: getCellY(y, isSp),
      },
    ],
  }
}

export function setIntervalByRequestAnimationFrame(
  callback: () => void,
  interval: number
): () => void {
  let accumulator = 0
  let lastTime = performance.now()
  let idForRaf: number | null = null
  let canceled = false

  const loop = (currentTime: number) => {
    if (canceled) return
    idForRaf = requestAnimationFrame(loop)
    accumulator += currentTime - lastTime
    lastTime = currentTime

    if (accumulator >= interval) {
      callback()
      accumulator = 0
    }
  }

  idForRaf = requestAnimationFrame(loop)

  return () => {
    if (idForRaf !== null) {
      cancelAnimationFrame(idForRaf)
    }
    canceled = true
  }
}

export function getReservationText(
  restaurantReservation: Pick<RestaurantReservationModel, 'customers' | 'kind'>
) {
  const customers = restaurantReservation.customers
  return customers.length === 0
    ? restaurantReservation.kind === 'walkin'
      ? t('ウォークイン')
      : t('未設定')
    : customers
        .map((customer) =>
          t('{{name}}様', { name: getCustomerDisplayName(customer) })
        )
        .join('、')
}

export function getUnselectableTableSeatIds({
  mode,
  date,
  startTime,
  endTime,
  restaurantReservations,
  restaurantReservation: targetReservation,
}: {
  mode: ChartMode
  date: dayjs.Dayjs
  startTime: number
  endTime: number
  restaurantReservations: RestaurantReservationModel[]
  restaurantReservation?: RestaurantReservationModel
}) {
  const unselectableTableSeatIds: Array<TableSeatModel['id']> = []

  if (mode === 'selectBlock') {
    return unselectableTableSeatIds
  }
  const startAt = date.startOf('day').add(startTime, 'seconds')
  const endAt = date.startOf('day').add(endTime, 'seconds')

  if (mode !== 'seatChange') {
    for (const restaurantReservation of restaurantReservations) {
      for (const tableSeat of restaurantReservation.table_seats) {
        if (
          !unselectableTableSeatIds.includes(tableSeat.id) &&
          overlapsWith(
            {
              startAt,
              endAt,
            },
            {
              startAt: dayjs(restaurantReservation.start_at),
              endAt: dayjs(restaurantReservation.end_at),
            }
          )
        ) {
          if (mode === 'selectSeats') {
            // NOTE: 予約変更前と日付が違う場合は選択不可とする
            if (
              restaurantReservation != null &&
              dayjs(targetReservation?.start_at).format('YYYY-MM-DD') !==
                date.format('YYYY-MM-DD')
            )
              unselectableTableSeatIds.push(tableSeat.id)
            // NOTE: 予約変更時でも開始時刻が同じ予約とは席交換可能にする
            if (startAt.isSame(dayjs(restaurantReservation.start_at))) continue
          } else {
            // NOTE 予約変更以外のモード
            unselectableTableSeatIds.push(tableSeat.id)
          }
        }
      }
    }
  }

  if (mode === 'seatChange' && targetReservation?.id != null) {
    const reserved = restaurantReservations.find(
      (i) => i.id === targetReservation?.id
    )
    const reservedTableSeatIds = reserved?.table_seats.map((i) => i.id)
    // 同じ席IDが含まれている次の予約
    const nextReservations = restaurantReservations.filter(
      (candidateReservation) => {
        if (targetReservation?.id === candidateReservation.id) return false

        const tableSeatIds = candidateReservation.table_seats.map((i) => i.id)
        if (!reservedTableSeatIds?.some((a) => tableSeatIds.includes(a)))
          return false
        return endAt.isSameOrAfter(dayjs(candidateReservation.start_at))
      }
    )

    // 交換前の席に次予約が入っている場合
    const unSelectableReservations = restaurantReservations.filter(
      (candidateUnSelectableReservation) => {
        if (targetReservation?.id === candidateUnSelectableReservation.id)
          return false
        const isDuplicationDuration = nextReservations.some(
          (nextReservation) => {
            if (nextReservation.id === candidateUnSelectableReservation.id)
              return false
            // 次予約の開始時刻以降の予約席は判定しない
            if (
              dayjs(candidateUnSelectableReservation.start_at).isSameOrAfter(
                dayjs(nextReservation.start_at)
              )
            )
              return false
            // 交換できる席は終了時刻が次予約の開始時刻前のみである
            return dayjs(nextReservation.start_at).isBefore(
              dayjs(candidateUnSelectableReservation.end_at)
            )
          }
        )
        return isDuplicationDuration
      }
    )
    for (const unSelectableReservation of unSelectableReservations) {
      for (const tableSeat of unSelectableReservation.table_seats) {
        if (!unselectableTableSeatIds.includes(tableSeat.id)) {
          unselectableTableSeatIds.push(tableSeat.id)
        }
      }
    }
    // 席の変更・交換は自席を含めて同じ開始時刻の予約のみである
    const nextReservationIds = nextReservations.map((i) => i.id)
    for (const notSameStartReservation of restaurantReservations.filter(
      (reservation) => {
        if (reservation.id === targetReservation?.id) return false
        if (nextReservationIds.includes(reservation.id)) return false

        // 時間が被ってても移動OKという整理になったため、一律でtrueにする
        // return startAt.isSame(reservation.start_at)
        return false
      }
    )) {
      for (const tableSeat of notSameStartReservation.table_seats) {
        if (!unselectableTableSeatIds.includes(tableSeat.id)) {
          unselectableTableSeatIds.push(tableSeat.id)
        }
      }
    }
  }

  return unselectableTableSeatIds
}

export function overlapsWith(
  left: {
    startAt: dayjs.Dayjs
    endAt: dayjs.Dayjs
  },
  right: {
    startAt: dayjs.Dayjs
    endAt: dayjs.Dayjs
  }
) {
  // 区間A, Bが重なっている場合、
  // (Aの開始地点) <= Bの終了地点) && (Aの終了地点 >= Bの開始地点)
  // という条件を満たしている
  return right.endAt.isAfter(left.startAt) && left.endAt.isAfter(right.startAt)
}

type SelectSeatState =
  | 'over-capacity'
  | 'selectable'
  | 'selected'
  | 'initial-selected'
  | 'under-capacity'
  | 'unselectable'

export function getSelectSeatState({
  selected,
  unselectable,
  partySize,
  minPartySize,
  maxPartySize,
  isInitialSelected,
}: {
  selected: boolean
  unselectable: boolean
  partySize: number
  minPartySize?: number
  maxPartySize?: number
  isInitialSelected: boolean
}): SelectSeatState {
  if (isInitialSelected) return 'initial-selected'
  if (selected) return 'selected'
  if (unselectable) return 'unselectable'

  if (minPartySize != null && maxPartySize != null) {
    if (minPartySize <= partySize && partySize <= maxPartySize)
      return 'selectable'
  }
  if (maxPartySize != null && maxPartySize < partySize) return 'over-capacity'
  if (minPartySize != null && minPartySize > partySize) return 'under-capacity'

  return 'selectable'
}

export function getSelectSeatStyle(state: SelectSeatState) {
  switch (state) {
    case 'over-capacity':
      return {
        borderColor: Colors.accent,
        backgroundColor: Colors.bgBlack,
      }
    case 'selectable':
      return {
        borderColor: Colors.accent,
        backgroundColor: Colors.accent40,
      }
    case 'selected':
      return {
        borderColor: Colors.accent,
        backgroundColor: Colors.accent,
      }
    case 'initial-selected':
      return {
        borderColor: Colors.caution,
        backgroundColor: Colors.caution,
      }
    case 'under-capacity':
      return {
        borderColor: Colors.secondaryBlack,
        backgroundColor: Colors.black50,
      }
    case 'unselectable':
      return {
        borderColor: 'transparent',
        backgroundColor: Colors.black20,
      }
  }
}

export function getOtherReservations({
  restaurantReservations,
}: {
  restaurantReservations: RestaurantReservationModel[] | undefined
}) {
  const reservations: RestaurantReservationModel[] = []

  if (restaurantReservations == null) {
    return reservations
  }

  for (const restaurantReservation of restaurantReservations) {
    if (restaurantReservation.table_seats.length === 0) {
      reservations.push(restaurantReservation)
    }
  }

  return reservations
}

export function findOverlappingItemsByTableSeatId({
  tableSeatId,
  timeRange,
  reservations,
  reservationBlockPeriods,
}: {
  tableSeatId: TableSeatModel['id']
  timeRange: {
    startAt: dayjs.Dayjs
    endAt: dayjs.Dayjs
  }
  reservations: RestaurantReservationModel[]
  reservationBlockPeriods: ListPeriodResponse_RestaurantReservationBlockPeriod[]
}) {
  const overlappingItems: Items = {
    reservations: [],
    reservationBlockPeriods: [],
  }

  for (const reservation of reservations) {
    if (
      reservation.table_seats.some(
        (tableSeat) => tableSeat.id === tableSeatId
      ) &&
      overlapsWith(
        {
          startAt: timeRange.startAt,
          endAt: timeRange.endAt,
        },
        {
          startAt: dayjs(reservation.start_at),
          endAt: dayjs(reservation.end_at),
        }
      )
    ) {
      overlappingItems.reservations.push(reservation)
    }
  }

  for (const reservationBlockPeriod of reservationBlockPeriods) {
    if (
      reservationBlockPeriod.startAt &&
      reservationBlockPeriod.endAt &&
      reservationBlockPeriod.tableSeats.some(
        (tableSeat) => tableSeat.id === tableSeatId
      ) &&
      overlapsWith(
        {
          startAt: timeRange.startAt,
          endAt: timeRange.endAt,
        },
        {
          startAt: dayjs(Timestamp.toDate(reservationBlockPeriod.startAt)),
          endAt: dayjs(Timestamp.toDate(reservationBlockPeriod.endAt)),
        }
      )
    ) {
      overlappingItems.reservationBlockPeriods.push(reservationBlockPeriod)
    }
  }
  return overlappingItems
}

// in hours
export function getStartTimeFromPosition(relativeX: number, isSp: boolean) {
  'worklet'
  const x =
    Math.round(
      relativeX /
        (isSp ? HOUR_SP_WIDTH : HOUR_PC_WIDTH) /
        SEAT_DURATION_INTERVAL
    ) * SEAT_DURATION_INTERVAL
  return x * 3600
}

export function getSeatIdFromPosition(
  relativeY: number,
  isSp: boolean,
  tableSeats: ReturnType<typeof useTableSeats>['tableSeats'][number][],
  unassignedSeatReservationLength: number
) {
  'worklet'
  const titleOffset = 1 // タイトル分の調整
  const offset = titleOffset + unassignedSeatReservationLength // 席が未指定の予約（ウォークイン、リクエスト）分の調整
  const columnIndex = Math.round(
    relativeY / (isSp ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT)
  )
  const index = columnIndex - offset
  return tableSeats[index]?.id
}

export function getPartySize(
  tableSeat: ReturnType<typeof useTableSeats>['tableSeats'][number]
) {
  return `${tableSeat.min_party_size ?? ''}~${tableSeat.max_party_size ?? ''}`
}

/**
 * テーブルの並びを確認し、連続しているテーブルをグループ化して返す関数
 *
 * @param tableSeats - 店舗全体のテーブル情報(配列)
 * @param reservationSeats - ある予約に紐づいているテーブル配列
 * @returns 連続しているテーブルをまとめた二次元配列
 */
export const groupConsecutiveReservationSeats = (
  tableSeats: TableSeat[],
  reservationSeats: { id: string; name: string }[]
): { id: string; name: string }[][] => {
  const seatIndexMap = new Map<string, number>()
  tableSeats.forEach((seat, idx) => {
    seatIndexMap.set(seat.id, idx)
  })

  const sortedReservationSeats = reservationSeats
    .filter((rSeat) => seatIndexMap.has(rSeat.id))
    .sort(
      (a, b) =>
        (seatIndexMap.get(a.id) ?? Number.MAX_SAFE_INTEGER) -
        (seatIndexMap.get(b.id) ?? Number.MAX_SAFE_INTEGER)
    )

  // 予約が席を持っていない場合など、空配列ならすぐ返す
  if (sortedReservationSeats.length === 0) {
    return []
  }

  // ソート済みの予約テーブルを「連番かどうか」で区切ってグループ化する
  const result: Array<Array<{ id: string; name: string }>> = []
  let currentGroup: Array<{ id: string; name: string }> = []
  let prevIndex = seatIndexMap.get(sortedReservationSeats[0].id)!
  currentGroup.push(sortedReservationSeats[0])

  for (let i = 1; i < sortedReservationSeats.length; i++) {
    const currentSeat = sortedReservationSeats[i]
    const currentSeatIndex = seatIndexMap.get(currentSeat.id)!

    if (currentSeatIndex === prevIndex + 1) {
      // 前の席と「連番」なので、同じグループに入れる
      currentGroup.push(currentSeat)
    } else {
      // 連番が途切れたので、新しいグループを開始する
      result.push(currentGroup)
      currentGroup = [currentSeat]
    }
    prevIndex = currentSeatIndex
  }

  if (currentGroup.length > 0) {
    result.push(currentGroup)
  }

  return result
}

/**
 * 衝突判定を行う関数
 * 予約情報とすべての予約情報を比較し、変更後の時刻が衝突しているかどうかを返す
 *
 * @param restaurantReservation - 予約情報
 * @param allRestaurantReservations - すべての予約情報
 * @param startAt - 変更後の開始時刻
 * @param endAt - 変更後の終了時刻
 * @returns 衝突しているかどうか
 */

export const checkCollisionByTime = (
  restaurantReservation: RestaurantReservationModel,
  allRestaurantReservations: RestaurantReservationModel[],
  nextStartAt?: dayjs.Dayjs,
  nextEndAt?: dayjs.Dayjs
): boolean => {
  const isBookingReservations = allRestaurantReservations
    .filter((i) => i.id !== restaurantReservation.id)
    .filter((i) =>
      i.table_seats
        .flatMap((t) => t.id)
        .some((tid) =>
          restaurantReservation.table_seats?.map((ts) => ts.id).includes(tid)
        )
    )

  // 時刻のかぶっているものがあるか
  const duplicateTimeReservations = isBookingReservations.filter(
    (br) =>
      dayjs(br.end_at)
        .tz()
        .isAfter(nextStartAt ?? restaurantReservation.start_at) &&
      dayjs(nextEndAt ?? restaurantReservation.end_at)
        .tz()
        .isAfter(br.start_at)
  )

  return duplicateTimeReservations.length > 0
}

export const hasCustomerAttribute = (
  customer: Customer,
  customerAttributes: Attribute[],
  name: string
) => {
  return customer.restaurant_profile?.custom_attributes
    ?.split(',')
    .some(
      (customAttributeId) =>
        customerAttributes.find(
          (customerAttribute) => customerAttribute.id === customAttributeId
        )?.name === name
    )
}

// 予約の View の幅を15分刻みに調整する関数
export const calcAdjustedReservationCellWidthAndMinutes = (
  cellWidth: number,
  isSP: boolean
): {
  width: number
  minutes: number
} => {
  const totalMinutes = getMinutesFromCellWidth(cellWidth, isSP)

  const remainder = totalMinutes % 15
  let adjustedMinutes = totalMinutes

  if (remainder !== 0) {
    // 残りが7.5分以上なら切り上げ、それ未満なら切り捨て
    if (remainder >= 7.5) {
      adjustedMinutes = totalMinutes + (15 - remainder)
    } else {
      adjustedMinutes = totalMinutes - remainder
    }
  }

  const adjustedHours = adjustedMinutes / 60
  return {
    width: getCellWidth(adjustedHours, isSP),
    minutes: adjustedMinutes,
  }
}

const getMinutesFromCellWidth = (width: number, isSP: boolean) => {
  const pixelsPerHour = getCellWidth(1, isSP) // 1時間あたりのピクセル数
  const totalHours = width / pixelsPerHour // 現在の幅から時間を計算
  return totalHours * 60 // 時間を分に変換
}

export const calcAdjustedStartOffsetXAndStartTime = (
  offsetX: number,
  isSP: boolean
) => {
  const hourWidth = isSP ? HOUR_SP_WIDTH : HOUR_PC_WIDTH
  const currentHour = offsetX / hourWidth
  const currentMinutes = currentHour * 60
  // 15分単位にスナップ (切り捨て/切り上げの判断)
  const remainder = currentMinutes % 15
  let adjustedMinutes = currentMinutes

  if (remainder !== 0) {
    // 残りが7.5分以上なら切り上げ、それ未満なら切り捨て
    if (remainder >= 7.5) {
      adjustedMinutes = currentMinutes + (15 - remainder)
    } else {
      adjustedMinutes = currentMinutes - remainder
    }
  }
  // adjustedMinutes から新しい offsetX を計算
  const adjustedHour = adjustedMinutes / 60
  return {
    // 時刻の border に被らないように 2.5px ずらしている
    offsetX: adjustedHour * hourWidth + 2.5,
    startTime: adjustedMinutes,
  }
}

export const calcAdjustedOffsetYAndIndex = (offsetY: number, isSP: boolean) => {
  // 新しい index を計算
  let nextIndex = Math.round(
    (offsetY - CELL_HEIGHT - CELL_PADDING_Y) /
      (isSP ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT)
  )

  if (nextIndex < 0) {
    nextIndex = 0
  }

  // 新しい index から新しい offsetY を計算
  const newHeight = getCellY(nextIndex, isSP)
  return {
    offsetY: newHeight,
    index: nextIndex,
  }
}
