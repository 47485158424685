import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import produce from 'immer'
import { inRange } from 'lodash'
import { View, Platform } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

import {
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import AsyncButton from '@hello-ai/ar_shared/src/components/AsyncButton'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { toSeconds } from '@hello-ai/ar_shared/src/modules/time'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { RestaurantReservationParams } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'

import { useRestaurant } from '../../models/Restaurant'
import { useRestaurantBusinessTimesByDate } from '../../models/RestaurantBusinessTime'
import {
  createRestaurantReservation,
  RestaurantReservation,
  updateRestaurantReservation,
} from '../../models/RestaurantReservation'
import { restaurantReservationBlockService } from '../../models/RestaurantReservationBlock'
import {
  TableSeat as TableSeatModel,
  useTableSeats,
} from '../../models/TableSeat'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { PartySize } from '../Reservation/ReservationForm/DateTimeAndPartySize/Form'
import { validate } from '../Reservation/ReservationForm/SelectSeats/Form'
import {
  displayToastError,
  displayToastSequence,
  displayToastSuccess,
} from '../Shared/Toast'

import { AddReservationFloatButton } from './AddReservationFloatButton'
import { Chart, createChartScrollResponder } from './Chart'
import { SelectParams, ChartMode } from './Chart/types'
import { EditReservationFloatCard } from './EditReservationFloatCard'
import { IrregularNotification } from './IrregularNotification'
import { NeedConfirmReservation } from './NeedConfirmReservation'

const DEFAULT_ADULT_PARTY_SIZE = 0
const DEFAULT_CHILD_PARTY_SIZE = 0
const INITIAL_STEP = 3 // 日時・人数、席はチャート画面で選択済みのため詳細画面に飛ばす

export function ChartView({
  restaurantId,
  startTime,
  date,
  dateString,
  onPressAddReservationButton,
  onLoadEnd,
  onReservationAdded,
}: {
  focusFlag?: dayjs.Dayjs
  restaurantId: number
  startTime: number | undefined
  date: dayjs.Dayjs
  dateString: string
  onPressAddReservationButton: () => void
  onLoadEnd?: () => void
  onReservationAdded?: () => void
}) {
  const { tableSeats } = useTableSeats(restaurantId, {})
  const token = useToken()
  const ref = useRef<React.ElementRef<typeof Chart>>(null)
  const [editReservationFloatCardHeight, setEditReservationFloatCardHeight] =
    useState(0)
  const { width, sm } = useResponsive()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const toastMarginBottom = useMemo(() => {
    const adjustment = Platform.OS === 'web' ? -12 : 24
    return width < sm ? editReservationFloatCardHeight - adjustment : undefined
  }, [width, sm, editReservationFloatCardHeight])

  const { setChartRef, reset, chartProps, requestScroll } = useMemo(
    () => createChartScrollResponder(),
    []
  )

  const { data: restaurant } = useRestaurant(restaurantId)

  const { restaurantBusinessTimes } = useRestaurantBusinessTimesByDate(
    restaurantId,
    {
      date: date.format('YYYY-MM-DD'),
    }
  )

  useEffect(() => {
    setChartRef(ref.current)
    return () => {
      reset()
    }
  }, [reset, setChartRef])

  const prevStartTime = usePrevious(startTime)
  const prevDate = usePrevious(date)
  const alertRef = useRef<AlertMethods>(null)

  useEffect(() => {
    const dateChanged = prevDate == null ? date != null : !prevDate.isSame(date)
    if (dateChanged || prevStartTime !== startTime) {
      requestScroll(
        (async () => {
          if (startTime != null) return { time: startTime }

          if (
            restaurantBusinessTimes == null ||
            restaurantBusinessTimes.length === 0
          ) {
            const now = dayjs()
            const defaultTime = toSeconds(
              now.hour(),
              Math.floor(now.minute() / 15) * 15
            )
            return { time: defaultTime }
          }
          const firstStartTime = restaurantBusinessTimes[0].start_time
          const lastEndTime =
            restaurantBusinessTimes[restaurantBusinessTimes.length - 1].end_time
          const current = dayjs()
          const currentTime = current.hour() * 3600 + current.minute() * 60
          // 現在時刻が最初の営業時間より早い場合は最初の営業時間にスクロール
          if (currentTime < firstStartTime) {
            return { time: firstStartTime }
          }
          // 現在時刻が最後の営業時間より遅い場合は最後の営業時間にスクロール
          if (currentTime > lastEndTime) {
            return { time: lastEndTime }
          }

          return {
            time: toSeconds(
              current.hour(),
              Math.floor(current.minute() / 15) * 15
            ),
          }
        })()
      )
    }
  }, [
    date,
    prevDate,
    prevStartTime,
    requestScroll,
    restaurantBusinessTimes,
    startTime,
  ])

  const onChangeSelectedSeatIds = (value: Array<TableSeatModel['id']>) => {
    setSelectParams(
      produce((draft) => {
        if (draft != null) {
          draft.selectedSeatIds = value
        }
      })
    )
  }

  const [mode, setMode] = useState<ChartMode>('default')

  // 編集中の予約情報を管理するstate
  const [editModeReservation, setEditModeReservation] =
    useState<RestaurantReservation | null>(null)

  const isEditMode = editModeReservation != null

  // 席交換したときに交換先となる予約情報を管理するstate
  const [otherTableSeats, setOtherTableSeats] = useState<
    Pick<RestaurantReservationParams, 'other_table_seats'> | undefined
  >(undefined)

  // 編集中の予約情報を更新する
  const handleUpdateEditModeReservation = useCallback(
    (updatedReservation: RestaurantReservation) => {
      setEditModeReservation(updatedReservation)
    },
    []
  )

  // Reservationから「長押しで編集モードにしたい」と言われたときのハンドラ
  const handleLongPressEditMode = useCallback(
    (reservation: RestaurantReservation) => {
      setEditModeReservation(reservation)
    },
    []
  )

  // 編集モードを終了する
  const onEndEditMode = useCallback(() => {
    setEditModeReservation(null)
    setOtherTableSeats(undefined)
  }, [])

  // 編集モードが残らないようにコンポーネントのアンマウント時に編集モードを終了する
  useEffect(() => {
    return () => {
      onEndEditMode()
    }
  }, [onEndEditMode])

  // 予約情報を更新する(確定処理)
  const handleUpdateReservation = useCallback(
    async (updatedReservation: RestaurantReservation) => {
      if (editModeReservation == null) return
      const selectedSeatIds = updatedReservation.table_seats.map(
        (seat) => seat.id
      )
      const updatePartySize =
        updatedReservation.adult_party_size +
        updatedReservation.child_party_size

      // バリデーションチェック
      const { errors, disabled } = validate({
        partySize: updatePartySize,
        selectedSeatIds,
        tableSeats,
        shouldValidatePartySize: false,
      })

      const showErrors = (errors: string[]) => {
        if (errors.length > 0) {
          const sequence: (() => void)[] = []
          errors.forEach((error) => {
            sequence.push(() =>
              displayToastError(error, undefined, {
                marginBottom: toastMarginBottom,
              })
            )
          })
          displayToastSequence(sequence)
        }
      }

      if (disabled) {
        showErrors(errors)
        return
      }

      showErrors(errors)
      const { error } = await updateRestaurantReservation(
        token,
        restaurantId,
        editModeReservation.id,
        {
          start_at: updatedReservation.start_at,
          end_at: updatedReservation.end_at,
          adult_party_size: updatedReservation.adult_party_size,
          child_party_size: updatedReservation.child_party_size,
          table_seat_ids: updatedReservation.table_seats.map((seat) => seat.id),
          other_table_seats: otherTableSeats?.other_table_seats,
        }
      )
      if (error != null) {
        ref.current?.refreshData()
        displayToastError(t('予約の更新に失敗しました'))
        return
      }

      // Chart の予約データを更新する
      ref.current?.refreshData()

      displayToastSuccess(
        t('予約を更新しました'),
        undefined,
        width < sm ? { marginBottom: 48 } : undefined
      )
    },
    [
      editModeReservation,
      tableSeats,
      token,
      restaurantId,
      otherTableSeats?.other_table_seats,
      width,
      sm,
      toastMarginBottom,
    ]
  )

  const [selectParams, setSelectParams] = useState<SelectParams | undefined>()

  const { errors } = useMemo(
    () =>
      validate({
        partySize:
          (selectParams?.adultPartySize ?? DEFAULT_ADULT_PARTY_SIZE) +
          (selectParams?.childPartySize ?? DEFAULT_CHILD_PARTY_SIZE),
        selectedSeatIds: selectParams?.selectedSeatIds ?? [],
        tableSeats,
      }),
    [selectParams, tableSeats]
  )

  useEffect(() => {
    if (errors.length > 0) {
      for (const error of errors) {
        displayToastError(error, undefined, {
          marginBottom: width < sm ? 272 : undefined,
        })
      }
    }
  }, [errors, width, sm])

  const onPressCancel = () => {
    setMode('default')
    setSelectParams(undefined)
  }

  const onPressBlock = async () => {
    if (
      // TODO: new_free への移行が完了したら削除
      restaurant?.reservation_book_plan_type === 'special' ||
      restaurant?.reservation_book_plan_type === 'new_free' ||
      restaurant?.reservation_book_plan_type === 'entry'
    ) {
      // フリー, エントリープランでは繰り返しのブロック枠を作成させないためにここで単発のブロック枠を作成する
      await restaurantReservationBlockService.create(token, {
        restaurantId,
        startDate: date.format('YYYY-MM-DD'),
        endDate: date.format('YYYY-MM-DD'),
        startTime: selectParams?.startTime ?? 0,
        endTime: selectParams?.endTime ?? 0,
        wdays: [0, 1, 2, 3, 4, 5, 6, 7],
        tableSeatIds: selectParams?.selectedSeatIds ?? [],
      })

      // Chart の予約データを更新する
      ref.current?.refreshData()

      displayToastSuccess(
        t('ブロックを追加しました'),
        undefined,
        width < sm ? { marginBottom: 48 } : undefined
      )
      setTimeout(() => {
        setMode('default')
      }, 0)
    } else {
      if (Platform.OS === 'web') {
        const params = new URLSearchParams()
        if (selectParams?.startTime != null) {
          params.append('startTime', selectParams.startTime.toString())
        }
        if (selectParams?.endTime != null) {
          params.append('endTime', selectParams.endTime.toString())
        }
        if (
          selectParams?.selectedSeatIds != null &&
          selectParams.selectedSeatIds.length > 0
        ) {
          params.append(
            'selectedSeatIds',
            selectParams.selectedSeatIds.join(',')
          )
        }
        params.append('date', date.format('YYYY-MM-DD'))
        navigate(
          `/restaurants/${restaurantId}/reservations/blocks/new?${params.toString()}`
        )
      } else {
        navigation.navigate('ReservationBlocksForm', {
          startTime: selectParams?.startTime,
          endTime: selectParams?.endTime,
          selectedSeatIds: selectParams?.selectedSeatIds,
          date,
        })
      }
      setTimeout(() => {
        setMode('default')
      }, 0)
    }
  }

  const onPressWalkin = async () => {
    if (selectParams === undefined) return

    const params: RestaurantReservationParams = {
      start_at: date
        .startOf('day')
        .add(selectParams.startTime, 'second')
        .format('YYYY-MM-DD HH:mm'),
      end_at: date
        .startOf('day')
        .add(selectParams.endTime, 'second')
        .format('YYYY-MM-DD HH:mm'),
      adult_party_size: selectParams.adultPartySize,
      child_party_size: selectParams.childPartySize,
      table_seat_ids: selectParams.selectedSeatIds,
      kind: 'walkin',
      visit_status: 'all_visited',
    }

    const { error } = await createRestaurantReservation(
      token,
      restaurantId,
      params
    )
    if (error != null) return

    // Chart の予約データを更新する
    ref.current?.refreshData()

    displayToastSuccess(
      t('ウォークインを追加しました'),
      undefined,
      width < sm ? { marginBottom: 48 } : undefined
    )
    setTimeout(() => {
      setMode('default')
    }, 0)
  }

  const onPressReserve = () => {
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/new?dateString=${dateString}&startTime=${
          selectParams?.startTime
        }&endTime=${
          selectParams?.endTime
        }&selectedSeatIds=${selectParams?.selectedSeatIds?.join(',')}&adultPartySize=${
          selectParams?.adultPartySize ?? DEFAULT_ADULT_PARTY_SIZE
        }&childPartySize=${selectParams?.childPartySize ?? DEFAULT_CHILD_PARTY_SIZE}&initialStep=${INITIAL_STEP}`
      )
    } else {
      navigation.navigate('ReservationForm', {
        dateString,
        startTime: selectParams?.startTime,
        endTime: selectParams?.endTime,
        selectedSeatIds: selectParams?.selectedSeatIds,
        adultPartySize: selectParams?.adultPartySize,
        childPartySize: selectParams?.childPartySize,
        initialStep: INITIAL_STEP,
      })
    }
    setTimeout(() => {
      setMode('default')
    }, 0)
  }

  const setEndTime = (endTime: number) => {
    setSelectParams(
      produce((draft) => {
        if (draft != null) {
          draft.endTime = endTime
        }
      })
    )
  }

  function SelectBlockWrapper({ children }: { children: React.ReactNode }) {
    if (mode !== 'selectBlock') return null

    if (width < sm) {
      return (
        <SafeAreaView
          style={[
            {
              backgroundColor: Colors.white,
              borderTopColor: Colors.border,
              borderTopWidth: 0.5,
              paddingHorizontal: 16,
              paddingTop: -42,
            },
            Platform.OS === 'web' && {
              paddingTop: 24,
              paddingBottom: 24,
            },
          ]}
        >
          {children}
        </SafeAreaView>
      )
    }

    return (
      <View
        style={[
          {
            flex: 1,
            position: 'absolute',
            bottom: 0,
            right: 0,
            top: 0,
            width: 296,
            backgroundColor: Colors.white,
            borderLeftColor: Colors.border,
            borderLeftWidth: 0.5,
            padding: 24,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        ]}
      >
        {children}
      </View>
    )
  }

  const handleMoveTableSeats = async ({
    restaurantId,
    params,
  }: {
    restaurantId: number
    params: Partial<
      Pick<
        RestaurantReservationParams,
        'start_at' | 'end_at' | 'table_seat_ids' | 'other_table_seats'
      >
    >
  }) => {
    if (editModeReservation == null) return
    // 編集中の予約情報を更新
    const updateParams: {
      end_at?: string
      start_at?: string
    } = {}
    if (params.end_at != null && params.end_at !== '') {
      updateParams.end_at = params.end_at
    }
    if (params.start_at != null && params.start_at !== '') {
      updateParams.start_at = params.start_at
    }

    const updatedReservation: RestaurantReservation = {
      ...editModeReservation,
      ...updateParams,
      table_seats:
        params.table_seat_ids?.flatMap((seatId) => {
          const seats = tableSeats.filter((s) => s.id === seatId)
          // params.other_table_seatsに同じ予約の席がある場合は、それも追加する
          const otherSeats =
            params.other_table_seats?.flatMap((otherSeat) =>
              otherSeat.reservation_id === editModeReservation.id
                ? otherSeat.table_seat_ids.flatMap((id) =>
                    tableSeats.filter((s) => s.id === id)
                  )
                : []
            ) ?? []
          return [...seats, ...otherSeats].map((seat) => ({
            id: seat.id,
            name: seat.name,
            restaurant_id: restaurantId,
            token: '',
            current_name: seat.name,
            position: 0,
            joined: false,
            next_reservation: null,
          }))
        }) ?? editModeReservation.table_seats,
    }
    if (params.other_table_seats != null) {
      // other_table_seatsから編集中の予約の席を除外する
      const otherTableSeats = params.other_table_seats.filter(
        (otherSeat) => otherSeat.reservation_id !== editModeReservation.id
      )
      setOtherTableSeats({
        other_table_seats: otherTableSeats,
      })
    }
    handleUpdateEditModeReservation(updatedReservation)
  }

  return (
    <AlertProvider ref={alertRef}>
      <View style={{ flex: 1, backgroundColor: Colors.bgBlack }}>
        {width > sm && (
          <View style={{ paddingTop: 24, paddingHorizontal: 16 }}>
            <IrregularNotification restaurantId={restaurantId} date={date} />
          </View>
        )}

        <NeedConfirmReservation restaurantId={restaurantId} date={date} />

        <Chart
          restaurantId={restaurantId}
          ref={ref}
          scrollViewProps={{
            style: {
              flex: 1,
            },
            showsVerticalScrollIndicator: false,
          }}
          date={date}
          mode={mode}
          editModeReservation={editModeReservation}
          handleLongPressEditMode={handleLongPressEditMode}
          onEndEditMode={onEndEditMode}
          onMoveTableSeats={handleMoveTableSeats}
          selectParams={selectParams}
          onStartSelectBlock={({ startTime, seatId }) => {
            // 該当する営業時間のうち
            const defaultStayingTimes =
              restaurantBusinessTimes?.reduce((prev, cur) => {
                if (
                  cur.staying_time != null &&
                  inRange(startTime, cur.start_time, cur.end_time)
                ) {
                  return [...prev, cur.staying_time]
                }
                return prev
              }, [] as number[]) ?? []
            // 最大の滞在時間 or 2h  を初期値に設定する
            const defaultStayingTime =
              defaultStayingTimes.length > 0
                ? Math.max(...defaultStayingTimes)
                : 3600 * 2

            setSelectParams({
              restaurantReservation: undefined,
              startTime,
              endTime: startTime + defaultStayingTime,
              setEndTime,
              partySize: Infinity,
              selectedSeatIds: [seatId],
              onChangeSelectedSeatIds,
            })
            setMode('selectBlock')
          }}
          onEndSelectBlock={() => {
            setMode('default')
          }}
          onLoadEnd={onLoadEnd}
          onReservationAdded={onReservationAdded}
          otherTableSeats={otherTableSeats}
          toastMarginBottom={toastMarginBottom}
          {...chartProps}
        />
        <SelectBlockWrapper>
          <View style={width >= sm && { flex: 1 }}>
            <View
              style={[
                width < sm && { marginBottom: 16 },
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                },
              ]}
            >
              {selectParams?.selectedSeatIds?.length ?? 0 ? (
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '600',
                    color: Colors.black,
                  }}
                >
                  {
                    tableSeats.find(
                      (seat) => seat.id === selectParams?.selectedSeatIds?.[0]
                    )?.name
                  }
                </Text>
              ) : (
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '600',
                    color: Colors.black60,
                  }}
                >
                  {t('タップして席を選択')}
                </Text>
              )}
              <TouchableOpacity onPress={onPressCancel}>
                <FontAwesomeIcon
                  icon={faTimes}
                  size={24}
                  color={Colors.primary}
                />
              </TouchableOpacity>
            </View>
            <PartySize
              adultPartySize={`${selectParams?.adultPartySize ?? DEFAULT_ADULT_PARTY_SIZE}`}
              childPartySize={`${selectParams?.childPartySize ?? DEFAULT_CHILD_PARTY_SIZE}`}
              setAdultPartySize={(value) =>
                setSelectParams(
                  produce((draft) => {
                    if (draft != null) {
                      draft.adultPartySize = Number(value)
                    }
                  })
                )
              }
              setChildPartySize={(value) =>
                setSelectParams(
                  produce((draft) => {
                    if (draft != null) {
                      draft.childPartySize = Number(value)
                    }
                  })
                )
              }
              isChartView
            />
          </View>
          <View
            style={[
              width < sm
                ? {
                    flexDirection: 'row',
                    gap: 8,
                    marginTop: 16,
                  }
                : {
                    flexDirection: 'column',
                    gap: 16,
                  },
            ]}
          >
            <View
              style={[
                width < sm
                  ? {
                      flex: 2,
                      flexDirection: 'row-reverse',
                      gap: 8,
                    }
                  : {
                      flex: Platform.OS === 'web' ? undefined : 0,
                      flexDirection: 'column',
                      gap: 16,
                    },
              ]}
            >
              <AsyncButton
                mode="outline"
                style={{
                  paddingHorizontal: 0,
                  height: 48,
                  flex: width < sm ? 1 : Platform.OS === 'web' ? undefined : 0,
                }}
                textStyle={{ fontSize: 16 }}
                onPress={onPressBlock}
              >
                {t('ブロック')}
              </AsyncButton>
              <Button
                mode="outline"
                style={{
                  paddingHorizontal: 0,
                  height: 48,
                  flex: width < sm ? 1 : Platform.OS === 'web' ? undefined : 0,
                }}
                textStyle={{ fontSize: 16 }}
                onPress={onPressWalkin}
              >
                {t('ウォークイン')}
              </Button>
            </View>
            <Button
              mode="contained"
              style={{
                paddingHorizontal: 0,
                height: 48,
                flex: width < sm ? 1 : Platform.OS === 'web' ? undefined : 0,
              }}
              textStyle={{ fontSize: 16 }}
              onPress={onPressReserve}
            >
              {t('予約')}
            </Button>
          </View>
        </SelectBlockWrapper>
        {!editModeReservation && mode !== 'selectBlock' && (
          <AddReservationFloatButton onPress={onPressAddReservationButton} />
        )}
        {isEditMode && (
          <EditReservationFloatCard
            restaurantId={restaurantId}
            editModeReservation={editModeReservation}
            onEndEditMode={onEndEditMode}
            handleUpdateReservation={handleUpdateReservation}
            onUpdateEditModeReservation={handleUpdateEditModeReservation}
            onChangeHeight={(height) =>
              setEditReservationFloatCardHeight(height)
            }
            alertRef={alertRef}
          />
        )}
      </View>
    </AlertProvider>
  )
}
