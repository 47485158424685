import React from 'react'

import dayjs from 'dayjs'
import { View } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { PaymentDetail } from 'models/Payment'
import numberWithDelimiter from 'modules/numberWithDelimiter'

import PaymentDetailItem from './PaymentDetailItem'

export default function PaymentDetailContent({
  paymentDetail,
}: {
  paymentDetail: PaymentDetail
}) {
  // TODO: バックエンドで計算したい
  const calculateOtherAmount = (paymentDetail: PaymentDetail | undefined) => {
    if (!paymentDetail) return 0

    const {
      deposit_amount,
      total_charge_amount,
      total_refunded_amount,
      application_fee_amount,
      bank_transfer_fee_amount,
      monthly_fee_amount,
      reservation_cancel_fee_amount,
      shop_charge_amount,
    } = paymentDetail
    return (
      (deposit_amount ?? 0) -
      (total_charge_amount -
        total_refunded_amount -
        application_fee_amount -
        bank_transfer_fee_amount -
        monthly_fee_amount) -
      reservation_cancel_fee_amount -
      shop_charge_amount
    )
  }
  return (
    <View
      style={{
        paddingTop: 32,
        paddingBottom: 32,
        paddingHorizontal: 32,
        borderColor: Colors.border,
        borderTopWidth: 0.5,
      }}
    >
      <Text style={{ fontSize: 14, fontWeight: '600', marginBottom: 20 }}>
        {t('入金額の詳細')}
        {t('({{start}}〜{{end}})', {
          start: dayjs(paymentDetail?.target_order_dates?.gte).format(
            // eslint-disable-next-line ar-i18n/require-translation-ja
            'YYYY年MM月DD日'
          ),
          end: dayjs(paymentDetail?.target_order_dates?.lte).format(
            // eslint-disable-next-line ar-i18n/require-translation-ja
            'YYYY年MM月DD日'
          ),
        })}
      </Text>
      <View
        style={{
          marginVertical: 12,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text style={{ fontSize: 18, fontWeight: '600' }}>{t('売上合計')}</Text>
        <Text style={{ fontSize: 18, fontWeight: '600' }}>
          {t('{{price}}円', {
            price: numberWithDelimiter(paymentDetail?.total_charge_amount ?? 0),
          })}
        </Text>
      </View>
      <PaymentDetailItem
        title={t('セルフオーダー売上(オンライン決済)')}
        amount={paymentDetail?.table_charge_amount ?? 0}
        by_fee_type={paymentDetail?.table_charge_amount_by_fee_type ?? []}
      />
      <PaymentDetailItem
        title={t('オンラインストア売上')}
        amount={paymentDetail?.shop_charge_amount ?? 0}
      />
      <PaymentDetailItem
        title={t('スマート決済')}
        amount={paymentDetail?.smart_payment_amount ?? 0}
        by_fee_type={paymentDetail?.smart_payment_amount_by_fee_type ?? []}
      />
      <PaymentDetailItem
        title={t('テイクアウト売上')}
        amount={paymentDetail?.takeout_charge_amount ?? 0}
        by_fee_type={paymentDetail?.takeout_charge_amount_by_fee_type ?? []}
      />
      <PaymentDetailItem
        title={t('予約キャンセル料')}
        amount={paymentDetail?.reservation_cancel_fee_amount ?? 0}
      />
      <PaymentDetailItem
        title={t('返金')}
        amount={paymentDetail?.total_refunded_amount ?? 0}
        isNegative
      />
      <PaymentDetailItem
        title={t('決済手数料')}
        amount={paymentDetail?.application_fee_amount_without_tax ?? 0}
        by_fee_type={
          paymentDetail?.application_fee_amount_without_tax_by_fee_type ?? []
        }
        isNegative
      />
      {paymentDetail?.application_fee_amount_shop_charge > 0 && (
        <PaymentDetailItem
          title={t('決済手数料(オンラインストア)')}
          amount={paymentDetail?.application_fee_amount_shop_charge ?? 0}
          isNegative
        />
      )}
      <PaymentDetailItem
        title={t('決済手数料消費税')}
        amount={paymentDetail?.tax_for_application_fee_amount ?? 0}
        isNegative
      />
      <PaymentDetailItem
        title={t('振込手数料')}
        amount={paymentDetail?.bank_transfer_fee_amount ?? 0}
        isNegative
      />
      {!!paymentDetail?.monthly_fee_amount &&
        paymentDetail?.monthly_fee_amount > 0 && (
          <PaymentDetailItem
            title={t('Respo 月額費用')}
            amount={paymentDetail?.monthly_fee_amount ?? 0}
            isNegative
          />
        )}
      <View
        style={{
          marginVertical: 12,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text style={{ fontSize: 18 }}>{t('その他')}</Text>
        <Text style={{ fontSize: 18 }}>
          {t('{{price}}円', {
            price: numberWithDelimiter(calculateOtherAmount(paymentDetail)),
          })}
        </Text>
      </View>
      <View
        style={{
          marginTop: 16,
          paddingTop: 24,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderTopColor: Colors.border,
          borderTopWidth: 0.5,
        }}
      >
        <Text style={{ fontSize: 18, fontWeight: '600' }}>{t('入金額')}</Text>
        <Text style={{ fontSize: 18, fontWeight: '600' }}>
          {t('{{price}}円', {
            price: numberWithDelimiter(paymentDetail?.deposit_amount ?? 0),
          })}
        </Text>
      </View>
    </View>
  )
}
