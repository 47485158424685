import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

/**
 * 座席表示用の型定義
 */
export type TableSeatView = {
  id: string
  name: string
  maxPartySize: number
  minPartySize: number
  isNoSeatAssigned: boolean
}

export type CustomerAttribute = {
  id: string
  name: string
}

// 座席指定なし用の定数
export const NO_SEAT_ASSIGNED_ID = 'no-seat-assigned'
export const NO_SEAT_ASSIGNED_NAME = t('座席指定なし')
// リクエスト用の定数
export const REQUEST_ID = 'request'
export const REQUEST_NAME = t('承認待ち')

type Timestamp = {
  seconds: number
  nanos: number
}

/**
 * 営業時間表示用の型定義
 */
export type BusinessTimeView = {
  startAt: Timestamp | null
  endAt: Timestamp | null
  open: boolean
}

/**
 * ChartView全体のProps型定義
 */
export type ChartViewProps = {
  restaurantName: string
  latestUpdatedAt: string
  tableSeats: TableSeatView[]
  displayStartHour: number
  displayEndHour: number
  /** 営業時間情報 */
  businessTimes: BusinessTimeView[]
  /** 表示日 */
  displayDate: string
  /** レストランのスマートペイメント利用可否 */
  isRestaurantSmartPaymentAvailable: boolean
  unifiedData: UnifiedReservationData[]
}

export type HourCellProps = {
  isFirstColumn: boolean
  isLastColumn: boolean
  isLastRow: boolean
  isOpenAt0min: boolean
  isOpenAt15min: boolean
  isOpenAt30min: boolean
  isOpenAt45min: boolean
}

/**
 * 統一された予約データの型定義
 */
export type UnifiedReservationData = {
  // 予約基本情報
  id: string
  startAt: string
  endAt: string
  kind: 'walkin' | 'request' | 'normal' | 'block'
  customers: {
    reservation_first_name: string
    reservation_last_name: string
    last_name: string
    first_name: string
    profiles: { id: string; custom_attributes?: string | null }[] | undefined
  }[]
  partySize: number
  adultPartySize: number
  childPartySize: number

  // アイコン表示用情報
  smartPayment: unknown | null
  memo: string | null
  source: string
  sourceBy: string
  reservationCourses: { id: string }[] | null
  reservationTags: { id: string; name: string }[] | null
  isWalkin: boolean
  isVip: boolean
  needAttention: boolean
  hasPreviousTableOrder: boolean
  reservation_site_controller_parsed_course?: {
    site_controller_parsed_course: {
      site: string
    }
  }

  // 座席情報
  tableSeatId: string
  tableSeatName: string
  maxPartySize: number
  minPartySize: number
  isNoSeatAssigned: boolean

  // 表示制御用情報
  displayOrder: number

  // 連続席情報（追加）
  consecutiveCount?: number
} & (
  | { type: 'reservation'; source: string }
  | { type: 'block'; isRepeat: boolean }
  | { type: 'request'; isChangeRequest: boolean }
  | { type: 'no-seat' }
)

export type ChunkedReservationRow = {
  index: number
  tableSeat: TableSeatView
  reservations: UnifiedReservationData[]
}

/**
 * 表示行チャンク
 */
export type RowChunk = {
  /** チャンクに含まれる予約データ */
  rows: ChunkedReservationRow[]
  /** チャンクの開始インデックス */
  startIndex: number
  /** チャンクの終了インデックス */
  endIndex: number
}

/**
 * 時間帯チャンク
 */
export type TimeChunk = {
  /** チャンクの開始時刻（時） */
  start: number
  /** チャンクの終了時刻（時） */
  end: number
  /** チャンクに含まれる予約データ */
  chunkedRows: RowChunk[]
}
