import React from 'react'

import { View } from 'react-native'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import { CreateRequest } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_course_question/restaurant_course_question_service'

import ModalCenter from '../Shared/ModalCenter'

import { RestaurantCourseQuestionForm } from './RestaurantCourseQuestionForm'

type RestaurantCourseQuestionModalProps = {
  restaurantId: Restaurant['id']
  isVisible: boolean
  onClose: () => void
  onCreate: (params: CreateRequest) => Promise<boolean>
  onCreateError: (errors: string[]) => void
}

export function RestaurantCourseQuestionModal({
  restaurantId,
  isVisible,
  onClose,
  onCreate,
  onCreateError,
}: RestaurantCourseQuestionModalProps) {
  const handleCreate = async (params: CreateRequest) => {
    const results = await onCreate(params)
    if (results) {
      onClose()
    }
  }

  return (
    <ModalCenter
      title={t('お客様への質問を作成')}
      width="100%"
      height="100%"
      isModalVisible={isVisible}
      onClose={onClose}
    >
      <View
        style={{
          backgroundColor: Colors.bgBlack,
          flex: 1,
        }}
      >
        <RestaurantCourseQuestionForm
          restaurantId={restaurantId}
          kind="modal"
          onCreate={handleCreate}
          onCreateError={onCreateError}
        />
      </View>
    </ModalCenter>
  )
}
