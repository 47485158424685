import React, { useMemo, useState } from 'react'

import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import dayjs from 'dayjs'
import { ScrollView, View } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import {
  SelectInput,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import MonthlyPaymentContent from 'components/Restaurant/Payments/MonthlyPaymentContent'
import { getYears, months } from 'components/Sales/SelectPeriodInput'
import { useRestaurantPayments } from 'models/Payment'

export default function RestaurantPayments() {
  const today = dayjs().startOf('month')
  const [selectedYear, setSelectedYear] = useState<number>(today.year())
  const [selectedMonth, setSelectedMonth] = useState<number | undefined>(
    undefined
  )

  const selectedValue = useMemo(() => {
    return dayjs(`${selectedYear}-${selectedMonth ?? today.month()}-01`)
  }, [selectedYear, selectedMonth, today])

  const years = useMemo(() => {
    return getYears()
  }, [])

  const monthItems = useMemo(() => {
    const items = [...months] as SelectItem<number | undefined>[]
    items.unshift({
      label: '',
      value: undefined,
    })
    return items
  }, [])

  const { data, isLoading } = useRestaurantPayments(
    selectedValue.format('YYYY-MM-DD')
  )

  const { width, sm } = useResponsive()
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: 'rgba(68, 68, 68, 0.06)' }}
      contentContainerStyle={{
        marginHorizontal: width < sm ? 24 : 48,
        paddingBottom: 40,
      }}
    >
      <View
        style={{
          paddingTop: 32,
          paddingBottom: 40,
          flexDirection: 'row',
          borderBottomWidth: 0.5,
          borderBottomColor: Colors.border,
        }}
      >
        <View
          style={{
            width: 120,
            marginRight: 16,
          }}
        >
          <SelectInput
            disabled={isLoading}
            selectedValue={selectedYear}
            setValue={(v) => setSelectedYear(v)}
            items={years}
            labelStyle={{
              fontSize: 16,
              fontWeight: '300',
            }}
            style={{
              backgroundColor: 'white',
            }}
            indicatorIcon={
              <FontAwesomeIcon
                icon={faChevronDown}
                size={10}
                color={Colors.black}
              />
            }
          />
        </View>
        <View
          style={{
            width: 100,
          }}
        >
          <SelectInput
            disabled={isLoading}
            selectedValue={selectedMonth}
            setValue={(v) => setSelectedMonth(v)}
            items={monthItems}
            labelStyle={{
              fontSize: 16,
              fontWeight: '300',
            }}
            style={{
              backgroundColor: 'white',
            }}
            indicatorIcon={
              <FontAwesomeIcon
                icon={faChevronDown}
                size={10}
                color={Colors.black}
              />
            }
          />
        </View>
      </View>
      <Text style={{ marginTop: 40, fontSize: 22, fontWeight: '600' }}>
        {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
        {selectedValue.format('YYYY年MM月')}
      </Text>
      {isLoading && (
        <ShadowBox
          style={[
            {
              paddingTop: 30,
              paddingBottom: 32,
              paddingHorizontal: 0,
              overflow: 'hidden',
              marginTop: 24,
            },
          ]}
        >
          <Loading />
        </ShadowBox>
      )}
      {!isLoading && data == null && (
        <ShadowBox
          style={[
            {
              paddingTop: 30,
              paddingBottom: 32,
              paddingHorizontal: 0,
              overflow: 'hidden',
              marginTop: 24,
            },
          ]}
        >
          <View style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Text>
              {t('入金額反映まで少々お待ちください（月末頃反映予定）')}
            </Text>
          </View>
        </ShadowBox>
      )}
      {!isLoading && data != null && (
        <ShadowBox
          style={[
            {
              paddingTop: 30,
              paddingBottom: 0,
              paddingHorizontal: 0,
              overflow: 'hidden',
              marginTop: 24,
            },
          ]}
        >
          <MonthlyPaymentContent monthlyPayment={data} />
        </ShadowBox>
      )}
    </ScrollView>
  )
}
