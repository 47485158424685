import React from 'react'

import { StyleSheet, View } from '@react-pdf/renderer'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import { HEADER_CELL_WIDTH, SEAT_PC_HEIGHT } from './const'

import type { HourCellProps, RowChunk, TimeChunk } from './types'

const borderColor = '#D6D6D6'

const styles = StyleSheet.create({
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: SEAT_PC_HEIGHT,
    maxHeight: SEAT_PC_HEIGHT,
  },
  dashLine: {
    height: '100%',
    borderLeftWidth: 0.5,
    borderStyle: 'dashed',
    borderColor,
  },
  hourCell: {
    display: 'flex',
    flexDirection: 'row',

    width: HEADER_CELL_WIDTH,
    height: SEAT_PC_HEIGHT,
    maxHeight: SEAT_PC_HEIGHT,
    borderColor,
    backgroundColor: Colors.white,
    boxSizing: 'border-box',

    borderLeftWidth: 0.5,
  },
  minuteCell: {
    width: '25%',
    height: '100%',
  },
})

type Props = {
  timeChunk: TimeChunk
  rowChunk: RowChunk
  isRestaurantOpenAt: (hour: number, minute: number) => boolean
}
export function HourCellList({
  timeChunk,
  rowChunk,
  isRestaurantOpenAt,
}: Props) {
  return (
    <View style={styles.columnContainer}>
      {rowChunk.rows.map((row, rowIndex) => {
        return (
          <View key={rowIndex} style={styles.rowContainer}>
            {Array.from(
              { length: timeChunk.end - timeChunk.start },
              (_, i) => timeChunk.start + i
            ).map((hour: number) => {
              return (
                <HourCell
                  key={hour}
                  isLastRow={rowIndex === rowChunk.rows.length - 1}
                  isFirstColumn={hour === timeChunk.start}
                  isLastColumn={hour === timeChunk.end - 1}
                  isOpenAt0min={isRestaurantOpenAt(hour, 0)}
                  isOpenAt15min={isRestaurantOpenAt(hour, 15)}
                  isOpenAt30min={isRestaurantOpenAt(hour, 30)}
                  isOpenAt45min={isRestaurantOpenAt(hour, 45)}
                />
              )
            })}
          </View>
        )
      })}
    </View>
  )
}

function VerticalDashLine() {
  return <View style={styles.dashLine} />
}

function HourCell({
  isLastColumn,
  isFirstColumn,
  isOpenAt0min,
  isOpenAt15min,
  isOpenAt30min,
  isOpenAt45min,
}: HourCellProps) {
  return (
    <View
      style={[
        styles.hourCell,
        {
          borderLeftWidth: isFirstColumn ? 0 : 1,

          borderRightWidth: isLastColumn ? 1 : 0,
          borderRightColor: borderColor,

          borderBottomWidth: 1,
          borderBottomColor: borderColor,
        },
      ]}
    >
      <MinuteCell isOpen={isOpenAt0min} />
      <VerticalDashLine />
      <MinuteCell isOpen={isOpenAt15min} />
      <VerticalDashLine />
      <MinuteCell isOpen={isOpenAt30min} />
      <VerticalDashLine />
      <MinuteCell isOpen={isOpenAt45min} />
    </View>
  )
}

function MinuteCell({ isOpen }: { isOpen: boolean }) {
  return (
    <View
      style={[
        styles.minuteCell,
        {
          backgroundColor: isOpen ? Colors.white : '#F4F4F4',
        },
      ]}
    />
  )
}
