import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { RestaurantReservation } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'

import type { ReservationData, Customer, EllipsisCourse } from './ListView'

const ensureString = (value: string | null | undefined): string => {
  return value === null || value === undefined ? '' : value
}

export const transformReservation = (
  reservation: RestaurantReservation
): ReservationData => {
  return {
    id: reservation.id,
    start_at: reservation.start_at,
    end_at: reservation.end_at,
    party_size: reservation.party_size,
    child_party_size: reservation.child_party_size,
    customers: reservation.customers.map(
      (customer): Customer => ({
        last_name: ensureString(customer.last_name),
        first_name: ensureString(customer.first_name),
        reservation_last_name: ensureString(customer.reservation_last_name),
        reservation_first_name: ensureString(customer.reservation_first_name),
        phone_number_format: ensureString(customer.phone_number_format),
        allergy: ensureString(customer.allergy),
        restaurant_customer_notes:
          customer.restaurant_customer_notes?.map((note) => ({
            id: note.id,
            content: ensureString(note.content),
          })) ?? null,
        restaurant_customer_note: customer.restaurant_customer_note
          ? {
              id: customer.restaurant_customer_note.id,
              content: ensureString(customer.restaurant_customer_note.content),
            }
          : {
              id: '',
              content: '',
            },
      })
    ),
    table_seats: reservation.table_seats.map((seat) => ({
      name: seat.name,
    })),
    memo: ensureString(reservation.memo),
    reservation_courses: reservation.reservation_courses.map(
      (course): EllipsisCourse => ({
        id: Number(course.id),
        party_size: course.party_size,
        restaurant_course: {
          title: course.restaurant_course.title,
          price: course.restaurant_course.price,
          price_str: `¥${course.restaurant_course.price.toLocaleString()}`,
        },
      })
    ),
    ...(reservation.reservation_child_details && {
      reservation_child_details: reservation.reservation_child_details.map(
        (child) => ({
          age: child.age,
        })
      ),
    }),
  }
}

export const transformReservations = (
  reservations: RestaurantReservation[]
): ReservationData[] => {
  return reservations
    .sort((a, b) => dayjs(a.start_at).unix() - dayjs(b.start_at).unix())
    .map(transformReservation)
}
