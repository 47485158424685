import React, { RefObject, createRef, useRef } from 'react'

import { View, TextInput as RNTextInput } from 'react-native'

import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

interface VerifyCodeInputProps {
  codeLength: number
  codes: (number | null)[]
  setCodes: React.Dispatch<React.SetStateAction<(number | null)[]>>
  onSubmitLatestEditing?: () => void
}

type TabIndex = React.ComponentProps<typeof TextInput>['tabIndex']

export default function VerifyCodeInput({
  codeLength,
  codes,
  setCodes,
  onSubmitLatestEditing,
}: VerifyCodeInputProps) {
  const { width, sm } = useResponsive()
  const inputRefs = useRef<RefObject<RNTextInput>[]>(
    Array.from({ length: codeLength }).map(() => createRef<RNTextInput>())
  )

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
        columnGap: width < sm ? 4 : 28,
      }}
    >
      {Array.from({ length: codeLength }).map((_, index) => (
        <TextInput
          key={index}
          onSubmitEditing={
            index === codeLength - 1 ? onSubmitLatestEditing : undefined
          }
          ref={inputRefs.current[index]}
          style={{
            paddingHorizontal: 16,
            paddingVertical: 24,
            borderWidth: 0,
            width: width < sm ? 50 : 64,
            height: width < sm ? 50 : 64,
            textAlign: 'center',
            fontWeight: '600',
            fontSize: width < sm ? 24 : 32,
          }}
          tabIndex={index as TabIndex}
          maxLength={1}
          autoCorrect={false}
          autoComplete="off"
          autoCapitalize="none"
          spellCheck={false}
          keyboardType="numeric"
          value={`${codes[index] == null ? '' : codes[index]}`}
          onKeyPress={({ nativeEvent }) => {
            if (nativeEvent.key === 'Backspace') {
              setCodes((prev) => {
                prev[index] = null
                if (index > 0) {
                  prev[index - 1] = null
                }
                return [...prev]
              })
              if (index > 0) {
                inputRefs.current[index - 1]?.current?.focus()
              }
            }
          }}
          onChangeText={(text) => {
            if (text === '') return
            if (Number.isNaN(Number(text))) return
            setCodes((prev) => {
              prev[index] = Number(text)
              return [...prev]
            })
            if (index < codeLength - 1) {
              inputRefs.current[index + 1]?.current?.focus()
            }
          }}
        />
      ))}
    </View>
  )
}
