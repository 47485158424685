export const HOUR_PC_WIDTH = 120
export const PADDING_HORIZONTAL = 12
export const PARTY_SIZE_WIDTH = 60
export const SCROLL_VIEW_PADDING_Y = 24
export const SCROLL_VIEW_PC_PADDING_X = 24
export const SEAT_PC_WIDTH = 200
export const SEAT_SP_HEIGHT = 60
export const VERTICAL_DASH_LINE_IMAGE_URI =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAMCAYAAACji9dXAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAVSURBVAiZY/j//z8DEwMDw38GBgYAJNwDNyHlZ1wAAAAASUVORK5CYII='

// FOR PDF
export const HEADER_CELL_HEIGHT = 20
export const HEADER_CELL_WIDTH = 80
export const SEAT_PC_HEIGHT = 40

export const HEADER_BACKGROUND_COLOR = '#8F8F8F'
export const PADDING = 4
export const BACKGROUND_COLOR = '#FFFFFF'
export const BORDER_WIDTH = 2
export const BORDER_COLOR = '#D6D6D6'
export const TEXT_SIZE = 8

export const PDF_STYLES = {
  headerCell: {
    width: 80,
    height: 20,
    borderWidth: 1.134,
    padding: 4,
    borderColor: '#8F8F8F',
    backgroundColor: '#8F8F8F',
  },
  container: {
    borderColor: '#D6D6D6',
    borderWidth: 1,
    backgroundColor: '#FFFFFF',
    padding: 4,
  },
  text: {
    color: '#444444',
    fontSize: 8,
    fontWeight: '300',
  },
} as const
