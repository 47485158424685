import React, { useState } from 'react'

import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { faUpload } from '@fortawesome/pro-regular-svg-icons/faUpload'
import * as ImagePicker from 'expo-image-picker'
import { ScrollView, Image, View } from 'react-native'
import { useNavigate } from 'react-router'

import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'

import { sendMessages } from 'models/Customer'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function CustomersSendMessages() {
  const restaurantId = useRestaurantId()
  const { width, md } = useResponsive()
  const navigate = useNavigate()

  const [body, setBody] = useState<string>()
  const [image, setImage] = useState<string>()

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ['images', 'videos'],
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    })
    if (!result.canceled) {
      setImage(result.assets[0]?.uri)
    }
  }

  const alertSendMessages = () => {
    if (body == null) {
      Alert.alert(t('内容を入力してください'))
      return null
    }
    Alert.alert(t('本当に配信しますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: () => send(),
      },
    ])
  }

  const send = async () => {
    if (body == null) {
      Alert.alert(t('内容を入力してください'))
      return null
    }
    const params: {
      body: string
      image?: string
    } = { body }
    if (image != null) {
      params.image = image
    }
    const { data } = await sendMessages(restaurantId, params)

    if (data != null) {
      displayToastInfo(t('配信しました'))
      navigate(`/restaurants/${restaurantId}/customers`)
    }
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        padding: width >= md ? 40 : 20,
      }}
    >
      <ShadowBox
        style={{
          padding: width >= md ? 32 : 20,
        }}
      >
        <FormGroup
          formLabel={<FormLabel value={t('写真')} />}
          mode={width >= md ? 'inline' : 'vertical'}
        >
          <TouchableOpacity
            style={{
              borderColor: Colors.border,
              borderStyle: 'dashed',
              borderWidth: 1,
              backgroundColor: Colors.field,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 24,
              borderRadius: 8,
            }}
            onPress={() => pickImage()}
          >
            {image == null ? (
              <>
                <FontAwesomeIcon
                  icon={faUpload}
                  color={Colors.primary}
                  size={48}
                />
                <Text style={{ marginTop: 12, textAlign: 'center' }}>
                  {t('写真をアップロード')}
                </Text>
              </>
            ) : (
              <View
                style={{
                  borderWidth: 1,
                  borderColor: Colors.border,
                  position: 'relative',
                }}
              >
                <Image
                  source={{ uri: image }}
                  style={{ width: 120, height: 120 }}
                  resizeMode="contain"
                />
                <TouchableOpacity
                  style={{
                    position: 'absolute',
                    top: -12,
                    right: -12,
                    backgroundColor: Colors.secondaryBlack,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 24,
                    height: 24,
                    borderRadius: 12,
                  }}
                  onPress={() => setImage(undefined)}
                >
                  <FontAwesomeIcon icon={faTimes} color="white" size={16} />
                </TouchableOpacity>
              </View>
            )}
          </TouchableOpacity>
        </FormGroup>
        <FormGroup
          formLabel={<FormLabel value={t('内容')} required />}
          style={{ marginTop: 32 }}
          mode={width >= md ? 'inline' : 'vertical'}
        >
          <TextInput
            multiline
            value={body}
            onChangeText={(text) => setBody(text)}
            style={{
              height: 200,
            }}
          />
        </FormGroup>
        <Button
          mode="contained"
          height={44}
          style={[
            { marginTop: 32 },
            width >= md && {
              width: '50%',
              margin: 'auto',
            },
          ]}
          onPress={() => alertSendMessages()}
        >
          {t('配信')}
        </Button>
      </ShadowBox>
    </ScrollView>
  )
}
