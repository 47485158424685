import React, { useRef, useState } from 'react'

import { TextInput as RNTextInput, View } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import LinkText from 'components/Shared/LinkText'
import { signUp } from 'models/Auth'
import { isAxiosError } from 'modules/axios'

import { sendGAEvent } from '../../modules/analytics'

import DraftOwnerInfo from './DraftOwnerInfo'
import { StepItem } from './Stepper'
import { GA_SIGNUP_STEPS } from './const'

interface CreateAccountProps {
  onPressNext: (draftOwnerInfo: DraftOwnerInfo) => void
}

const createErrorText = (label: string, errorText?: string | string[]) => {
  if (errorText == null) return undefined
  if (Array.isArray(errorText))
    return errorText.map((text) => `${label}${text}`).join('\n')
  return `${label}${errorText}`
}

export default function CreateAccountForm({ onPressNext }: CreateAccountProps) {
  const { width, sm } = useResponsive()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState<Record<string, string>>({})
  const disabledSubmit = email === '' || password.length < 6

  const onSubmit = async () => {
    if (disabledSubmit) return
    setErrors({})

    // GAイベント送信
    sendGAEvent({ event: 'click', step: GA_SIGNUP_STEPS.MAIL_REGISTER })

    const { id, error } = await signUp(email, password)
    if (error != null) {
      if (isAxiosError(error) && error.response?.status === 422) {
        setErrors(error.response?.data as Record<string, string>)
      }
      return
    }
    if (id != null) {
      onPressNext({
        draftOwnerId: id,
        email,
        password,
      })
    }
  }

  const formMode = width < sm ? 'vertical' : 'inline'

  const emailTextInputRef = useRef<RNTextInput>(null)
  const passwordTextInputRef = useRef<RNTextInput>(null)

  return (
    <View style={{ flexGrow: 1 }}>
      <View
        style={[
          {
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: width < sm ? 40 : 80,
          },
          width < sm && {
            paddingHorizontal: 16,
          },
        ]}
      >
        <StepItem
          stepNumber={1}
          state="selected"
          labelTextStyle={{
            fontSize: 22,
            fontWeight: '600',
            marginLeft: 8,
            lineHeight: 33,
          }}
          hiddenCircle={width < sm}
        >
          {t('メールアドレス、パスワードを登録します')}
        </StepItem>
        <Text
          style={{
            marginTop: width < sm ? 32 : 40,
            fontSize: width < sm ? 14 : 18,
            fontWeight: '300',
            lineHeight: width < sm ? 21 : 27,
            width: width < sm ? '100%' : 600,
            textAlign: 'center',
          }}
        >
          {t(
            'Respoのご利用に必要です。オーナー様ご自身のメールアドレス、ログイン用パスワードを入力してください。'
          )}
        </Text>
        <View style={{ marginVertical: width < sm ? 32 : 40, width: '100%' }}>
          <FormGroup
            mode={formMode}
            formLabel={
              <FormLabel
                value={t('メールアドレス')}
                labelStyle={[width < sm && { fontSize: 14 }]}
              />
            }
            formLabelContainerStyle={{
              width: 162,
              marginRight: 24,
            }}
            containerStyle={{
              width: width < sm ? '100%' : 420,
            }}
          >
            <TextInput
              ref={emailTextInputRef}
              placeholder={t('information@hello.ai')}
              value={email}
              onChangeText={setEmail}
              clearButtonMode="always"
              autoCapitalize="none"
              errorText={createErrorText(t('メールアドレス'), errors.email)}
              onSubmitEditing={() => {
                passwordTextInputRef.current?.focus()
              }}
              style={{
                height: width < sm ? 48 : 64,
              }}
            />
          </FormGroup>
          <FormGroup
            mode={formMode}
            formLabel={
              <FormLabel
                value={t('パスワード')}
                labelStyle={[width < sm && { fontSize: 14 }]}
              />
            }
            style={{ marginTop: width < sm ? 32 : 24 }}
            formLabelContainerStyle={{
              width: 162,
              marginRight: 24,
            }}
            containerStyle={{
              width: width < sm ? '100%' : 420,
            }}
          >
            <TextInput
              ref={passwordTextInputRef}
              placeholder={t('6文字以上')}
              value={password}
              onChangeText={setPassword}
              secureTextEntry
              onSubmitEditing={onSubmit}
              style={{
                height: width < sm ? 48 : 64,
              }}
            />
          </FormGroup>
        </View>
        <Button
          variant="primary"
          style={{ marginBottom: 24, paddingVertical: 12, height: 48 }}
          textStyle={{ fontSize: 16 }}
          onPress={onSubmit}
          width={278}
          disabled={disabledSubmit}
        >
          {t('次へ')}
        </Button>

        <LinkText
          style={{
            fontSize: 16,
            color: Colors.primary,
            paddingVertical: 8,
            fontWeight: '300',
          }}
          to="/sign_in"
        >
          {t('すでに登録済みの方はこちら')}
        </LinkText>
      </View>
    </View>
  )
}
