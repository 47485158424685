import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Platform, View } from 'react-native'

import {
  Alert,
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { getFormatTime } from '@hello-ai/ar_shared/src/modules/time'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { useRestaurantBusinessTimesByDate } from '../../models/RestaurantBusinessTime'
import {
  exchangeRestaurantReservationTableSeats,
  RestaurantReservationParams,
  updateRestaurantReservation,
  useRestaurantReservation,
  useRestaurantReservations,
} from '../../models/RestaurantReservation'
import { TableSeat, useTableSeats } from '../../models/TableSeat'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { Chart, createChartScrollResponder } from '../Reservations/Chart'
import { overlapsWith } from '../Reservations/Chart/utils'
import { EditReservationFloatCard } from '../Reservations/EditReservationFloatCard'
import {
  displayToastError,
  displayToastSequence,
  displayToastSuccess,
} from '../Shared/Toast'

import {
  initDefaultReservationFormState,
  initReservationFormState,
} from './Form'
import {
  getDefaultDuration,
  validate,
} from './ReservationForm/SelectSeats/Form'

type Props = {
  restaurantId: number
  restaurantReservationId: string
  // 予約変更に使う変更先の人数
  updateAdultPartySize?: number
  updateChildPartySize?: number
  editedSeatIds?: TableSeat['id'][]
}

export function ReservationChangeSeat({
  restaurantId,
  restaurantReservationId,
  updateAdultPartySize,
  updateChildPartySize,
  editedSeatIds,
}: Props) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const token = useToken()
  const { width, sm } = useResponsive()
  const [editReservationFloatCardHeight, setEditReservationFloatCardHeight] =
    useState(0)
  const alertRef = useRef<AlertMethods>(null)

  const { restaurantReservation } = useRestaurantReservation(
    restaurantId,
    restaurantReservationId
  )

  const [state] = useFormState(
    restaurantReservation
      ? {
          ...initReservationFormState(restaurantReservation),
          selectedSeatIds: restaurantReservation.table_seats.map(
            (seat) => seat.id
          ),
        }
      : initDefaultReservationFormState(dayjs())
  )

  const { tableSeats } = useTableSeats(restaurantId, {})
  const { dateString, startTime, adultPartySize, childPartySize } = state
  const date = dayjs(dateString)
  let partySize: number
  if (updateAdultPartySize != null && updateChildPartySize != null) {
    partySize = updateAdultPartySize + updateChildPartySize
  } else {
    partySize = adultPartySize + childPartySize
  }
  const [selectedSeatIds, setSelectedSeatIds] = useFormState<
    Array<TableSeat['id']>
  >(editedSeatIds ?? state.selectedSeatIds)

  const { restaurantBusinessTimes } = useRestaurantBusinessTimesByDate(
    restaurantId,
    {
      date: state.dateString,
    }
  )

  const editModeReservation = useMemo(() => {
    if (restaurantReservation == null) return null
    return {
      ...restaurantReservation,
      table_seats: tableSeats.filter((seat) =>
        selectedSeatIds.includes(seat.id)
      ),
    }
  }, [restaurantReservation, selectedSeatIds, tableSeats])

  const isDisabled = useMemo(() => {
    // 席を編集していない場合は「変更する」を押せないようにする
    return restaurantReservation?.table_seats.every((seat) =>
      editModeReservation?.table_seats.every((s) => s.id === seat.id)
    )
  }, [restaurantReservation, editModeReservation])

  const [endTime, setEndTime] = useFormState(
    state.endTime != null
      ? state.endTime
      : startTime + getDefaultDuration({ startTime, restaurantBusinessTimes })
  )

  const ref = useRef<React.ElementRef<typeof Chart>>(null)
  const { setChartRef, reset, chartProps, requestScroll } = useMemo(
    () => createChartScrollResponder(),
    []
  )
  useEffect(() => {
    setChartRef(ref.current)
    return () => {
      reset()
    }
  }, [reset, setChartRef])

  useEffect(() => {
    requestScroll({ time: startTime })
  }, [requestScroll, startTime])

  const { errors } = useMemo(
    () =>
      validate({
        partySize,
        selectedSeatIds,
        startTime,
        endTime,
        tableSeats,
        restaurantBusinessTimes,
      }),
    [
      startTime,
      endTime,
      partySize,
      restaurantBusinessTimes,
      selectedSeatIds,
      tableSeats,
    ]
  )

  const goBack = () => {
    if (Platform.OS === 'web') {
      // web の場合、対象予約の開始時刻を表示する（前後の予約が見えるように SP なら1時間、PC なら2時間前を開始位置とする）
      const adjustedStartTime =
        width < sm ? startTime - 60 * 60 * 1 : startTime - 60 * 60 * 2
      navigate(
        `/restaurants/${restaurantId}/reservations?m=chart&date=${dateString}&startTime=${Math.max(adjustedStartTime, 0)}`
      )
    } else {
      navigation.navigate('Reservations', {
        dateString: state.dateString,
        mode: 'chart',
      })
    }
  }

  const onComplete = (toastMessage?: string) => {
    goBack()
    displayToastSuccess(
      toastMessage ?? t('予約情報を更新しました'),
      undefined,
      width < sm ? { marginBottom: 48 } : undefined
    )
  }

  const { restaurantReservations } = useRestaurantReservations(restaurantId, {
    date: date.format('YYYY-MM-DD'),
  })

  const onPressSubmit = async () => {
    if (restaurantReservation == null) return
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })

    const isExchangeMode = restaurantReservations
      .filter(
        (i) =>
          i.id !== restaurantReservationId &&
          i.table_seats
            .flatMap((t) => t.id)
            .some((tid) => selectedSeatIds.includes(tid))
      )
      .some((i) => {
        // restaurantReservation と予約時間が被るものがあるか
        const startAt = dayjs(i.start_at)
        const endAt = dayjs(i.end_at)
        return overlapsWith(
          {
            startAt: dayjs(restaurantReservation.start_at),
            endAt: dayjs(restaurantReservation.end_at),
          },
          { startAt, endAt }
        )
      })

    if (isExchangeMode) {
      alert(t('席の入れ替え'), t('席を入れ替えますか？'), [
        {
          text: t('いいえ'),
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: t('はい'),
          onPress: async () => {
            /**
             * NOTE
             * 予約１：テーブル１、２、３
             * 予約２：テーブルA、B、C
             * のとき、予約１をテーブル１、２，Aとしたい場合は
             * table_seat_ids = １、２、A
             * other_table_seats = ３、B、C
             * とする。
             * このとき、３を交換元、Aを交換先と呼ぶ
             */
            // 交換元の開放予定シートId 例）３
            const releaseSeatIds = restaurantReservation?.table_seats.flatMap(
              (original) =>
                selectedSeatIds.includes(original.id) ? [] : [original.id]
            )
            const selectedEndAt = dayjs(
              `${state.dateString} ${getFormatTime(endTime)}`
            )
            const startAt = dayjs(restaurantReservation?.start_at)
            const params = {
              end_at: selectedEndAt.format('YYYY-MM-DD HH:mm:ss'),
              table_seat_ids: selectedSeatIds,
              other_table_seats: restaurantReservations.flatMap(
                (reservation) => {
                  if (reservation.id === restaurantReservationId) return []
                  // 交換先シート 例）A
                  const exchangeSeatIds = reservation.table_seats.flatMap(
                    (tableSeat) =>
                      selectedSeatIds.includes(tableSeat.id)
                        ? [tableSeat.id]
                        : []
                  )

                  if (exchangeSeatIds.length === 0) return []

                  // 時間の重複チェック
                  // 予約時間が完全に前後している場合（終了時間と開始時間が同じ場合）は重複としない
                  const isOverlapping = !(
                    selectedEndAt.isSame(dayjs(reservation.start_at)) ||
                    startAt.isSame(dayjs(reservation.end_at)) ||
                    selectedEndAt.isBefore(dayjs(reservation.start_at)) ||
                    startAt.isAfter(dayjs(reservation.end_at))
                  )
                  if (!isOverlapping) return [] // 時間が重複していない場合はスキップ

                  // 交換先予約で交換しないシートIds 例) B、C
                  const otherReservationNewSeatIds =
                    reservation.table_seats.flatMap((tableSeat) => {
                      return !exchangeSeatIds.includes(tableSeat.id)
                        ? [tableSeat.id]
                        : []
                    })
                  // 交換作業 例) 3 <-> A
                  exchangeSeatIds.forEach(() => {
                    const releaseSeatId = releaseSeatIds?.shift()
                    if (releaseSeatId != null) {
                      otherReservationNewSeatIds.push(releaseSeatId)
                    }
                  })

                  return [
                    {
                      reservation_id: reservation.id,
                      table_seat_ids: otherReservationNewSeatIds,
                    },
                  ]
                }
              ),
            }
            const { error } = await exchangeRestaurantReservationTableSeats(
              token,
              restaurantId,
              restaurantReservationId,
              params
            )

            if (error != null) return
            if (updateAdultPartySize != null && updateChildPartySize != null) {
              const { error: e } = await updateRestaurantReservation(
                token,
                restaurantId,
                restaurantReservationId,
                {
                  adult_party_size: updateAdultPartySize,
                  child_party_size: updateChildPartySize,
                }
              )
              if (e != null) return
            }

            onComplete(t('席を交換しました'))
          },
        },
      ])
      return
    }

    const params: Partial<RestaurantReservationParams> = {
      table_seat_ids: selectedSeatIds,
    }

    if (updateAdultPartySize != null && updateChildPartySize != null) {
      params.adult_party_size = updateAdultPartySize
      params.child_party_size = updateChildPartySize
    }

    const { error } = await updateRestaurantReservation(
      token,
      restaurantId,
      restaurantReservationId,
      params
    )
    if (error != null) return
    onComplete(t('予約情報を更新しました'))
  }

  useEffect(() => {
    if (errors.length > 0) {
      const sequence: (() => void)[] = []
      const adjustment = Platform.OS === 'web' ? -12 : 24
      errors.forEach((error) => {
        sequence.push(() =>
          displayToastError(error, undefined, {
            marginBottom:
              width < sm
                ? editReservationFloatCardHeight - adjustment
                : undefined,
          })
        )
      })

      // editModeReservation が存在する場合 FloatCard が表示されるが、初回で高さ計算が間に合わない場合はずらして表示する
      if (editModeReservation != null && editReservationFloatCardHeight === 0) {
        setTimeout(() => {
          displayToastSequence(sequence)
        }, 500)
      } else {
        displayToastSequence(sequence)
      }
    }
  }, [errors, editReservationFloatCardHeight, width, sm, editModeReservation])

  return (
    <>
      <Chart
        restaurantId={restaurantId}
        ref={ref}
        date={date}
        mode="seatChange"
        editModeReservation={null}
        handleLongPressEditMode={() => {}}
        onEndEditMode={() => {}}
        selectParams={{
          restaurantReservation,
          startTime,
          endTime,
          setEndTime,
          partySize,
          selectedSeatIds,
          initialSelectedSeatIds: undefined,
          onChangeSelectedSeatIds: setSelectedSeatIds,
        }}
        scrollViewProps={{
          style: {
            backgroundColor: width < sm ? Colors.bgLightBlack : Colors.white,
            flex: 1,
          },
        }}
        {...chartProps}
      />
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents="box-none"
      >
        <AlertProvider ref={alertRef} />
      </View>
      {editModeReservation && (
        <EditReservationFloatCard
          restaurantId={restaurantId}
          editModeReservation={editModeReservation}
          onEndEditMode={goBack}
          handleUpdateReservation={onPressSubmit}
          onUpdateEditModeReservation={() => {}}
          isChangeSeatPage
          isDisabled={isDisabled}
          onChangeHeight={(height) => setEditReservationFloatCardHeight(height)}
          alertRef={alertRef}
        />
      )}
    </>
  )
}
