import React, { createContext, useContext } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'

import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import { usePreventScroll } from '../../modules/usePreventScroll'
import { TouchableOpacity } from '../Touchables'
import { FontAwesomeIcon } from '../FontAwesomeIcon'
import { Modal } from '../Modal'
import { Text } from '../Text'
import { Colors } from '../../constants/Colors'
import { useResponsive } from '../../modules/useResponsive'
import { useVisualViewportHeight } from './useVisualViewportHeight'
import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft'
import { useSafeAreaInsets } from '../../modules/useSafeAreaInsets'

type ModalCenterMode = 'default' | 'fullScreen'

const ModalCenterModeContext = createContext<ModalCenterMode>('default')

type ModalCenterModeProviderProps = {
  mode: ModalCenterMode
  children: React.ReactNode
}

export function ModalCenterModeProvider({
  mode,
  children,
}: ModalCenterModeProviderProps) {
  return (
    <ModalCenterModeContext.Provider value={mode}>
      {children}
    </ModalCenterModeContext.Provider>
  )
}

export function useModalCenterMode(): ModalCenterMode {
  const context = useContext(ModalCenterModeContext)
  if (context === undefined) {
    throw new Error(
      'useModalCenterMode must be used within a ModalCenterModeProvider'
    )
  }
  return context
}

export type ModalCenterProps = {
  mode?: ModalCenterMode
  isVisible: boolean
  onClose?: () => void
  style?: StyleProp<ViewStyle>
  contentContainerStyle?: StyleProp<ViewStyle>
  children?: React.ReactNode
  shouldDisableBackDropPress?: boolean
}

export function HeaderBackButton({
  variant = 'default',
  onPress,
  testID,
}: {
  variant?: 'default' | 'white' | 'primary'
  onPress?: () => void
  testID?: string
}) {
  return (
    <TouchableOpacity onPress={onPress} testID={testID}>
      <FontAwesomeIcon
        icon={faAngleLeft}
        color={
          variant === 'default' || variant === 'primary'
            ? Colors.primary
            : 'white'
        }
        size={24}
      />
    </TouchableOpacity>
  )
}

export function HeaderCloseButton({
  variant = 'default',
  onPress,
  testID,
}: {
  variant?: 'default' | 'white' | 'primary'
  onPress: () => void
  testID?: string
}) {
  return (
    <TouchableOpacity onPress={onPress} testID={testID}>
      <FontAwesomeIcon
        icon={faTimes}
        color={
          variant === 'default' || variant === 'primary'
            ? Colors.primary
            : 'white'
        }
        size={24}
      />
    </TouchableOpacity>
  )
}

export function ModalHeader({
  variant = 'default',
  onClose,
  headerLeft,
  children,
  style,
  closeButtonTestID,
}: {
  variant?: 'default' | 'white' | 'primary'
  onClose?: () => void
  headerLeft?: React.ReactNode
  children: string
  style?: StyleProp<ViewStyle>
  closeButtonTestID?: string
}) {
  const insets = useSafeAreaInsets()
  const mode = useModalCenterMode()

  if (onClose != null && headerLeft == null) {
    headerLeft = (
      <HeaderCloseButton
        variant={variant}
        onPress={onClose}
        testID={closeButtonTestID}
      />
    )
  }

  return (
    <View
      style={[
        {
          paddingTop: mode === 'fullScreen' ? insets.top : undefined,
          flexDirection: 'row',
          alignItems: 'center',
          padding: 16,
        },
        style,
      ]}
    >
      {headerLeft}
      <Text
        style={{
          flexShrink: 0,
          flex: 1,
          fontWeight: '600',
          textAlign: 'center',
          color:
            variant === 'default'
              ? Colors.black
              : variant === 'primary'
                ? Colors.primary
                : 'white',
          fontSize: 16,
        }}
      >
        {children}
      </Text>
      {headerLeft != null && (
        <View
          style={{
            width: 24,
          }}
        />
      )}
    </View>
  )
}

export function ModalCenter({
  mode = 'default',
  isVisible,
  onClose,
  style,
  contentContainerStyle,
  children,
  shouldDisableBackDropPress = false,
}: ModalCenterProps) {
  const { width, md } = useResponsive()
  const visualViewportHeight = useVisualViewportHeight()

  usePreventScroll({
    isDisabled: !isVisible,
  })

  return (
    <ModalCenterModeProvider mode={mode}>
      <Modal
        isVisible={isVisible}
        onBackdropPress={shouldDisableBackDropPress ? undefined : onClose}
        onBackButtonPress={onClose}
        style={[
          {
            alignSelf: 'center',
            width: 580,
          },
          width < md && {
            margin: 0,
            width: mode === 'default' ? 300 : '100%',
            maxHeight: visualViewportHeight,
          },
          style,
        ]}
        contentContainerStyle={[
          {
            backgroundColor: 'white',
            maxHeight: visualViewportHeight,
            overflow: 'hidden',
            borderRadius: 8,
          },
          width < md &&
            mode === 'fullScreen' && {
              height: '100%',
              borderRadius: 0,
            },
          contentContainerStyle,
        ]}
      >
        {children}
      </Modal>
    </ModalCenterModeProvider>
  )
}
