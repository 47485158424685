import React, { useEffect, useMemo } from 'react'

import { usePDF } from '@react-pdf/renderer'
import { Text, View } from 'react-native'

import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

import { ChartViewPDF, ChartViewPDFProps } from './ChartView'
import { ReservationData, ReservationListView } from './ListView'
import { ListViewPDF } from './ListViewPDF'
import { usePrintChartView, usePrintListView } from './hooks'
import { transformReservations } from './models'
import { useMetaPDF } from './resize'

type Props = (
  | {
      kind: 'list'
    }
  | {
      kind: 'chart'
    }
) & {
  restaurantId: number
  reservationDate: dayjs.Dayjs
}

type PDFGeneratorForRNProps = Omit<Extract<Props, { kind: 'list' }>, 'kind'> & {
  heights: number[]
  data: ReservationData[]
  restaurantName: string
  latestUpdatedAt: string
}

function ListViewPDFGeneratorForRN(
  props: PDFGeneratorForRNProps
): JSX.Element | null {
  const [listViewInstance] = usePDF({
    document: <ListViewPDF {...props} reservations={props.data} />,
  })

  // Native側からのメッセージを受け取るためのグローバル関数を定義
  useEffect(() => {
    try {
      // @ts-expect-error
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ type: 'running-list' })
      )
      const send = async () => {
        if (
          // @ts-expect-error
          window.ReactNativeWebView != null &&
          !listViewInstance.loading &&
          listViewInstance.blob != null
        ) {
          const arrayBuffer = await listViewInstance.blob.arrayBuffer()
          const uint8Array = new Uint8Array(arrayBuffer)
          const base64Data = Buffer.from(uint8Array).toString('base64')
          // @ts-expect-error
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'chart',
              data: base64Data,
            })
          )
        }
      }
      void send()
    } catch (error) {
      console.error('Error in ListViewPDFGeneratorForRN useEffect:', error)
    }

    return () => {
      // @ts-ignore
      delete window.onMessageList2
    }
  }, [listViewInstance])

  // 画面再レンダリングのため必要
  return <Text>List</Text>
}

function ListViewPrintButtonForRN(
  props: Omit<PDFGeneratorForRNProps, 'heights'>
): JSX.Element | null {
  const memoizedPDFProps = useMemo(
    () =>
      props.data.map((restaurantReservation) => ({
        restaurantReservation,
      })),
    [props.data]
  )

  const { heights, generators, isReady } = useMetaPDF({
    component: ReservationListView,
    props: memoizedPDFProps,
    getStableKey: (prop) => `reservation-${prop.restaurantReservation.id}`,
  })

  return (
    <View>
      <View style={{ display: 'none' }}>{generators}</View>

      {isReady ? (
        <ListViewPDFGeneratorForRN heights={heights} {...props} />
      ) : null}
    </View>
  )
}

function ChartViewPDFGeneratorForRN(
  props: ChartViewPDFProps
): JSX.Element | null {
  const [chartViewInstance] = usePDF({
    document: <ChartViewPDF {...props} />,
  })

  // Native側からのメッセージを受け取るためのグローバル関数を定義
  useEffect(() => {
    try {
      // @ts-expect-error
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ type: 'running-chart' })
      )
      const send = async () => {
        if (
          // @ts-expect-error
          window.ReactNativeWebView != null &&
          !chartViewInstance.loading &&
          chartViewInstance.blob != null
        ) {
          const arrayBuffer = await chartViewInstance.blob.arrayBuffer()
          const uint8Array = new Uint8Array(arrayBuffer)
          const base64Data = Buffer.from(uint8Array).toString('base64')
          // @ts-expect-error
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({
              type: 'chart',
              data: base64Data,
            })
          )
        }
      }
      void send()
    } catch (error) {
      console.error('Error in ChartViewPDFGeneratorForRN useEffect:', error)
    }
  }, [chartViewInstance])

  // 画面再レンダリングのため必要
  return <Text>Chart</Text>
}

export function PrintButton(props: Props): JSX.Element | null {
  const { reservations, isLoading } = usePrintListView(
    props.restaurantId,
    props.reservationDate
  )
  const chartProps = usePrintChartView(
    props.restaurantId,
    props.reservationDate
  )

  const dateKey = props.reservationDate.format('YYYY-MM-DD')
  if (isLoading || chartProps.isLoading) {
    return <Text>Loading...</Text>
  }
  switch (props.kind) {
    case 'list':
      if (reservations.length === 0) {
        // @ts-expect-error
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: 'empty' })
        )
        return null
      }
      return (
        <ListViewPrintButtonForRN
          {...props}
          key={`list-print-${props.restaurantId}-${dateKey}-${JSON.stringify(chartProps)}`}
          data={transformReservations(reservations)}
          restaurantName={chartProps.restaurantName}
          latestUpdatedAt={chartProps.latestUpdatedAt}
        />
      )
    case 'chart':
      return (
        <ChartViewPDFGeneratorForRN
          key={`chart-print-${props.restaurantId}-${dateKey}-${JSON.stringify(chartProps)}`}
          {...chartProps}
          {...props}
        />
      )
  }
}
