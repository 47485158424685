import React from 'react'

import { faCakeCandles } from '@fortawesome/pro-solid-svg-icons/faCakeCandles'
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons/faPhoneAlt'
import { StyleSheet, Text, View } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import type { UseNeedConfirmReservations } from './hooks'

type Props = {
  reservationAmounts: UseNeedConfirmReservations
  isOpenAccordion: boolean
}
export function NeedConfirmReservationItem({
  reservationAmounts: {
    requestReservationAmount,
    smartPaymentAmount,
    unallocatedSeatAmount,
    vipAmount,
    cautionAmount,
    birthdayAmount,
    confirmationAmount,
  },
  isOpenAccordion,
}: Props) {
  const { width, sm } = useResponsive()

  const renderItem = (
    label: string,
    count: number,
    icon?: () => JSX.Element
  ) => (
    <View style={styles.item}>
      {icon && icon()}
      <Text style={styles.itemLabel}>{label}</Text>
      <Text style={styles.itemCount}>
        <Text style={styles.itemCountUnit}>
          {count}
          {t('件')}
        </Text>
      </Text>
    </View>
  )

  return (
    <View
      style={width > sm ? styles.itemsContainer : mobileStyles.itemsContainer}
    >
      {isOpenAccordion ? (
        <>
          {requestReservationAmount > 0 &&
            renderItem(t('リクエスト承認待ち'), requestReservationAmount)}
          {unallocatedSeatAmount > 0 &&
            renderItem(t('座席指定なし'), unallocatedSeatAmount)}
          {smartPaymentAmount > 0 &&
            renderItem(t('スマート決済あり'), smartPaymentAmount, SmartIcon)}
          {vipAmount > 0 && renderItem(t('VIP'), vipAmount, VipIcon)}
          {cautionAmount > 0 &&
            renderItem(t('要注意'), cautionAmount, CautionIcon)}
          {birthdayAmount > 0 &&
            renderItem(t('誕生日'), birthdayAmount, BirthdayIcon)}
          {confirmationAmount > 0 &&
            renderItem(t('確認事項あり'), confirmationAmount, ConfirmationIcon)}
        </>
      ) : (
        <>
          {requestReservationAmount > 0
            ? renderItem(t('リクエスト承認待ち'), requestReservationAmount)
            : unallocatedSeatAmount > 0
              ? renderItem(t('座席指定なし'), unallocatedSeatAmount)
              : smartPaymentAmount > 0
                ? renderItem(
                    t('スマート決済あり'),
                    smartPaymentAmount,
                    SmartIcon
                  )
                : vipAmount > 0
                  ? renderItem(t('VIP'), vipAmount, VipIcon)
                  : cautionAmount > 0
                    ? renderItem(t('要注意'), cautionAmount, CautionIcon)
                    : birthdayAmount > 0
                      ? renderItem(t('誕生日'), birthdayAmount, BirthdayIcon)
                      : birthdayAmount > 0
                        ? renderItem(
                            t('確認事項あり'),
                            confirmationAmount,
                            ConfirmationIcon
                          )
                        : null}
        </>
      )}
    </View>
  )
}

function SmartIcon() {
  return (
    <View
      style={{
        width: 16,
        height: 16,
        backgroundColor: Colors.primary,
        borderColor: Colors.white,
        borderWidth: 1,
        borderRadius: 16,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 2,
      }}
    >
      <Text
        style={{
          fontSize: 10,
          lineHeight: 14,
          fontWeight: '600',
          color: Colors.white,
        }}
      >
        {t('ス')}
      </Text>
    </View>
  )
}

function VipIcon() {
  return (
    <View style={{ width: 28, height: 16, marginRight: 2 }}>
      <Image
        style={{
          height: 16,
          width: 28,
        }}
        source={require('../../../assets/images/chart_vip.png')}
      />
    </View>
  )
}

function CautionIcon() {
  return (
    <View style={{ width: 16, height: 16, marginRight: 2 }}>
      <Image
        style={{
          height: 16,
          width: 16,
        }}
        source={require('../../../assets/images/chart_caution.png')}
      />
    </View>
  )
}

function BirthdayIcon() {
  return (
    <View style={{ width: 16, height: 16, marginRight: 2 }}>
      <FontAwesomeIcon icon={faCakeCandles} size={16} color={Colors.black40} />
    </View>
  )
}

function ConfirmationIcon() {
  return (
    <View style={{ width: 16, height: 16, marginRight: 2 }}>
      <FontAwesomeIcon icon={faPhoneAlt} size={16} color={Colors.black40} />
    </View>
  )
}

const styles = StyleSheet.create({
  itemsContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 16,
    gap: 24,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemLabel: {
    fontSize: 14,
    color: Colors.black,
    marginRight: 4,
  },
  itemCount: {
    fontSize: 14,
    fontWeight: '600',
  },
  itemCountUnit: {
    fontSize: 14,
    fontWeight: '600',
    marginLeft: 1,
  },
})

const mobileStyles = StyleSheet.create({
  itemsContainer: {
    ...styles.itemsContainer,
    alignItems: 'flex-start',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 0,
    gap: 6,
    flexDirection: 'column',
  },
})
