import React, { useState } from 'react'

import { View } from 'react-native'
import { useNavigate, useParams } from 'react-router'

import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { DetailModalContent } from '@hello-ai/for_r_app/src/components/Reservation/RequestView/DetailModalContent'
import RejectModal from '@hello-ai/for_r_app/src/components/Reservation/RequestView/RejectModal'
import {
  ReservationRequestToastKinds,
  useReservationRequestToast,
} from '@hello-ai/for_r_app/src/components/Reservation/RequestView/hooks/useReservationRequestToast'
import { useRestaurant } from '@hello-ai/for_r_app/src/models/Restaurant'
import {
  rejectionRequestReservation,
  useRestaurantRequestReservation,
} from '@hello-ai/for_r_app/src/models/RestaurantRequestReservation'

import { useRestaurantId } from 'modules/useRestaurantId'

function RequestReservationShowPage() {
  const token = useToken()
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()
  const { data: restaurant } = useRestaurant(restaurantId)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const { restaurantRequestReservationId } = useParams<{
    restaurantRequestReservationId: string
  }>()
  const { requestReservation: data } = useRestaurantRequestReservation(
    restaurantId,
    parseInt(restaurantRequestReservationId!)
  )
  const { displayRemainingRequest } = useReservationRequestToast({
    restaurantId,
    kind: ReservationRequestToastKinds.Reject,
  })

  const isInPossibleRange =
    data != null
      ? data.start_at === data.start_at_min &&
        data.start_at === data.start_at_max
      : false
  const shouldSkipSeatSelection =
    restaurant?.reservation_book_plan_type === 'entry'

  const onPressApproval = () => {
    const skipSteps = isInPossibleRange ? [1, 3] : [3]
    if (shouldSkipSeatSelection) {
      skipSteps.push(2)
      skipSteps.sort()
    }
    navigate(
      `/restaurants/${restaurantId}/reservations/requests/${restaurantRequestReservationId}/form?skipSteps=${skipSteps.join(
        ','
      )}&mode=request`
    )
  }

  if (restaurant == null) return null

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        <DetailModalContent
          restaurantId={restaurantId}
          data={data}
          isInPossibleRange={isInPossibleRange}
          onPressApproval={onPressApproval}
          onPressRejection={() => setIsModalVisible(true)}
        />
      </View>
      <RejectModal
        isModalVisible={isModalVisible}
        onClose={() => {
          setIsModalVisible(false)
        }}
        onPressRejection={async ({ cancelReason, otherCancelReason }) => {
          if (data == null || token == null) return
          const startAt = dayjs(data.start_at)
          const dateString = startAt.format('YYYY-MM-DD')
          await rejectionRequestReservation(token, restaurantId, data.id, {
            date: dateString,
            cancel_reason:
              cancelReason ||
              t('{{reason}}ため', { reason: otherCancelReason }),
          })
          setIsModalVisible(false)
          await displayRemainingRequest({ dateString, startTime: 0 })
        }}
      />
    </View>
  )
}

export default RequestReservationShowPage
