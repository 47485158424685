import React, { useCallback, useEffect } from 'react'

import { faSearchMinus } from '@fortawesome/pro-regular-svg-icons/faSearchMinus'
import { faSearchPlus } from '@fortawesome/pro-regular-svg-icons/faSearchPlus'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { View, ViewStyle } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  clamp,
  withTiming,
} from 'react-native-reanimated'

import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import { useComponentSize } from '../../../modules/useComponentSize'

export function FreeDirectionalScrollView({
  containerStyle,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  contentSize,
  children,
  initialScale,
  onPressContentView,
  onChangeScrollPosition,
  onChangeScale,
}: {
  paddingLeft: number
  paddingRight: number
  paddingTop: number
  paddingBottom: number
  containerStyle?: Omit<
    ViewStyle,
    | 'padding'
    | 'paddingVertical'
    | 'paddingHorizontal'
    | 'paddingTop'
    | 'paddingLeft'
    | 'paddingRight'
    | 'paddingBottom'
  >
  contentSize: { width: number; height: number }
  children: React.ReactNode
  initialScale?: number
  onPressContentView?: () => void
  onChangeScrollPosition?: (position: { x: number; y: number }) => void
  onChangeScale?: (scale: number) => void
}) {
  const [containerSize, , onLayoutContainer] = useComponentSize()
  const [isSetInitialScale, setIsSetInitialScale] = React.useState(false)

  const isDragging = useSharedValue(false)
  const startX = useSharedValue(0)
  const offsetX = useSharedValue(0)
  const startY = useSharedValue(0)
  const offsetY = useSharedValue(0)

  const scale = useSharedValue(1)
  const startScale = useSharedValue(0)

  const allVisibleMinScale = Math.min(
    (containerSize.width - paddingLeft - paddingRight) / contentSize.width,
    (containerSize.height - paddingTop - paddingBottom) / contentSize.height
  )

  const maxScale = Math.max(1.8, initialScale ?? 1.8)
  const minScale = Math.min(allVisibleMinScale, 1)
  const scaleChangeInterval = (maxScale - minScale) / 8

  const calcMinXFromScale = useCallback(
    (scale: number) => {
      const paddingHorizontal = paddingLeft + paddingRight
      return (
        containerSize.width -
        contentSize.width -
        (contentSize.width * (scale - 1)) / 2 -
        paddingHorizontal
      )
    },
    [containerSize.width, contentSize.width, paddingLeft, paddingRight]
  )

  const calcMinYFromScale = useCallback(
    (scale: number) => {
      const paddingVertical = paddingTop + paddingBottom
      return (
        containerSize.height -
        contentSize.height -
        (contentSize.height * (scale - 1)) / 2 -
        paddingVertical
      )
    },
    [containerSize.height, contentSize.height, paddingBottom, paddingTop]
  )

  const calcMaxXFromScale = useCallback(
    (scale: number) => {
      return (contentSize.width * (scale - 1)) / 2
    },
    [contentSize.width]
  )

  const calcMaxYFromScale = useCallback(
    (scale: number) => {
      return (contentSize.height * (scale - 1)) / 2
    },
    [contentSize.height]
  )

  const calcCurrentPosition = useCallback(
    (scale: number, offsetX: number, offsetY: number) => {
      return {
        x: (-offsetX + (contentSize.width * (scale - 1)) / 2) / scale,
        y: (-offsetY + (contentSize.height * (scale - 1)) / 2) / scale,
      }
    },
    [contentSize.height, contentSize.width]
  )

  const pinchGesture = Gesture.Pinch()
    .onStart(() => {
      // eslint-disable-next-line react-compiler/react-compiler
      startScale.set(scale.get())
    })
    .onUpdate((event) => {
      scale.set(clamp(startScale.get() * event.scale, minScale, maxScale))
    })
    .onEnd(() => {
      const minX = calcMinXFromScale(scale.get())
      const minY = calcMinYFromScale(scale.get())
      const maxX = calcMaxXFromScale(scale.get())
      const maxY = calcMaxYFromScale(scale.get())

      const nextX = clamp(offsetX.get(), minX, maxX)
      const nextY = clamp(offsetY.get(), minY, maxY)

      offsetX.set(withTiming(nextX))
      offsetY.set(withTiming(nextY))
      startX.set(nextX)
      startY.set(nextY)

      const currentPosition = calcCurrentPosition(scale.get(), nextX, nextY)
      onChangeScrollPosition?.(currentPosition)
      onChangeScale?.(scale.get())
    })
    .runOnJS(true)

  const dragGesture = Gesture.Pan()
    .runOnJS(true)
    .onStart(() => {
      isDragging.set(true)
    })
    .onUpdate((e) => {
      if (!isDragging.get()) return
      const nextX = e.translationX + startX.get()
      const nextY = e.translationY + startY.get()

      const minX = calcMinXFromScale(scale.get())
      const minY = calcMinYFromScale(scale.get())
      const maxX = calcMaxXFromScale(scale.get())
      const maxY = calcMaxYFromScale(scale.get())

      offsetX.set(clamp(nextX, minX, maxX))
      offsetY.set(clamp(nextY, minY, maxY))
    })
    .onEnd(() => {
      startY.set(offsetY.get())
      startX.set(offsetX.get())

      const currentPosition = calcCurrentPosition(
        scale.get(),
        offsetX.get(),
        offsetY.get()
      )
      onChangeScrollPosition?.(currentPosition)
      isDragging.set(false)
    })

  const tapGesture = Gesture.Tap()
    .runOnJS(true)
    .onEnd(() => {
      onPressContentView?.()
    })

  const contentViewGestures = Gesture.Race(dragGesture, tapGesture)

  const dragAnimatedStyle = useAnimatedStyle((): ViewStyle => {
    return {
      transform: [{ translateX: offsetX.get() }, { translateY: offsetY.get() }],
    }
  }, [])

  const scaleAnimatedStyle = useAnimatedStyle(() => ({
    transform: [{ scale: scale.get() }],
  }))

  useEffect(() => {
    if (initialScale != null && !isSetInitialScale) {
      setIsSetInitialScale(true)
      scale.set(withTiming(initialScale, { duration: 10 }))
      onChangeScale?.(initialScale)

      const maxX = calcMaxXFromScale(initialScale)
      const maxY = calcMaxYFromScale(initialScale)

      const nextX = maxX
      const nextY = maxY

      offsetX.set(withTiming(nextX, { duration: 10 }))
      offsetY.set(withTiming(nextY, { duration: 10 }))
      startX.set(nextX)
      startY.set(nextY)

      const currentPosition = calcCurrentPosition(initialScale, nextX, nextY)
      onChangeScrollPosition?.(currentPosition)
    }
  }, [
    calcCurrentPosition,
    calcMaxXFromScale,
    calcMaxYFromScale,
    calcMinXFromScale,
    calcMinYFromScale,
    initialScale,
    offsetX,
    offsetY,
    onChangeScale,
    onChangeScrollPosition,
    scale,
    startX,
    startY,
    isSetInitialScale,
  ])

  return (
    <GestureDetector gesture={pinchGesture}>
      <View
        style={[
          containerStyle,
          { paddingTop, paddingLeft, paddingBottom, paddingRight },
          { overflow: 'hidden', position: 'relative' },
        ]}
        onLayout={onLayoutContainer}
      >
        <View
          style={{
            position: 'absolute',
            zIndex: 999,
            top: 8,
            right: 8,
            flexDirection: 'row',
            gap: 8,
          }}
        >
          <View
            style={{
              backgroundColor: Colors.white,
              width: 48,
              height: 48,
              borderRadius: 24,
            }}
          >
            <TouchableOpacity
              onPressMinInterval={100}
              style={{
                width: 48,
                height: 48,
                backgroundColor: Colors.primary10,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 24,
              }}
              onPress={() => {
                const next = clamp(
                  scale.get() + scaleChangeInterval,
                  minScale,
                  maxScale
                )
                scale.set(withTiming(next))
                onChangeScale?.(next)

                const minX = calcMinXFromScale(scale.get())
                const minY = calcMinYFromScale(scale.get())
                const maxX = calcMaxXFromScale(scale.get())
                const maxY = calcMaxYFromScale(scale.get())

                const nextX = clamp(offsetX.get(), minX, maxX)
                const nextY = clamp(offsetY.get(), minY, maxY)

                offsetX.set(withTiming(nextX))
                offsetY.set(withTiming(nextY))
                startX.set(nextX)
                startY.set(nextY)

                const currentPosition = calcCurrentPosition(
                  scale.get(),
                  nextX,
                  nextY
                )
                onChangeScrollPosition?.(currentPosition)
              }}
            >
              <FontAwesomeIcon
                icon={faSearchPlus}
                size={20}
                color={Colors.primary}
              />
            </TouchableOpacity>
          </View>
          <View
            style={{
              backgroundColor: Colors.white,
              width: 48,
              height: 48,
              borderRadius: 24,
            }}
          >
            <TouchableOpacity
              onPressMinInterval={100}
              style={{
                width: 48,
                height: 48,
                backgroundColor: Colors.primary10,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 24,
              }}
              onPress={() => {
                const next = clamp(
                  scale.get() - scaleChangeInterval,
                  minScale,
                  maxScale
                )
                scale.set(withTiming(next))
                onChangeScale?.(next)

                const minX = calcMinXFromScale(next)
                const minY = calcMinYFromScale(next)
                const maxX = calcMaxXFromScale(next)
                const maxY = calcMaxYFromScale(next)

                const nextX = clamp(offsetX.get(), minX, maxX)
                const nextY = clamp(offsetY.get(), minY, maxY)

                offsetX.set(withTiming(nextX))
                offsetY.set(withTiming(nextY))
                startX.set(nextX)
                startY.set(nextY)

                const currentPosition = calcCurrentPosition(next, nextX, nextY)
                onChangeScrollPosition?.(currentPosition)
              }}
            >
              <FontAwesomeIcon
                icon={faSearchMinus}
                size={20}
                color={Colors.primary}
              />
            </TouchableOpacity>
          </View>
        </View>
        <GestureDetector gesture={contentViewGestures}>
          <Animated.View
            style={[
              { width: contentSize.width, height: contentSize.height },
              dragAnimatedStyle,
              { overflow: '' },
            ]}
          >
            <Animated.View
              style={[
                {
                  width: contentSize.width,
                  height: contentSize.height,
                },
                scaleAnimatedStyle,
              ]}
            >
              {children}
            </Animated.View>
          </Animated.View>
        </GestureDetector>
      </View>
    </GestureDetector>
  )
}
