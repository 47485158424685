import React, { useState } from 'react'

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faArrowDownArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowDownArrowUp'
import { Platform, View } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { useRestaurantCourses } from '../../models/RestaurantCourse'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { AddFloatingButton } from '../Shared/AddFloatingButton'
import Loading from '../Shared/Loading'

import { RestaurantCourse } from './RestaurantCourse'
import SupplyCalendarModal from './SupplyCalendarModal'

interface RestaurantCourseListViewProps {
  restaurantId: number
}

export default function RestaurantCourseListView({
  restaurantId,
}: RestaurantCourseListViewProps) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { width, sm } = useResponsive()
  const { restaurantCourses } = useRestaurantCourses(restaurantId)

  const onPressCreateRestaurantCourse = () => {
    if (Platform.OS === 'web') {
      navigate(`/restaurants/${restaurantId}/courses/new`)
    } else {
      navigation.navigate('RestaurantCourseForm')
    }
  }

  const onPressQuestionRestaurantCourse = () => {
    if (Platform.OS === 'web') {
      navigate(`/restaurants/${restaurantId}/course_questions`)
    } else {
      navigation.navigate('RestaurantCourseQuestions')
    }
  }

  const onPressOrderRestaurantCourse = () => {
    if (Platform.OS === 'web') {
      navigate(`/restaurants/${restaurantId}/courses/edit_order`)
    } else {
      navigation.navigate('RestaurantCourseEditOrder')
    }
  }

  const [isVisibleSupplyCalendarModal, setIsVisibleSupplyCalendarModal] =
    useState(false)

  if (restaurantCourses == null) {
    return <Loading />
  }

  return (
    <View
      style={[
        { position: 'relative', flex: 1 },
        Platform.OS === 'web' && { flex: 1, backgroundColor: Colors.bgBlack },
      ]}
    >
      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: Colors.bgBlack }}
        contentContainerStyle={[
          width < sm ? { padding: 24 } : { padding: 32 },
          { paddingBottom: 100 }, // 追加ボタンのための余白
        ]}
      >
        <View
          style={{
            flexDirection: width >= sm ? 'row' : 'column',
            columnGap: 24,
            rowGap: 12,
            alignItems: 'center',
          }}
        >
          <Button
            mode="contained"
            variant="secondary"
            style={{
              height: 96,
              paddingHorizontal: 20,
              paddingVertical: 20,
              width: '100%',
              flex: width >= sm ? 1 : undefined,
            }}
            onPress={onPressQuestionRestaurantCourse}
          >
            <View style={{ flex: 1, gap: 8 }}>
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: 18,
                  lineHeight: 18,
                }}
              >
                {t('お客様への質問を設定')}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: '300',
                  lineHeight: 14,
                }}
              >
                {t('予約時にお客様に確認したい質問を作成できます')}
              </Text>
            </View>
            <FontAwesomeIcon icon={faChevronRight} size={14} />
          </Button>
          <Button
            mode="contained"
            variant="secondary"
            style={{
              height: 96,
              paddingHorizontal: 20,
              paddingVertical: 20,
              width: '100%',
              flex: width >= sm ? 1 : undefined,
            }}
            onPress={() => {
              setIsVisibleSupplyCalendarModal(true)
            }}
          >
            <View style={{ flex: 1, gap: 8 }}>
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: 18,
                  lineHeight: 18,
                }}
              >
                {t('コース提供可能日を設定')}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: '300',
                  lineHeight: 14,
                }}
              >
                {t('コースごとに提供する日を設定します')}
              </Text>
            </View>
            <FontAwesomeIcon icon={faChevronRight} size={14} />
          </Button>
        </View>
        <View
          style={{
            flexDirection: width >= sm ? 'row' : 'column',
            marginTop: 24,
            gap: 16,
            alignItems: width >= sm ? 'center' : 'flex-end',
          }}
        >
          <Text
            style={{
              fontSize: 14,
              color: Colors.black60,
              flex: 1,
            }}
          >
            {t(
              '予約サービスAutoReserveで使用します。セルフオーダーには反映されません。'
            )}
          </Text>
          <Button
            mode="text"
            style={{
              backgroundColor: Colors.primary10,
              borderWidth: 0,
              borderRadius: 18,
            }}
            onPress={onPressOrderRestaurantCourse}
          >
            <FontAwesomeIcon
              icon={faArrowDownArrowUp}
              size={16}
              color={Colors.primary}
            />
            <Text
              style={{
                color: Colors.primary,
                fontSize: 14,
                fontWeight: '300',
                marginLeft: 4,
              }}
            >
              {t('コースの並べ替え')}
            </Text>
          </Button>
        </View>
        <ShadowBox style={{ marginTop: 16, padding: 0 }}>
          {restaurantCourses.map((restaurantCourse, index) => {
            return (
              <React.Fragment key={restaurantCourse.id}>
                <View
                  style={{
                    marginHorizontal: 24,
                    borderTopWidth: index === 0 ? 0 : 0.5,
                    borderColor: Colors.border,
                  }}
                />
                <RestaurantCourse
                  key={restaurantCourse.id}
                  restaurantCourse={restaurantCourse}
                  onPress={() => {
                    if (Platform.OS === 'web') {
                      navigate(
                        `/restaurants/${restaurantId}/courses/${restaurantCourse.id}`
                      )
                    } else {
                      navigation.navigate('RestaurantCourseForm', {
                        restaurantCourseId: restaurantCourse.id,
                      })
                    }
                  }}
                />
              </React.Fragment>
            )
          })}
        </ShadowBox>
      </KeyboardAwareScrollView>
      <AddFloatingButton
        title={width >= sm ? t('コース\n追加') : t('追加')}
        onPress={onPressCreateRestaurantCourse}
      />
      <SupplyCalendarModal
        restaurantId={restaurantId}
        isVisible={isVisibleSupplyCalendarModal}
        onClose={() => setIsVisibleSupplyCalendarModal(false)}
      />
    </View>
  )
}
