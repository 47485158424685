import React from 'react'

import { Platform, View } from 'react-native'
import { useNavigate } from 'react-router'

import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { AddFloatingButton } from '@hello-ai/for_r_app/src/components/Shared/AddButton'

import LinkOpacity from 'components/Shared/LinkOpacity'
import {
  RestaurantBusinessTime as RestaurantBusinessTimeModel,
  useRestaurantBusinessTimes,
} from 'models/RestaurantBusinessTime'
import { getFormatTime, splitHourAndMinute, wdayShortName } from 'modules/time'
import { useRestaurantId } from 'modules/useRestaurantId'

function RestaurantBusinessTime({
  restaurantBusinessTime,
}: {
  restaurantBusinessTime: RestaurantBusinessTimeModel
}) {
  const restaurantId = useRestaurantId()
  const stayingTime =
    restaurantBusinessTime.staying_time != null
      ? splitHourAndMinute(restaurantBusinessTime.staying_time)
      : null

  return (
    <LinkOpacity
      style={{ flexDirection: 'row', gap: 16 }}
      to={`/restaurants/${restaurantId}/business_times/${restaurantBusinessTime.id}/edit`}
    >
      <Text style={{ flex: 1 }}>{restaurantBusinessTime.name}</Text>
      <Text style={{ flex: 1 }}>
        {getFormatTime(restaurantBusinessTime.start_time)}
      </Text>
      <Text style={{ flex: 1 }}>
        {getFormatTime(restaurantBusinessTime.last_order_time)}
      </Text>
      <Text style={{ flex: 1 }}>
        {getFormatTime(restaurantBusinessTime.end_time)}
      </Text>
      <Text style={{ flex: 1 }}>
        {stayingTime !== null
          ? `${`${
              stayingTime.hour === 0
                ? ''
                : t('{{hours}}時間', { hours: stayingTime.hour })
            }${
              stayingTime.minute === 0
                ? ''
                : t('{{minutes}}分', { minutes: stayingTime.minute })
            }`}`
          : '-'}
      </Text>
      <Text style={{ flex: 1 }}>
        {wdayShortName(restaurantBusinessTime.wdays)}
      </Text>
    </LinkOpacity>
  )
}

export default function RestaurantBusinessTimes() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()
  const { restaurantBusinessTimes } = useRestaurantBusinessTimes(restaurantId)

  return (
    <View
      style={[
        { position: 'relative', flex: 1 },
        Platform.OS === 'web' && { flex: 1, backgroundColor: Colors.bgBlack },
      ]}
    >
      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: Colors.bgBlack }}
        contentContainerStyle={[
          width < sm ? { padding: 24 } : { padding: 32 },
          { paddingBottom: 100 },
        ]}
      >
        <ShadowBox
          style={{
            paddingHorizontal: 0,
            paddingVertical: 0,
          }}
        >
          <View
            style={{
              paddingHorizontal: 24,
              flexDirection: 'row',
              borderBottomWidth: 1,
              borderColor: Colors.border,
              paddingVertical: 16,
              alignItems: 'flex-end',
              gap: 16,
            }}
          >
            <Text style={{ flex: 1, fontWeight: '600' }}>{t('名称')}</Text>
            <Text style={{ flex: 1, fontWeight: '600' }}>{t('開始')}</Text>
            <Text style={{ flex: 1, fontWeight: '600' }}>
              {t('ラストオーダー')}
            </Text>
            <Text style={{ flex: 1, fontWeight: '600' }}>{t('終了')}</Text>
            <Text style={{ flex: 1, fontWeight: '600' }}>{t('滞在')}</Text>
            <Text style={{ flex: 1, fontWeight: '600' }}>{t('曜日')}</Text>
          </View>

          {restaurantBusinessTimes?.map(
            (
              restaurantBusinessTime: RestaurantBusinessTimeModel,
              index: number
            ) => {
              return (
                <View
                  key={restaurantBusinessTime.id}
                  style={{
                    paddingVertical: 24,
                    paddingHorizontal: 24,
                    position: 'relative',
                  }}
                >
                  {index !== 0 && (
                    <View
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 24,
                        right: 24,
                        height: 0.5,
                        backgroundColor: Colors.border,
                      }}
                    />
                  )}

                  <RestaurantBusinessTime
                    restaurantBusinessTime={restaurantBusinessTime}
                  />
                </View>
              )
            }
          )}
        </ShadowBox>
      </KeyboardAwareScrollView>
      <AddFloatingButton
        title={width >= sm ? t('営業時間\n追加') : t('追加')}
        onPress={() =>
          navigate(`/restaurants/${restaurantId}/business_times/new`)
        }
      />
    </View>
  )
}
