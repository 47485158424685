import React from 'react'

import { StyleSheet, Text, View } from '@react-pdf/renderer'
import uniq from 'lodash/uniq'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { HEADER_CELL_WIDTH } from './const'
import { NO_SEAT_ASSIGNED_ID, REQUEST_ID, RowChunk } from './types'

const styles = StyleSheet.create({
  root: {
    width: HEADER_CELL_WIDTH,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 4,
    gap: 2,

    borderColor: '#D6D6D6',
    borderWidth: 1,
    backgroundColor: '#FFFFFF',
  },
  text: {
    color: '#444444',
    fontSize: 8,
    fontFamily: 'NotoSansJPBold',
  },
  partySizeText: {
    color: '#444444',
    fontSize: 8,
    fontFamily: 'NotoSansJPRegular',
  },
})

type Props = RowChunk
export function LeftContent(props: Props) {
  const noSeats = props.rows.filter(
    (reservation) => reservation.tableSeat.id === NO_SEAT_ASSIGNED_ID
  )
  const noSeat = noSeats.length > 0 ? noSeats[0] : null
  const requests = props.rows.filter(
    (reservation) => reservation.tableSeat.id === REQUEST_ID
  )
  const request = requests.length > 0 ? requests[0] : null
  const reservations = props.rows.filter(
    (reservation) =>
      reservation.tableSeat.id !== NO_SEAT_ASSIGNED_ID &&
      reservation.tableSeat.id !== REQUEST_ID
  )
  const tableSeats = uniq(
    [noSeat, request, ...reservations].filter((r) => r != null)
  )

  return (
    <View style={styles.root}>
      {tableSeats.map((tableSeat, index) => (
        <View
          key={tableSeat.tableSeat.id}
          style={[
            styles.container,
            {
              borderTopWidth: index === 0 ? styles.container.borderWidth : 0,
              height:
                tableSeat.tableSeat.id === NO_SEAT_ASSIGNED_ID
                  ? 40 * noSeats.length
                  : tableSeat.tableSeat.id === REQUEST_ID
                    ? 40 * requests.length
                    : 40,
            },
          ]}
        >
          <Text style={styles.text}>
            {tableSeat.tableSeat.id === NO_SEAT_ASSIGNED_ID
              ? t('座席指定なし')
              : tableSeat.tableSeat.id === REQUEST_ID
                ? t('承認待ち')
                : tableSeat.tableSeat.name}
          </Text>
          <Text style={styles.partySizeText}>
            {tableSeat?.tableSeat.id === NO_SEAT_ASSIGNED_ID ||
            tableSeat?.tableSeat.id === REQUEST_ID
              ? t('{{count}}件', {
                  count:
                    tableSeat?.tableSeat.id === NO_SEAT_ASSIGNED_ID
                      ? noSeats.length
                      : tableSeat?.tableSeat.id === REQUEST_ID
                        ? requests.length
                        : 0,
                })
              : `${tableSeat?.tableSeat.minPartySize}~${tableSeat?.tableSeat.maxPartySize}`}
          </Text>
        </View>
      ))}
    </View>
  )
}
