/* eslint-disable ar-i18n/require-translation-ja */
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import useSWR, { fetcher, swrKey } from 'modules/swr'

import { useToken } from './Auth'

// SEE: https://stripe.com/docs/api/payouts/object#payout_object-status
export const PaymentStatusTypes = {
  Paid: 'paid',
  Pending: 'pending',
  InTransit: 'in_transit',
  Canceled: 'canceled',
  Failed: 'failed',
} as const

export type PaymentStatusType =
  (typeof PaymentStatusTypes)[keyof typeof PaymentStatusTypes]

export const getPaymentStatusLabel = (status?: PaymentStatusType): string => {
  switch (status) {
    case PaymentStatusTypes.Paid:
      return t('入金済')
    case PaymentStatusTypes.Pending:
      return t('保留中')
    case PaymentStatusTypes.InTransit:
      return t('処理中')
    case PaymentStatusTypes.Canceled:
      return t('キャンセル')
    case PaymentStatusTypes.Failed:
      return t('エラー')
    default:
      return t('不明')
  }
}

export interface PaymentByFeeType {
  name: string
  amount: number
}

export interface Payment {
  deposit_amount: number | null
  arrival_date: string | null
  status?: PaymentStatusType
}

export interface PaymentDetail {
  deposit_amount: number
  target_order_dates: {
    gte: string
    lte: string
  }
  total_charge_amount: number
  application_fee_amount: number
  application_fee_amount_without_tax: number
  application_fee_amount_without_tax_by_fee_type: PaymentByFeeType[]
  application_fee_amount_shop_charge: number
  tax_for_application_fee_amount: number
  bank_transfer_fee_amount: number
  total_refunded_amount: number
  monthly_fee_amount: number
  table_charge_amount: number
  table_charge_amount_by_fee_type: PaymentByFeeType[]
  shop_charge_amount: number
  smart_payment_amount: number
  smart_payment_amount_by_fee_type: PaymentByFeeType[]
  takeout_charge_amount: number
  takeout_charge_amount_by_fee_type: PaymentByFeeType[]
  reservation_cancel_fee_amount: number
}

export interface MonthlyPayment {
  deposit_amount_total: number
  payments: Payment[]
  details: PaymentDetail[]
}

export function useRestaurantPayments(date: string) {
  const token = useToken()

  const { data, error, mutate } = useSWR<
    MonthlyPayment,
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/deposits/monthly/${date}/v2`), fetcher)

  return {
    data,
    isLoading: data == null && error != null,
    error,
    mutate,
  }
}
