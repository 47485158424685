import React from 'react'

import { StyleSheet, Text, View } from '@react-pdf/renderer'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { GOURMET_SITE_PROVIDER_SOURCE } from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'

import { HEADER_CELL_HEIGHT, HEADER_CELL_WIDTH, SEAT_PC_HEIGHT } from './const'
import { ICONS } from './icons'
import { calculateIconCount, getReservationText } from './model'
import { UnifiedReservationData } from './types'

// 定数定義
const MIN_DURATION = 0.25 // 15分

const styles = StyleSheet.create({
  reservationContainer: {
    position: 'absolute',
    borderRadius: 4,
    borderWidth: 1,
    backgroundColor: Colors.white,
    display: 'flex',
    width: '100%',
    height: SEAT_PC_HEIGHT,
    padding: 4,
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 2,
    flexShrink: 0,
  },
  reservationHeader: {
    display: 'flex',
    flexDirection: 'row',
    height: 12,
    maxHeight: 12,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2,
  },
  headerText: {
    color: '#444444',
    fontSize: 10,
    fontFamily: 'NotoSansJPBold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flex: 1,
    height: 15,
    maxHeight: 15,
    textAlign: 'left',
    maxWidth: '100%',
  },
  headerIconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
    flexShrink: 0,
  },
  reservationBody: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: 2,
  },
  bodyText: {
    color: '#444444',
    fontSize: 8,
    fontFamily: 'NotoSansJPRegular',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flex: 1,
    height: 15,
    maxHeight: 15,
    maxWidth: '100%',
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
    flexShrink: 0,
  },
  ellipsisText: {
    fontSize: 10,
    fontFamily: 'NotoSansJPBold',
    color: '#444444',
  },
  siteControllerContainer: {
    height: 14,
    minHeight: 14,
    backgroundColor: Colors.white,
    borderWidth: 0.5,
    borderRadius: 16,
    borderColor: '#444444',
    paddingHorizontal: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  siteControllerText: {
    fontSize: 8,
    color: '#444444',
    fontFamily: 'NotoSansJPRegular',
  },
})

/**
 * 数値を2桁の文字列に変換する (例: 1 -> "01")
 */
function toDoubleDigits(num: number): string {
  return num < 10 ? `0${num}` : `${num}`
}

/**
 * 秒数を "HH:mm" 形式の時刻文字列に変換する
 * @example
 * getFormatTime(3600) -> "01:00"
 * getFormatTime(5400) -> "01:30"
 */
function getFormatTime(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  return `${toDoubleDigits(hours)}:${toDoubleDigits(minutes)}`
}

/**
 * 時間と分を秒数に変換する
 * @example
 * toSeconds(1, 30) -> 5400
 */
function toSeconds(hours: number, mins: number) {
  return hours * 3600 + mins * 60
}

function getPartySize(
  restaurantReservation: Pick<
    UnifiedReservationData,
    'partySize' | 'adultPartySize' | 'childPartySize'
  >
) {
  return t('{{party_size}}({{adult_party_size}},{{child_party_size}})', {
    party_size: t('{{count}}人', {
      count: restaurantReservation.partySize,
    }),
    adult_party_size: t('大人{{count}}', {
      count: restaurantReservation.adultPartySize,
    }),
    child_party_size: t('子供{{count}}', {
      count: restaurantReservation.childPartySize,
    }),
  })
}

// メインコンポーネント
type ReservationHeaderProps = {
  restaurantReservation: UnifiedReservationData
  isAutoReserve: boolean
  siteControllerSite?: string
  hours: number
}

function ReservationHeader({
  restaurantReservation,
  isAutoReserve,
  siteControllerSite,
  hours,
}: ReservationHeaderProps) {
  // 予約表示テキスト
  const text = getReservationText(restaurantReservation)

  // AutoReserveアイコンコンポーネントを取得
  const AutoReserveIcon = ICONS.autoreserve

  // 30分以下の場合はアイコンを表示しない
  const shouldShowIcons = hours > 0.75

  return (
    <View style={styles.reservationHeader}>
      <Text style={styles.headerText}>{text}</Text>

      {shouldShowIcons && (
        <View style={styles.headerIconContainer}>
          {isAutoReserve === true && AutoReserveIcon != null ? (
            <View style={{ width: 12, height: 12 }}>
              <AutoReserveIcon size={12} />
            </View>
          ) : null}

          {typeof siteControllerSite === 'string' &&
          siteControllerSite !== '' &&
          GOURMET_SITE_PROVIDER_SOURCE[
            siteControllerSite as keyof typeof GOURMET_SITE_PROVIDER_SOURCE
          ] != null ? (
            <View style={styles.siteControllerContainer}>
              <Text style={styles.siteControllerText}>
                {
                  GOURMET_SITE_PROVIDER_SOURCE[
                    siteControllerSite as keyof typeof GOURMET_SITE_PROVIDER_SOURCE
                  ].simplifiedLabel
                }
              </Text>
            </View>
          ) : null}
        </View>
      )}
    </View>
  )
}

// ReservationBodyコンポーネント
type ReservationBodyProps = {
  startTimeSeconds: number
  partySize: string
  hours: number
  // アイコン表示用のbooleanプロパティ
  isVip: boolean
  hasSmartPayment: boolean
  hasMemo: boolean
  isAutoReserve: boolean
  hasCourse: boolean
  hasHistory: boolean
  hasWarning: boolean
  hasBirthdayTag: boolean
  hasDrinkTag: boolean
  hasAllergyTag: boolean
  hasTableTag: boolean
  hasPhoneTag: boolean
}

function ReservationBody({
  startTimeSeconds,
  partySize,
  hours,
  isVip,
  hasSmartPayment,
  hasMemo,
  isAutoReserve,
  hasCourse,
  hasHistory,
  hasWarning,
  hasBirthdayTag,
  hasDrinkTag,
  hasAllergyTag,
  hasTableTag,
  hasPhoneTag,
}: ReservationBodyProps) {
  // アイコン数のカウント
  const iconCount = calculateIconCount({
    isVip,
    hasSmartPayment,
    hasMemo,
    isAutoReserve,
    hasCourse,
    hasHistory,
    hasWarning,
    hasBirthdayTag,
    hasDrinkTag,
    hasAllergyTag,
    hasTableTag,
    hasPhoneTag,
  })

  // 時間と人数の表示テキスト
  const text = `${getFormatTime(startTimeSeconds)}-  ${partySize}`

  // 滞在時間に応じたアイコン表示制限
  const maxIconsToShow = (() => {
    if (hours <= MIN_DURATION) return 0
    if (hours >= 2) return 5
    if (hours >= 1) return 3
    return 1
  })()

  // 省略記号表示条件
  const showEllipsis = iconCount > maxIconsToShow

  // 実際に表示するアイコン
  const renderIcons = () => {
    if (hours <= MIN_DURATION) return null

    // アイコンと表示条件の配列
    const iconConfigs = [
      { condition: isVip, icon: 'vip' },
      { condition: hasWarning, icon: 'caution' },
      { condition: hasAllergyTag, icon: 'allergy' },
      { condition: hasMemo, icon: 'memo' },
      { condition: hasSmartPayment, icon: 'smartPay' },
      { condition: hasHistory, icon: 'history' },
      { condition: hasBirthdayTag, icon: 'birthday' },
      { condition: hasDrinkTag, icon: 'drink' },
      { condition: hasTableTag, icon: 'table' },
      { condition: hasPhoneTag, icon: 'phone' },
      { condition: hasCourse, icon: 'course' },
    ]

    // 表示するアイコンを制限
    const iconsToRender = iconConfigs
      .filter(({ condition }) => condition)
      .slice(0, maxIconsToShow)
      .map(({ icon }, index) => {
        const IconComponent = ICONS[icon as keyof typeof ICONS]
        return <IconComponent key={`${icon}-${index}`} size={12} />
      })

    return iconsToRender
  }

  return (
    <View style={styles.reservationBody}>
      <Text style={styles.bodyText}>{text}</Text>

      <View style={styles.iconContainer}>{renderIcons()}</View>
      {showEllipsis && <Text style={styles.ellipsisText}>…</Text>}
    </View>
  )
}

type Props = {
  index: number
  restaurantReservation: UnifiedReservationData
  date: dayjs.Dayjs
  isRestaurantSmartPaymentAvailable: boolean
  displayStartHour: number
  displayEndHour: number
}

export function Reservation({
  index,
  restaurantReservation,
  date,
  isRestaurantSmartPaymentAvailable,
  displayStartHour,
  displayEndHour,
}: Props) {
  // restaurantReservationからconsecutiveCount情報を取得
  // 存在しない場合はデフォルトで1を使用
  const consecutiveCount = restaurantReservation.consecutiveCount ?? 1

  const startAt = dayjs(restaurantReservation.startAt).hour()
  const startMinute = dayjs(restaurantReservation.startAt).minute()
  const endAt = dayjs(restaurantReservation.endAt).hour()
  const endMinute = dayjs(restaurantReservation.endAt).minute()

  // displayStartHourを基準とした相対位置を計算
  // ただ、PDFで時間を跨ぐ場合、leftとwidthの計算を工夫する必要がある
  const startTotalMinutes = startAt * 60 + startMinute
  const endTotalMinutes = endAt * 60 + endMinute
  const displayStartMinutes = displayStartHour * 60
  const displayEndMinutes = displayEndHour * 60

  // 日付をまたぐ予約の場合の時刻計算
  const dayDiff = dayjs(restaurantReservation.startAt).diff(date, 'day')
  const endDayDiff = dayjs(restaurantReservation.endAt).diff(date, 'day')

  // 日付をまたぐ場合は、その日数分の時間（分）を加算
  const adjustedStartTotalMinutes = startTotalMinutes + dayDiff * 24 * 60
  const adjustedEndTotalMinutes = endTotalMinutes + endDayDiff * 24 * 60

  const left =
    (Math.max(adjustedStartTotalMinutes - displayStartMinutes, 0) *
      HEADER_CELL_WIDTH) /
    60

  const width =
    ((Math.min(adjustedEndTotalMinutes, displayEndMinutes) -
      Math.max(adjustedStartTotalMinutes, displayStartMinutes)) *
      HEADER_CELL_WIDTH) /
    60
  const hours =
    (Math.min(adjustedEndTotalMinutes, displayEndMinutes) -
      Math.max(adjustedStartTotalMinutes, displayStartMinutes)) /
    60

  const startTimeSeconds = toSeconds(dayDiff * 24 + startAt, startMinute)

  // 表示用のテキストとアイコン数の計算
  const partySize = getPartySize(restaurantReservation)

  // アイコン表示条件
  const isVip = restaurantReservation.isVip
  const hasSmartPayment =
    restaurantReservation.smartPayment !== null &&
    isRestaurantSmartPaymentAvailable
  const hasMemo =
    restaurantReservation.memo !== null && restaurantReservation.memo !== ''
  const isAutoReserve =
    restaurantReservation.source === 'autoreserve' &&
    restaurantReservation.sourceBy === 'auto'
  const hasCourse =
    restaurantReservation.reservationCourses !== null &&
    restaurantReservation.reservationCourses.length > 0
  // 各タグタイプごとに条件を追加
  const hasBirthdayTag =
    restaurantReservation.reservationTags?.some(
      (tag) => tag.name === t('誕生日・記念日')
    ) ?? false
  const hasDrinkTag =
    restaurantReservation.reservationTags?.some(
      (tag) => tag.name === t('飲み放題')
    ) ?? false
  const hasAllergyTag =
    restaurantReservation.reservationTags?.some(
      (tag) => tag.name === t('アレルギー')
    ) ?? false
  const hasTableTag =
    restaurantReservation.reservationTags?.some(
      (tag) => tag.name === t('席指定')
    ) ?? false
  const hasPhoneTag =
    restaurantReservation.reservationTags?.some(
      (tag) => tag.name === t('確認事項あり')
    ) ?? false
  const hasHistory = restaurantReservation.hasPreviousTableOrder
  const hasWarning = restaurantReservation.needAttention

  return (
    <View
      style={[
        styles.reservationContainer,
        {
          top: SEAT_PC_HEIGHT * index + HEADER_CELL_HEIGHT,
          left,
          width,
          height: SEAT_PC_HEIGHT * consecutiveCount, // 連続席数に基づいて高さを調整
        },
      ]}
    >
      {/* 15分以下の場合は何も表示しない */}
      {hours > MIN_DURATION && (
        <ReservationHeader
          restaurantReservation={restaurantReservation}
          isAutoReserve={isAutoReserve}
          siteControllerSite={
            restaurantReservation.reservation_site_controller_parsed_course
              ?.site_controller_parsed_course.site
          }
          hours={hours}
        />
      )}
      {hours > MIN_DURATION && (
        <ReservationBody
          startTimeSeconds={startTimeSeconds}
          partySize={partySize}
          hours={hours}
          isVip={isVip}
          hasSmartPayment={hasSmartPayment}
          hasMemo={hasMemo}
          isAutoReserve={isAutoReserve}
          hasCourse={hasCourse}
          hasHistory={hasHistory}
          hasWarning={hasWarning}
          hasBirthdayTag={hasBirthdayTag}
          hasDrinkTag={hasDrinkTag}
          hasAllergyTag={hasAllergyTag}
          hasTableTag={hasTableTag}
          hasPhoneTag={hasPhoneTag}
        />
      )}
    </View>
  )
}
