import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { numberWithDelimiter } from '@hello-ai/ar_shared/src/modules/numberWithDelimiter'
import { ReservationStatus } from '@hello-ai/ar_shared/src/types/ForR/Reservation'

export function getRestaurantCourseText({
  title,
  price,
  partySize,
}: {
  title: string
  price: number | string | null
  partySize: number
}) {
  const priceStr =
    typeof price === 'number' && price > 0
      ? `・${numberWithDelimiter(price)}`
      : typeof price === 'string'
        ? `・${price}`
        : ''

  return t('{{title}}{{priceStr}}（{{partySize}}名）', {
    title,
    priceStr,
    partySize,
  })
}

export function getStatusLabelText({
  status,
  past,
}: {
  status: ReservationStatus
  past: boolean
}) {
  switch (status) {
    case 'reserved':
      if (past) return t('来店済み')
      return t('予約成立')
    case 'cancel_progress':
    case 'change_progress':
      return t('予約成立')
    case 'canceled':
      return t('予約キャンセル')
    default:
      return ''
  }
}

export function getStatusLabelColor({
  status,
  past,
}: {
  status: ReservationStatus
  past: boolean
}) {
  switch (status) {
    case 'reserved':
      if (past) return Colors.accent
      return Colors.black
    case 'cancel_progress':
    case 'change_progress':
      return Colors.black
    case 'canceled':
      return Colors.caution
    default:
      return 'transparent'
  }
}
