import { useCallback, useMemo } from 'react'

import { Platform } from 'react-native'

import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { useRestaurant } from '../../../../models/Restaurant'
import { useRequestReservationCount } from '../../../../models/RestaurantRequestReservation'
import { useTableSeats } from '../../../../models/TableSeat'
import { useNavigate } from '../../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../../modules/navigation/useNavigation'
import {
  displayToastConfirm,
  displayToastInfo,
  displayToastSequence,
  displayToastSuccess,
} from '../../../Shared/Toast'

const TOAST_DURATION = 5_000

export const ReservationRequestToastKinds = {
  Approve: 'Approve',
  Reject: 'Reject',
}
type ReservationRequestToastKind =
  (typeof ReservationRequestToastKinds)[keyof typeof ReservationRequestToastKinds]

type Params = {
  restaurantId: number
  kind: ReservationRequestToastKind
}
export function useReservationRequestToast({ restaurantId, kind }: Params) {
  const { width, sm } = useResponsive()
  const { mutate } = useRequestReservationCount(restaurantId)
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { data: restaurant } = useRestaurant(restaurantId)
  const { tableSeats } = useTableSeats(restaurantId, {})

  const isEntryRestaurant = useMemo(() => {
    if (restaurant == null) return false
    return (
      restaurant.reservation_book_plan_type === 'entry' &&
      tableSeats.length <= 1
    )
  }, [restaurant, tableSeats])

  const displayRemainingRequest = useCallback(
    async ({
      dateString,
      startTime,
    }: {
      dateString: string
      startTime: number
    }) => {
      const data = await mutate()
      if (!data) return
      const { count } = data
      const message = t('未承認のリクエスト予約が{{count}}件あります', {
        count,
      })
      const sequence = []
      let prevStatus: 'approved' | 'rejected' = 'approved'

      switch (kind) {
        case ReservationRequestToastKinds.Approve:
          sequence.push(() =>
            displayToastSuccess(
              t('予約を追加しました'),
              TOAST_DURATION,
              width < sm ? { marginBottom: 48 } : undefined
            )
          )
          prevStatus = 'approved'
          break
        case ReservationRequestToastKinds.Reject:
          sequence.push(() =>
            displayToastInfo(
              t('予約を拒否しました'),
              TOAST_DURATION,
              width < sm ? { marginBottom: 48 } : undefined
            )
          )
          prevStatus = 'rejected'
      }
      if (count > 0 && isEntryRestaurant === false) {
        sequence.push(() =>
          displayToastConfirm(
            message,
            () => {
              if (Platform.OS === 'web') {
                navigate(`/restaurants/${restaurantId}/reservations/requests`)
              } else {
                navigation.navigate('RequestReservationList')
              }
            },
            {
              excludeFromConfirmRemoval: true,
            }
          )
        )
      }
      displayToastSequence(sequence)
      if (count === 0 || isEntryRestaurant === false) {
        if (Platform.OS === 'web') {
          navigate(
            `/restaurants/${restaurantId}/reservations?date=${dateString}&m=chart`
          )
        } else {
          navigation.navigate('Reservations', {
            dateString,
            startTime,
          })
        }
      } else {
        if (Platform.OS === 'web') {
          navigate(
            `/restaurants/${restaurantId}/reservations/requests?prev=${prevStatus}`
          )
        } else {
          navigation.navigate('RequestReservationList', { prev: prevStatus })
        }
      }
    },
    [
      isEntryRestaurant,
      kind,
      mutate,
      navigate,
      navigation,
      restaurantId,
      sm,
      width,
    ]
  )

  return { displayRemainingRequest }
}
