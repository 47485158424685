import React, { useRef, useState } from 'react'

import { Trans } from 'react-i18next'
import { TextInput as RNTextInput, View } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import config from '@hello-ai/for_r_app/src/modules/config'

import LinkText from 'components/Shared/LinkText'
import { ownerSignUpFromDraft } from 'models/Auth'
import { isAxiosError } from 'modules/axios'

import { sendGAEvent } from '../../modules/analytics'

import DraftOwnerInfo from './DraftOwnerInfo'
import { StepItem } from './Stepper'
import { GA_SIGNUP_STEPS } from './const'

const HelperTextStyle = {
  fontSize: 14,
  fontWeight: '300',
  color: Colors.black60,
  lineHeight: 21,
} as const

interface OwnerAccountFormProps {
  draftOwnerInfo: DraftOwnerInfo
  onPressNext: (param: { username: string }) => void
}

const createErrorText = (label: string, errorText?: string | string[]) => {
  if (errorText == null) return undefined
  if (Array.isArray(errorText))
    return errorText.map((text) => `${label}${text}`).join('\n')
  return `${label}${errorText}`
}

export default function OwnerAccountForm({
  draftOwnerInfo,
  onPressNext,
}: OwnerAccountFormProps) {
  const arWebOrigin = config.isStaging
    ? 'https://staging.autoreserve.com'
    : 'https://autoreserve.com'
  const { width, sm } = useResponsive()

  const [name, setName] = useState('')
  const [username, setUsername] = useState('')
  const [errors, setErrors] = useState<Record<string, string>>({})

  const onSubmit = async () => {
    setErrors({})

    // GAイベント送信
    sendGAEvent({ event: 'click', step: GA_SIGNUP_STEPS.OWNER_REGISTER })

    const { error } = await ownerSignUpFromDraft({
      id: draftOwnerInfo.draftOwnerId,
      name,
      username,
    })
    if (error != null) {
      if (isAxiosError(error) && error.response?.status === 422) {
        setErrors(error.response?.data as Record<string, string>)
      }
      return
    }
    onPressNext({ username })
  }

  const formMode = width < sm ? 'vertical' : 'inline'

  const emailTextInputRef = useRef<RNTextInput>(null)
  const passwordTextInputRef = useRef<RNTextInput>(null)

  return (
    <View style={{ flexGrow: 1 }}>
      <View
        style={[
          {
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: width < sm ? 40 : 80,
          },
          width < sm && {
            paddingHorizontal: 12,
          },
        ]}
      >
        <StepItem
          stepNumber={3}
          state="selected"
          labelTextStyle={{
            fontSize: 22,
            fontWeight: '600',
            lineHeight: 33,
          }}
          hiddenCircle={width < sm}
        >
          {t('オーナー情報を登録します')}
        </StepItem>
        <View style={{ marginVertical: width < sm ? 32 : 40 }}>
          <FormGroup
            mode={formMode}
            formLabel={
              <FormLabel
                value={t('オーナー名')}
                labelStyle={[width < sm && { fontSize: 14 }]}
              />
            }
            formLabelContainerStyle={{
              width: 162,
              marginRight: 24,
            }}
            containerStyle={[
              {
                width: width < sm ? '100%' : 420,
              },
              width < sm && {
                marginTop: 4,
              },
            ]}
          >
            <TextInput
              ref={emailTextInputRef}
              placeholder={t('例：株式会社ハロー')}
              value={name}
              onChangeText={setName}
              clearButtonMode="always"
              autoCapitalize="none"
              errorText={createErrorText(t('オーナー名'), errors.name)}
              onSubmitEditing={() => {
                passwordTextInputRef.current?.focus()
              }}
              style={{
                height: width < sm ? 48 : 64,
              }}
            />
            <Text
              style={[
                HelperTextStyle,
                width < sm && {
                  fontSize: 10,
                  fontWeight: '300',
                  lineHeight: 18,
                },
              ]}
            >
              {t('社名や店舗名などを入力してください')}
            </Text>
          </FormGroup>
          <FormGroup
            mode={formMode}
            formLabel={
              <FormLabel
                value={t('オーナーID')}
                labelStyle={[width < sm && { fontSize: 14 }]}
              />
            }
            style={{ marginTop: width < sm ? 32 : 24 }}
            formLabelContainerStyle={{
              width: 162,
              marginRight: 24,
            }}
            containerStyle={[
              {
                width: width < sm ? '100%' : 420,
              },
              width < sm && {
                marginTop: 4,
              },
            ]}
          >
            <TextInput
              ref={passwordTextInputRef}
              placeholder="helloinc"
              value={username}
              onChangeText={setUsername}
              onSubmitEditing={onSubmit}
              errorText={createErrorText(t('オーナーID'), errors.username)}
              style={{
                height: width < sm ? 48 : 64,
              }}
            />
            <Text
              style={[
                HelperTextStyle,
                width < sm && {
                  fontSize: 10,
                  fontWeight: '300',
                  lineHeight: 18,
                },
              ]}
            >
              {t(
                'オーナーIDは、所属するメンバー全員がログインに使用します。英数字でわかりやすいものを入力してください'
              )}
            </Text>
          </FormGroup>
        </View>
        {/* eslint-disable ar-i18n/require-translation-ja */}
        <Trans
          i18nKey={
            '<0><0>利用規約</0><1>プライバシーポリシー</1><2>に同意して</2></0><1>オーナーアカウントを作成</1>'
          }
        >
          <Text style={{ marginBottom: 8 }}>
            <LinkText
              href={`${arWebOrigin}/for_restaurants/terms`}
              target="_blank"
              style={{ color: Colors.primary, fontSize: width < sm ? 12 : 14 }}
            >
              {'利用規約'}
            </LinkText>
            <LinkText
              href={`${arWebOrigin}/privacy`}
              target="_blank"
              style={{
                marginHorizontal: 8,
                color: Colors.primary,
                fontSize: width < sm ? 12 : 14,
              }}
            >
              {'プライバシーポリシー'}
            </LinkText>
            <Text style={{ fontSize: width < sm ? 12 : 14 }}>
              {'に同意して'}
            </Text>
          </Text>
          <Button
            variant="primary"
            style={{ marginBottom: 24, paddingVertical: 12, height: 48 }}
            onPress={onSubmit}
            width={278}
          >
            <Text
              style={{ fontSize: 16, color: Colors.white, fontWeight: '600' }}
            >
              {'オーナーアカウントを作成'}
            </Text>
          </Button>
        </Trans>
        {/* eslint-enable ar-i18n/require-translation-ja */}
      </View>
    </View>
  )
}
