import type React from 'react'
import { useEffect, useMemo } from 'react'

import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { View, TouchableOpacity, StyleSheet } from 'react-native'
import Reanimated, {
  withTiming,
  useAnimatedStyle,
  useSharedValue,
  useAnimatedRef,
} from 'react-native-reanimated'

import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'

interface CollapsedAccordionProps {
  isOpen: boolean
  collapsedHeight?: number
  children?: React.ReactNode
  toggleOpen?: () => void
}

export function CollapsedAccordion({
  isOpen,
  collapsedHeight = 40,
  children,
  toggleOpen,
}: CollapsedAccordionProps) {
  /**
   * 実際に子要素の高さを測りたいので useSharedValue で保持
   * もしも最小限の高さ (collapsedHeight) を差し引いてアニメーションする
   */
  const contentHeight = useSharedValue(0)
  const progress = useSharedValue(isOpen ? 1 : 0)

  const ref = useAnimatedRef<View>()
  const prevIsOpen = usePrevious(isOpen)
  useEffect(() => {
    if (prevIsOpen !== isOpen) {
      // TODO: react-compiler for react-native-reanimated
      // eslint-disable-next-line react-compiler/react-compiler
      progress.set(isOpen ? withTiming(1) : withTiming(0))
    }
  }, [isOpen, prevIsOpen, progress])

  const styles = useMemo(() => createStyles(isOpen), [isOpen])

  /**
   * 実際のアコーディオン高さを計算する。
   *  - 閉じているとき: collapsedHeight + (コンテンツ全体 - collapsedHeight) * 0
   *  - 開いているとき: collapsedHeight + (コンテンツ全体 - collapsedHeight) * 1
   */
  const animatedStyle = useAnimatedStyle(() => {
    const fullHeight = contentHeight.get()
    const clampedHeight =
      collapsedHeight + (fullHeight - collapsedHeight) * progress.get()

    return {
      height: clampedHeight < collapsedHeight ? collapsedHeight : clampedHeight,
      overflow: 'hidden',
    }
  }, [])

  const animatedChevronStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotateZ: `${progress.get() * 180}deg`,
        },
      ],
    }
  }, [])

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        toggleOpen?.()
      }}
    >
      <Reanimated.View style={[animatedStyle, styles.children]}>
        <View
          ref={ref}
          onLayout={({
            nativeEvent: {
              layout: { height: measuredHeight },
            },
          }) => {
            contentHeight.set(measuredHeight)
          }}
        >
          {children}
        </View>
      </Reanimated.View>

      <Reanimated.View style={[styles.chevronContainer, animatedChevronStyle]}>
        <FontAwesomeIcon icon={faChevronDown} size={12} color={'#333'} />
      </Reanimated.View>
    </TouchableOpacity>
  )
}

const createStyles = (isOpen: boolean) =>
  StyleSheet.create({
    container: {
      width: '100%',
      flexDirection: 'row',
      alignItems: isOpen ? 'flex-end' : 'center',
    },
    children: {
      flexGrow: 1,
    },
    chevronContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 16,
      height: 16,
      marginBottom: isOpen ? 8 : 0,
    },
  })
