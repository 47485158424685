import React, { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { PrintButton } from '@hello-ai/for_r_app/src/components/Reservations/PrintButton/PrintForWebView'
import {
  useIsValidToken,
  useTokenForWebView,
} from '@hello-ai/for_r_app/src/components/Reservations/PrintButton/hooks'

type ViewMode = 'list' | 'chart'
type RenderMode = 'pending' | 'list' | 'chart'

function PrintPage() {
  const { id } = useParams<{ id: string }>()
  const token = useTokenForWebView()
  const restaurantId = parseInt(id ?? '', 10)
  const isValid = useIsValidToken({ token, restaurantId })
  const [renderMode, setRenderMode] = useState<RenderMode>('pending')

  useEffect(() => {
    // @ts-ignore
    window.initialize = () => {
      if (isValid) {
        // @ts-expect-error
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: 'valid-token' })
        )
      } else {
        // @ts-expect-error
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: 'invalid-token' })
        )
      }
    }
    // @ts-ignore
    window.onMessageList = () => {
      setRenderMode('list')
    }
    // @ts-ignore
    window.onMessageChart = () => {
      setRenderMode('chart')
    }
    // @ts-ignore
    window.onPending = () => {
      setRenderMode('pending')
    }
    return () => {
      // @ts-ignore
      delete window.initialize
      // @ts-ignore
      delete window.onMessageList
      // @ts-ignore
      delete window.onMessageChart
      // @ts-ignore
      delete window.onPending
    }
  }, [isValid])

  const [searchParams] = useSearchParams()

  if (id == null) {
    return null
  }

  if (isNaN(restaurantId)) {
    return null
  }

  const dateParam = searchParams.get('date')
  const reservationDate =
    dateParam != null ? dayjs(dateParam, 'YYYY-MM-DD') : dayjs()
  const mode = (searchParams.get('m') as ViewMode) ?? 'list'

  // バリデーション
  if (!reservationDate.isValid()) {
    return null
  }

  if (mode !== 'list' && mode !== 'chart') {
    return null
  }

  // @ts-expect-error
  window?.ReactNativeWebView?.postMessage(
    JSON.stringify({ type: `${renderMode}-log` })
  )

  switch (renderMode) {
    case 'pending':
      return null
    case 'list':
      return (
        <PrintButton
          kind={mode}
          restaurantId={restaurantId}
          reservationDate={reservationDate}
        />
      )
    case 'chart':
      return (
        <PrintButton
          kind={mode}
          restaurantId={restaurantId}
          reservationDate={reservationDate}
        />
      )
    default:
      return null
  }
}

export default PrintPage
