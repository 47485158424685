import React from 'react'

import { Trans } from 'react-i18next'
import { View } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { MonthlyPayment } from 'models/Payment'
import numberWithDelimiter from 'modules/numberWithDelimiter'

import PaymentContent from './PaymentContent'
import PaymentDetailContent from './PaymentDetailContent'

export default function MonthlyPaymentContent({
  monthlyPayment,
}: {
  monthlyPayment: MonthlyPayment
}) {
  const depositAmountTotal = numberWithDelimiter(
    monthlyPayment.deposit_amount_total
  )
  return (
    <View>
      <View
        style={{
          paddingHorizontal: 32,
        }}
      >
        <Text style={{ fontSize: 14, fontWeight: '600' }}>{t('入金額')}</Text>
        <Text>
          {/* eslint-disable ar-i18n/require-translation-ja */}
          <Trans
            i18nKey={'<0>{{price}}</0><1>円</1>'}
            values={{ price: depositAmountTotal }}
          >
            <Text style={{ fontSize: 28, fontWeight: '600' }}>
              {`${depositAmountTotal}`}
            </Text>
            <Text style={{ fontSize: 14, fontWeight: '600' }}>{'円'}</Text>
          </Trans>
          {/* eslint-enable ar-i18n/require-translation-ja */}
        </Text>
      </View>
      <View
        style={{
          marginTop: 32,
          paddingBottom: 32,
          paddingHorizontal: 32,
        }}
      >
        <Text style={{ fontSize: 14, fontWeight: '600' }}>{t('入金日')}</Text>
        {monthlyPayment.payments?.map((payment, i) => (
          <PaymentContent key={i} payment={payment} />
        ))}
      </View>
      {monthlyPayment.details?.map((paymentDetail, i) => (
        <PaymentDetailContent key={i} paymentDetail={paymentDetail} />
      ))}
    </View>
  )
}
