/* eslint-disable ar-i18n/require-translation-ja */

import React, { useState } from 'react'

import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare'
import { faGear } from '@fortawesome/pro-solid-svg-icons/faGear'
import * as Linking from 'expo-linking'
import { ScrollView, View, StyleProp, ViewStyle, TextStyle } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { LinkOpacity } from '@hello-ai/ar_shared/src/components/Link/LinkOpacity'
import { SelectedImage } from '@hello-ai/ar_shared/src/components/SelectImage'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import {
  SupportedLocale,
  localeData,
} from '@hello-ai/ar_shared/src/modules/locale'
import {
  createResponsiveSheet,
  useResponsive,
  useResponsiveStyle,
} from '@hello-ai/ar_shared/src/modules/useResponsive'
import {
  Restaurant,
  RestaurantMichelinAwardStatus,
  RestaurantTabelogAwardStatus,
} from '@hello-ai/ar_shared/src/types/ForR/Restaurant'

import { LanguageExpandToggleButton } from '../../Shared/LanguageExpandToggleButton'
import { getLocaleValues } from '../../Translation'

import { MichelinList } from './MichelinList'
import { TabelogList } from './TabelogList'
import { getOpenStatusColor, getOpenStatusLabel } from './common'

function InformationBlock({
  label,
  children,
  style,
  labelStyle,
}: {
  label: string
  children?: React.ReactNode
  style?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
}) {
  const _style = useResponsiveStyle(responsiveSheet)
  const renderChildren = () => {
    if (typeof children === 'string' || typeof children === 'number') {
      return <Text style={_style.informationBlockContentText}>{children}</Text>
    }
    return children
  }
  return (
    <View style={[_style.informationBlock, style]}>
      <Text style={[_style.informationBlockLabel, labelStyle]}>{label}</Text>
      {renderChildren()}
    </View>
  )
}

function ExternalLinkButton({
  children,
  href,
}: {
  children: React.ReactNode
  href?: string
}) {
  return (
    <Button
      style={{
        flexDirection: 'row',
        borderRadius: 30,
        columnGap: 8,
        height: 48,
      }}
      mode="outline"
      onPress={() => {
        if (href != null) {
          Linking.openURL(href)
        }
      }}
    >
      <FontAwesomeIcon
        icon={faArrowUpRightFromSquare}
        size={20}
        color={Colors.primary}
      />
      <Text
        style={{
          fontSize: 16,
          fontWeight: '600',
          lineHeight: 24,
          color: Colors.primary,
        }}
      >
        {children}
      </Text>
    </Button>
  )
}

function getRecommendedProductName(
  recommendedProduct: NonNullable<
    Restaurant['restaurant_recommended_products']
  >[number]
) {
  if (recommendedProduct.table_product != null) {
    return recommendedProduct.table_product.name
  }

  if (recommendedProduct.restaurant_course != null) {
    return recommendedProduct.restaurant_course.title
  }

  return ''
}

function InformationVerticalBlock({
  label,
  children,
}: {
  label: string
  children?: React.ReactNode
}) {
  const { width, sm } = useResponsive()
  return (
    <View>
      <Text
        style={{
          fontSize: width < sm ? 18 : 22,
          fontWeight: '600',
        }}
      >
        {label}
      </Text>
      <View style={{ marginTop: 24 }}>{children}</View>
    </View>
  )
}

function RestaruantDescriptionSection({
  sortedLocales,
  restaurant,
}: {
  sortedLocales: SupportedLocale[]
  restaurant: Restaurant
}) {
  const style = useResponsiveStyle(responsiveSheet)

  const [
    isRestaurantFoodDescriptionExpanded,
    setIsRestaurantFoodDescriptionExpanded,
  ] = useState(false)

  const [
    isRestaurantAtmosphereDescriptionExpanded,
    setIsRestaurantAtmosphereDescriptionExpanded,
  ] = useState(false)

  const [
    isRestaurantHistoryDescriptionExpanded,
    setIsRestaurantHistoryDescriptionExpanded,
  ] = useState(false)

  const [
    isRestaurantChefDescriptionChefNameExpanded,
    setIsRestaurantChefDescriptionChefNameExpanded,
  ] = useState(false)

  const [
    isRestaurantChefDescriptionContentExpanded,
    setIsRestaurantChefDescriptionContentExpanded,
  ] = useState(false)

  return (
    <View style={style.section}>
      <Text style={style.sectionTitle}>お店について</Text>
      <ShadowBox style={style.shadowBox}>
        <InformationVerticalBlock label={t('おすすめのメニュー')}>
          <View
            style={{
              gap: 24,
            }}
          >
            {restaurant.restaurant_recommended_products?.map(
              (recommendedProduct, index) => (
                <Text key={index}>
                  {getRecommendedProductName(recommendedProduct)}
                </Text>
              )
            )}
            {(restaurant.restaurant_recommended_products ?? []).length ===
              0 && <Text>{t('未設定')}</Text>}
          </View>
        </InformationVerticalBlock>
        <Divider />
        <InformationVerticalBlock label={t('お店の料理')}>
          <View
            style={{
              gap: 24,
            }}
          >
            {restaurant.restaurant_food_description?.image?.sm != null && (
              <InformationBlock label={t('画像')}>
                <SelectedImage
                  source={{
                    uri: restaurant.restaurant_food_description.image.sm,
                  }}
                  style={{
                    width: 180,
                    height: 120,
                  }}
                />
              </InformationBlock>
            )}
            {sortedLocales
              .slice(
                0,
                isRestaurantFoodDescriptionExpanded ? sortedLocales.length : 1
              )
              .filter((locale) => localeData[locale] !== undefined)
              .map((locale: SupportedLocale, index) => (
                <InformationBlock label={localeData[locale]?.label} key={index}>
                  <Text style={{ flex: 1 }}>
                    {getLocaleValues(
                      'content_',
                      restaurant.restaurant_food_description
                    )[locale] || '未設定'}
                  </Text>
                </InformationBlock>
              ))}
          </View>
          <LanguageExpandToggleButton
            style={{ marginTop: 24 }}
            isExpanded={isRestaurantFoodDescriptionExpanded}
            onPress={() => {
              setIsRestaurantFoodDescriptionExpanded((value) => !value)
            }}
            title={'他の言語を表示'}
          />
        </InformationVerticalBlock>
        <Divider />
        <InformationVerticalBlock label={t('お店の雰囲気')}>
          <View style={{ gap: 24 }}>
            {restaurant.restaurant_atmosphere_description?.image?.sm !=
              null && (
              <InformationBlock label={t('画像')}>
                <SelectedImage
                  source={{
                    uri: restaurant.restaurant_atmosphere_description.image.sm,
                  }}
                  style={{
                    width: 180,
                    height: 120,
                  }}
                />
              </InformationBlock>
            )}
            {sortedLocales
              .slice(
                0,
                isRestaurantAtmosphereDescriptionExpanded
                  ? sortedLocales.length
                  : 1
              )
              .filter((locale) => localeData[locale] !== undefined)
              .map((locale: SupportedLocale, index) => (
                <InformationBlock label={localeData[locale]?.label} key={index}>
                  <Text style={{ flex: 1 }}>
                    {getLocaleValues(
                      'content_',
                      restaurant.restaurant_atmosphere_description
                    )[locale] || '未設定'}
                  </Text>
                </InformationBlock>
              ))}
          </View>
          <LanguageExpandToggleButton
            style={{ marginTop: 24 }}
            isExpanded={isRestaurantAtmosphereDescriptionExpanded}
            onPress={() => {
              setIsRestaurantAtmosphereDescriptionExpanded((value) => !value)
            }}
            title={'他の言語を表示'}
          />
        </InformationVerticalBlock>
        <Divider />
        <InformationVerticalBlock label={t('お店の歴史')}>
          <View
            style={{
              gap: 24,
            }}
          >
            {restaurant.restaurant_history_description?.image?.sm != null && (
              <InformationBlock label={t('画像')}>
                <SelectedImage
                  source={{
                    uri: restaurant.restaurant_history_description.image.sm,
                  }}
                  style={{
                    width: 180,
                    height: 120,
                  }}
                />
              </InformationBlock>
            )}
            {sortedLocales
              .slice(
                0,
                isRestaurantHistoryDescriptionExpanded
                  ? sortedLocales.length
                  : 1
              )
              .filter((locale) => localeData[locale] !== undefined)
              .map((locale: SupportedLocale, index) => (
                <InformationBlock label={localeData[locale]?.label} key={index}>
                  <Text style={{ flex: 1 }}>
                    {getLocaleValues(
                      'content_',
                      restaurant.restaurant_history_description
                    )[locale] || '未設定'}
                  </Text>
                </InformationBlock>
              ))}
          </View>
          <LanguageExpandToggleButton
            style={{ marginTop: 24 }}
            isExpanded={isRestaurantHistoryDescriptionExpanded}
            onPress={() => {
              setIsRestaurantHistoryDescriptionExpanded((value) => !value)
            }}
            title={'他の言語を表示'}
          />
        </InformationVerticalBlock>
        <Divider />
        <InformationVerticalBlock label={t('お店の料理人')}>
          <View
            style={{
              gap: 24,
            }}
          >
            {restaurant.restaurant_chef_description?.image?.sm != null && (
              <InformationBlock label={t('画像')}>
                <SelectedImage
                  source={{
                    uri: restaurant.restaurant_chef_description.image.sm,
                  }}
                  style={{
                    width: 120,
                    height: 120,
                  }}
                />
              </InformationBlock>
            )}
            <Text
              style={{
                fontSize: 14,
                fontWeight: '600',
                lineHeight: 22,
              }}
            >
              {t('名前')}
            </Text>
            {sortedLocales
              .slice(
                0,
                isRestaurantChefDescriptionChefNameExpanded
                  ? sortedLocales.length
                  : 1
              )
              .filter((locale) => localeData[locale] !== undefined)
              .map((locale: SupportedLocale, index) => (
                <InformationBlock label={localeData[locale]?.label} key={index}>
                  <Text style={{ flex: 1 }}>
                    {getLocaleValues(
                      'chef_name_',
                      restaurant.restaurant_chef_description
                    )[locale] || '未設定'}
                  </Text>
                </InformationBlock>
              ))}
            <LanguageExpandToggleButton
              isExpanded={isRestaurantChefDescriptionChefNameExpanded}
              onPress={() => {
                setIsRestaurantChefDescriptionChefNameExpanded(
                  (value) => !value
                )
              }}
              title={'他の言語を表示'}
            />
            <Text
              style={{
                fontSize: 14,
                fontWeight: '600',
                lineHeight: 22,
              }}
            >
              {t('説明')}
            </Text>
            {sortedLocales
              .slice(
                0,
                isRestaurantChefDescriptionContentExpanded
                  ? sortedLocales.length
                  : 1
              )
              .filter((locale) => localeData[locale] !== undefined)
              .map((locale: SupportedLocale, index) => (
                <InformationBlock label={localeData[locale]?.label} key={index}>
                  <Text style={{ flex: 1 }}>
                    {getLocaleValues(
                      'content_',
                      restaurant.restaurant_chef_description
                    )[locale] || '未設定'}
                  </Text>
                </InformationBlock>
              ))}
            <LanguageExpandToggleButton
              isExpanded={isRestaurantChefDescriptionContentExpanded}
              onPress={() => {
                setIsRestaurantChefDescriptionContentExpanded((value) => !value)
              }}
              title={'他の言語を表示'}
            />
          </View>
        </InformationVerticalBlock>
      </ShadowBox>
    </View>
  )
}

export function RestaurantInformation({
  restaurant,
  sortedLocales,
  michelinAwards,
  tabelogAwards,
  onPressEditButton,
  onPressLink,
  externalLinks,
}: {
  restaurant: Restaurant
  sortedLocales: SupportedLocale[]
  michelinAwards?: RestaurantMichelinAwardStatus[]
  tabelogAwards?: RestaurantTabelogAwardStatus[]
  onPressEditButton: () => void
  onPressLink?: (link: string) => void
  externalLinks?: {
    arWeb?: string
    forRWeb?: string
  }
}) {
  const facility = restaurant?.facility
  const { width, sm } = useResponsive()

  const [isReservationInfoExpanded, setIsReservationInfoExpanded] =
    useState(false)
  const reservation_info = restaurant.restaurant_reservation_info
  const reservation_info_i18n = {
    ja: reservation_info?.content_i18n_ja ?? '',
    ko: reservation_info?.content_i18n_ko ?? '',
    en: reservation_info?.content_i18n_en ?? '',
    'zh-CN': reservation_info?.content_i18n_zh_cn ?? '',
    'zh-TW': reservation_info?.content_i18n_zh_tw ?? '',
    'zh-HK': reservation_info?.content_i18n_zh_hk ?? '',
    th: reservation_info?.content_i18n_th ?? '',
  }

  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)

  const style = useResponsiveStyle(responsiveSheet)

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={style.container}
    >
      <View>
        <View
          style={{
            flexDirection: width < sm ? 'column' : 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <View
            style={{
              flexDirection: width < sm ? 'column' : 'row',
              columnGap: 16,
              rowGap: 16,
            }}
          >
            {externalLinks?.arWeb != null && (
              <ExternalLinkButton href={externalLinks?.arWeb}>
                {t('自店舗ページを表示')}
              </ExternalLinkButton>
            )}
            {externalLinks?.forRWeb != null && (
              <ExternalLinkButton href={externalLinks?.forRWeb}>
                {t('Web管理画面を表示')}
              </ExternalLinkButton>
            )}
          </View>
          <Button
            mode="text"
            style={{
              flexDirection: 'row',
              columnGap: 8,
              paddingHorizontal: 24,
            }}
            height={48}
            roundness={24}
            onPress={onPressEditButton}
          >
            <FontAwesomeIcon icon={faGear} size={20} color={Colors.primary} />
            <Text
              style={{
                fontWeight: '300',
                fontSize: 16,
                color: Colors.primary,
                lineHeight: 24,
              }}
            >
              店舗情報を編集
            </Text>
          </Button>
        </View>

        <View style={style.section}>
          <Text style={style.sectionTitle}>基本情報</Text>
          <ShadowBox style={style.shadowBox}>
            <InformationBlock label="名前">{restaurant.name}</InformationBlock>
            <InformationBlock label="電話番号">
              {restaurant.national_phone_number != null
                ? restaurant.national_phone_number
                : '非公開'}
            </InformationBlock>
            <InformationBlock label="住所">
              {restaurant.address != null ? restaurant.address : '非公開'}
            </InformationBlock>
            <InformationBlock label="オープン日">
              {restaurant.open_at != null ? restaurant.open_at : '未設定'}
            </InformationBlock>
          </ShadowBox>
        </View>

        <View style={style.section}>
          <Text style={style.sectionTitle}>{t('概要')}</Text>
          <ShadowBox style={style.shadowBox}>
            <InformationBlock label={t('公式動画')}>
              {restaurant.restaurant_introduction_videos?.[0] != null ? (
                <LinkOpacity
                  to={restaurant.restaurant_introduction_videos[0].video_url}
                  onPress={
                    onPressLink
                      ? () =>
                          onPressLink(
                            restaurant.restaurant_introduction_videos![0]
                              .video_url
                          )
                      : undefined
                  }
                >
                  <Text style={{ color: Colors.primary }}>
                    {restaurant.restaurant_introduction_videos[0].video_url}
                  </Text>
                </LinkOpacity>
              ) : (
                t('未設定')
              )}
            </InformationBlock>
            <InformationBlock label={t('店舗の紹介')} />
            {sortedLocales
              .slice(0, isDescriptionExpanded ? sortedLocales.length : 1)
              .filter((locale) => localeData[locale] !== undefined)
              .map((locale: SupportedLocale, index) => (
                <InformationBlock
                  label={localeData[locale]?.label}
                  key={index}
                  labelStyle={{ marginBottom: 12 }}
                >
                  <View style={{ flex: 1 }}>
                    <Text style={{ flex: 1, fontWeight: 600 }}>
                      {getLocaleValues(
                        'title_i18n_',
                        restaurant.restaurant_description
                      )[locale] || t('未設定')}
                    </Text>
                    <Text style={{ flex: 1 }}>
                      {getLocaleValues(
                        'content_i18n_',
                        restaurant.restaurant_description
                      )[locale] || t('未設定')}
                    </Text>
                  </View>
                </InformationBlock>
              ))}
            <LanguageExpandToggleButton
              isExpanded={isDescriptionExpanded}
              onPress={() => {
                setIsDescriptionExpanded((value) => !value)
              }}
              title={t('他の言語を表示')}
            />
          </ShadowBox>
        </View>

        <View style={style.section}>
          <Text style={style.sectionTitle}>お知らせ</Text>
          <ShadowBox style={style.shadowBox}>
            <InformationBlock label="店舗からのお知らせ" />
            <>
              {sortedLocales
                .slice(0, isReservationInfoExpanded ? sortedLocales.length : 1)
                .filter((locale) => localeData[locale] !== undefined)
                .map((locale: SupportedLocale, index) => (
                  <InformationBlock
                    label={localeData[locale]?.label}
                    key={index}
                    labelStyle={{ marginBottom: 12 }}
                  >
                    <Text style={{ flex: 1 }}>
                      {reservation_info_i18n[locale] != null
                        ? reservation_info_i18n[locale]
                        : '未設定'}
                    </Text>
                  </InformationBlock>
                ))}
            </>
            <LanguageExpandToggleButton
              isExpanded={isReservationInfoExpanded}
              onPress={() => {
                setIsReservationInfoExpanded((value) => !value)
              }}
              title={'他の言語を表示'}
            />
          </ShadowBox>
        </View>

        {restaurant.open_status && (
          <View style={style.section}>
            <Text style={style.sectionTitle}>営業状況</Text>
            <ShadowBox style={style.shadowBox}>
              <Text
                style={{
                  fontSize: 18,
                  fontWeight: '600',
                  color: getOpenStatusColor(restaurant.open_status),
                }}
              >
                {getOpenStatusLabel(restaurant.open_status)}
              </Text>
            </ShadowBox>
          </View>
        )}

        <View style={style.section}>
          <Text style={style.sectionTitle}>受賞歴</Text>
          <View style={{ rowGap: 16 }}>
            <ShadowBox style={style.shadowBox}>
              <InformationBlock label="ミシュラン">
                <View style={{ flex: 1 }}>
                  <MichelinList michelinAwards={michelinAwards || []} />
                </View>
              </InformationBlock>
            </ShadowBox>
            <ShadowBox style={style.shadowBox}>
              <InformationBlock label="食べログ">
                <View style={{ flex: 1 }}>
                  <TabelogList tablelogAwards={tabelogAwards || []} />
                </View>
              </InformationBlock>
            </ShadowBox>
          </View>
        </View>

        <View style={style.section}>
          <Text style={style.sectionTitle}>公式写真</Text>
          <ShadowBox style={style.shadowBox}>
            <InformationBlock label="雰囲気" style={{ alignItems: 'center' }}>
              <View
                style={{
                  flexWrap: 'wrap',
                  flex: 1,
                  flexDirection: 'row',
                  gap: 24,
                }}
              >
                {restaurant.atmosphere_images
                  ?.slice(0, 5)
                  .map((atmosphereImage) => (
                    <SelectedImage
                      source={{
                        uri: atmosphereImage.image.md,
                      }}
                      key={atmosphereImage.id}
                    />
                  ))}
              </View>
            </InformationBlock>
            <InformationBlock
              label="食事"
              style={{ marginTop: 24, alignItems: 'center' }}
            >
              <View
                style={[
                  {
                    flexWrap: 'wrap',
                    flex: 1,
                    flexDirection: 'row',
                    gap: 24,
                  },
                ]}
              >
                {restaurant.meal_images?.slice(0, 5).map((mealImage) => (
                  <SelectedImage
                    source={{
                      uri: mealImage.image.md,
                    }}
                    key={mealImage.id}
                  />
                ))}
              </View>
            </InformationBlock>
          </ShadowBox>
        </View>

        <View style={style.section}>
          <Text style={style.sectionTitle}>設備</Text>
          <ShadowBox style={style.shadowBox}>
            <InformationBlock label="駐車場">
              {facility?.parking_str != null ? facility.parking_str : '未設定'}
            </InformationBlock>
            <InformationBlock label="バリアフリー">
              {facility?.barrier_free_str != null
                ? facility.barrier_free_str
                : '未設定'}
            </InformationBlock>
            <InformationBlock label="Wi-Fi">
              {facility?.wifi_status_i18n != null
                ? facility.wifi_status_i18n
                : '未設定'}
            </InformationBlock>
            <InformationBlock label="電源">
              {facility?.power_status_i18n != null
                ? facility.power_status_i18n
                : '未設定'}
            </InformationBlock>
            <InformationBlock label="ウォシュレット">
              {facility?.washlet_status_i18n != null
                ? facility.washlet_status_i18n
                : '未設定'}
            </InformationBlock>
            <InformationBlock label="タバコ">
              {restaurant.smoking_status_i18n != null
                ? restaurant.smoking_status_i18n
                : '未設定'}
            </InformationBlock>
          </ShadowBox>
        </View>
      </View>
      <View style={style.section}>
        <Text style={style.sectionTitle}>リンク</Text>
        <ShadowBox style={style.shadowBox}>
          <InformationBlock label="Instagram">
            {restaurant.instagram_username !== undefined &&
            restaurant.instagram_username !== '' ? (
              <LinkOpacity
                to={`https://instagram.com/${restaurant.instagram_username}`}
                onPress={
                  onPressLink
                    ? () =>
                        onPressLink(
                          `https://instagram.com/${restaurant.instagram_username}`
                        )
                    : undefined
                }
              >
                <Text
                  style={{ color: Colors.primary }}
                >{`https://instagram.com/${restaurant.instagram_username}`}</Text>
              </LinkOpacity>
            ) : (
              '未設定'
            )}
          </InformationBlock>
        </ShadowBox>
      </View>
      <RestaruantDescriptionSection
        sortedLocales={sortedLocales}
        restaurant={restaurant}
      />
    </ScrollView>
  )
}

const responsiveSheet = createResponsiveSheet(({ width, sm }) => ({
  container: {
    padding: width < sm ? 24 : 48,
  },
  section: {
    marginTop: width < sm ? 24 : 48,
  },
  sectionTitle: {
    fontSize: 22,
    fontWeight: '600',
    color: Colors.black,
    marginBottom: width < sm ? 16 : 24,
  },
  shadowBox: {
    flexDirection: 'column',
    rowGap: 24,
    columnGap: 24,
    padding: 24,
  },
  informationBlock: {
    flexDirection: width < sm ? 'column' : 'row',
  },
  informationBlockLabel: {
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 22,
    width: width < sm ? '100%' : 200,
  },
  informationBlockContentText: {
    fontSize: 18,
    fontWeight: '300',
    lineHeight: 27,
  },
}))
