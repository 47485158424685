import React from 'react'

import { faArrowDownArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowDownArrowUp'
import { Dimensions, Platform, TouchableOpacity, View } from 'react-native'
import DragList from 'react-native-draglist'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { toDragHandlers } from '@hello-ai/ar_shared/src/modules/drag'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import {
  createResponsiveSheet,
  useResponsiveStyle,
} from '@hello-ai/ar_shared/src/modules/useResponsive'

import {
  RestaurantCourse,
  updateRestaurantCoursePosition,
  useRestaurantCourses,
} from '../../models/RestaurantCourse'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { displayToastError, displayToastSuccess } from '../Shared/Toast'

const responsiveStyle = createResponsiveSheet(({ width, sm }) => ({
  container: { flex: 1 },
  scrollViewContainer: {
    padding: width < sm ? 24 : 48,
    backgroundColor: Colors.bgBlack,
    flexGrow: 1,
    rowGap: 16,
  },
  flatListRoot: {
    maxHeight:
      Dimensions.get('window').height -
      (width < sm
        ? // NOTE: header + footer + padding + table header
          264
        : // NOTE: header + footer + padding + table header
          288),
    backgroundColor: Colors.white,
    borderRadius: 16,
  },
  tableContainer: {},
  rowContainer: {
    paddingHorizontal: width < sm ? 20 : 24,
    paddingVertical: 16,
    borderBottomWidth: 0.5,
    borderColor: Colors.border,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 16,
  },
  courseTitleText: {
    fontSize: 18,
    fontWeight: '600',
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 16,
    backgroundColor: Colors.white,
    paddingTop: 12,
    paddingBottom: 24,
  },
  saveButton: {
    width: width < sm ? 240 : 280,
    height: 48,
  },
  cancelButton: {
    paddingHorizontal: 0,
    width: width < sm ? 102 : 280,
    height: 48,
  },
}))

interface RestaurantCourseEditOrderProps {
  restaurantId: number
}

export default function RestaurantCourseEditOrder({
  restaurantId,
}: RestaurantCourseEditOrderProps) {
  const token = useToken()
  const style = useResponsiveStyle(responsiveStyle)
  const { restaurantCourses, mutate } = useRestaurantCourses(restaurantId)
  const navigation = useNavigation()
  const navigate = useNavigate()

  // モデルの型を拡張して 'to' プロパティを追加
  type ExtendedRestaurantCourse = RestaurantCourse & { to: number }

  const [formData, setFormData] = useFormState<
    ExtendedRestaurantCourse[] | null
  >(
    restaurantCourses
      ? restaurantCourses.map((course) => ({ ...course, to: course.position }))
      : null
  )

  const [submitting, setSubmitting] = React.useState(false)
  const onSubmit = async () => {
    if (formData == null || token == null) return
    setSubmitting(true)
    try {
      const diffRestaurantCoursesByPosition = formData?.filter((course) => {
        return course.position !== course.to
      })
      if (diffRestaurantCoursesByPosition?.length === 0) return
      const responses = await Promise.all(
        diffRestaurantCoursesByPosition?.map((course) => {
          return updateRestaurantCoursePosition(
            token,
            restaurantId,
            course.id,
            course.to
          )
        })
      )
      if (responses.some((res) => res.error != null)) {
        displayToastError(t('並べ替えに失敗しました'))
        return
      }
      displayToastSuccess(t('並べ替えが完了しました'))
      await mutate()

      if (Platform.OS === 'web') {
        navigate(`/restaurants/${restaurantId}/courses`)
      } else {
        navigation.goBack()
      }
    } catch (error) {
      onError(error)
    } finally {
      setSubmitting(false)
    }
  }

  const onPressCancel = () => {
    if (Platform.OS === 'web') {
      navigate(-1)
    } else {
      navigation.goBack()
    }
  }

  if (restaurantCourses == null) {
    return <Loading />
  }

  return (
    <View style={style.container}>
      <View style={style.scrollViewContainer}>
        <Text
          style={{
            fontSize: 14,
            fontWeight: '300',
            lineHeight: 21,
          }}
        >
          {t('コースをドラッグ&ドロップで並べ替えすることができます')}
        </Text>
        <DragList
          key={
            Platform.OS === 'web'
              ? formData
                  ?.map((item: ExtendedRestaurantCourse) => item.id)
                  .join('')
              : undefined
          }
          style={style.flatListRoot}
          containerStyle={style.tableContainer}
          data={(formData ?? []) as ExtendedRestaurantCourse[]}
          keyExtractor={(item: ExtendedRestaurantCourse) => item.id.toString()}
          renderItem={({ item, onDragStart, onDragEnd }) => (
            <View key={item.id} style={style.rowContainer}>
              <TouchableOpacity
                style={style.row}
                {...toDragHandlers(onDragStart, onDragEnd)}
              >
                <FontAwesomeIcon
                  icon={faArrowDownArrowUp}
                  size={24}
                  color={Colors.primary}
                />

                <Text style={style.courseTitleText}>{item.title}</Text>
              </TouchableOpacity>
            </View>
          )}
          onReordered={async (fromIndex, toIndex) => {
            setFormData((prev) => {
              if (prev == null) return prev
              const removed = prev[fromIndex]
              const reordered = prev
                .toSpliced(fromIndex, 1)
                .toSpliced(toIndex, 0, removed)
              return reordered.map((item, index) => ({
                ...item,
                to: index,
              }))
            })
          }}
        />
      </View>
      <View style={style.footerContainer}>
        <Button
          mode="outline"
          style={style.cancelButton}
          onPress={onPressCancel}
          textStyle={{ fontSize: 16, fontWeight: '300' }}
        >
          {t('キャンセル')}
        </Button>
        <Button
          style={style.saveButton}
          onPress={onSubmit}
          disabled={submitting}
          textStyle={{ fontSize: 16, fontWeight: '600' }}
        >
          {t('保存する')}
        </Button>
      </View>
    </View>
  )
}
