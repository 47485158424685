import React from 'react'

import { Path, Svg } from '@react-pdf/renderer'

type MemoIconProps = {
  size?: number
  color?: string
}

export function MemoIcon({ size = 16, color = '#444444' }: MemoIconProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        d="M3.5 3.5C3.5 2.67266 4.17266 2 5 2H11C11.8273 2 12.5 2.67266 12.5 3.5V12.5C12.5 13.3273 11.8273 14 11 14H5C4.17266 14 3.5 13.3273 3.5 12.5V3.5ZM5 5.375C5 5.58125 5.16875 5.75 5.375 5.75H10.625C10.8313 5.75 11 5.58125 11 5.375C11 5.16875 10.8313 5 10.625 5H5.375C5.16875 5 5 5.16875 5 5.375ZM5.375 7.25C5.16875 7.25 5 7.41875 5 7.625C5 7.83125 5.16875 8 5.375 8H10.625C10.8313 8 11 7.83125 11 7.625C11 7.41875 10.8313 7.25 10.625 7.25H5.375ZM5.375 9.5C5.16875 9.5 5 9.66875 5 9.875C5 10.0813 5.16875 10.25 5.375 10.25H8.375C8.58125 10.25 8.75 10.0813 8.75 9.875C8.75 9.66875 8.58125 9.5 8.375 9.5H5.375Z"
        fill={color}
        fillOpacity={0.6}
      />
    </Svg>
  )
}
