import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { useParams } from 'react-router'

import { parseNumber } from '@hello-ai/ar_shared/src/modules/number'
import { ReservationChangeSeat } from '@hello-ai/for_r_app/src/components/Reservation/ChangeSeat'

import { useRestaurantId } from 'modules/useRestaurantId'

export default function ReservationChangeSeatPage() {
  const restaurantId = useRestaurantId()

  const { restaurantReservationId } = useParams<{
    restaurantReservationId: string
  }>()

  const [params] = useSearchParams()
  const updateAdultPartySizeParam = params.get('updateAdultPartySize')
  const updateChildPartySizeParam = params.get('updateChildPartySize')
  const updateAdultPartySize = parseNumber(updateAdultPartySizeParam)
  const updateChildPartySize = parseNumber(updateChildPartySizeParam)
  const editedSeatIdsParam = params.get('editedSeatIds')
  const editedSeatIds = editedSeatIdsParam?.split(',')

  return (
    <ReservationChangeSeat
      restaurantId={restaurantId}
      restaurantReservationId={restaurantReservationId!}
      updateAdultPartySize={
        isNaN(updateAdultPartySize) ? undefined : updateAdultPartySize
      }
      updateChildPartySize={
        isNaN(updateChildPartySize) ? undefined : updateChildPartySize
      }
      editedSeatIds={editedSeatIds}
    />
  )
}
