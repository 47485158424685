import { useCallback, useMemo } from 'react'

import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import type { RestaurantRequestReservation } from '@hello-ai/ar_shared/src/types/ForR/RestaurantRequestReservation'
import { RestaurantReservation } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'
import { TableSeat as OldTableSeatModel } from '@hello-ai/ar_shared/src/types/ForR/TableSeat'

import { useAuth } from '../../../models/Auth'
import { Attribute } from '../../../models/CustomerAttributes'
import { restaurantBusinessTimeService } from '../../../models/RestaurantBusinessTime'
import { UseRestaurantReservationsParams } from '../../../models/RestaurantReservation'
import { restaurantReservationBlockService } from '../../../models/RestaurantReservationBlock'
import axios from '../../../modules/axios'
import { rpcOptions } from '../../../modules/rpc'
import useSWR, {
  fetcher,
  fetcherWithPaginationHeader,
  swrKey,
  WithHeaderData,
} from '../../../modules/swr'

import { createChartViewProps } from './ChartView/model'

import type { ChartViewProps } from './ChartView/types'

// fetchLatestDataを含むChartViewPropsの拡張型を定義
export interface PrintChartViewProps extends ChartViewProps {
  isLoading: false
}

const fetchReservations = async (
  restaurantId: number,
  date: dayjs.Dayjs,
  token: string | null
): Promise<RestaurantReservation[]> => {
  const response = await axios.get<RestaurantReservation[]>(
    `/reservation_book/restaurants/${restaurantId}/reservations`,
    {
      params: {
        date: date.format('YYYY-MM-DD'),
        status: 'reserved',
      },
      headers:
        token !== null
          ? {
              Authorization: `Token token=${token}`,
              'X-AR-Token': token,
            }
          : undefined,
    }
  )
  return response.data
}

export function useTokenForWebView() {
  const { auth } = useAuth()
  const token = auth?.token ?? localStorage.getItem('nativeToken')
  return token ?? ''
}

export const usePrintListView = (restaurantId: number, date: dayjs.Dayjs) => {
  const token = useTokenForWebView()
  const { data, error, isLoading, mutate } = useSWR(
    token !== null
      ? ['print-reservations', restaurantId, date.format('YYYY-MM-DD')]
      : null,
    () => fetchReservations(restaurantId, date, token),
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      dedupingInterval: 0,
      revalidateIfStale: true,
      refreshInterval: 30000,
    }
  )

  // 強制的に最新データを取得する関数
  const fetchLatestData = useCallback(async () => {
    return await mutate()
  }, [mutate])

  return {
    reservations: data || [],
    error,
    isLoading,
    fetchLatestData,
  }
}

export function usePrintChartView(
  restaurantId: number,
  date: dayjs.Dayjs
): PrintChartViewProps | { isLoading: true } {
  const token = useTokenForWebView()
  // レストラン情報
  const {
    data: restaurantData,
    isLoading: isLoadingRestaurant,
    // mutate: mutateRestaurant,
  } = useSWR<Restaurant, any, ReturnType<typeof swrKey>>(
    swrKey(token, `/restaurants/${restaurantId}`),
    fetcher,
    {
      dedupingInterval: 0,
      refreshInterval: 30000,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )
  // 顧客情報
  const {
    data: customerAttributesData,
    isLoading: isLoadingCustomerAttributes,
    // mutate: mutateCustomerAttributes,
  } = useSWR<Attribute[], any, ReturnType<typeof swrKey> | null>(
    restaurantId === undefined
      ? null
      : swrKey(
          token,
          `/restaurants/${restaurantId}/customer_custom_attributes`
        ),
    fetcher,
    {
      dedupingInterval: 0,
      refreshInterval: 30000,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )

  // テーブル席情報
  const {
    data: tableSeatsData,
    isLoading: isLoadingTableSeats,
    // mutate: mutateTableSeats,
  } = useSWR<OldTableSeatModel[]>(
    swrKey(token, `/restaurants/${restaurantId}/seats`, {
      date: date.format('YYYY-MM-DD'),
    }),
    ([token, url]: [token: string | null, url: string]) =>
      fetcher<OldTableSeatModel[]>([
        token,
        url,
        {
          date: date.format('YYYY-MM-DD'),
        },
      ]),
    {
      refreshInterval: 30000,
      dedupingInterval: 0,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )

  // 予約情報
  const reservationsParams = {
    date: date.format('YYYY-MM-DD'),
  }
  const {
    data: reservationsData,
    isLoading: isLoadingReservations,
    // mutate: mutateReservations,
  } = useSWR<
    WithHeaderData<RestaurantReservation[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/reservation_book/restaurants/${restaurantId}/reservations`,
      reservationsParams
    ),
    ([token, url]) =>
      fetcherWithPaginationHeader<
        RestaurantReservation[],
        UseRestaurantReservationsParams
      >([token, url, reservationsParams]),
    {
      refreshInterval: 30000,
      dedupingInterval: 0,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )

  // ブロック期間データの取得
  const restaurantReservationBlocksParams = {
    restaurantId,
    startDate: date.format('YYYY-MM-DD'),
    endDate: date.format('YYYY-MM-DD'),
  }
  const {
    data: restaurantReservationBlocks,
    isLoading: isLoadingRestaurantReservationBlocks,
    // mutate: mutateBlocks,
  } = useSWR(
    swrKey(
      token,
      '/restaurant_reservation_blocks',
      restaurantReservationBlocksParams
    ),
    () =>
      restaurantReservationBlockService.client.listPeriod(
        restaurantReservationBlocksParams,
        rpcOptions({ token })
      ).response,
    {
      refreshInterval: 30000,
      dedupingInterval: 0,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )

  // restaurantBusinessTimeService
  const businessTImeParams = {
    restaurantId,
    gte: date.startOf('month').format('YYYY-MM-DD'),
    lte: date.endOf('month').format('YYYY-MM-DD'),
  }
  const {
    data: businessTimesData,
    isLoading: isLoadingBusinessTimes,
    // mutate: mutateBusinessTimes,
  } = useSWR(
    swrKey(token, '/restaurant_business_times/calendar', businessTImeParams),
    () =>
      restaurantBusinessTimeService.client.getCalendar(
        businessTImeParams,
        rpcOptions({ token })
      ).response,
    {
      refreshInterval: 30000,
      dedupingInterval: 0,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )
  const businessTimes = useMemo(
    () =>
      businessTimesData?.dates?.find(
        (d) => d.date === date.format('YYYY-MM-DD')
      )?.businessTimes ?? [],
    [businessTimesData?.dates, date]
  )

  const requestReservationsParams = {
    date: date.format('YYYY-MM-DD'),
  }
  const {
    data: requestReservationsData,
    isLoading: isLoadingRequestReservations,
    // mutate: mutateRequestReservations,
  } = useSWR<
    WithHeaderData<RestaurantRequestReservation[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/reservation_book/restaurants/${restaurantId}/reservation_requests`,
      requestReservationsParams
    ),
    ([token, url]) =>
      fetcherWithPaginationHeader([token, url, requestReservationsParams]),
    {
      dedupingInterval: 0,
      revalidateIfStale: true,
      refreshInterval: 30000,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )

  if (
    isLoadingRestaurant ||
    isLoadingCustomerAttributes ||
    isLoadingTableSeats ||
    isLoadingReservations ||
    isLoadingRestaurantReservationBlocks ||
    isLoadingBusinessTimes ||
    isLoadingRequestReservations
  ) {
    return { isLoading: true }
  }

  return {
    isLoading: false,
    ...createChartViewProps({
      restaurantName: restaurantData?.name ?? '',
      tableSeats: tableSeatsData ?? [],
      reservations: (reservationsData?.data ?? []).filter(
        (r) => r.table_seats.length > 0
      ),
      otherReservations: (reservationsData?.data ?? []).filter(
        (r) => r.table_seats.length === 0
      ),
      businessTimes,
      blockPeriods:
        restaurantReservationBlocks?.restaurantReservationBlockPeriods ?? [],
      displayDate: date.format('YYYY-MM-DD'),
      isRestaurantSmartPaymentAvailable:
        restaurantData?.table_restaurant_setting?.smart_payment_available ??
        false,
      requestReservations: requestReservationsData?.data ?? [],
      customerAttributes: customerAttributesData ?? [],
    }),
  }
}

export function useIsValidToken({
  token,
  restaurantId,
}: {
  token: string
  restaurantId: number
}) {
  const { data: restaurantData } = useSWR<
    Restaurant,
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/restaurants/${restaurantId}`), fetcher, {
    dedupingInterval: 0,
    refreshInterval: 30000,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
  })
  return restaurantData != null
}
