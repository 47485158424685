import React from 'react'

import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { GestureResponderEvent, StyleProp, View, ViewStyle } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import {
  DEFAULT_TAX_RATE,
  REDUCED_TAX_RATE,
} from '@hello-ai/ar_shared/src/constants/TaxRates'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

import { RestaurantCourse as RestaurantCourseModel } from '../../models/RestaurantCourse'

const getCustomerRegionRestrictionType = (
  customerRegionRestrictionType: RestaurantCourseModel['customer_region_restriction_type']
) => {
  switch (customerRegionRestrictionType) {
    case 'no_restriction':
      return t('すべての国')
    case 'domestic_only':
      return t('国内(日本)')
    case 'overseas_only':
      return t('海外')
    case 'specific_countries_only':
      // CAUTION: NOT IMPLEMENTED
      return t('特定の国')
  }
}

export function RestaurantCourse({
  restaurantCourse,
  onPress,
  style,
}: {
  restaurantCourse: RestaurantCourseModel
  onPress?: (event: GestureResponderEvent) => void
  style?: StyleProp<ViewStyle>
}) {
  return (
    <TouchableOpacity
      style={[
        {
          padding: 24,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        style,
      ]}
      disabled={onPress == null}
      onPress={onPress}
    >
      <View>
        <Text style={{ fontWeight: '600' }}>{restaurantCourse.title}</Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 8,
          }}
        >
          <Text>{restaurantCourse.price_str}</Text>
          <View
            style={{
              padding: 4,
              marginLeft: 8,
              backgroundColor: Colors.bgBlack,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Text
              style={{
                fontSize: 12,
              }}
            >
              {`${restaurantCourse.tax_included ? t('税込') : t('税抜')}${
                restaurantCourse.tax_type === 'default'
                  ? DEFAULT_TAX_RATE
                  : REDUCED_TAX_RATE
              }%`}
            </Text>
          </View>
        </View>
        <View style={{ gap: 4, marginTop: 8 }}>
          {(restaurantCourse.table_menus?.length ?? 0) > 0 && (
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
              }}
            >
              {restaurantCourse.table_menus?.map((tableMenu, index) => {
                return (
                  <Text
                    key={tableMenu.id}
                    style={{
                      fontSize: 14,
                      color: Colors.black60,
                      marginLeft: index === 0 ? 0 : 4,
                    }}
                  >
                    {tableMenu.name}
                  </Text>
                )
              })}
            </View>
          )}
          <Text style={{ fontSize: 14, color: Colors.black60 }}>
            {getCustomerRegionRestrictionType(
              restaurantCourse.customer_region_restriction_type
            )}
          </Text>
        </View>
      </View>
      {onPress && <FontAwesomeIcon icon={faChevronRight} />}
    </TouchableOpacity>
  )
}
