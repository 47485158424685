import type React from 'react'

import { Document, Font, Page, StyleSheet } from '@react-pdf/renderer'

import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

import { ListView, ReservationListView, TableHeader } from './ListView'
import { PrintWrap } from './PrintWrap'

import type { ReservationData } from './ListView'

import NotoSansJPBold from 'assets/fonts/NotoSansJP-Bold.ttf'
import NotoSansJPRegular from 'assets/fonts/NotoSansJP-Regular.ttf'

Font.register({ family: 'NotoSansJPRegular', src: NotoSansJPRegular })
Font.register({ family: 'NotoSansJPBold', src: NotoSansJPBold })

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    padding: 24,
  },
})

/**
 * 提供された高さに基づいて予約をチャンクに分割します。
 * 各チャンクには、高さ622以内に収まる予約が含まれます。
 *
 * @param {ReservationData[]} reservations - 分割する予約のリスト。
 * @param {number[]} heights - 各予約の高さ。
 * @returns {ReservationData[][]} - チャンクに分割された予約。
 */
const splitReservationsByHeight = (
  reservations: ReservationData[],
  heights: number[]
): ReservationData[][] => {
  const chunks: ReservationData[][] = []
  let currentChunk: ReservationData[] = []
  let currentHeight = 0

  reservations.forEach((reservation, index) => {
    if (currentHeight + heights[index] > 622) {
      chunks.push(currentChunk)
      currentChunk = [reservation]
      currentHeight = heights[index]
    } else {
      currentChunk.push(reservation)
      currentHeight += heights[index]
    }
  })

  if (currentChunk.length > 0) {
    chunks.push(currentChunk)
  }

  return chunks
}

type Props = {
  reservationDate: dayjs.Dayjs
  reservations: ReservationData[]
  heights: number[]
  restaurantName: string
  latestUpdatedAt: string
}
/**
 * 予約のPDFドキュメントを生成します。
 *
 * @param {Object} props - PDFドキュメントのプロパティ。
 * @param {Object} props.restaurant - レストラン情報。
 * @param {string} props.restaurant.name - レストランの名前。
 * @param {dayjs.Dayjs} props.date - 予約の日付。
 * @param {ReservationData[]} props.reservations - 予約のリスト。
 * @param {"portrait" | "landscape"} props.orientation - PDFの向き。
 * @param {number[]} props.heights - 各予約の高さ。
 * @returns {JSX.Element} - PDFドキュメントコンポーネント。
 */
export function ListViewPDF({
  restaurantName,
  latestUpdatedAt,
  reservationDate,
  reservations,
  heights,
}: Props) {
  const chunks = splitReservationsByHeight(reservations, heights)
  const totalPages = chunks.length

  return (
    <Document>
      {chunks.map((chunk, pageIndex) => (
        <Page
          key={`${pageIndex}-${JSON.stringify(chunk)}`}
          size="A4"
          style={styles.page}
          orientation={'portrait'}
        >
          <PrintWrap
            orientation={'portrait'}
            currentPage={pageIndex + 1}
            totalPages={totalPages}
            lastUpdatedAt={latestUpdatedAt}
            restaurantName={restaurantName}
            reservationDate={reservationDate}
          >
            <ListView>
              <TableHeader />
              {chunk.map((reservation) => (
                <ReservationListView
                  key={reservation.id}
                  restaurantReservation={reservation}
                />
              ))}
            </ListView>
          </PrintWrap>
        </Page>
      ))}
    </Document>
  )
}
