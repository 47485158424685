import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import { inRange } from 'lodash'
import _uniq from 'lodash/uniq'
import { Alert, Platform, View } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import { Menu } from 'react-native-paper'
import Animated, {
  runOnJS,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated'

import {
  type AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { onError } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useEffectEvent } from '@hello-ai/ar_shared/src/modules/useEffectEvent'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { TableSeat } from '@hello-ai/ar_shared/src/types/ForR/TableSeat'
import type { ListPeriodResponse_RestaurantReservationBlockPeriod } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation_block/restaurant_reservation_block_service'
import { Timestamp } from '@hello-ai/proto/src/gen/google/protobuf/timestamp'

import { useBusinessTimesFromGetCalendar } from '../../../models/RestaurantBusinessTime'
import { useRestaurantRequestReservations } from '../../../models/RestaurantRequestReservation'
import {
  type RestaurantReservation as RestaurantReservationModel,
  useRestaurantReservations,
} from '../../../models/RestaurantReservation'
import { restaurantReservationBlockService } from '../../../models/RestaurantReservationBlock'
import {
  TableSeat as TableSeatModel,
  useTableSeats,
} from '../../../models/TableSeat'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { toHoursAndMinutes, toSeconds } from '../../../modules/time'
import Loading from '../../Shared/Loading'
import { displayToastError } from '../../Shared/Toast'

import { HeaderCell } from './HeaderCell'
import { HourCellList } from './HourCellList'
import { Reservation } from './Reservation'
import { RestaurantReservationBlockPeriod } from './RestaurantReservationBlockPeriod'
import { SelectSeatList } from './SelectSeatList'
import {
  HOURS,
  SCROLL_VIEW_PADDING_Y,
  PADDING_HORIZONTAL,
  HOUR_PC_WIDTH,
  BORDER_WIDTH,
  SEAT_PC_WIDTH,
  CELL_HEIGHT,
  PARTY_SIZE_WIDTH,
  HOUR_SP_WIDTH,
  SEAT_PC_HEIGHT,
  SEAT_SP_HEIGHT,
  SEAT_SP_WIDTH,
  SCROLL_VIEW_PC_PADDING_X,
  REFRESH_INTERVAL,
  DEFAULT_SHOW_REQUEST_RESERVATION_SIZE,
} from './const'
import {
  getReservationText,
  getOtherReservations,
  findOverlappingItemsByTableSeatId,
  getStartTimeFromPosition,
  getSeatIdFromPosition,
  getPartySize,
  groupConsecutiveReservationSeats,
  overlapsWith,
} from './utils'

import type { Items, ChartProps, ChartMethods } from './types'
import type { ReanimatedScrollEvent } from 'react-native-reanimated/lib/typescript/hook/commonTypes'

export const Chart = React.forwardRef<ChartMethods, ChartProps>(
  (
    {
      restaurantId,
      date,
      mode = 'default',
      selectParams,
      scrollViewProps,
      editModeReservation,
      handleLongPressEditMode,
      onEndEditMode,
      onLoadStart,
      onLoadEnd,
      onReservationAdded,
      onStartSelectBlock,
      onEndSelectBlock,
      onMoveTableSeats,
      otherTableSeats,
      toastMarginBottom,
    },
    ref
  ) => {
    const { tableSeats, isLoading: tableSeatsLoading } = useTableSeats(
      restaurantId,
      {}
    )
    const { width, sm } = useResponsive()
    const navigation = useNavigation()
    const navigate = useNavigate()
    const alertRef = useRef<AlertMethods>(null)

    const {
      restaurantReservations: allRestaurantReservations,
      mutate,
      isLoading: restaurantReservationsLoading,
    } = useRestaurantReservations(
      restaurantId,
      {
        date: date.format('YYYY-MM-DD'),
      },
      {
        refreshInterval: REFRESH_INTERVAL,
      }
    )

    const [refreshCount, setRefreshCount] = useState(0)

    const refreshReservationData = async () => {
      await mutate()
      await mutateBlocks()
      setRefreshCount((pre) => pre + 1)
      runOnJS(onEndEditMode)()
    }

    const restaurantReservations = useMemo(() => {
      if (!allRestaurantReservations?.length) return []
      // モードがselectSeatsの場合、自身の予約を除外して表示したいケースがある
      if (mode === 'selectSeats' && selectParams?.restaurantReservation) {
        return allRestaurantReservations.filter(
          (item) => item.id !== selectParams.restaurantReservation?.id
        )
      }
      return allRestaurantReservations
    }, [allRestaurantReservations, mode, selectParams])

    const otherReservations = useMemo(() => {
      return getOtherReservations({
        restaurantReservations,
      })
    }, [restaurantReservations])

    const { businessTimes } = useBusinessTimesFromGetCalendar(
      restaurantId,
      date
    )

    const isRestaurantOpenAt = useCallback(
      (hour: number, min: number) =>
        businessTimes?.some(({ open, startAt, endAt }) => {
          if (!open || !startAt || !endAt) return false
          // 営業時間が日を跨ぐ場合は1日分の秒数を加算し、適切に計算できるようにする
          const getAdjustedSeconds = (day: dayjs.Dayjs) =>
            day.isSame(date, 'day')
              ? toSeconds(day.hour(), day.minute())
              : 86400 + toSeconds(day.hour(), day.minute())
          const startSeconds = getAdjustedSeconds(dayjs.unix(startAt.seconds))
          const endSeconds = getAdjustedSeconds(dayjs.unix(endAt.seconds))
          return inRange(toSeconds(hour, min), startSeconds, endSeconds)
        }),
      [businessTimes, date]
    )

    const { data: restaurantReservationBlocks, mutate: mutateBlocks } =
      restaurantReservationBlockService.useListPeriod({
        restaurantId,
        startDate: date.format('YYYY-MM-DD'),
        endDate: date.format('YYYY-MM-DD'),
      })

    const [isShowMoreRequestReservations, setIsShowMoreRequestReservation] =
      useState(false)
    const { requestReservations: requestReservationsData = [] } =
      useRestaurantRequestReservations(restaurantId, { date })

    const filteredRequestReservations = useMemo(() => {
      return requestReservationsData.filter((requestReservation) => {
        if (
          requestReservation.reservation?.reservation_change_request?.status ===
          'requesting'
        ) {
          const fromReservationDate = dayjs(
            requestReservation.reservation.reservation_change_request
              .from_reserved_at
          ).format('YYYY-MM-DD')
          const toReservationDate = dayjs(
            requestReservation.reservation.reservation_change_request
              .to_reserved_at
          ).format('YYYY-MM-DD')
          const condition =
            // 同日の変更の場合は表示する
            fromReservationDate === toReservationDate ||
            // 変更前のChart日付では予約行のreservationで処理表示するため、リクエスト予約行では表示しない
            date.format('YYYY-MM-DD') !== fromReservationDate
          return condition
        }
        return true
      })
    }, [requestReservationsData, date])

    const reservationChangeRequests = useMemo(() => {
      return requestReservationsData.filter((requestReservation) => {
        if (
          requestReservation?.reservation?.reservation_change_request != null
        ) {
          // 変更リクエストが変更後日時にも含まれるがChart画面では表示しない
          const condition =
            date.format('YYYY-MM-DD') ===
            dayjs(
              requestReservation.reservation.reservation_change_request
                .to_reserved_at
            ).format('YYYY-MM-DD')
          return !condition
        }
        return false
      })
    }, [requestReservationsData, date])

    const requestReservations = useMemo(
      () =>
        mode === 'default'
          ? filteredRequestReservations.slice(
              0,
              isShowMoreRequestReservations
                ? undefined
                : DEFAULT_SHOW_REQUEST_RESERVATION_SIZE
            )
          : [],
      [isShowMoreRequestReservations, mode, filteredRequestReservations]
    )

    const scrollViewRef = useRef<Animated.ScrollView>(null)
    const scrollViewContentOffsetRef = useRef(0)

    const scrollRootRef = useRef<Animated.ScrollView>(null)

    useImperativeHandle(ref, () => ({
      scrollToTime: ({ time, animated = false }) => {
        setTimeout(() => {
          const { hours: hour, minutes: minute } = toHoursAndMinutes(time)
          scrollViewRef.current?.scrollTo({
            x:
              scrollViewContentOffsetRef.current > 0
                ? scrollViewContentOffsetRef.current
                : (hour + minute / 60) *
                  (width < sm ? HOUR_SP_WIDTH : HOUR_PC_WIDTH),
            y: 0,
            animated,
          })
        }, 0)
      },
      refreshData: refreshReservationData,
    }))

    const latestRootScrollViewEvent =
      useSharedValue<ReanimatedScrollEvent | null>(null)
    const headerTranslateY = useSharedValue(0)

    const onScroll = useAnimatedScrollHandler((event) => {
      const scrollY = event.contentOffset.y
      // TODO: react-compiler for react-native-reanimated
      // eslint-disable-next-line react-compiler/react-compiler
      headerTranslateY.set(
        scrollY < SCROLL_VIEW_PADDING_Y ? 0 : scrollY - SCROLL_VIEW_PADDING_Y
      )
      latestRootScrollViewEvent.set(event)
    })

    const latestHorizontalScrollViewEvent =
      useSharedValue<ReanimatedScrollEvent | null>(null)
    const onHorizontalScroll = useAnimatedScrollHandler((event) => {
      scrollViewContentOffsetRef.current = event.contentOffset.x
      // TODO: react-compiler for react-native-reanimated
      // eslint-disable-next-line react-compiler/react-compiler
      latestHorizontalScrollViewEvent.value = event
    })

    const isLoading = tableSeatsLoading || restaurantReservationsLoading

    const prevIsLoading = usePrevious(isLoading)
    const prevDate = usePrevious(date)
    const prevAllRestaurantReservations = usePrevious(allRestaurantReservations)

    useEffect(() => {
      if (prevDate !== date) {
        onLoadStart?.()
      }
    }, [date, onLoadStart, prevDate])

    useEffect(() => {
      if (
        (prevDate !== date && !isLoading) ||
        (prevIsLoading !== isLoading && !isLoading)
      ) {
        onLoadEnd?.()
      }
    }, [date, isLoading, onLoadEnd, prevDate, prevIsLoading])

    const handleReservationAdded = useEffectEvent(() => {
      onReservationAdded?.()
    })

    useEffect(() => {
      // NOTE: 新規予約追加後データに反映されるまでを記録する
      if (
        (allRestaurantReservations?.length ?? 0) >
        (prevAllRestaurantReservations?.length ?? 0)
      ) {
        handleReservationAdded()
      }
    }, [
      prevAllRestaurantReservations,
      allRestaurantReservations,
      handleReservationAdded,
    ])

    const [menuPopOver, setMenuPopOver] = useState<
      | {
          visible: false
          absoluteX?: number
          absoluteY?: number
          items?: Items
        }
      | {
          visible: true
          absoluteX: number
          absoluteY: number
          items: Items
        }
    >({
      visible: false,
    })

    const headerCellStyle = useAnimatedStyle(() => {
      return { transform: [{ translateY: headerTranslateY.get() }] }
    })

    const onPressBackgroundView = Gesture.Tap().onEnd((event, success) => {
      if (!success) return

      if (mode === 'default') {
        const startTime = getStartTimeFromPosition(event.x, width < sm)
        const seatId = getSeatIdFromPosition(
          event.y,
          width < sm,
          tableSeats,
          requestReservations.length + otherReservations.length
        )
        // 編集モード時は編集モードを終了する
        // 選択ブロックモード時は選択ブロックを開始する
        if (onStartSelectBlock) {
          if (editModeReservation != null) {
            runOnJS(onEndEditMode)()
          } else {
            runOnJS(onStartSelectBlock)({ startTime, seatId })
          }
        }
      } else if (mode === 'selectBlock') {
        const { startTime, endTime } = selectParams!
        const tappedTime = getStartTimeFromPosition(event.x, width < sm)
        // 選択ブロックの外側をタップした場合は終了する
        if (
          onEndSelectBlock &&
          (tappedTime < startTime || tappedTime > endTime)
        ) {
          runOnJS(onEndSelectBlock)()
        }
      }
    })
    onPressBackgroundView.enabled(mode === 'default' || mode === 'selectBlock')

    const onPressReservation = ({
      restaurantReservation,
      tableSeatId,
      absoluteX,
      absoluteY,
    }: {
      restaurantReservation: RestaurantReservationModel
      tableSeatId: TableSeatModel['id']
      absoluteX: number
      absoluteY: number
    }) => {
      const items = findOverlappingItemsByTableSeatId({
        tableSeatId,
        timeRange: {
          startAt: dayjs(restaurantReservation.start_at),
          endAt: dayjs(restaurantReservation.end_at),
        },
        reservations: restaurantReservations,
        reservationBlockPeriods:
          restaurantReservationBlocks?.restaurantReservationBlockPeriods ?? [],
      })

      if (items.reservationBlockPeriods.length > 0) {
        setMenuPopOver({
          visible: true,
          absoluteX,
          absoluteY,
          items,
        })
      }

      if (restaurantReservation.reservation_change_request != null) {
        if (Platform.OS === 'web') {
          navigate(
            `/restaurants/${restaurantId}/reservations/change_requests/${restaurantReservation.id}`
          )
        } else {
          navigation.navigate('ChangeRequestReservation', {
            data: {
              ...restaurantReservation,
              restaurant_course:
                restaurantReservation.reservation_courses.length > 0
                  ? restaurantReservation.reservation_courses[0]
                      .restaurant_course
                  : undefined,
            },
            customer: restaurantReservation.customers[0],
          })
        }
        return
      }

      runOnJS(onEndEditMode)()
      if (Platform.OS === 'web') {
        if (restaurantReservation.kind === 'walkin') {
          navigate(
            `/restaurants/${restaurantId}/reservations/walkins/${restaurantReservation.id}`
          )
        } else {
          navigate(
            `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}`
          )
        }
      } else {
        if (restaurantReservation.kind === 'walkin') {
          navigation.navigate('ReservationWalkinsShow', {
            restaurantReservationId: restaurantReservation.id,
          })
        } else {
          navigation.navigate('ReservationsShow', {
            restaurantReservationId: restaurantReservation.id,
          })
        }
      }
    }

    const onPressReservationBlockPeriod = ({
      restaurantReservationBlockPeriod,
      tableSeatId,
      absoluteX,
      absoluteY,
    }: {
      restaurantReservationBlockPeriod: ListPeriodResponse_RestaurantReservationBlockPeriod
      tableSeatId: TableSeatModel['id']
      absoluteX: number
      absoluteY: number
    }) => {
      const items = findOverlappingItemsByTableSeatId({
        tableSeatId,
        timeRange: {
          startAt: dayjs(
            Timestamp.toDate(restaurantReservationBlockPeriod.startAt!)
          ),
          endAt: dayjs(
            Timestamp.toDate(restaurantReservationBlockPeriod.endAt!)
          ),
        },
        reservations: restaurantReservations,
        reservationBlockPeriods:
          restaurantReservationBlocks?.restaurantReservationBlockPeriods ?? [],
      })

      if (items.reservations.length > 0) {
        setMenuPopOver({
          visible: true,
          absoluteX,
          absoluteY,
          items,
        })
        return
      }

      if (Platform.OS === 'web') {
        navigate(
          `/restaurants/${restaurantId}/reservations/blocks/${restaurantReservationBlockPeriod.restaurantReservationBlock?.id}?startAt=${restaurantReservationBlockPeriod.startAt?.seconds}&endAt=${restaurantReservationBlockPeriod.endAt?.seconds}`
        )
      } else {
        navigation.navigate('ReservationBlocksShow', {
          restaurantReservationBlockId:
            restaurantReservationBlockPeriod.restaurantReservationBlock?.id,
          startAt: restaurantReservationBlockPeriod.startAt,
          endAt: restaurantReservationBlockPeriod.endAt,
        })
      }
    }

    /**
     * 席を一括で移動する（ドラッグアンドドロップ）ロジック
     * 連続席で1つのカードにまとめて表示されている場合は、そのカードを1つの予約として扱う
     */
    const handleMoveTableSeats = async (
      restaurantReservation: RestaurantReservationModel,
      prevTableSeats: Pick<TableSeat, 'id' | 'name'>[],
      nextTableSeats: Pick<TableSeat, 'id' | 'name'>[],
      nextSeatsSize: number,
      nextStartAt?: dayjs.Dayjs,
      nextEndAt?: dayjs.Dayjs
    ) => {
      const alert = Platform.select({
        web: alertRef.current?.alert,
        default: Alert.alert,
      })
      // ここから関数定義
      const runManualChangeTableSeats = (rr: RestaurantReservationModel) => {
        alert(
          t('予約人数が定員を超えています'),
          t('席を追加、または変更しますか？'),
          [
            {
              text: t('いいえ'),
              onPress: () => {
                runSimpleMoveTableSeats()
              },
              style: 'cancel',
            },
            {
              text: t('はい'),
              onPress: () => {
                const editedSeatIds = nextTableSeats.map((seat) => seat.id)
                if (Platform.OS === 'web') {
                  navigate(
                    `/restaurants/${restaurantId}/reservations/${rr.id}/change_seat?editedSeatIds=${editedSeatIds.join(',')}`
                  )
                } else {
                  navigation.navigate('ChangeSeatForm', {
                    restaurantReservationId: rr.id,
                    editedSeatIds,
                  })
                }
                // 変更後に座標移動が残ってしまうので先に refresh しておく
                refreshReservationData()
              },
            },
          ]
        )
      }

      const runSimpleMoveTableSeats = () => {
        const finalNextTableSeatIds = [
          // 移動前の席を除外
          ...restaurantReservation.table_seats
            .filter((seat) => !prevTableSeats.some((p) => p.id === seat.id))
            .map((seat) => seat.id),
          // 新しく追加される移動先の席
          ...nextTableSeats.map((seat) => seat.id),
        ]
        onMoveTableSeats?.({
          restaurantId,
          params: {
            table_seat_ids: _uniq(finalNextTableSeatIds),
            start_at: nextStartAt?.format(),
            end_at: nextEndAt?.format(),
          },
        })
      }

      const runSeatExchange = (params?: {
        end_at: string
        table_seat_ids: string[]
        other_table_seats: {
          reservation_id: string
          table_seat_ids: string[]
        }[]
      }) => {
        alert(t('席の入れ替え'), t('席を入れ替えますか？'), [
          {
            text: t('いいえ'),
            onPress: () => {
              refreshReservationData()
            },
            style: 'cancel',
          },
          {
            text: t('はい'),
            onPress: () => {
              onMoveTableSeats?.({
                restaurantId,
                params: {
                  ...params,
                },
              })
            },
          },
        ])
      }

      // 衝突していた場合
      const runCollisionChangeTableSeats = () => {
        alert(t('他の予約と被っています'), t('席を変更しますか？'), [
          {
            text: t('いいえ'),
            onPress: () => {
              refreshReservationData()
            },
            style: 'cancel',
          },
          {
            text: t('はい'),
            onPress: () => {
              if (Platform.OS === 'web') {
                navigate(
                  `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}/change_seat`
                )
              } else {
                navigation.navigate('ChangeSeatForm', {
                  restaurantReservationId: restaurantReservation.id,
                })
                refreshReservationData()
              }
            },
          },
        ])
      }

      // 衝突チェック
      const checkCollision = (): boolean => {
        // 移動先シートが他で予約されているか
        const nextTableSeatIds = nextTableSeats.map((ts) => ts.id)
        if (editModeReservation == null) return false
        return (
          allRestaurantReservations
            // 自身を除外
            .filter((i) => i.id !== editModeReservation.id)
            // 移動先の席が含まれている予約を取得
            .filter((i) =>
              i.table_seats
                .flatMap((t) => t.id)
                .some((tid) => nextTableSeatIds.includes(tid))
            )
            .some((r) => {
              return overlapsWith(
                {
                  startAt: dayjs(r.start_at),
                  endAt: dayjs(r.end_at),
                },
                {
                  startAt: nextStartAt ?? dayjs(editModeReservation.start_at),
                  endAt: nextEndAt ?? dayjs(editModeReservation.end_at),
                }
              )
            })
        )
      }

      // 席交換ロジック
      const exchange = async () => {
        // 移動する予約のID
        const movingReservationId = restaurantReservation.id
        // 移動前の席ID
        const prevTableSeatIds = prevTableSeats.map((ts) => ts.id)
        // 移動先の席ID
        const nextTableSeatIds = nextTableSeats.map((ts) => ts.id)

        // 自分の予約を除外し、時間がかぶる予約だけを取得
        const overlappingReservations = restaurantReservations
          .filter((r) => r.id !== movingReservationId)
          .filter(
            (r) =>
              dayjs(restaurantReservation.start_at).isBefore(r.end_at) &&
              dayjs(r.start_at).isBefore(restaurantReservation.end_at)
          )

        // 移動先の予約を取得
        const existingNextReservations = overlappingReservations.filter((r) =>
          r.table_seats.some((ts) => nextTableSeatIds.includes(ts.id))
        )

        // 移動先の予約の席のIDを取得
        const existingNextSeatIds = existingNextReservations
          .flatMap((r) => r.table_seats.map((ts) => ts.id))
          .filter((id) => nextTableSeatIds.includes(id))

        // ドロップ範囲外の予約
        const restNextReservations: {
          reservation_id: string
          table_seat_ids: string[]
        }[] = [
          // 移動先の予約のうち、ドロップ範囲ではない席の予約を取得する
          ...restaurantReservations
            .filter((r) => r.id !== movingReservationId) // 自分の予約を除外
            .filter((r) => {
              // 時間がかぶらないものを除外
              return (
                dayjs(restaurantReservation.start_at).isBefore(r.end_at) &&
                dayjs(r.start_at).isBefore(restaurantReservation.end_at)
              )
            })
            .filter((r) => {
              const tableSeatIds = r.table_seats.map((ts) => ts.id)
              return tableSeatIds.some((id) => nextTableSeatIds.includes(id))
            })
            .map((r) => {
              return {
                reservation_id: r.id,
                table_seat_ids: r.table_seats
                  .filter((ts) => !nextTableSeatIds.includes(ts.id))
                  .map((ts) => ts.id),
              }
            })
            .filter((r) =>
              r.table_seat_ids.some((id) => !nextTableSeatIds.includes(id))
            ),
          // 移動する予約のうち、ドロップ範囲外のものだけを取得
          ...restaurantReservations
            .filter((r) => r.id === movingReservationId)
            .map((r) => {
              return {
                reservation_id: r.id,
                table_seat_ids: r.table_seats
                  .filter((ts) => !prevTableSeatIds.includes(ts.id))
                  .map((ts) => ts.id),
              }
            }),
        ]

        // 移動前の席のうち移動先とかぶらない席
        const restPrevTableSeatIds = prevTableSeats
          .filter((ts) => !nextTableSeatIds.includes(ts.id))
          .map((ts) => ts.id)

        const otherTableSeats: {
          reservation_id: string
          table_seat_ids: string[]
        }[] = [
          // ドロップ先の席を、ドラッグ元の予約の席に移動させる
          ...existingNextSeatIds.map((id, index) => {
            if (restPrevTableSeatIds[index] == null) {
              return {
                reservation_id: '',
                table_seat_ids: [],
              }
            }
            return {
              reservation_id:
                existingNextReservations.find((e) =>
                  e.table_seats.some((ts) => ts.id === id)
                )?.id ?? '',
              table_seat_ids: [restPrevTableSeatIds[index]],
            }
          }),
          // ドロップ先の予約の席のうち、ドロップ範囲でない席をそのままの席で予約し直す
          ...restNextReservations.map((r) => {
            return {
              reservation_id: r.reservation_id,
              table_seat_ids: r.table_seat_ids,
            }
          }),
        ].filter((r) => r.table_seat_ids.length > 0)

        const params = {
          end_at: dayjs(restaurantReservation.end_at).format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          table_seat_ids: _uniq(nextTableSeatIds),
          other_table_seats: otherTableSeats,
        }

        runSeatExchange(params)
      }
      // ここまで関数定義

      // 移動先シートの人数上限が超えていないかチェックする
      const requireSeatSize = restaurantReservation.party_size
      // 既存の予約で使われている席IDのうち、移動元に該当する席を除外してから
      // 移動先の席IDを足し合わせた配列を作成する
      const updatedTableSeatIds = [
        // 移動前の席を除外
        ...restaurantReservation.table_seats
          .filter((seat) => !prevTableSeats.some((p) => p.id === seat.id))
          .map((seat) => seat.id),
        // 新しく追加される移動先の席
        ...nextTableSeats.map((seat) => seat.id),
      ]

      // 合計最大収容人数を計算
      const allMaxPartySize = updatedTableSeatIds.reduce((acc, seatId) => {
        const ts = tableSeats.find((i) => i.id === seatId)
        if (ts != null) {
          acc += ts?.max_party_size ?? 0
        }
        return acc
      }, 0)

      // 変更前のデータを取得
      const reservation = restaurantReservations.find(
        (r) => r.id === restaurantReservation.id
      )
      if (reservation == null) return
      const beforeStartAt = dayjs(reservation.start_at)
      const beforeEndAt = dayjs(reservation.end_at)
      const editedStartAt = nextStartAt ?? dayjs(editModeReservation?.start_at)
      const editedEndAt = nextEndAt ?? dayjs(editModeReservation?.end_at)
      const isSameTime =
        beforeStartAt.isSame(editedStartAt) && beforeEndAt.isSame(editedEndAt)

      // 衝突チェック
      if (checkCollision()) {
        if (isSameTime) {
          runCollisionChangeTableSeats()
        } else {
          displayToastError(t('他の予約と被るため変更できません'), undefined, {
            marginBottom: toastMarginBottom,
          })
          setRefreshCount((prev) => prev + 1)
        }
        return
      }

      if (requireSeatSize > allMaxPartySize) {
        if (isSameTime) {
          runManualChangeTableSeats(restaurantReservation)
        } else {
          displayToastError(
            t('時間または人数変更後に席を移動してください'),
            undefined,
            {
              marginBottom: toastMarginBottom,
            }
          )
          setRefreshCount((prev) => prev + 1)
        }
        return
      }

      // 移動先の席が0件の場合は、席の移動を実行
      if (nextSeatsSize === 0) {
        runSimpleMoveTableSeats()
        return
      }

      exchange()
    }

    const onDragEnd = (
      updates: {
        index?: number
        startAtMinutes?: number
        endAtMinutes?: number
      },
      restaurantReservation: RestaurantReservationModel,
      group: { id: string; name: string }[] | null
    ) => {
      if (editModeReservation == null) return
      let newStartAt = dayjs(editModeReservation.start_at)
      if (updates.startAtMinutes != null) {
        newStartAt = date
          .hour(0)
          .minute(0)
          .second(0)
          .add(updates.startAtMinutes, 'minute')
      }
      let newEndAt = dayjs(editModeReservation.end_at)
      if (updates.endAtMinutes != null) {
        newEndAt = date
          .hour(0)
          .minute(0)
          .second(0)
          .add(updates.endAtMinutes, 'minute')
      }

      if (updates.index != null) {
        onDragSeatGroup(
          editModeReservation,
          group,
          updates.index,
          newStartAt,
          newEndAt
        )
      } else {
        if (
          editModeReservation?.id !== restaurantReservation.id ||
          onMoveTableSeats == null
        ) {
          return
        }

        // 変更中の席を考慮して予約時間が被るかをチェック
        const isOverlapped = allRestaurantReservations
          .filter((r) => r.id !== editModeReservation.id)
          // 移動先の席が含まれている予約を取得
          .filter((r) =>
            r.table_seats
              .flatMap((t) => t.id)
              .some((id) =>
                editModeReservation.table_seats.some((ts) => ts.id === id)
              )
          )
          .some((r) => {
            return overlapsWith(
              {
                startAt: newStartAt,
                endAt: newEndAt,
              },
              {
                startAt: dayjs(r.start_at),
                endAt: dayjs(r.end_at),
              }
            )
          })

        if (isOverlapped) {
          displayToastError(t('他の予約と被るため変更できません'), undefined, {
            marginBottom: toastMarginBottom,
          })
          setRefreshCount((prev) => prev + 1)
        } else {
          onMoveTableSeats({
            restaurantId,
            params: {
              end_at: newEndAt.format(),
              start_at: newStartAt.format(),
            },
          })
        }
      }
    }

    /**
     * ドラッグ・ドロップしたときのハンドラ
     * @param reservation 予約
     * @param seatGroup   今動かそうとしている連続席 (座席指定なしの場合は null)
     * @param dropIndex   ドロップ先のインデックス
     */
    const onDragSeatGroup = (
      reservation: RestaurantReservationModel,
      seatGroup: { id: string; name: string }[] | null,
      dropIndex: number,
      nextStartAt: dayjs.Dayjs,
      nextEndAt: dayjs.Dayjs
    ) => {
      if (!tableSeats?.length) return

      const prevSeatsSize = seatGroup?.length ?? 1
      const nextTableSeats: TableSeat[] = []
      for (let i = 0; i < prevSeatsSize; i++) {
        // NOTE: リクエスト予約や座席未指定予約がある場合はその分チャートの行が増えるので、それを考慮してindexを計算する
        const index =
          dropIndex + i - requestReservations.length - otherReservations.length
        if (tableSeats[index] == null) {
          onError(new Error(t('移動先の席を特定できませんでした。')))
          refreshReservationData()
          return
        }
        const targetTable = tableSeats[index] ?? undefined
        nextTableSeats.push(targetTable)
      }

      // drop先のシートを取得
      const droppedSeat: TableSeat[] = []
      for (let i = 0; i < prevSeatsSize; i++) {
        // NOTE: リクエスト予約や座席未指定予約がある場合はその分チャートの行が増えるので、それを考慮してindexを計算する
        const index =
          dropIndex + i - requestReservations.length - otherReservations.length
        droppedSeat.push(tableSeats[index])
      }

      // ドロップ先の予約の席のグループを取得
      const droppedSeatGroup = restaurantReservations
        // 自分の予約を除外
        .filter((r) => r.id !== reservation.id)
        // 予約時間がかぶらないものを除外
        .filter((r) => {
          return overlapsWith(
            {
              startAt: nextStartAt ?? dayjs(reservation.start_at),
              endAt: nextEndAt ?? dayjs(reservation.end_at),
            },
            {
              startAt: dayjs(r.start_at),
              endAt: dayjs(r.end_at),
            }
          )
        })
        ?.flatMap((restaurantReservation) => {
          // 「連続している席」をグループ化
          const seatGroups = groupConsecutiveReservationSeats(
            tableSeats,
            restaurantReservation.table_seats
          )

          // seatGroupsからdropedSeatがどのグループに含まれているかを取得する
          return seatGroups.find((group) =>
            group.some((seat) => droppedSeat.some((ds) => ds.id === seat.id))
          )
        })
        .filter((group) => group != null)

      const nextSeatsSize = droppedSeatGroup?.length ?? 1

      handleMoveTableSeats(
        reservation,
        seatGroup ?? [],
        nextTableSeats,
        nextSeatsSize,
        nextStartAt,
        nextEndAt
      )
    }

    return (
      <>
        {isLoading && (
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 2,
            }}
          >
            <Loading />
          </View>
        )}
        <Animated.ScrollView
          {...scrollViewProps}
          contentContainerStyle={[
            {
              position: 'relative',
              paddingVertical: width < sm ? 8 : SCROLL_VIEW_PADDING_Y,
              paddingHorizontal: width < sm ? 0 : SCROLL_VIEW_PC_PADDING_X,
            },
            Platform.OS === 'web' ? { height: '100%' } : {},
            scrollViewProps?.contentContainerStyle,
          ]}
          onScroll={onScroll}
          scrollEventThrottle={16}
          ref={scrollRootRef}
        >
          <View
            style={[
              {
                flexDirection: 'row',
                height: Platform.OS === 'web' ? '100%' : undefined,
              },
            ]}
          >
            <View
              style={{
                marginRight: width < sm ? 4 : 20,
                marginLeft: width < sm ? 4 : 0,
              }}
            >
              {width >= sm && (
                <Animated.View
                  style={[{ flexDirection: 'row', zIndex: 1 }, headerCellStyle]}
                >
                  <HeaderCell width={SEAT_PC_WIDTH} text={t('席')} isFirst />
                  <HeaderCell
                    width={PARTY_SIZE_WIDTH}
                    text={t('定員')}
                    isFirst={false}
                    justifyContent="center"
                  />
                </Animated.View>
              )}
              <View style={width < sm && { marginTop: CELL_HEIGHT }}>
                {otherReservations.length > 0 && (
                  <View key={'other'} style={{ flexDirection: 'row' }}>
                    <View
                      style={{
                        width:
                          width < sm
                            ? SEAT_SP_WIDTH
                            : SEAT_PC_WIDTH + PARTY_SIZE_WIDTH,
                        height:
                          (width < sm ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT) *
                          otherReservations.length,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderLeftWidth: width < sm ? 0 : BORDER_WIDTH,
                        borderColor: Colors.border,
                        borderRightWidth: width < sm ? 0 : BORDER_WIDTH,
                        borderBottomWidth: BORDER_WIDTH * 2,
                        paddingHorizontal: width < sm ? 8 : PADDING_HORIZONTAL,
                        backgroundColor: Colors.white,
                      }}
                    >
                      {width < sm ? (
                        <View>
                          <Text style={{ fontSize: 14 }}>
                            {t('座席指定なし')}
                          </Text>
                          <Text
                            style={{
                              fontSize: 12,
                              fontWeight: '600',
                              marginTop: -18,
                              alignSelf: 'flex-end',
                            }}
                          >
                            {t('{{count}}件', {
                              count: otherReservations.length,
                            })}
                          </Text>
                        </View>
                      ) : (
                        <View style={{ flexDirection: 'row', gap: 4 }}>
                          <Text
                            style={{
                              fontSize: 14,
                            }}
                          >
                            {t('座席指定なし')}
                          </Text>
                          <Text
                            style={{
                              fontSize: 14,
                              fontWeight: '600',
                            }}
                          >
                            {t('{{count}}件', {
                              count: otherReservations.length,
                            })}
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                )}
                {requestReservations.length > 0 && (
                  <View
                    key={'request_reservation'}
                    style={{ flexDirection: 'row' }}
                  >
                    <View
                      style={{
                        width:
                          width < sm
                            ? SEAT_SP_WIDTH
                            : SEAT_PC_WIDTH + PARTY_SIZE_WIDTH,
                        height:
                          (width < sm ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT) *
                          requestReservations.length,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        borderLeftWidth: width < sm ? 0 : BORDER_WIDTH,
                        borderColor: Colors.border,
                        borderRightWidth: width < sm ? 0 : BORDER_WIDTH,
                        borderBottomWidth: BORDER_WIDTH * 2,
                        paddingHorizontal: width < sm ? 8 : PADDING_HORIZONTAL,
                        backgroundColor: Colors.white,
                      }}
                    >
                      {width < sm ? (
                        <View>
                          <Text
                            style={{
                              fontSize: 14,
                            }}
                          >
                            {t('承認待ち')}
                          </Text>
                          <View
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                              alignItems: 'baseline',
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 12,
                                fontWeight: '600',
                              }}
                            >
                              {t('{{count}}件', {
                                count: filteredRequestReservations.length,
                              })}
                            </Text>
                          </View>
                        </View>
                      ) : (
                        <View style={{ flexDirection: 'row', gap: 4 }}>
                          <Text
                            style={{
                              fontSize: 14,
                            }}
                          >
                            {t('承認待ち')}
                          </Text>
                          <Text
                            style={{
                              fontSize: 14,
                              fontWeight: '600',
                            }}
                          >
                            {t('{{count}}件', {
                              count: filteredRequestReservations.length,
                            })}
                          </Text>
                        </View>
                      )}
                      {filteredRequestReservations.length >
                        DEFAULT_SHOW_REQUEST_RESERVATION_SIZE && (
                        <Button
                          mode="text"
                          onPress={() => {
                            setIsShowMoreRequestReservation(
                              !isShowMoreRequestReservations
                            )
                          }}
                          variant="primary"
                          style={width < sm && { paddingHorizontal: 0 }}
                        >
                          <Text
                            style={[
                              width < sm && {
                                color: Colors.primary,
                                height: 40,
                              },
                              {
                                marginTop: 14,
                                fontSize: 14,
                              },
                            ]}
                          >
                            {width < sm
                              ? isShowMoreRequestReservations
                                ? t('とじる')
                                : t('もっとみる')
                              : isShowMoreRequestReservations
                                ? t('閉じる')
                                : t('もっと見る')}
                          </Text>
                        </Button>
                      )}
                    </View>
                  </View>
                )}
                {tableSeats?.map((tableSeat, index) => {
                  return (
                    <View key={tableSeat.id} style={{ flexDirection: 'row' }}>
                      <View
                        style={[
                          {
                            borderColor: Colors.border,
                            backgroundColor: Colors.white,
                          },
                          width < sm
                            ? {
                                width: SEAT_SP_WIDTH,
                                height: SEAT_SP_HEIGHT,
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                justifyContent: 'center',
                                borderRightWidth: 0,
                                borderBottomWidth:
                                  tableSeats.length - 1 === index
                                    ? 0
                                    : BORDER_WIDTH,
                                paddingHorizontal: 8,
                              }
                            : {
                                width: SEAT_PC_WIDTH,
                                height: SEAT_PC_HEIGHT,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                borderLeftWidth: BORDER_WIDTH,
                                borderRightWidth: BORDER_WIDTH,
                                borderBottomWidth: BORDER_WIDTH,
                                paddingHorizontal: PADDING_HORIZONTAL,
                              },
                        ]}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                            lineHeight: 21,
                            fontWeight: width < sm ? '600' : '300',
                          }}
                          numberOfLines={width < sm ? 1 : undefined}
                          ellipsizeMode={width < sm ? 'tail' : undefined}
                        >
                          {tableSeat.name}
                        </Text>
                        {width < sm && (
                          <Text style={{ fontSize: 12, lineHeight: 18 }}>
                            {t('{{party_size}}名', {
                              party_size: getPartySize(tableSeat),
                            })}
                          </Text>
                        )}
                      </View>
                      {width >= sm && (
                        <View
                          style={{
                            width: PARTY_SIZE_WIDTH,
                            height: SEAT_PC_HEIGHT,
                            borderColor: Colors.border,
                            borderRightWidth: BORDER_WIDTH,
                            borderBottomWidth: BORDER_WIDTH,
                            paddingHorizontal: PADDING_HORIZONTAL,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: Colors.white,
                          }}
                        >
                          <Text style={{ fontSize: 14, lineHeight: 21 }}>
                            {getPartySize(tableSeat)}
                          </Text>
                        </View>
                      )}
                    </View>
                  )
                })}
              </View>
            </View>
            <Animated.ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{
                flex: 1,
                position: 'relative',
                height:
                  (width >= sm ? SEAT_PC_HEIGHT : SEAT_SP_HEIGHT) *
                    (tableSeats?.length ?? 0) + // 席数分
                  (width < sm ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT) *
                    otherReservations.length + // 未指定席分
                  (width < sm ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT) *
                    requestReservations.length + // リクエスト予約分
                  24, // ヘッダー分
              }}
              ref={scrollViewRef}
              onScroll={onHorizontalScroll}
              scrollEventThrottle={16}
            >
              <GestureDetector gesture={onPressBackgroundView}>
                <View
                  style={{
                    flex: 1,
                    position: 'relative',
                  }}
                >
                  {mode !== 'default' && (
                    <SelectSeatList
                      mode={mode}
                      selectParams={selectParams!}
                      tableSeats={tableSeats}
                      restaurantReservations={restaurantReservations}
                      date={date}
                      otherReservations={otherReservations}
                    />
                  )}
                  <View
                    style={{
                      flexDirection: 'column',
                      position: 'relative',
                      zIndex: 1,
                    }}
                  >
                    <View style={{ zIndex: 1 }}>
                      {otherReservations.map((restaurantReservation, index) => {
                        // 編集によって席が追加された場合、重複表示を防ぐために表示しない
                        if (
                          editModeReservation?.id ===
                            restaurantReservation.id &&
                          editModeReservation.table_seats.length > 0
                        ) {
                          return null
                        }
                        return (
                          <Reservation
                            key={`${restaurantReservation.id}_other_${refreshCount}`}
                            type={
                              mode === 'default' ? 'default' : 'unselectable'
                            }
                            index={index}
                            restaurantReservation={
                              editModeReservation?.id ===
                              restaurantReservation.id
                                ? editModeReservation
                                : restaurantReservation
                            }
                            restaurantId={restaurantId}
                            date={date}
                            // 未配席の予約は下矢印のみ表示
                            shouldShowEditDownIcon
                            shouldShowEditUpIcon={false}
                            isEditMode={
                              editModeReservation?.id ===
                              restaurantReservation.id
                            }
                            latestRootScrollViewEvent={
                              latestRootScrollViewEvent
                            }
                            latestHorizontalScrollViewEvent={
                              latestHorizontalScrollViewEvent
                            }
                            scrollRootRef={scrollRootRef}
                            scrollHorizontalRef={scrollViewRef}
                            onLongPressEditMode={() =>
                              handleLongPressEditMode(restaurantReservation)
                            }
                            onDragEnd={(updates) =>
                              onDragEnd(updates, restaurantReservation, null)
                            }
                            onPress={() => {
                              if (Platform.OS === 'web') {
                                navigate(
                                  `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}`
                                )
                              } else {
                                navigation.navigate('ReservationsShow', {
                                  restaurantReservationId:
                                    restaurantReservation.id,
                                })
                              }
                            }}
                          />
                        )
                      })}
                      {requestReservations.map((requestReservation, index) => {
                        return (
                          <Reservation
                            key={`${requestReservation.id}_request_reservation`}
                            index={index + otherReservations.length}
                            type="default"
                            shouldShowEditDownIcon={false}
                            shouldShowEditUpIcon={false}
                            restaurantReservation={{
                              ...requestReservation,
                              memo: requestReservation.memo ?? '',
                              adult_party_size:
                                requestReservation.adult_party_size,
                              customers:
                                requestReservation.customer == null
                                  ? []
                                  : [
                                      {
                                        ...requestReservation.customer,
                                        reservation_first_name:
                                          requestReservation.name
                                            ? ''
                                            : requestReservation.customer
                                                .reservation_first_name,
                                        reservation_last_name:
                                          requestReservation.name ??
                                          requestReservation.customer
                                            .reservation_last_name,
                                      },
                                    ],
                              kind: 'normal',
                              smart_payment: null,
                              reservation_courses: [],
                            }}
                            restaurantId={restaurantId}
                            date={date}
                            isEditMode={false}
                            onPress={() => {
                              if (requestReservation.reservation != null) {
                                if (Platform.OS === 'web') {
                                  navigate(
                                    `/restaurants/${restaurantId}/reservations/change_requests/${requestReservation.reservation.uuid}`
                                  )
                                } else {
                                  navigation.navigate(
                                    'ChangeRequestReservation',
                                    {
                                      data: requestReservation.reservation,
                                      customer: requestReservation.customer,
                                    }
                                  )
                                }
                                return
                              }

                              if (Platform.OS === 'web') {
                                navigate(
                                  `/restaurants/${restaurantId}/reservations/requests/${requestReservation.id}`
                                )
                              } else {
                                navigation.navigate('RequestReservation', {
                                  id: requestReservation.id,
                                })
                              }
                            }}
                          />
                        )
                      })}
                      {reservationChangeRequests.map(
                        (requestReservation, index) => {
                          index +=
                            requestReservations.length +
                            otherReservations.length
                          return (
                            <Reservation
                              key={`${requestReservation.id}_change_request_reservation`}
                              index={index}
                              type="default"
                              shouldShowEditDownIcon={false}
                              shouldShowEditUpIcon={false}
                              restaurantReservation={{
                                ...requestReservation,
                                memo: requestReservation.memo ?? '',
                                adult_party_size:
                                  requestReservation.adult_party_size,
                                customers:
                                  requestReservation.customer == null
                                    ? []
                                    : [requestReservation.customer],
                                kind: 'normal',
                                smart_payment: null,
                                reservation_courses: [],
                              }}
                              restaurantId={restaurantId}
                              date={date}
                              isEditMode={false}
                              onPress={() => {
                                if (Platform.OS === 'web') {
                                  navigate(
                                    `/restaurants/${restaurantId}/reservations/requests/${requestReservation.id}`
                                  )
                                } else {
                                  navigation.navigate('RequestReservation', {
                                    id: requestReservation.id,
                                  })
                                }
                              }}
                              isWarning
                            />
                          )
                        }
                      )}
                      {/* ▼▼▼ ここから、複数席(連続席)の予約を1つのカードとして描画する ▼▼▼ */}
                      {restaurantReservations?.map((restaurantReservation) => {
                        // 編集中の予約の場合は、編集中の情報を使用
                        const displayReservation =
                          editModeReservation?.id === restaurantReservation.id
                            ? editModeReservation
                            : // 交換先の席の場合は、交換後の情報を使用
                              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                              otherTableSeats?.other_table_seats?.some(
                                  (seat: {
                                    reservation_id: string
                                    table_seat_ids: string[]
                                  }) =>
                                    seat.reservation_id ===
                                    restaurantReservation.id
                                )
                              ? {
                                  ...restaurantReservation,
                                  table_seats: tableSeats
                                    .filter((seat) =>
                                      otherTableSeats.other_table_seats?.find(
                                        (other: {
                                          reservation_id: string
                                          table_seat_ids: string[]
                                        }) =>
                                          other.reservation_id ===
                                            restaurantReservation.id &&
                                          other.table_seat_ids.includes(seat.id)
                                      )
                                    )
                                    .map((seat) => ({
                                      id: seat.id,
                                      name: seat.name,
                                      restaurant_id: restaurantId,
                                      token: '',
                                      current_name: seat.name,
                                      position: 0,
                                      joined: false,
                                      next_reservation: null,
                                    })),
                                }
                              : restaurantReservation
                        const tableSeatsOrder =
                          displayReservation.table_seats.reduce(
                            (prev, current) => {
                              const index = tableSeats.findIndex(
                                (ts) => ts.id === current.id
                              )
                              if (index < 0) {
                                // 席が見つからない(アーカイブなど)→スキップ
                                return prev
                              }
                              prev.set(current.id, {
                                index,
                                consecutiveCount: 0,
                              })
                              return prev
                            },
                            new Map<
                              string,
                              {
                                index: number
                                consecutiveCount: number
                              }
                            >()
                          )

                        // 席変更はグループ化しない
                        const seatGroups =
                          mode === 'seatChange'
                            ? displayReservation.table_seats.map((seat) => [
                                seat,
                              ])
                            : groupConsecutiveReservationSeats(
                                tableSeats,
                                displayReservation.table_seats
                              )
                        // 各シートグループごとに1つのReservationCardを描画
                        return seatGroups.map((group) => {
                          if (group.length === 0) return null
                          const firstSeat = group[0]
                          const item = tableSeatsOrder.get(firstSeat.id)
                          if (item == null) return null

                          // 連続数を記録
                          tableSeatsOrder.set(firstSeat.id, {
                            ...item,
                            consecutiveCount: group.length,
                          })
                          // 代表Seatのindex
                          const index =
                            otherReservations.length +
                            requestReservations.length +
                            reservationChangeRequests.length +
                            item.index

                          // 予約カードを返す
                          return (
                            <Reservation
                              key={`${restaurantReservation.id}_${firstSeat.id}_${refreshCount}`}
                              type={
                                mode === 'default' ? 'default' : 'unselectable'
                              }
                              index={index}
                              shouldShowEditUpIcon={item.index !== 0}
                              shouldShowEditDownIcon={
                                item.index !== tableSeats.length - 1
                              }
                              consecutiveCount={group.length}
                              restaurantReservation={displayReservation}
                              restaurantId={restaurantId}
                              onPress={({ absoluteX, absoluteY }) =>
                                onPressReservation({
                                  restaurantReservation,
                                  tableSeatId: firstSeat.id,
                                  absoluteX,
                                  absoluteY,
                                })
                              }
                              date={date}
                              isEditMode={
                                editModeReservation?.id ===
                                restaurantReservation.id
                              }
                              onLongPressEditMode={() =>
                                handleLongPressEditMode(restaurantReservation)
                              }
                              latestRootScrollViewEvent={
                                latestRootScrollViewEvent
                              }
                              latestHorizontalScrollViewEvent={
                                latestHorizontalScrollViewEvent
                              }
                              scrollRootRef={scrollRootRef}
                              scrollHorizontalRef={scrollViewRef}
                              onDragEnd={(updates) =>
                                onDragEnd(updates, restaurantReservation, group)
                              }
                              isWarning={
                                restaurantReservation.reservation_change_request !=
                                null
                              }
                            />
                          )
                        })
                      })}
                      {/* ▲▲▲ ここまでが 複数席(連続席)を一括描画する部分 ▲▲▲ */}

                      {restaurantReservationBlocks?.restaurantReservationBlockPeriods.map(
                        (restaurantReservationBlockPeriod) => {
                          return restaurantReservationBlockPeriod.tableSeats.map(
                            (seat) => {
                              const index =
                                otherReservations.length +
                                requestReservations.length +
                                reservationChangeRequests.length +
                                tableSeats.findIndex(({ id }) => seat.id === id)
                              return (
                                <RestaurantReservationBlockPeriod
                                  key={`${restaurantReservationBlockPeriod.id}_${seat.id}`}
                                  type={
                                    mode === 'default'
                                      ? 'default'
                                      : 'unselectable'
                                  }
                                  index={index}
                                  restaurantReservationBlockPeriod={
                                    restaurantReservationBlockPeriod
                                  }
                                  onPress={({ absoluteX, absoluteY }) => {
                                    onPressReservationBlockPeriod({
                                      restaurantReservationBlockPeriod,
                                      tableSeatId: seat.id,
                                      absoluteX,
                                      absoluteY,
                                    })
                                  }}
                                  isSp={width < sm}
                                />
                              )
                            }
                          )
                        }
                      )}
                    </View>
                    <Animated.View
                      style={[
                        {
                          flexDirection: 'row',
                          zIndex: 2,
                        },
                        headerCellStyle,
                      ]}
                    >
                      {HOURS.map((hour, index) => (
                        <HeaderCell
                          key={`${hour}`}
                          width={width < sm ? HOUR_SP_WIDTH : HOUR_PC_WIDTH}
                          text={`${hour}:00`}
                          isFirst={index === 0}
                          isSp={width < sm}
                        />
                      ))}
                    </Animated.View>
                    <HourCellList
                      otherReservations={otherReservations}
                      requestReservations={requestReservations}
                      tableSeats={tableSeats}
                      isRestaurantOpenAt={isRestaurantOpenAt}
                      isSp={width < sm}
                    />
                  </View>
                </View>
              </GestureDetector>
            </Animated.ScrollView>
          </View>
          <Menu
            visible={menuPopOver.visible}
            anchor={{
              x: menuPopOver?.absoluteX ?? 0,
              y: menuPopOver?.absoluteY ?? 0,
            }}
            onDismiss={() => {
              setMenuPopOver((menuPopOver) => ({
                ...menuPopOver,
                visible: false,
              }))
            }}
            contentStyle={{
              paddingVertical: 0,
            }}
          >
            {menuPopOver.items?.reservations.map((restaurantReservation) => {
              return (
                <Menu.Item
                  key={`${restaurantReservation.id}`}
                  onPress={() => {
                    setMenuPopOver((menuPopOver) => ({
                      ...menuPopOver,
                      visible: false,
                    }))
                    if (Platform.OS === 'web') {
                      navigate(
                        `/restaurants/${restaurantId}/reservations/${restaurantReservation.id}`
                      )
                    } else {
                      navigation.navigate('ReservationsShow', {
                        restaurantReservationId: restaurantReservation.id,
                      })
                    }
                  }}
                  title={t('{{name}} の予約', {
                    name: getReservationText(restaurantReservation) || '-',
                  })}
                  contentStyle={{
                    minWidth: 240,
                  }}
                />
              )
            })}
            {menuPopOver.items?.reservationBlockPeriods.map(
              (reservationBlockPeriod) => {
                return (
                  <Menu.Item
                    key={`${reservationBlockPeriod.id}`}
                    onPress={() => {
                      setMenuPopOver((menuPopOver) => ({
                        ...menuPopOver,
                        visible: false,
                      }))
                      if (Platform.OS === 'web') {
                        navigate(
                          `/restaurants/${restaurantId}/reservations/blocks/${reservationBlockPeriod.restaurantReservationBlock?.id}?startAt=${reservationBlockPeriod.startAt?.seconds}&endAt=${reservationBlockPeriod.endAt?.seconds}`
                        )
                      } else {
                        navigation.navigate('ReservationBlocksShow', {
                          restaurantReservationBlockId:
                            reservationBlockPeriod.restaurantReservationBlock
                              ?.id,
                          startAt: reservationBlockPeriod.startAt,
                          endAt: reservationBlockPeriod.endAt,
                        })
                      }
                    }}
                    title={t('ブロック')}
                    contentStyle={{
                      minWidth: 240,
                    }}
                  />
                )
              }
            )}
          </Menu>
        </Animated.ScrollView>
        <View
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          pointerEvents="box-none"
        >
          <AlertProvider ref={alertRef} />
        </View>
      </>
    )
  }
)
