import { usePrevious } from '../modules/usePrevious'
import type React from 'react'
import { useEffect } from 'react'
import { Platform, View } from 'react-native'
import Reanimated, {
  withTiming,
  useAnimatedStyle,
  useSharedValue,
  useAnimatedRef,
} from 'react-native-reanimated'

interface AccordionProps {
  open: boolean

  children?: React.ReactNode
}

export function Accordion({ open, children }: AccordionProps) {
  const progress = useSharedValue(open ? 1 : 0)
  const height = useSharedValue(0)
  const animatedStyle = useAnimatedStyle(
    () => ({
      height: height.get() * progress.get() + 1,
      opacity: Platform.OS === 'web' ? 1 : progress.get() === 0 ? 0 : 1,
    }),
    [height, progress]
  )

  const ref = useAnimatedRef<View>()

  const prevOpen = usePrevious(open)

  useEffect(() => {
    if (prevOpen !== open) {
      // TODO: react-compiler for react-native-reanimated
      // eslint-disable-next-line react-compiler/react-compiler
      progress.set(open ? withTiming(1) : withTiming(0))
    }
  }, [open, prevOpen, progress])

  return (
    <Reanimated.View
      style={[
        {
          overflow: 'hidden',
        },
        animatedStyle,
      ]}
    >
      <View
        ref={ref}
        onLayout={({
          nativeEvent: {
            layout: { height: value },
          },
        }) => {
          height.set(value)
        }}
      >
        {children}
      </View>
    </Reanimated.View>
  )
}
