import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { GourmetSiteProvider } from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import { RestaurantRequestReservation } from '@hello-ai/ar_shared/src/types/ForR/RestaurantRequestReservation'
import {
  RestaurantReservation,
  RestaurantReservationParams,
} from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'
import { RestaurantReservationClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation/restaurant_reservation_service.client'

import axios, { wrapResponse } from '../modules/axios'
import { createRpcService } from '../modules/rpc'
import useSWR, {
  fetcher,
  fetcherWithPaginationHeader,
  mutate,
  onError,
  SWRConfiguration,
  swrKey,
  WithHeaderData,
} from '../modules/swr'

export * from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'

export const restaurantReservationService = createRpcService(
  RestaurantReservationClient
)

export type UseRestaurantReservationsParams = {
  date: string
  status?: RestaurantReservation['status'] // default: reserved
  future_only?: boolean
  page?: number
  per_page?: number
  table_seat_id?: string
  exclude_processed_smart_payments?: boolean
}

export function useRestaurantReservation(
  restaurantId: number,
  restaurantReservationId: RestaurantReservation['id'] | undefined
) {
  const token = useToken()
  const {
    data: restaurantReservation,
    error,
    mutate,
  } = useSWR<RestaurantReservation, any, ReturnType<typeof swrKey> | null>(
    restaurantReservationId !== undefined
      ? swrKey(
          token,
          `/reservation_book/restaurants/${restaurantId}/reservations/${restaurantReservationId}`
        )
      : null,
    fetcher
  )

  return {
    restaurantReservation,
    error,
    mutate,
  }
}

export function useRestaurantReservations(
  restaurantId: number,
  params: UseRestaurantReservationsParams,
  config?: SWRConfiguration<WithHeaderData<RestaurantReservation[]> | null>
) {
  const token = useToken()
  const { data, error, mutate, isLoading } = useSWR<
    WithHeaderData<RestaurantReservation[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/reservation_book/restaurants/${restaurantId}/reservations`,
      params
    ),
    ([token, url]) =>
      fetcherWithPaginationHeader<
        RestaurantReservation[],
        UseRestaurantReservationsParams
      >([token, url, params]),
    config
  )

  return {
    restaurantReservations: data?.data ?? [],
    headerData: data?.headerData,
    error,
    mutate,
    isLoading,
  }
}

export function useRestaurantReservationsWithFloorId(
  restaurantId: number,
  table_floor_id: string | undefined,
  params: UseRestaurantReservationsParams,
  config?: SWRConfiguration<WithHeaderData<RestaurantReservation[]> | null>
) {
  const token = useToken()
  const { data, error, mutate, isLoading } = useSWR<
    WithHeaderData<RestaurantReservation[]> | null,
    any,
    ReturnType<typeof swrKey> | null
  >(
    table_floor_id != null
      ? swrKey(
          token,
          `/reservation_book/restaurants/${restaurantId}/reservations`,
          { ...params, table_floor_id }
        )
      : null,
    ([token, url]) =>
      fetcherWithPaginationHeader<
        RestaurantReservation[],
        UseRestaurantReservationsParams & { table_floor_id?: string }
      >([token, url, { ...params, table_floor_id }]),
    config
  )

  return {
    restaurantReservations: data?.data ?? [],
    headerData: data?.headerData,
    error,
    mutate,
    isLoading,
  }
}

export async function createRestaurantReservation(
  token: string | null,
  restaurantId: number,
  params: RestaurantReservationParams
) {
  const { response, error } = await wrapResponse(
    axios.post<RestaurantReservation>(
      `/reservation_book/restaurants/${restaurantId}/reservations`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/reservation_book/restaurants/${restaurantId}/reservations/${response.data.id}`
      )
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    restaurantReservation: response?.data,
    error,
  }
}

export async function updateRestaurantReservation(
  token: string | null,
  restaurantId: number,
  restaurantReservationId: RestaurantReservation['id'],
  params: Partial<RestaurantReservationParams>
) {
  const { response, error } = await wrapResponse(
    axios.patch<RestaurantReservation>(
      `/reservation_book/restaurants/${restaurantId}/reservations/${restaurantReservationId}`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/reservation_book/restaurants/${restaurantId}/reservations/${response.data.id}`
      )
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    restaurantReservation: response?.data,
    error,
  }
}

export async function cancelRestaurantReservation(
  token: string | null,
  restaurantId: number,
  restaurantReservationId: RestaurantReservation['id'],
  params: {
    charge: boolean
    refund_reservation_fee?: boolean
  }
) {
  const { response, error } = await wrapResponse(
    axios.post<{}>(
      `/reservation_book/restaurants/${restaurantId}/reservations/${restaurantReservationId}/cancel`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/reservation_book/restaurants/${restaurantId}/reservations/${restaurantReservationId}`
      )
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    restaurantReservation: response?.data,
    error,
  }
}

export async function exchangeRestaurantReservationTableSeats(
  token: string | null,
  restaurantId: number,
  restaurantReservationId: RestaurantReservation['id'],
  params: {
    end_at: string
    table_seat_ids: string[]
    other_table_seats: {
      reservation_id: string
      table_seat_ids: string[]
    }[]
  }
) {
  const { response, error } = await wrapResponse(
    axios.patch(
      `/reservation_book/restaurants/${restaurantId}/reservations/${restaurantReservationId}/exchange_table_seats`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/reservation_book/restaurants/${restaurantId}/reservations/${restaurantReservationId}`
      )
    )
  }

  if (error != null) {
    onError(error)
  }

  return { restaurantReservation: response?.data, error }
}

type Notification = {
  id: string
  message_type:
    | 'reserved'
    | 'requested'
    | 'canceled'
    | 'modified'
    | 'modification_requested'
  source: 'autoreserve' | GourmetSiteProvider
  notifiable_id: string | number
  restaurant: {}
  status: 'unread' | 'read'
  title: string
  updated_at: string
  created_at: string
} & (
  | {
      notifiable_type: 'ReservationElement'
      notifiable: RestaurantRequestReservation
    }
  | {
      notifiable_type: 'Reservation'
      notifiable: RestaurantReservation
    }
)

export const useRestaurantUnreadReservationActivityNotifications = (
  restaurantId: number,
  page: number
) => {
  const token = useToken()

  const { data, error, mutate } = useSWR<
    WithHeaderData<Notification[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/restaurants/${restaurantId}/unread_reservation_activity_notifications`,
      { page }
    ),
    ([token, url]) =>
      fetcherWithPaginationHeader<Notification[], { page: number }>([
        token,
        url,
        { page },
      ]),
    { refreshInterval: 20000 }
  )

  return {
    data,
    error,
    mutate,
  }
}

export const useRestaurantReservationActivityNotifications = (
  restaurantId: number,
  status: 'unread' | 'read',
  page: number
) => {
  const token = useToken()
  const { data, error, mutate } = useSWR(
    swrKey(
      token,
      `/restaurants/${restaurantId}/reservation_activity_notifications`,
      { page, status }
    ),
    ([token, url]) =>
      fetcherWithPaginationHeader<
        Notification[],
        { page: number; status: 'read' | 'unread' }
      >([token, url, { page, status }])
  )
  return {
    data,
    error,
    mutate,
  }
}

interface MarkAsReadRestaurantReservationActivityNotification {
  reservation_activity_notification:
    | {
        uuids: string[]
      }
    | {
        mark_all_as_read: true
      }
}

export const markAsReadRestaurantReservationActivityNotification = async (
  restaurantId: number,
  params: MarkAsReadRestaurantReservationActivityNotification
) => {
  const { response, error } = await wrapResponse(
    axios.put(
      `/restaurants/${restaurantId}/reservation_activity_notifications/mark_as_reads`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    data: response?.data,
    error,
  }
}

export const updateRestaurantReservationActivityNotifications = async (
  restaurantId: number,
  status: 'unread' | 'read',
  uuids: string[]
) => {
  const { response, error } = await wrapResponse(
    axios.put(
      `/restaurants/${restaurantId}/reservation_activity_notifications/statuses`,
      {
        reservation_activity_notification: {
          status,
          uuids,
        },
      }
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    data: response?.data,
    error,
  }
}
