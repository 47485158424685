import React, { useCallback, useMemo, useState } from 'react'

import { Document, Page, usePDF } from '@react-pdf/renderer'

type StablePDFComponentConfig<T> = {
  component: React.ComponentType<T>
  props: T[]
  getStableKey?: (prop: T, index: number) => string
}

type PDFGeneratorProps<T> = {
  prop: T
  component: React.ComponentType<T>
  onHeightUpdate: (height: number, index: number) => void
  index: number
}

function PDFGenerator<T>({
  prop,
  component,
  onHeightUpdate,
  index,
}: PDFGeneratorProps<T>): JSX.Element | null {
  const [mounted, setMounted] = React.useState(true)

  React.useEffect(() => {
    return () => {
      setMounted(false)
    }
  }, [])

  usePDF({
    document: (
      <SizeMe
        component={component}
        {...prop}
        setHeights={(height) => mounted && onHeightUpdate(height, index)}
      />
    ),
  })
  return null
}

export function useMetaPDF<T>({
  component,
  props,
  getStableKey,
}: StablePDFComponentConfig<T>) {
  const [heights, setHeights] = useState<number[]>(
    new Array(props.length).fill(0)
  )
  const [isReady, setIsReady] = useState(false)
  const memoizedProps = useMemo(() => props, [props])

  const handleHeightUpdate = useCallback((height: number, index: number) => {
    setHeights((prev) => {
      const newHeights = [...prev]
      newHeights[index] = Math.floor(height)
      const allHeightsCalculated = newHeights.every((h) => h > 0)
      if (allHeightsCalculated) {
        setIsReady(true)
      }
      return newHeights
    })
  }, [])

  const generators = useMemo(
    () =>
      memoizedProps.map((prop, index) => (
        <PDFGenerator
          key={
            getStableKey ? getStableKey(prop, index) : `pdf-generator-${index}`
          }
          prop={prop}
          component={component}
          index={index}
          onHeightUpdate={handleHeightUpdate}
        />
      )),
    [memoizedProps, component, handleHeightUpdate, getStableKey]
  )

  return {
    heights,
    generators,
    isReady,
  }
}

type SizeMeProps<T> = {
  component: React.ComponentType<T>
  setHeights: (height: number) => void
} & T

export function SizeMe<T>(props: SizeMeProps<T>): JSX.Element {
  const Component = props.component
  return (
    <Document
      // @ts-expect-error; ここはハックで、_INTERNAL__LAYOUT__DATA_ を無理やり取り出している
      onRender={({ _INTERNAL__LAYOUT__DATA_ }) => {
        const height =
          _INTERNAL__LAYOUT__DATA_.children[0].children[0].box.height
        props.setHeights(height)
      }}
    >
      <Page size="A4">
        <Component {...props} />
      </Page>
    </Document>
  )
}
