import React, { useState, useCallback } from 'react'

import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation'
import { View, StyleSheet } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { CalendarModal } from '@hello-ai/for_r_app/src/components/Reservation/CalendarModal'
import { useRestaurantRequestReservations } from '@hello-ai/for_r_app/src/models/RestaurantRequestReservation'

export function HeaderDateSelectBar({
  restaurantId,
  date,
  onChangeDate,
}: {
  restaurantId: number
  date: dayjs.Dayjs
  onChangeDate: (date: dayjs.Dayjs, by?: 'calendar' | 'addOrSub') => void
}) {
  const { width, sm, md } = useResponsive()
  const [isModalVisible, setModalVisible] = useState(false)

  const onDayPress = useCallback(
    (d: dayjs.Dayjs) => {
      onChangeDate(d, 'calendar')
    },
    [onChangeDate]
  )
  const onModalClose = useCallback(() => {
    setModalVisible(false)
  }, [setModalVisible])

  const { requestReservations } = useRestaurantRequestReservations(
    restaurantId,
    {
      date,
    }
  )

  return (
    <View style={style.root}>
      <TouchableOpacity
        style={style.dateChangingSection}
        onPress={() => {
          onChangeDate(date.subtract(1, 'day'), 'addOrSub')
        }}
        onPressMinInterval={0}
      >
        <FontAwesomeIcon
          icon={faChevronLeft}
          size={width < sm ? 20 : 18}
          color={Colors.primary}
        />
        {width >= sm && (
          <Text style={[style.dateChangingText]}>{t('前日')}</Text>
        )}
      </TouchableOpacity>
      <TouchableOpacity
        style={{
          paddingVertical: width < sm ? 12 : 8,
          paddingHorizontal: 16,
          borderRadius: 30,
          backgroundColor: Colors.primaryBg,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          columnGap: 8,
        }}
        onPress={() => setModalVisible(true)}
        onPressMinInterval={0}
      >
        {width >= sm && (
          <FontAwesomeIcon
            size={20}
            icon={faCalendarAlt}
            color={Colors.primary}
          />
        )}
        <Text
          style={{
            fontWeight: '600',
            fontSize: width > sm ? 18 : 14,
            color: Colors.primary,
          }}
        >
          {width <= md
            ? date.format('M/D(ddd)')
            : // eslint-disable-next-line ar-i18n/require-translation-ja
              date.format('YYYY年M月D日(ddd)')}
        </Text>
        {(requestReservations?.length ?? 0) > 0 && (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            color={Colors.caution}
            size={16}
          />
        )}
      </TouchableOpacity>
      <TouchableOpacity
        style={style.dateChangingSection}
        onPress={() => {
          onChangeDate(date.add(1, 'day'), 'addOrSub')
        }}
        onPressMinInterval={0}
      >
        {width >= sm && (
          <Text style={[style.dateChangingText]}>{t('翌日')}</Text>
        )}
        <FontAwesomeIcon
          icon={faChevronRight}
          size={width < sm ? 20 : 18}
          color={Colors.primary}
        />
      </TouchableOpacity>
      {width < sm && (
        <Button
          mode="outline"
          style={[
            style.todayButton,
            {
              right: 16,
              paddingHorizontal: 12,
            },
          ]}
          textStyle={{
            fontSize: 14,
            fontWeight: '300',
            lineHeight: 24,
          }}
          onPress={() => {
            onChangeDate(dayjs().tz())
          }}
        >
          {t('今日')}
        </Button>
      )}
      <CalendarModal
        restaurantId={restaurantId}
        title={t('日付を選択')}
        isModalVisible={isModalVisible}
        shouldShowRequestReservationBadge
        onClose={onModalClose}
        selectedDate={date}
        onPress={onDayPress}
      />
    </View>
  )
}

const style = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 12,
  },
  todayButton: {
    width: 60,
    height: 33,
    position: 'absolute',
  },
  dateChangingSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateChangingText: {
    color: Colors.primary,
    fontSize: 14,
    fontWeight: '600',
    lineHeight: 22,
  },
})
