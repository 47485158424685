import type { FC } from 'react'
import React from 'react'

import { Path, Svg } from '@react-pdf/renderer'

export type IconType =
  | string
  | FC<{ size?: number; color?: string; strokeColor?: string }>

// SVGをReactコンポーネントとして実装
export function VipIcon({ size = 12 }: { size?: number }): JSX.Element {
  return (
    <Svg width={size * 1.75} height={size} viewBox="0 0 28 16" fill="none">
      <Path
        d="M8 0.5H20C24.1421 0.5 27.5 3.85786 27.5 8C27.5 12.1421 24.1421 15.5 20 15.5H8C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5Z"
        fill="#444444"
        stroke="white"
      />
      <Path
        d="M5.752 5.032L8.032 11.176C8.08 11.308 8.2 11.656 8.32 12.016C8.44 11.644 8.512 11.428 8.596 11.176L10.852 5.032C11.068 4.468 11.164 4.12 11.2 3.808H12.448C12.256 4.132 12.136 4.42 11.836 5.2L9.268 11.812C9.064 12.34 8.956 12.676 8.896 12.928H7.732C7.684 12.676 7.6 12.436 7.348 11.812L4.78 5.2C4.516 4.504 4.312 4.06 4.168 3.808H5.404C5.476 4.192 5.596 4.588 5.752 5.032ZM13.5256 3.808H14.6656C14.5936 4.204 14.5576 4.648 14.5576 5.152V11.584C14.5576 12.112 14.5936 12.52 14.6656 12.928H13.5256C13.5976 12.484 13.6216 12.184 13.6216 11.584V5.152C13.6216 4.552 13.5976 4.24 13.5256 3.808ZM17.6418 12.928H16.5138C16.5858 12.496 16.6098 12.148 16.6098 11.584V5.14C16.6098 4.648 16.5858 4.276 16.5138 3.772C16.9458 3.796 17.2818 3.808 17.8338 3.808H20.2818C22.1298 3.808 23.1738 4.756 23.1738 6.424C23.1738 8.188 22.0938 9.208 20.2218 9.208H17.5458V11.572C17.5458 12.148 17.5698 12.52 17.6418 12.928ZM17.5458 8.38H20.2218C21.4938 8.38 22.2138 7.696 22.2138 6.472C22.2138 5.272 21.5178 4.636 20.2218 4.636H17.5458V8.38Z"
        fill="white"
      />
    </Svg>
  )
}

export function CautionIcon({ size = 16 }: { size?: number }): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        d="M8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5Z"
        fill="#444444"
        stroke="white"
      />
      <Path
        d="M8.00105 3.63623C7.58855 3.63623 7.25105 3.98275 7.25105 4.40628V8.51324C7.25105 8.93997 7.58699 9.28329 8.00105 9.28329C8.41512 9.28329 8.75105 8.93837 8.75105 8.51324V4.40628C8.75105 3.98115 8.41355 3.63623 8.00105 3.63623Z"
        fill="white"
      />
      <Path
        d="M7.01855 11.3547C7.01855 11.9117 7.45855 12.3635 8.00105 12.3635C8.5448 12.3635 8.9823 11.9111 8.98355 11.3547C8.98355 10.7977 8.54387 10.346 8.00105 10.346C7.45824 10.346 7.01855 10.7977 7.01855 11.3547Z"
        fill="white"
      />
    </Svg>
  )
}

export function SmartPayIcon({
  size = 16,
  color = '#444444',
}: {
  size?: number
  color?: string
}): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        d="M8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5Z"
        fill={color}
        stroke="white"
      />
      <Path
        d="M12.33 11.29L11.37 12.33C10.54 11.31 9.39 10.19 8.3 9.36C7.17 10.62 6.02 11.5 4.49 12.25C4.21 11.73 4.09 11.57 3.68 11.14C4.45 10.84 4.93 10.57 5.68 10.04C7.19 8.98 8.39 7.53 9.04 5.97L9.15 5.71C8.99 5.72 8.99 5.72 5.84 5.77C5.36 5.78 5.09 5.8 4.75 5.85L4.71 4.57C4.99 4.62 5.18 4.63 5.59 4.63C5.63 4.63 5.71 4.63 5.83 4.63L9.35 4.58C9.91 4.57 9.99 4.56 10.24 4.47L10.9 5.15C10.77 5.3 10.69 5.44 10.58 5.69C10.12 6.76 9.63 7.63 9.02 8.45C9.65 8.87 10.19 9.29 10.87 9.9C11.12 10.12 11.12 10.12 12.33 11.29Z"
        fill="white"
      />
    </Svg>
  )
}

export function MemoIcon({
  size = 16,
  color = '#444444',
}: {
  size?: number
  color?: string
}): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 12 12">
      <Path
        d="M8.18854 2.62336L9.91111 4.38543C9.98368 4.45966 9.98368 4.58078 9.91111 4.65501L5.74028 8.92147L3.96806 9.12268C3.73125 9.15003 3.53073 8.94491 3.55747 8.70268L3.75417 6.88982L7.925 2.62336C7.99757 2.54913 8.11597 2.54913 8.18854 2.62336ZM11.2823 2.17601L10.3503 1.2227C10.0601 0.925767 9.58837 0.925767 9.29618 1.2227L8.62014 1.91424C8.54757 1.98847 8.54757 2.10959 8.62014 2.18383L10.3427 3.94589C10.4153 4.02012 10.5337 4.02012 10.6063 3.94589L11.2823 3.25435C11.5726 2.95546 11.5726 2.47294 11.2823 2.17601V2.17601ZM7.83333 7.76109V9.74976H1.72222V3.49854H6.11076C6.17187 3.49854 6.22917 3.47314 6.27309 3.43016L7.03698 2.64876C7.18212 2.50029 7.07899 2.24829 6.87465 2.24829H1.41667C0.91059 2.24829 0.5 2.66829 0.5 3.18597V10.0623C0.5 10.58 0.91059 11 1.41667 11H8.13889C8.64497 11 9.05556 10.58 9.05556 10.0623V6.97968C9.05556 6.77066 8.8092 6.66712 8.66406 6.81364L7.90017 7.59504C7.85816 7.63997 7.83333 7.69857 7.83333 7.76109Z"
        fill={color}
      />
    </Svg>
  )
}

export function AutoreserveIcon({ size = 16 }: { size?: number }): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        d="M8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0Z"
        fill="#444444"
        fillOpacity={0.06}
      />
      <Path
        d="M5.21282 12.2H3.99023C3.99023 12.2 3.99023 3.97045 3.99023 3.95563C3.99023 3.93587 4.01493 3.93093 4.02481 3.94575L5.21529 5.6302V12.2H5.21282Z"
        fill="#444444"
      />
      <Path
        d="M5.21533 7.87041L8.27796 12.2001H9.86114L5.21533 5.63025V7.87041Z"
        fill="#444444"
        fillOpacity={0.4}
      />
      <Path
        d="M11.0738 9.76229C11.8469 9.14977 12.3458 8.20381 12.3458 7.14177C12.3458 5.29925 10.8466 3.80005 9.00406 3.80005H5.28198L6.18348 5.09179H9.00406C10.1353 5.09179 11.054 6.01058 11.054 7.14177C11.054 8.27297 10.2118 9.11272 9.14732 9.18682C9.1078 9.18929 9.10039 9.20905 9.12015 9.23868L11.2121 12.2H12.7953C12.7953 12.2 11.9605 11.0195 11.0713 9.76229H11.0738Z"
        fill="#444444"
      />
    </Svg>
  )
}

export function TabelogIcon({ size = 16 }: { size?: number }): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0Z"
        fill="#444444"
        fillOpacity={0.06}
      />
      <Path
        d="M7.172 9.608H5.384V12.26C6.5 12.044 7.088 11.9 8.276 11.552L8.264 12.248C6.776 12.68 6.152 12.824 3.632 13.328C3.5 13.352 3.392 13.376 3.188 13.424L2.984 12.584C3.368 12.584 3.812 12.536 4.628 12.392V6.776C4.628 6.38 4.616 6.02 4.592 5.78C3.884 6.164 3.572 6.308 2.876 6.596C2.72 6.224 2.648 6.116 2.432 5.864C4.484 5.192 6.38 3.944 7.484 2.54H8.3C8.948 3.296 9.548 3.812 10.46 4.412C11.432 5.024 12.5 5.54 13.532 5.864C13.316 6.128 13.208 6.308 13.076 6.632C12.392 6.344 11.936 6.116 11.252 5.732C11.228 5.996 11.228 6.224 11.228 6.608V8.672C11.228 9.152 11.24 9.368 11.264 9.644C10.976 9.62 10.652 9.608 10.148 9.608H7.952C8.348 10.22 8.756 10.64 9.428 11.108C10.484 10.4 11.072 9.908 11.72 9.176L12.404 9.632C11.6 10.364 11 10.844 10.052 11.492C11.06 12.068 12.152 12.452 13.316 12.632C13.112 12.92 12.992 13.124 12.884 13.412C11.096 12.98 9.8 12.356 8.624 11.36C8.012 10.844 7.604 10.352 7.172 9.608ZM8.228 5.612H10.1C10.52 5.612 10.688 5.612 11.012 5.588C9.536 4.712 8.648 4.004 7.904 3.128C7.112 4.076 6.176 4.844 4.904 5.6C5.216 5.612 5.372 5.612 5.732 5.612H7.508V5.204C7.508 4.76 7.484 4.532 7.436 4.292H8.288C8.24 4.556 8.228 4.772 8.228 5.204V5.612ZM5.384 6.248V7.244H10.484V6.248H5.384ZM5.384 7.88V8.972H10.484V7.88H5.384Z"
        fill="#444444"
      />
    </Svg>
  )
}

export function HistoryIcon({
  size = 16,
  color = '#444444',
}: {
  size?: number
  color?: string
}): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        d="M14.6666 7.98743C14.6734 11.6605 11.6779 14.6641 8.00475 14.6667C6.41833 14.6678 4.96113 14.1148 3.81599 13.1906C3.51822 12.9502 3.49597 12.504 3.76656 12.2334L4.06943 11.9305C4.30086 11.6991 4.67032 11.6738 4.92672 11.8772C5.7704 12.5467 6.83811 12.9463 7.99991 12.9463C10.7339 12.9463 12.9461 10.7336 12.9461 8.00003C12.9461 5.26603 10.7335 3.0538 7.99991 3.0538C6.68771 3.0538 5.49591 3.56372 4.61099 4.39606L5.97535 5.76041C6.24631 6.03138 6.0544 6.49466 5.67123 6.49466H1.76336C1.52581 6.49466 1.33325 6.30211 1.33325 6.06455V2.15668C1.33325 1.77351 1.79653 1.5816 2.0675 1.85254L3.3947 3.17974C4.59156 2.03595 6.21368 1.33337 7.99991 1.33337C11.6776 1.33337 14.6598 4.31133 14.6666 7.98743ZM9.80335 10.1053L10.0674 9.76576C10.2862 9.4845 10.2355 9.07915 9.95424 8.86041L8.86013 8.00942V5.20434C8.86013 4.84802 8.57128 4.55918 8.21497 4.55918H7.78486C7.42854 4.55918 7.1397 4.84802 7.1397 5.20434V8.85087L8.898 10.2184C9.17927 10.4372 9.58459 10.3865 9.80335 10.1053V10.1053Z"
        fill={color}
      />
    </Svg>
  )
}

// 予約タグアイコン
export function BirthdayIcon({
  size = 16,
  color = '#444444',
}: {
  size?: number
  color?: string
}): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        d="M12 3.47187C12.6903 3.47187 13.25 2.91312 13.25 2.22281C13.25 1.5325 12 0 12 0C12 0 10.75 1.55969 10.75 2.25C10.75 2.94031 11.3094 3.47187 12 3.47187ZM8 3.47187C8.69031 3.47187 9.25 2.91312 9.25 2.22281C9.25 1.5325 8 0 8 0C8 0 6.75 1.55969 6.75 2.25C6.75 2.94031 7.30937 3.47187 8 3.47187ZM12.9719 6.97188L13 5C13 4.72387 12.7761 4.5 12.5 4.5H11.5C11.225 4.5 11 4.725 11 5V7H9V5C9 4.72387 8.77613 4.5 8.5 4.5H7.5C7.225 4.5 7 4.725 7 5V7H5V5C5 4.72387 4.77613 4.5 4.5 4.5H3.5C3.22375 4.5 3 4.725 3 5V6.99906C1.89531 6.99906 1 7.89438 1 8.97094V11.1178C1.30669 11.3085 1.66844 11.4719 2 11.4719C2.645 11.4719 3.40781 10.8553 3.64687 10.6178C3.84219 10.4235 4.1575 10.4235 4.35281 10.6178C4.56563 10.8844 5.35625 11.4719 6 11.4719C6.64375 11.4719 7.40781 10.8553 7.64687 10.6178C7.84219 10.4235 8.1575 10.4235 8.35281 10.6178C8.56562 10.8844 9.35625 11.4719 10 11.4719C10.6438 11.4719 11.4078 10.8553 11.6469 10.6178C11.8422 10.4235 12.1575 10.4235 12.3528 10.6178C12.5656 10.8844 13.3562 11.4719 14 11.4719C14.3316 11.4719 14.6934 11.3085 15 11.1178V8.97188C15 7.89375 14.1031 6.97188 12.9719 6.97188ZM12 11.6656C11.5703 12.0078 10.8116 12.4987 10 12.4987C9.18844 12.4987 8.42969 12.0081 8 11.6656C7.57031 12.0078 6.81156 12.4987 6 12.4987C5.18844 12.4987 4.42969 12.0081 4 11.6656C3.57031 12.0078 2.81156 12.4987 2 12.4987C1.64781 12.4987 1.30969 12.4012 1 12.2682V14.9988C1 15.5531 1.44781 16 2 16H14C14.5522 16 15 15.5522 15 15V12.2694C14.6903 12.4024 14.3522 12.4999 14 12.4999C13.1875 12.4719 12.4313 12.0094 12 11.6656ZM4 3.47187C4.69031 3.47187 5.25 2.91312 5.25 2.22281C5.25 1.5325 4 0 4 0C4 0 2.75 1.55969 2.75 2.25C2.75 2.94031 3.30969 3.47187 4 3.47187Z"
        fill={color}
      />
    </Svg>
  )
}

export function AllYouCanDrinkIcon({
  size = 16,
  color = '#444444',
}: {
  size?: number
  color?: string
}): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        d="M0.666748 2.28576C0.666748 1.75897 1.10369 1.33337 1.64453 1.33337H10.4445C10.9854 1.33337 11.4223 1.75897 11.4223 2.28576V3.23814H12.9867C14.2823 3.23814 15.3334 4.26195 15.3334 5.52385V8.56254C15.3334 9.4673 14.7865 10.2858 13.9401 10.6518L11.4223 11.7411V12.7619C11.4223 13.8125 10.5454 14.6667 9.46675 14.6667H2.6223C1.54369 14.6667 0.666748 13.8125 0.666748 12.7619V2.28576ZM11.4223 9.6548L13.1456 8.90778C13.2862 8.84528 13.3779 8.71135 13.3779 8.55956V5.52087C13.3779 5.30956 13.2037 5.13992 12.9867 5.13992L11.4223 5.1429V9.6548ZM4.57786 4.66671C4.57786 4.4048 4.35786 4.19052 4.08897 4.19052C3.82008 4.19052 3.60008 4.4048 3.60008 4.66671V11.3334C3.60008 11.5953 3.82008 11.8096 4.08897 11.8096C4.35786 11.8096 4.57786 11.5953 4.57786 11.3334V4.66671ZM6.53341 4.66671C6.53341 4.4048 6.31341 4.19052 6.04453 4.19052C5.77564 4.19052 5.55564 4.4048 5.55564 4.66671V11.3334C5.55564 11.5953 5.77564 11.8096 6.04453 11.8096C6.31341 11.8096 6.53341 11.5953 6.53341 11.3334V4.66671ZM8.48897 4.66671C8.48897 4.4048 8.26897 4.19052 8.00008 4.19052C7.73119 4.19052 7.51119 4.4048 7.51119 4.66671V11.3334C7.51119 11.5953 7.73119 11.8096 8.00008 11.8096C8.26897 11.8096 8.48897 11.5953 8.48897 11.3334V4.66671Z"
        fill={color}
      />
    </Svg>
  )
}

export function AllergyIcon({
  size = 16,
  color = '#444444',
}: {
  size?: number
  color?: string
}): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        fillRule="evenodd"
        d="M0 8C0 3.58065 3.58065 0 8 0C12.4194 0 16 3.58065 16 8C16 12.4194 12.4194 16 8 16C3.58065 16 0 12.4194 0 8ZM11.6129 6.45157C11.6129 5.8806 11.1516 5.41931 10.5806 5.41931C10.0096 5.41931 9.54834 5.8806 9.54834 6.45157C9.54834 7.02254 10.0096 7.48383 10.5806 7.48383C11.1516 7.48383 11.6129 7.02254 11.6129 6.45157ZM5.41947 5.41943C5.99043 5.41943 6.45172 5.88072 6.45172 6.45169C6.45172 7.02266 5.99043 7.48395 5.41947 7.48395C4.8485 7.48395 4.38721 7.02266 4.38721 6.45169C4.38721 5.88072 4.8485 5.41943 5.41947 5.41943ZM8.00018 11.0968C9.12599 11.0968 10.1873 11.5936 10.9099 12.4581C11.3453 12.9839 12.1389 12.3226 11.7002 11.8001C10.7841 10.6968 9.43244 10.0646 8.00018 10.0646C6.56793 10.0646 5.21631 10.6968 4.29696 11.7968C3.86147 12.3226 4.65502 12.9839 5.09051 12.4581C5.81309 11.5936 6.87438 11.0968 8.00018 11.0968Z"
        fill={color}
      />
    </Svg>
  )
}

export function SeatDesignationIcon({
  size = 16,
  color = '#444444',
}: {
  size?: number
  color?: string
}): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        d="M4.50107 4C4.50107 3.07812 5.05555 2.28125 5.87015 1.84687V8H7.51304V1.5H9.15594V8H10.7988V1.84687C11.6134 2.28125 12.1679 3.07812 12.1679 4V8H13.8108V4C13.8108 2.93913 13.3492 1.92172 12.5276 1.17157C11.706 0.421427 10.5917 0 9.42975 0L7.23923 0C6.0773 0 4.96296 0.421427 4.14136 1.17157C3.31975 1.92172 2.85818 2.93913 2.85818 4V8H4.50107V4ZM15.9431 10.6844L15.5769 9.68437C15.5043 9.48494 15.3646 9.31147 15.1778 9.18862C14.9911 9.06576 14.7666 8.99977 14.5364 9H2.12914C1.89894 8.99977 1.67451 9.06576 1.48772 9.18862C1.30094 9.31147 1.1613 9.48494 1.08864 9.68437L0.722416 10.6844C0.66826 10.8348 0.65388 10.9947 0.680453 11.1511C0.707026 11.3076 0.773797 11.456 0.875299 11.5844C0.976802 11.7127 1.11015 11.8173 1.26443 11.8895C1.41871 11.9618 1.58953 11.9996 1.76292 12V15.5C1.76292 15.6326 1.82061 15.7598 1.92331 15.8536C2.02601 15.9473 2.16531 16 2.31055 16H3.40581C3.55105 16 3.69034 15.9473 3.79304 15.8536C3.89574 15.7598 3.95344 15.6326 3.95344 15.5V12H12.7155V15.5C12.7155 15.6326 12.7732 15.7598 12.8759 15.8536C12.9786 15.9473 13.1179 16 13.2632 16H14.3584C14.5037 16 14.643 15.9473 14.7457 15.8536C14.8484 15.7598 14.9061 15.6326 14.9061 15.5V12C15.0794 11.9997 15.2501 11.9618 15.4043 11.8895C15.5584 11.8172 15.6916 11.7126 15.7928 11.5842C15.8941 11.4557 15.9605 11.3072 15.9866 11.1508C16.0127 10.9944 15.9978 10.8345 15.9431 10.6844Z"
        fill={color}
      />
    </Svg>
  )
}

export function PhoneIcon({
  size = 16,
  color = '#444444',
}: {
  size?: number
  color?: string
}): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        d="M14.2863 10.7552L11.3696 9.50515C11.245 9.45205 11.1065 9.44086 10.975 9.47327C10.8435 9.50568 10.7261 9.57993 10.6404 9.68485L9.34876 11.263C7.32157 10.3072 5.69015 8.67578 4.73435 6.64859L6.31251 5.35691C6.41763 5.2714 6.49204 5.15398 6.52446 5.02241C6.55688 4.89084 6.54556 4.75228 6.4922 4.62773L5.24217 1.71101C5.18361 1.57674 5.08003 1.46711 4.94929 1.40103C4.81856 1.33495 4.66886 1.31656 4.52602 1.34902L1.81764 1.97403C1.67992 2.00584 1.55704 2.08338 1.46907 2.19401C1.3811 2.30464 1.33322 2.44182 1.33325 2.58316C1.33325 9.26296 6.74741 14.6667 13.4168 14.6667C13.5582 14.6668 13.6954 14.6189 13.8061 14.531C13.9168 14.443 13.9944 14.3201 14.0262 14.1823L14.6512 11.4739C14.6835 11.3304 14.6647 11.1801 14.5981 11.0489C14.5315 10.9177 14.4213 10.8139 14.2863 10.7552V10.7552Z"
        fill={color}
      />
    </Svg>
  )
}

// FontAwesomeアイコンをラップしたコンポーネント
export function WarningIcon({
  size = 16,
  color = '#FFC107',
}: {
  size?: number
  color?: string
}): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        fillRule="evenodd"
        d="M0 8C0 3.58065 3.58065 0 8 0C12.4194 0 16 3.58065 16 8C16 12.4194 12.4194 16 8 16C3.58065 16 0 12.4194 0 8ZM11.6129 6.45157C11.6129 5.8806 11.1516 5.41931 10.5806 5.41931C10.0096 5.41931 9.54834 5.8806 9.54834 6.45157C9.54834 7.02254 10.0096 7.48383 10.5806 7.48383C11.1516 7.48383 11.6129 7.02254 11.6129 6.45157ZM5.41947 5.41943C5.99043 5.41943 6.45172 5.88072 6.45172 6.45169C6.45172 7.02266 5.99043 7.48395 5.41947 7.48395C4.8485 7.48395 4.38721 7.02266 4.38721 6.45169C4.38721 5.88072 4.8485 5.41943 5.41947 5.41943ZM8.00018 11.0968C9.12599 11.0968 10.1873 11.5936 10.9099 12.4581C11.3453 12.9839 12.1389 12.3226 11.7002 11.8001C10.7841 10.6968 9.43244 10.0646 8.00018 10.0646C6.56793 10.0646 5.21631 10.6968 4.29696 11.7968C3.86147 12.3226 4.65502 12.9839 5.09051 12.4581C5.81309 11.5936 6.87438 11.0968 8.00018 11.0968Z"
        fill={color}
      />
    </Svg>
  )
}

export function CourseIcon({
  size = 16,
  color = '#444444',
}: {
  size?: number
  color?: string
}): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        d="M8.10294 1.50982C8.12389 1.6365 8.52458 4.05689 8.52458 4.98137C8.52458 6.39102 7.79652 7.39637 6.72015 7.80066L7.05799 14.2182C7.06239 14.306 7.0494 14.3938 7.01981 14.4762C6.99022 14.5587 6.94465 14.6341 6.88586 14.6979C6.82707 14.7617 6.7563 14.8125 6.67784 14.8473C6.59938 14.882 6.51487 14.9 6.42945 14.9001H4.75334C4.66792 14.9 4.58341 14.882 4.50495 14.8473C4.42649 14.8125 4.35571 14.7617 4.29693 14.6979C4.23814 14.6341 4.19256 14.5587 4.16297 14.4762C4.13338 14.3938 4.1204 14.306 4.1248 14.2182L4.46264 7.80066C3.38364 7.39637 2.6582 6.38832 2.6582 4.98137C2.6582 4.05419 3.0589 1.6365 3.07985 1.50982C3.16365 0.962676 4.26622 0.95459 4.33431 1.53947V5.34524C4.36836 5.43688 4.72977 5.43149 4.75334 5.34524C4.79 4.66333 4.96023 1.59338 4.96285 1.5233C5.04928 0.962676 6.13351 0.962676 6.21731 1.5233C6.22255 1.59607 6.39016 4.66333 6.42683 5.34524C6.4504 5.43149 6.81443 5.43688 6.84585 5.34524V1.53947C6.91395 0.957285 8.01913 0.962676 8.10294 1.50982ZM11.2247 9.21031L10.8319 14.1993C10.8246 14.2885 10.8354 14.3782 10.8636 14.4628C10.8917 14.5474 10.9366 14.6251 10.9955 14.691C11.0543 14.7569 11.1257 14.8094 11.2053 14.8454C11.2849 14.8814 11.3709 14.9 11.4578 14.9001H12.9244C13.007 14.9003 13.0888 14.8837 13.1651 14.8513C13.2415 14.8189 13.3109 14.7712 13.3693 14.7111C13.4277 14.651 13.474 14.5796 13.5055 14.501C13.537 14.4224 13.5531 14.3382 13.5529 14.2532V1.74701C13.5529 1.57545 13.4867 1.41091 13.3688 1.2896C13.2509 1.16829 13.0911 1.10014 12.9244 1.10014C10.7638 1.10014 7.12608 5.91125 11.2247 9.21031Z"
        fill={color}
      />
    </Svg>
  )
}

// アイコンのインデックス
export const ICONS = {
  vip: VipIcon,
  caution: CautionIcon,
  smartPay: SmartPayIcon,
  memo: MemoIcon,
  autoreserve: AutoreserveIcon,
  tabelog: TabelogIcon,
  history: HistoryIcon,
  warning: WarningIcon,
  course: CourseIcon,
  birthday: BirthdayIcon,
  allYouCanDrink: AllYouCanDrinkIcon,
  drink: AllYouCanDrinkIcon,
  allergy: AllergyIcon,
  seatDesignation: SeatDesignationIcon,
  table: SeatDesignationIcon,
  phone: PhoneIcon,
} as const satisfies Record<string, IconType>

// アイコンキーの型定義
export type IconKey = keyof typeof ICONS
