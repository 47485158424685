import React from 'react'

import { Path, Svg } from '@react-pdf/renderer'

type PhoneIconProps = {
  size?: number
  color?: string
}

export function PhoneIcon({
  size = 16,
  color = '#444444',
}: PhoneIconProps): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        d="M14.2863 10.7552L11.3696 9.50515C11.245 9.45205 11.1065 9.44086 10.975 9.47327C10.8435 9.50568 10.7261 9.57993 10.6404 9.68485L9.34876 11.263C7.32157 10.3072 5.69015 8.67578 4.73435 6.64859L6.31251 5.35691C6.41763 5.2714 6.49204 5.15398 6.52446 5.02241C6.55688 4.89084 6.54556 4.75228 6.4922 4.62773L5.24217 1.71101C5.18361 1.57674 5.08003 1.46711 4.94929 1.40103C4.81856 1.33495 4.66886 1.31656 4.52602 1.34902L1.81764 1.97403C1.67992 2.00584 1.55704 2.08338 1.46907 2.19401C1.3811 2.30464 1.33322 2.44182 1.33325 2.58316C1.33325 9.26296 6.74741 14.6667 13.4168 14.6667C13.5582 14.6668 13.6954 14.6189 13.8061 14.531C13.9168 14.443 13.9944 14.3201 14.0262 14.1823L14.6512 11.4739C14.6835 11.3304 14.6647 11.1801 14.5981 11.0489C14.5315 10.9177 14.4213 10.8139 14.2863 10.7552V10.7552Z"
        fill={color}
        fillOpacity={0.6}
      />
    </Svg>
  )
}
