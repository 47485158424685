import React, { useEffect } from 'react'

import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { Platform, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { useNavigate } from 'react-router'

import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { AddFloatingButton } from '@hello-ai/for_r_app/src/components/Shared/AddButton'

import { useNavigation } from 'components/Shared/Navigation/Screen'
import { restaurantCourseQuestionService } from 'models/RestaurantCourseQuestion'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function RestaurantCourseQuestionsScreen() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()
  const navigation = useNavigation()
  const navigate = useNavigate()

  const { data } = restaurantCourseQuestionService.useList({
    restaurantId,
    page: 1,
    perPage: 20,
  })

  useEffect(() => {
    navigation.setOptions({
      title: t('お客様への質問'),
    })
  }, [navigation])

  if (data === undefined) {
    return <Loading />
  }

  const { restaurantCourseQuestions } = data

  return (
    <View
      style={[
        { position: 'relative', flex: 1 },
        Platform.OS === 'web' && { flex: 1, backgroundColor: Colors.bgBlack },
      ]}
    >
      <KeyboardAwareScrollView
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: Colors.bgBlack }}
        contentContainerStyle={[
          width < sm ? { padding: 24 } : { padding: 32 },
          { paddingBottom: 100 },
        ]}
      >
        <ShadowBox style={{ paddingHorizontal: 0, paddingVertical: 0 }}>
          {/* ヘッダー行は不要なので削除 */}
          {restaurantCourseQuestions.map((item, index) => (
            <View
              key={item.id}
              style={{
                paddingVertical: 24,
                paddingHorizontal: 24,
                position: 'relative',
              }}
            >
              {index !== 0 && (
                <View
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 24,
                    right: 24,
                    height: 0.5,
                    backgroundColor: Colors.border,
                  }}
                />
              )}

              <TouchableOpacity
                onPress={() =>
                  navigate(
                    `/restaurants/${restaurantId}/course_questions/${item.id}`
                  )
                }
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 16,
                }}
              >
                <View style={{ flex: 1, gap: 8 }}>
                  <Text
                    style={{
                      alignSelf: 'stretch',
                      color: '#444',
                      fontFamily: 'Hiragino Sans',
                      fontSize: 18,
                      fontWeight: '600',
                      lineHeight: 27,
                    }}
                  >
                    {item.name}
                  </Text>
                  <Text
                    numberOfLines={1}
                    style={{
                      alignSelf: 'stretch',
                      color: 'rgba(68, 68, 68, 0.60)',
                      fontFamily: 'Hiragino Sans',
                      fontSize: 14,
                      fontWeight: '300',
                      lineHeight: 21,
                      overflow: 'hidden',
                    }}
                  >
                    {item.description}
                  </Text>
                </View>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ marginLeft: 'auto' }}
                />
              </TouchableOpacity>
            </View>
          ))}
        </ShadowBox>
      </KeyboardAwareScrollView>
      <AddFloatingButton
        title={width >= sm ? t('質問追加') : t('追加')}
        onPress={() =>
          navigate(`/restaurants/${restaurantId}/course_questions/new`)
        }
      />
    </View>
  )
}
