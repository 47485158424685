import React from 'react'

import { faTrashCan } from '@fortawesome/pro-regular-svg-icons/faTrashCan'
import produce from 'immer'
import { Platform, View } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Checkbox } from '@hello-ai/ar_shared/src/components/Checkbox'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import {
  FormDivider,
  FormGroup,
  FormLabel,
} from '@hello-ai/ar_shared/src/components/Form'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import {
  Radio,
  RadioGroup,
  RadioLabel,
} from '@hello-ai/ar_shared/src/components/Radio'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import {
  localeData,
  SupportedLocale,
  supportedLocales,
} from '@hello-ai/ar_shared/src/modules/locale'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { useSafeAreaInsets } from '@hello-ai/ar_shared/src/modules/useSafeAreaInsets'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import {
  RestaurantCourseQuestionResource,
  RestaurantCourseQuestionResource_Category,
  RestaurantCourseQuestionResource_Status,
  RestaurantCourseQuestionSelectionResource,
  RestaurantCourseQuestionSelectionResource_Status,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_course/restaurant_course_question_resource'
import {
  ArchiveRequest,
  CreateRequest,
  UpdateRequest,
  UpdateRequest_QuestionSelectionResource,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_course_question/restaurant_course_question_service'
import { StringValue } from '@hello-ai/proto/src/gen/google/protobuf/wrappers'

import { LanguageExpandToggleButton } from '../Shared/LanguageExpandToggleButton'

type RestaurantCourseQuestionFormCommonProps = {
  restaurantId: Restaurant['id']
  onCreate: (params: CreateRequest) => void
  onCreateError: (errors: string[]) => void
}

type RestaurantCourseQuestionFormPageProps =
  RestaurantCourseQuestionFormCommonProps & {
    kind: 'page'
    restaurantCourseQuestionId:
      | RestaurantCourseQuestionResource['id']
      | undefined
    restaurantCourseQuestion: RestaurantCourseQuestionResource | undefined
    onUpdate: (params: UpdateRequest) => void
    onUpdateError: (errors: string[]) => void
    onArchive: (params: ArchiveRequest) => void
  }

type RestaurantCourseQuestionFormModalProps =
  RestaurantCourseQuestionFormCommonProps & {
    kind: 'modal'
  }

type RestaurantCourseQuestionFormProps =
  | RestaurantCourseQuestionFormPageProps
  | RestaurantCourseQuestionFormModalProps

interface FormQuestion {
  id?: StringValue
  name: string
  descriptionI18NJa: string
  descriptionI18NEn: string
  descriptionI18NZhCn: string
  descriptionI18NZhTw: string
  descriptionI18NZhHk: string
  descriptionI18NKo: string
  category: RestaurantCourseQuestionResource_Category
  isRequired: boolean
  status: RestaurantCourseQuestionResource_Status
  selections: Array<
    Omit<UpdateRequest_QuestionSelectionResource, 'description'>
  >
}

type CourseQuestionDescriptionType = keyof RestaurantCourseQuestionResource &
  `descriptionI18N${string}` // descriptionI18NJaなどにヒットする

type SelectionDescriptionType =
  keyof RestaurantCourseQuestionSelectionResource & `descriptionI18N${string}` // descriptionI18NJaなどにヒットする

export function RestaurantCourseQuestionForm(
  props: RestaurantCourseQuestionFormProps
) {
  const { width, sm, md } = useResponsive()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const insets = useSafeAreaInsets()
  const { restaurantId, onCreate, onCreateError: _onCreateError } = props

  const [question, setQuestion] = useFormState<FormQuestion>(() => {
    if (props.kind === 'page' && props.restaurantCourseQuestion) {
      return {
        id: { value: props.restaurantCourseQuestion.id },
        name: props.restaurantCourseQuestion.name,
        descriptionI18NJa: props.restaurantCourseQuestion.descriptionI18NJa,
        descriptionI18NEn: props.restaurantCourseQuestion.descriptionI18NEn,
        descriptionI18NKo: props.restaurantCourseQuestion.descriptionI18NKo,
        descriptionI18NZhCn: props.restaurantCourseQuestion.descriptionI18NZhCn,
        descriptionI18NZhHk: props.restaurantCourseQuestion.descriptionI18NZhHk,
        descriptionI18NZhTw: props.restaurantCourseQuestion.descriptionI18NZhTw,
        category: props.restaurantCourseQuestion.category,
        isRequired: props.restaurantCourseQuestion.isRequired,
        status: props.restaurantCourseQuestion.status,
        selections: props.restaurantCourseQuestion.selections.map(
          (selection) => ({
            id: { value: selection.id },
            descriptionI18NJa: selection.descriptionI18NJa,
            descriptionI18NEn: selection.descriptionI18NEn,
            descriptionI18NKo: selection.descriptionI18NKo,
            descriptionI18NZhCn: selection.descriptionI18NZhCn,
            descriptionI18NZhHk: selection.descriptionI18NZhHk,
            descriptionI18NZhTw: selection.descriptionI18NZhTw,
            status: selection.status,
          })
        ),
      }
    }

    return {
      name: '',
      descriptionI18NJa: '',
      descriptionI18NEn: '',
      descriptionI18NKo: '',
      descriptionI18NZhCn: '',
      descriptionI18NZhHk: '',
      descriptionI18NZhTw: '',
      category: RestaurantCourseQuestionResource_Category.TEXT,
      isRequired: false,
      status: RestaurantCourseQuestionResource_Status.PUBLISHED,
      selections: [],
    }
  })

  const onChangeQuestionName = (value: string) => {
    setQuestion((question) =>
      produce(question, (draft) => {
        draft.name = value
      })
    )
  }

  const onChangeQuestionCategory = (
    value: RestaurantCourseQuestionResource_Category
  ) => {
    setQuestion((question) =>
      produce(question, (draft) => {
        draft.category = value
      })
    )
  }

  // 海外言語がある場合は開いておく
  const [isQuestionDescriptionExpanded, setIsQuestionDescriptionExpanded] =
    useFormState(
      props.kind === 'page' && props.restaurantCourseQuestion
        ? Boolean(props.restaurantCourseQuestion.descriptionI18NEn)
        : false
    )

  const onChangeQuestionDescription = <T extends CourseQuestionDescriptionType>(
    type: T,
    value: RestaurantCourseQuestionResource[T]
  ) => {
    setQuestion((question) =>
      produce(question, (draft) => {
        draft[type] = value
      })
    )
  }

  const onChangeQuestionIsRequired = (
    value: RestaurantCourseQuestionResource['isRequired']
  ) => {
    setQuestion((question) =>
      produce(question, (draft) => {
        draft.isRequired = value
      })
    )
  }

  // 海外言語がある場合は開いておく
  const [isSelectionDescriptionExpanded, setIsSelectionDescriptionExpanded] =
    useFormState(
      question.selections.map((selection) =>
        Boolean(selection?.descriptionI18NEn)
      )
    )

  const onChangeSelectionDescription = <T extends SelectionDescriptionType>(
    selectionIndex: number,
    type: T,
    value: RestaurantCourseQuestionSelectionResource[T]
  ) => {
    setQuestion((question) =>
      produce(question, (draft) => {
        draft.selections[selectionIndex][type] = value
      })
    )
  }

  const onChangeSelectionArchive = (selectionIndex: number) => {
    setQuestion((question) =>
      produce(question, (draft) => {
        if (draft.selections[selectionIndex].id !== undefined) {
          draft.selections[selectionIndex].status =
            RestaurantCourseQuestionSelectionResource_Status.ARCHIVED
        } else {
          draft.selections.splice(selectionIndex, 1)
        }
      })
    )
  }

  const addSelectionForm = () => {
    setQuestion((question) =>
      produce(question, (draft) => {
        draft.selections.push({
          descriptionI18NJa: '',
          descriptionI18NEn: '',
          descriptionI18NKo: '',
          descriptionI18NZhCn: '',
          descriptionI18NZhHk: '',
          descriptionI18NZhTw: '',
          status: RestaurantCourseQuestionSelectionResource_Status.PUBLISHED,
        })
      })
    )
  }

  const onCreate_ = async () => {
    setIsSubmitting(true)
    try {
      const params: CreateRequest = {
        restaurantId,
        name: question.name,
        description: question.descriptionI18NJa,
        descriptionI18NJa: question.descriptionI18NJa,
        descriptionI18NEn: question.descriptionI18NEn,
        descriptionI18NKo: question.descriptionI18NKo,
        descriptionI18NZhCn: question.descriptionI18NZhCn,
        descriptionI18NZhHk: question.descriptionI18NZhHk,
        descriptionI18NZhTw: question.descriptionI18NZhTw,
        category: question.category,
        isRequired: question.isRequired,
        selections:
          question.category === RestaurantCourseQuestionResource_Category.TEXT
            ? []
            : question.selections.map((selection) => ({
                description: selection.descriptionI18NJa,
                ...selection,
              })),
      }

      await onCreate(params)
    } finally {
      setIsSubmitting(false)
    }
  }

  const onUpdate_ = async () => {
    if (props.kind !== 'page') return
    setIsSubmitting(true)
    try {
      if (props.restaurantCourseQuestionId === undefined) return

      const params: UpdateRequest = {
        id: props.restaurantCourseQuestionId,
        name: question.name,
        description: question.descriptionI18NJa,
        descriptionI18NJa: question.descriptionI18NJa,
        descriptionI18NEn: question.descriptionI18NEn,
        descriptionI18NKo: question.descriptionI18NKo,
        descriptionI18NZhCn: question.descriptionI18NZhCn,
        descriptionI18NZhHk: question.descriptionI18NZhHk,
        descriptionI18NZhTw: question.descriptionI18NZhTw,
        category: question.category,
        isRequired: question.isRequired,
        selections:
          question.category === RestaurantCourseQuestionResource_Category.TEXT
            ? []
            : question.selections.map((selection) => ({
                description: selection.descriptionI18NJa,
                ...selection,
              })),
      }
      await props.onUpdate(params)
    } finally {
      setIsSubmitting(false)
    }
  }

  const onArchive_ = async () => {
    if (props.kind !== 'page') return
    setIsSubmitting(true)
    try {
      if (props.restaurantCourseQuestionId === undefined) return

      const params: ArchiveRequest = {
        id: props.restaurantCourseQuestionId,
      }
      await props.onArchive(params)
    } finally {
      setIsSubmitting(false)
    }
  }

  // ページ用フッターコンポーネント
  function PageFooter() {
    if (props.kind !== 'page') return null
    return (
      <View
        style={[
          {
            position: 'sticky',
            bottom: 0,
            paddingBottom:
              Platform.OS === 'web' ? undefined : insets.bottom || 24,
            width: '100%',
            paddingVertical: width < sm ? 16 : 12,
            paddingHorizontal: 24,
            backgroundColor: Colors.white,
            flexDirection: 'row',
            justifyContent: 'center',
            gap: width > sm ? 16 : 12,
            borderTopWidth: 1,
            borderColor: Colors.black10,
          },
        ]}
      >
        {props.restaurantCourseQuestionId != null && (
          <Button
            mode="outline"
            variant="danger-secondary"
            style={{
              height: 48,
              maxWidth: width < sm ? '100%' : 296,
              flex: 1,
            }}
            onPress={onArchive_}
            disabled={isSubmitting}
          >
            {t('削除する')}
          </Button>
        )}
        <Button
          onPress={
            props.restaurantCourseQuestionId == null ? onCreate_ : onUpdate_
          }
          disabled={isSubmitting}
          loading={isSubmitting}
          style={{
            height: 48,
            maxWidth: width < sm ? '100%' : 296,
            flex: 1,
          }}
        >
          {props.restaurantCourseQuestionId != null
            ? t('更新する')
            : t('作成する')}
        </Button>
      </View>
    )
  }

  // モーダル用フッターコンポーネント
  function ModalFooter() {
    if (props.kind !== 'modal') return null
    return (
      <View
        style={[
          {
            width: '100%',
            paddingVertical: 12,
            paddingHorizontal: 24,
            backgroundColor: Colors.white,
            flexDirection: 'row',
            justifyContent: 'center',
            borderTopWidth: 1,
            borderColor: Colors.black10,
          },
        ]}
      >
        <Button
          onPress={onCreate_}
          disabled={isSubmitting}
          loading={isSubmitting}
          style={{
            height: 48,
            width: '100%',
            maxWidth: width < sm ? '100%' : 296,
            flex: 1,
            marginBottom:
              Platform.OS === 'web' ? undefined : insets.bottom || 24,
          }}
        >
          {t('作成する')}
        </Button>
      </View>
    )
  }

  return (
    <View style={{ position: 'relative', flex: 1 }}>
      <KeyboardAwareScrollView
        style={{
          height: '100%',
          paddingHorizontal: width < sm ? 24 : 32,
          paddingTop: width < sm ? 24 : 32,
          ...(props.kind === 'modal' && { maxHeight: '70vh' }),
        }}
      >
        <View style={{ gap: 32 }}>
          <ShadowBox style={{ gap: 24 }}>
            <FormGroup
              mode={width < sm ? 'vertical' : 'inline-expanded'}
              formLabel={
                <View
                  style={{
                    marginTop: 8,
                    gap: 4,
                  }}
                >
                  <FormLabel required value={t('管理用タイトル')} />
                  <Text
                    style={{
                      fontSize: 14,
                      color: Colors.black60,
                    }}
                  >
                    {t('お客様には表示されません')}
                  </Text>
                </View>
              }
            >
              <TextInput
                value={question.name}
                onChangeText={onChangeQuestionName}
                placeholder={t('例：お祝いメッセージの確認')}
              />
            </FormGroup>

            <FormGroup
              mode={width < sm ? 'vertical' : 'inline'}
              formLabel={<FormLabel value={t('質問形式')} required />}
            >
              <RadioGroup
                value={question.category}
                onChange={(value) =>
                  onChangeQuestionCategory(
                    value as RestaurantCourseQuestionResource_Category
                  )
                }
                mode={width < sm ? 'vertical' : 'inline'}
                style={{
                  gap: width < sm ? 8 : 16,
                }}
                radioContainerStyle={{
                  marginTop: 0,
                  marginLeft: 0,
                }}
              >
                <Radio
                  value={RestaurantCourseQuestionResource_Category.TEXT}
                  style={{ flex: 1 }}
                  radioLabel={
                    <RadioLabel
                      style={{ paddingVertical: 8 }}
                      value={t('テキスト')}
                    />
                  }
                />
                <Radio
                  value={RestaurantCourseQuestionResource_Category.CHECKBOX}
                  style={{ flex: 1 }}
                  radioLabel={
                    <RadioLabel
                      style={{ paddingVertical: 8 }}
                      value={t('チェックボックス')}
                    />
                  }
                />
                <Radio
                  value={RestaurantCourseQuestionResource_Category.RADIO}
                  style={{ flex: 1 }}
                  radioLabel={
                    <RadioLabel
                      style={{ paddingVertical: 8 }}
                      value={t('ラジオボタン')}
                    />
                  }
                />
              </RadioGroup>
            </FormGroup>

            <FormGroup
              mode={width < sm ? 'vertical' : 'inline'}
              formLabel={<FormLabel value={t('回答を必須にするか')} />}
            >
              <Checkbox
                checked={question.isRequired}
                onChange={onChangeQuestionIsRequired}
                checkboxLabel={<Text>{t('必須にする')}</Text>}
                style={{ paddingVertical: 8 }}
              />
            </FormGroup>

            <FormGroup
              mode={'vertical'}
              formLabel={<FormLabel value={t('お客様への質問文')} required />}
              containerStyle={{
                marginTop: 8,
                gap: 24,
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: Colors.black60,
                }}
              >
                {t(
                  '日本語の情報を入力して登録すると、自動で翻訳して他の言語も登録します。ただし編集時には自動翻訳はされません。'
                )}
              </Text>

              {supportedLocales
                .slice(
                  0,
                  isQuestionDescriptionExpanded ? supportedLocales.length : 1
                )
                .map((locale: SupportedLocale) => {
                  const descriptionType = ('descriptionI18N' +
                    locale
                      .split(/-/)
                      .map(
                        (value) =>
                          value[0].toUpperCase() + value.slice(1).toLowerCase()
                      )
                      .join('')) as CourseQuestionDescriptionType

                  return (
                    <FormGroup
                      key={locale}
                      formLabel={<FormLabel value={localeData[locale].label} />}
                      mode={width >= md ? 'inline' : 'vertical'}
                      formLabelContainerStyle={{
                        paddingLeft: width < md ? 0 : 24,
                      }}
                      containerStyle={{
                        marginTop: width < md ? 8 : 0,
                      }}
                    >
                      <TextInput
                        multiline
                        style={{ height: 96 }}
                        onChangeText={(text) =>
                          onChangeQuestionDescription(descriptionType, text)
                        }
                        placeholder={t(
                          '例：お祝いメッセージのご希望があればご記入ください。'
                        )}
                        value={question[descriptionType]}
                      />
                    </FormGroup>
                  )
                })}

              <LanguageExpandToggleButton
                isExpanded={isQuestionDescriptionExpanded}
                onPress={() => {
                  setIsQuestionDescriptionExpanded((value) => !value)
                }}
              />
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <View style={{ flex: 1 }}>
                  {question.category !== 0 && (
                    <View style={{ gap: 24 }}>
                      {question.selections.map((selection, selectionIndex) => {
                        if (
                          selection.status ===
                          RestaurantCourseQuestionSelectionResource_Status.ARCHIVED
                        )
                          return null
                        return (
                          <View key={selectionIndex} style={{ gap: 24 }}>
                            <FormDivider />
                            <FormGroup
                              mode={'vertical'}
                              containerStyle={{
                                gap: 24,
                              }}
                              formLabel={
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <FormLabel
                                    value={t('回答 {{text}}', {
                                      text: selectionIndex + 1,
                                    })}
                                  />
                                  <TouchableOpacity
                                    style={[
                                      { paddingLeft: width < md ? 0 : 24 },
                                    ]}
                                    onPress={() =>
                                      onChangeSelectionArchive(selectionIndex)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashCan}
                                      size={24}
                                      color={Colors.secondaryBlack}
                                    />
                                  </TouchableOpacity>
                                </View>
                              }
                            >
                              {supportedLocales
                                .slice(
                                  0,
                                  isSelectionDescriptionExpanded[selectionIndex]
                                    ? supportedLocales.length
                                    : 1
                                )
                                .map((locale: SupportedLocale) => {
                                  const selectionDescriptionType =
                                    ('descriptionI18N' +
                                      locale
                                        .split(/-/)
                                        .map(
                                          (value) =>
                                            value[0].toUpperCase() +
                                            value.slice(1).toLowerCase()
                                        )
                                        .join('')) as SelectionDescriptionType
                                  return (
                                    <FormGroup
                                      key={locale}
                                      formLabelContainerStyle={{
                                        paddingLeft: width < md ? 0 : 24,
                                      }}
                                      containerStyle={{
                                        marginTop: width < md ? 8 : 0,
                                      }}
                                      formLabel={
                                        <FormLabel
                                          value={localeData[locale].label}
                                        />
                                      }
                                      mode={width >= md ? 'inline' : 'vertical'}
                                    >
                                      <TextInput
                                        onChangeText={(text) =>
                                          onChangeSelectionDescription(
                                            selectionIndex,
                                            selectionDescriptionType,
                                            text
                                          )
                                        }
                                        value={
                                          selection[selectionDescriptionType]
                                        }
                                      />
                                    </FormGroup>
                                  )
                                })}
                              <LanguageExpandToggleButton
                                isExpanded={
                                  isSelectionDescriptionExpanded[selectionIndex]
                                }
                                onPress={() => {
                                  setIsSelectionDescriptionExpanded((value) =>
                                    produce(value, (draft) => {
                                      draft[selectionIndex] =
                                        !value[selectionIndex]
                                    })
                                  )
                                }}
                              />
                            </FormGroup>
                          </View>
                        )
                      })}
                      <Button
                        style={{
                          marginTop: 24,
                        }}
                        mode="outline"
                        onPress={() => addSelectionForm()}
                      >
                        {t('回答を追加する')}
                      </Button>
                    </View>
                  )}
                </View>
              </View>
            </FormGroup>
          </ShadowBox>
        </View>
        <View
          style={
            Platform.OS === 'web'
              ? {
                  height: width < md ? 24 : 32,
                }
              : { height: width < md ? 24 * 2 : 32 * 2 }
          }
        />
      </KeyboardAwareScrollView>

      {props.kind === 'page' ? <PageFooter /> : <ModalFooter />}
    </View>
  )
}
