import React from 'react'

import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck'
import { Text, View, StyleSheet } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { useRequestReservationCount } from '../../../../models/RestaurantRequestReservation'

const styles = StyleSheet.create({
  statusContainer: {
    gap: 4,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  statusText: {
    color: Colors.black,
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 27,
  },
  countTextContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  countText: {
    fontSize: 14,
    fontWeight: '300',
    lineHeight: 21,
    color: Colors.black,
  },
  wrapper: {
    gap: 24,
  },
})

function StatusApprovedView() {
  return (
    <View style={styles.statusContainer}>
      <FontAwesomeIcon icon={faCircleCheck} color={Colors.accent} size={20} />
      <Text style={styles.statusText}>
        {t('リクエスト予約の承認が完了しました')}
      </Text>
    </View>
  )
}

function StatusRejectedView() {
  return (
    <View style={styles.statusContainer}>
      <Text style={styles.statusText}>
        {t('リクエスト予約の棄却が完了しました')}
      </Text>
    </View>
  )
}

export type PrevStatus = 'approved' | 'rejected' | null

function StatusView({ count, prev }: { count: number; prev: PrevStatus }) {
  const { width, sm } = useResponsive()
  const isDesktop = width > sm
  const isPaddingTop = count > 0 || prev !== null

  return (
    <View
      style={[
        styles.wrapper,
        { paddingTop: isPaddingTop ? (isDesktop ? 40 : 24) : 0 },
      ]}
    >
      {prev === 'approved' && <StatusApprovedView />}
      {prev === 'rejected' && <StatusRejectedView />}
      {count > 0 && (
        <View
          style={[
            styles.countTextContainer,
            { flexDirection: isDesktop ? 'row' : 'column' },
          ]}
        >
          <Text style={styles.countText}>
            {t('未承認のリクエスト予約が{{count}}件あります。', { count })}
          </Text>
          <Text style={styles.countText}>{t('内容をご確認ください。')}</Text>
        </View>
      )}
    </View>
  )
}

type Props = {
  restaurantId: number
  prev: PrevStatus
}

export function ReservationStatus({ restaurantId, prev }: Props) {
  const { data } = useRequestReservationCount(restaurantId)

  if (!data) {
    return null
  }

  return <StatusView count={data.count} prev={prev} />
}
