import React from 'react'

import { View } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { numberWithDelimiter } from '@hello-ai/ar_shared/src/modules/numberWithDelimiter'

import { PaymentByFeeType } from 'models/Payment'

export default function PaymentDetailItem({
  title,
  amount,
  by_fee_type = [],
  isNegative = false,
}: {
  title: string
  amount: number
  by_fee_type?: PaymentByFeeType[]
  isNegative?: boolean
}) {
  const priceText = (amount: number) => {
    const price = numberWithDelimiter(amount)
    if (isNegative) {
      return t('-{{price}}円', { price })
    }
    return t('{{price}}円', { price })
  }

  return (
    <View style={{ marginTop: 12 }}>
      <View
        style={{
          marginBottom: 12,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text style={{ fontSize: 18 }}>{title}</Text>
        <Text style={{ fontSize: 18 }}>{priceText(amount)}</Text>
      </View>
      {by_fee_type.map((item) => (
        <View
          key={item.name}
          style={{
            marginBottom: 8,
            marginLeft: 24,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={{ fontSize: 14 }}>{item.name}</Text>
          <Text style={{ fontSize: 14 }}>{priceText(item.amount)}</Text>
        </View>
      ))}
    </View>
  )
}
