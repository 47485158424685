import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { toDoubleDigits } from 'modules/number'

export function splitHourAndMinute(num: number) {
  return {
    hour: Math.floor(num / 3600),
    minute: Math.floor((num % 3600) / 60),
  }
}

export function getFormatTime(num: number) {
  const { hour, minute } = splitHourAndMinute(num)
  return `${toDoubleDigits(hour)}:${toDoubleDigits(minute)}`
}

export function toSeconds(hours: number, mins: number) {
  return hours * 3600 + mins * 60
}

export function wdayName(wday: number) {
  if (wday === 7) {
    return t('祝日')
  }

  return dayjs.weekdays()[wday]
}

export function wdayShortName(wday: number | number[]): string {
  // 配列の場合は各曜日を「、」で区切って返す
  if (Array.isArray(wday)) {
    return wday.map((w) => wdayShortName(w)).join('、')
  }

  if (wday === 7) {
    return t('祝')
  }

  // 日本語の曜日の頭文字だけを返す
  const shortNames = [
    t('日'),
    t('月'),
    t('火'),
    t('水'),
    t('木'),
    t('金'),
    t('土'),
  ]
  return shortNames[wday]
}

export function showDayUnlessToday(time: number) {
  return dayjs(time).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')
    ? dayjs(time).format('HH:mm')
    : dayjs(time).format('M/D HH:mm')
}
