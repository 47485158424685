/* eslint-disable ar-i18n/require-translation-ja */
import React, { useMemo, useRef, useState } from 'react'

import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import _isUndefined from 'lodash/isUndefined'
import _omitBy from 'lodash/omitBy'
import { ScrollView, View, Platform, ViewStyle, StyleProp } from 'react-native'

import { ShadowBox } from '@hello-ai/ar_shared/src//components/ShadowBox'
import { UpdateRestaurantParams } from '@hello-ai/ar_shared/src//types/ForR/RestaurantUpdateParams'
import {
  Alert,
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import {
  FormGroup,
  FormLabel,
  FormDivider,
} from '@hello-ai/ar_shared/src/components/Form'
import {
  RadioGroup,
  Radio,
  RadioLabel,
} from '@hello-ai/ar_shared/src/components/Radio'
import {
  SelectedImage,
  SelectImage,
} from '@hello-ai/ar_shared/src/components/SelectImage'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { onError } from '@hello-ai/ar_shared/src/modules/auth'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import {
  SupportedLocale,
  supportedLocales,
} from '@hello-ai/ar_shared/src/modules/locale'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import {
  Restaurant,
  RestaurantMichelinAwardStatus,
  RestaurantSmokingStatus,
  RestaurantTabelogAwardStatus,
} from '@hello-ai/ar_shared/src/types/ForR/Restaurant'

import {
  getOpenStatusColor,
  getOpenStatusLabel,
} from '../../Restaurant/Information/common'
import { LanguageExpandToggleButton } from '../../Shared/LanguageExpandToggleButton'
import { MultipleSelectImage } from '../../Shared/MultipleSelectImage'
import {
  TranslationButton,
  TranslationFormItem,
  TranslationFormList,
  TranslationModal,
  confirmSaveIncompleteChangesAsync,
  getTranslationLocaleLabel,
  hasIncompleteChanges,
  toLocaleParams,
  LocaleValue,
  useLocaleFormState,
} from '../../Translation'

import { MichelinAddForm } from './MichelinAddForm'
import { MichelinList } from './MichelinList'
import { RecommendedProductCandidatesSearchModal } from './RecommendedProductCandidatesSearchModal'
import { TabelogAddForm } from './TabelogAddForm'
import { TabelogList } from './TabelogList'

const nameLocaleData: Record<
  SupportedLocale,
  {
    label: string
  }
> = {
  ja: {
    label: '日本語',
  },
  en: { label: '英語' },
  ko: {
    label: '韓国語',
  },
  'zh-CN': { label: '简体中文' },
  'zh-TW': { label: '繁體中文（台灣）' },
  'zh-HK': { label: '繁體中文（香港）' },
  th: { label: 'タイ語' },
}

const descriptionLocaleData: Record<
  SupportedLocale,
  {
    label: string
    title: string
    content: string
  }
> = {
  ja: {
    label: '日本語',
    title: 'タイトル',
    content: '詳細',
  },
  en: { label: '英語', title: 'Title', content: 'Detail' },
  ko: {
    label: '韓国語',
    title: '제목',
    content: '상세',
  },
  'zh-CN': { label: '简体中文', title: '标题', content: '细节' },
  'zh-TW': { label: '繁體中文（台灣）', title: '标题', content: '细节' },
  'zh-HK': { label: '繁體中文（香港）', title: '标题', content: '细节' },
  th: { label: 'タイ語', title: 'หัวข้อ', content: 'รายละเอียด' },
}

const reservationInfoLocaleData: Record<
  SupportedLocale,
  {
    label: string
    content: string
  }
> = {
  ja: {
    label: '日本語',
    content:
      '※年末年始は臨時休業となります。詳細な日付は予約カレンダーをご確認ください。\n※ご予約は2〜6名様のみ承ります。 7名様以上でのご利用の場合はお電話にてご相談下さい。\n※当日のご予約はお電話にて、直接店舗へお電話ください。\n※ご希望のお時間が満席の場合もお電話でのご予約席が空いてる場合がありますのでお電話にてご相談ください。',
  },
  en: { label: '英語', content: 'Detail' },
  ko: {
    label: '韓国語',
    content: '상세',
  },
  'zh-CN': { label: '简体中文', content: '细节' },
  'zh-TW': { label: '繁體中文（台灣）', content: '细节' },
  'zh-HK': { label: '繁體中文（香港）', content: '细节' },
  th: { label: 'タイ語', content: 'รายละเอียด' },
}

const radioPaddingHorizontal = 12

const formSpace = 24

function toImageParams(state: {
  selectedImage: string | null
  remove: boolean
  imageId?: string | null
}): {
  image?: string | null
  image_id?: string | null
  image_destroy?: boolean
} {
  return {
    ...(state.remove
      ? { image: null, image_id: state.imageId, image_destroy: true }
      : state.selectedImage != null
        ? { image: state.selectedImage, image_id: state.imageId }
        : {}),
  }
}

function useImageState() {
  const [state, setState] = useFormState<{
    selectedImage: string | null
    remove: boolean
    imageId?: string | null
  }>({
    selectedImage: null,
    remove: false,
  })

  return [state, setState] as const
}

function AvailabilityRadio({
  value,
  onChange,
}: {
  value: string
  onChange: (s: string) => void
}) {
  return (
    <RadioGroup onChange={onChange} value={value}>
      <Radio
        radioLabel={<RadioLabel value="あり" />}
        value="available"
        style={{
          paddingHorizontal: radioPaddingHorizontal,
          paddingLeft: 0,
        }}
      />
      <Radio
        radioLabel={<RadioLabel value="なし" />}
        value="not_available"
        style={{ paddingHorizontal: radioPaddingHorizontal }}
      />
    </RadioGroup>
  )
}

function SmokingStatusRadio({
  value,
  onChange,
}: {
  value: RestaurantSmokingStatus
  onChange: (s: RestaurantSmokingStatus) => void
}) {
  return (
    <RadioGroup onChange={onChange} value={value}>
      <Radio
        radioLabel={<RadioLabel value={t('禁煙')} />}
        value="no_smoking"
        style={{
          paddingHorizontal: radioPaddingHorizontal,
          paddingLeft: 0,
        }}
      />
      <Radio
        radioLabel={<RadioLabel value={t('喫煙')} />}
        value="smoking"
        style={{ paddingHorizontal: radioPaddingHorizontal }}
      />
      <Radio
        radioLabel={<RadioLabel value={t('分煙')} />}
        value="separating_smoking"
        style={{ paddingHorizontal: radioPaddingHorizontal }}
      />
    </RadioGroup>
  )
}

type InformationChange = {
  currentName?: string
  changedName: string
}

function ShopNameForm({
  formLabel,
  value,
  placeholder,
  informationChange,
  onChangeText,
}: {
  formLabel: string
  value: string
  placeholder: string
  informationChange?: InformationChange
  onChangeText: (text: string) => void
}) {
  const { width, sm } = useResponsive()

  return (
    <FormGroup
      style={{
        marginTop: 24,
      }}
      formLabel={<FormLabel value={formLabel} />}
      mode={width >= sm ? 'inline' : 'vertical'}
      containerStyle={{
        marginTop: width >= sm ? 8 : 0,
      }}
    >
      <TextInput
        value={value}
        placeholder={placeholder}
        onChangeText={onChangeText}
        disabled={informationChange != null}
      />
      {informationChange && (
        <Text
          style={{
            fontSize: 12,
            color: Colors.secondaryBlack,
            marginTop: 8,
          }}
        >
          「{informationChange.currentName}
          」から「
          {informationChange.changedName}
          」への変更をリクエスト中
        </Text>
      )}
    </FormGroup>
  )
}

function SelectOptionBox({
  name,
  onPressDelete,
}: {
  name: string | null
  onPressDelete: () => void
}) {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center', height: 60 }}>
      <Text style={{ flex: 1, marginRight: 24 }}>{name}</Text>

      <TouchableOpacity
        style={[{ marginLeft: 20 }]}
        onPress={() => onPressDelete()}
      >
        <FontAwesomeIcon
          icon={faTrashAlt}
          size={24}
          color={Colors.secondaryBlack}
        />
      </TouchableOpacity>
    </View>
  )
}

function URLWithPrefixForm({
  formLabel,
  prefix,
  value,
  formGroupStyle,
  onChangeText,
}: {
  formLabel: string
  prefix: string
  value: string
  formGroupStyle?: StyleProp<ViewStyle>
  onChangeText: (text: string) => void
}) {
  const { width, md } = useResponsive()

  return (
    <FormGroup
      formLabel={<FormLabel value={formLabel} />}
      mode={width >= md ? 'inline' : 'vertical'}
      style={formGroupStyle}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          columnGap: 4,
        }}
      >
        <Text style={{ overflowWrap: 'anywhere' }}>{prefix}</Text>
        <TextInput
          value={value}
          onChangeText={onChangeText}
          style={{ flex: 1, minWidth: 150 }}
        />
      </View>
    </FormGroup>
  )
}

function getYoutubeVideoId(restaurant: Restaurant) {
  // 将来的に複数持つ可能性はあるが、現状は1つだけしか持たない
  const introductionVideo = restaurant.restaurant_introduction_videos?.[0]
  if (introductionVideo == null) {
    return null
  }

  const introcuctionVideoUrl = new URL(introductionVideo.video_url)
  return introcuctionVideoUrl.searchParams.get('v')
}

export default function RestaurantInformationForm({
  isApp,
  restaurant,
  sortedLocales,
  michelinAwards,
  tabelogAwards,
  openAt,
  openDateSelectInput,
  isAddingAtmosphereImage,
  isAddingMealImage,
  onChangeRequest,
  onMichelinAwardAddRequest,
  onTabelogAwardAddRequest,
  onUpdate,
  onCreateImage,
  onDestroyImage,
  createTranslation,
}: {
  isApp: boolean
  restaurant?: Restaurant
  sortedLocales: SupportedLocale[]
  michelinAwards?: RestaurantMichelinAwardStatus[]
  tabelogAwards?: RestaurantTabelogAwardStatus[]
  openAt?: string
  openDateSelectInput: React.ReactNode
  isAddingAtmosphereImage: boolean
  isAddingMealImage: boolean
  onChangeRequest: ({
    informationChangeRequestParams,
  }: {
    informationChangeRequestParams: {
      changed_name?: string
      changed_phone_number?: string
      changed_address?: string
      changed_open_at?: string
    }
  }) => Promise<void>
  onMichelinAwardAddRequest: ({
    awards,
  }: {
    awards: Omit<RestaurantMichelinAwardStatus, 'status'>[]
  }) => Promise<void>
  onTabelogAwardAddRequest: (
    awards: Omit<RestaurantTabelogAwardStatus, 'status'>[]
  ) => Promise<void>
  onUpdate: ({ params }: { params: UpdateRestaurantParams }) => Promise<void>
  onCreateImage: (params: {
    image: string
    category: 'meal' | 'atmosphere'
  }) => void
  onDestroyImage: (params: { restaurantImageId: number }) => void
  createTranslation: (params: {
    text: string
    source_lang: string
    target_langs: string[]
  }) => Promise<{
    data?: {
      translations: Record<SupportedLocale, string>
    }
    error?: unknown
  }>
}) {
  const { width, md, sm } = useResponsive()
  const facility = restaurant?.facility
  const description = restaurant?.restaurant_description
  const restaurant_reservation_info = restaurant?.restaurant_reservation_info
  const foodDescription = restaurant?.restaurant_food_description
  const alertRef = useRef<AlertMethods>(null)

  // 基本情報

  const {
    state: name,
    setState: setName,
    defaultValues: defaultName,
  } = useLocaleFormState('name_i18n_', restaurant)

  const [yomi, setYomi] = useFormState(restaurant?.yomi ?? '')
  const changedNames = useMemo(() => {
    const names: {
      changed_name?: string
      changed_yomi?: string
      changed_name_en?: string
      changed_name_ko?: string
      changed_name_zh_cn?: string
      changed_name_zh_tw?: string
      changed_name_zh_hk?: string
    } = {}
    if (name.ja !== restaurant?.name) {
      names.changed_name = name.ja
    }
    if (yomi !== restaurant?.yomi) {
      names.changed_yomi = yomi
    }
    if (name.en !== restaurant?.name_i18n_en) {
      names.changed_name_en = name.en
    }
    if (name.ko !== restaurant?.name_i18n_ko) {
      names.changed_name_ko = name.ko
    }
    if (name['zh-CN'] !== restaurant?.name_i18n_zh_cn) {
      names.changed_name_zh_cn = name['zh-CN']
    }
    if (name['zh-TW'] !== restaurant?.name_i18n_zh_tw) {
      names.changed_name_zh_tw = name['zh-TW']
    }
    if (name['zh-HK'] !== restaurant?.name_i18n_zh_hk) {
      names.changed_name_zh_hk = name['zh-HK']
    }
    return names
  }, [
    name,
    restaurant?.name,
    restaurant?.name_i18n_en,
    restaurant?.name_i18n_ko,
    restaurant?.name_i18n_zh_cn,
    restaurant?.name_i18n_zh_hk,
    restaurant?.name_i18n_zh_tw,
    restaurant?.yomi,
    yomi,
  ])

  const informationChange = useMemo(():
    | ({
        [key in SupportedLocale]?: InformationChange
      } & { yomi?: InformationChange })
    | undefined => {
    if (restaurant?.requested_information_change_request == null) {
      return
    }

    const params: {
      [key in SupportedLocale]?: InformationChange
    } & { yomi?: InformationChange } = {}
    if (restaurant.requested_information_change_request.changed_name != null) {
      params.ja = {
        currentName: restaurant.requested_information_change_request.name,
        changedName:
          restaurant.requested_information_change_request.changed_name,
      }
    }
    if (restaurant.requested_information_change_request.changed_yomi != null) {
      params.yomi = {
        currentName: restaurant.yomi,
        changedName:
          restaurant.requested_information_change_request.changed_yomi,
      }
    }
    if (
      restaurant.requested_information_change_request.changed_name_en != null
    ) {
      params.en = {
        currentName: restaurant.name_i18n_en,
        changedName:
          restaurant.requested_information_change_request.changed_name_en,
      }
    }
    if (
      restaurant.requested_information_change_request.changed_name_ko != null
    ) {
      params.ko = {
        currentName: restaurant.name_i18n_ko,
        changedName:
          restaurant.requested_information_change_request.changed_name_ko,
      }
    }
    if (
      restaurant.requested_information_change_request.changed_name_zh_cn != null
    ) {
      params['zh-CN'] = {
        currentName: restaurant.name_i18n_zh_cn,
        changedName:
          restaurant.requested_information_change_request.changed_name_zh_cn,
      }
    }
    if (
      restaurant.requested_information_change_request.changed_name_zh_tw != null
    ) {
      params['zh-TW'] = {
        currentName: restaurant.name_i18n_zh_tw,
        changedName:
          restaurant.requested_information_change_request.changed_name_zh_tw,
      }
    }
    if (
      restaurant.requested_information_change_request.changed_name_zh_hk != null
    ) {
      params['zh-HK'] = {
        currentName: restaurant.name_i18n_zh_hk,
        changedName:
          restaurant.requested_information_change_request.changed_name_zh_hk,
      }
    }
    return params
  }, [restaurant])

  const [phoneNumber, setPhoneNumber] = useFormState(
    restaurant?.national_phone_number ?? ''
  )
  const [address, setAddress] = useFormState(restaurant?.address ?? '')

  // 概要

  const initialYoutubeVideoId =
    restaurant != null ? getYoutubeVideoId(restaurant) ?? '' : ''

  const [youtubeVideoId, setYoutubeVideoId] = useFormState(
    initialYoutubeVideoId
  )

  const [
    isDescriptionTranslationModalOpen,
    setIsDescriptionTranslationModalOpen,
  ] = useState(false)

  const defaultDescriptionTitle = useMemo(
    () => ({
      ja: description?.title_i18n_ja ?? '',
      en: description?.title_i18n_en ?? '',
      ko: description?.title_i18n_ko ?? '',
      'zh-CN': description?.title_i18n_zh_cn ?? '',
      'zh-TW': description?.title_i18n_zh_tw ?? '',
      'zh-HK': description?.title_i18n_zh_hk ?? '',
      th: description?.title_i18n_th ?? '',
    }),
    [
      description?.title_i18n_en,
      description?.title_i18n_ja,
      description?.title_i18n_ko,
      description?.title_i18n_th,
      description?.title_i18n_zh_cn,
      description?.title_i18n_zh_hk,
      description?.title_i18n_zh_tw,
    ]
  )

  const [descriptionTitle, setDescriptionTitle] = useFormState<LocaleValue>(
    defaultDescriptionTitle
  )

  const defaultDescriptionContent = useMemo(
    () => ({
      ja: description?.content_i18n_ja ?? '',
      en: description?.content_i18n_en ?? '',
      ko: description?.content_i18n_ko ?? '',
      'zh-CN': description?.content_i18n_zh_cn ?? '',
      'zh-TW': description?.content_i18n_zh_tw ?? '',
      'zh-HK': description?.content_i18n_zh_hk ?? '',
      th: description?.content_i18n_th ?? '',
    }),
    [
      description?.content_i18n_en,
      description?.content_i18n_ja,
      description?.content_i18n_ko,
      description?.content_i18n_th,
      description?.content_i18n_zh_cn,
      description?.content_i18n_zh_hk,
      description?.content_i18n_zh_tw,
    ]
  )

  const [descriptionContent, setDescriptionContent] = useFormState<LocaleValue>(
    defaultDescriptionContent
  )

  const defaultDescriptionValues = useMemo(
    () =>
      Object.fromEntries(
        supportedLocales.map((locale) => [
          locale,
          [defaultDescriptionTitle[locale], defaultDescriptionContent[locale]]
            .filter(Boolean)
            .join('\n'),
        ])
      ) as Record<SupportedLocale, string>,
    [defaultDescriptionTitle, defaultDescriptionContent]
  )

  const descriptionValues = useMemo(
    () =>
      Object.fromEntries(
        supportedLocales.map((locale) => [
          locale,
          [descriptionTitle[locale], descriptionContent[locale]]
            .filter(Boolean)
            .join('\n'),
        ])
      ) as Record<SupportedLocale, string>,
    [descriptionTitle, descriptionContent]
  )

  // 海外言語がある場合は開いておく
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(
    Boolean(description?.content_i18n_en)
  )

  // 営業状況
  const [openStatus, setOpenStatus] = useFormState(restaurant?.open_status)

  // SNS
  const [instagramUsername, setInstagramUsername] = useFormState(
    restaurant?.instagram_username ?? ''
  )
  const [twitterUsername, setTwitterUsername] = useFormState(
    restaurant?.twitter_username ?? ''
  )
  const [facebookUsername, setFacebookUsername] = useFormState(
    restaurant?.facebook_username ?? ''
  )
  const [hpUrl, setHpUrl] = useFormState(restaurant?.hp_url ?? '')

  // 設備
  const [parkingStatus, setParkingStatus] = useFormState(
    facility?.parking_status ?? ''
  )
  const [parkingRemarks, setParkingRemarks] = useFormState(
    facility?.parking_remarks ?? ''
  )
  const [barrierFreeStatus, setBarrierFreeStatus] = useFormState(
    facility?.barrier_free_status ?? ''
  )
  const [barrierFreeRemarks, setBarrierFreeRemarks] = useFormState(
    facility?.barrier_free_remarks ?? ''
  )
  const [wifiStatus, setWifiStatus] = useFormState(facility?.wifi_status ?? '')
  const [powerStatus, setPowerStatus] = useFormState(
    facility?.power_status ?? ''
  )
  const [washletStatus, setWashletStatus] = useFormState(
    facility?.washlet_status ?? ''
  )

  const [smokingStatus, setSmokingStatus] = useFormState(
    restaurant?.smoking_status ?? ''
  )

  // 予約時のお知らせ

  const defaultReservationInfo = useMemo(
    () => ({
      ja: restaurant_reservation_info?.content_i18n_ja ?? '',
      en: restaurant_reservation_info?.content_i18n_en ?? '',
      ko: restaurant_reservation_info?.content_i18n_ko ?? '',
      'zh-CN': restaurant_reservation_info?.content_i18n_zh_cn ?? '',
      'zh-TW': restaurant_reservation_info?.content_i18n_zh_tw ?? '',
      'zh-HK': restaurant_reservation_info?.content_i18n_zh_hk ?? '',
      th: restaurant_reservation_info?.content_i18n_th ?? '',
    }),
    [
      restaurant_reservation_info?.content_i18n_en,
      restaurant_reservation_info?.content_i18n_ja,
      restaurant_reservation_info?.content_i18n_ko,
      restaurant_reservation_info?.content_i18n_th,
      restaurant_reservation_info?.content_i18n_zh_cn,
      restaurant_reservation_info?.content_i18n_zh_hk,
      restaurant_reservation_info?.content_i18n_zh_tw,
    ]
  )
  const [reservationInfo, setReservationInfo] = useFormState<LocaleValue>(
    defaultReservationInfo
  )

  const [
    isRecommendedProductCandidatesSearchModalOpen,
    setIsRecommendedProductCandidatesSearchModalOpen,
  ] = useState(false)

  const [foodDescriptionImageState, setFoodDescriptionImageState] =
    useImageState()

  const {
    state: foodDescriptionContent,
    setState: setFoodDescriptionContent,
    defaultValues: defaultFoodDescriptionContent,
  } = useLocaleFormState('content_', foodDescription)

  const [
    restaurantAtmosphereDescriptionImageState,
    setRestaurantAtmosphereDescriptionImageState,
  ] = useImageState()

  const {
    state: restaurantAtmosphereDescriptionContent,
    setState: setRestaurantAtmosphereDescriptionContent,
    defaultValues: defaultRestaurantAtmosphereDescriptionContent,
  } = useLocaleFormState(
    'content_',
    restaurant?.restaurant_atmosphere_description
  )

  const [restaurantHistoryImageState, setRestaurantHistoryImageState] =
    useImageState()

  const {
    state: restaurantHistoryDescriptionContent,
    setState: setRestaurantHistoryDescriptionContent,
    defaultValues: defaultRestaurantHistoryDescriptionContent,
  } = useLocaleFormState('content_', restaurant?.restaurant_history_description)

  const [
    restaurantChefDescriptionImageState,
    setRestaurantChefDescriptionImageState,
  ] = useImageState()

  const {
    state: restaurantChefDescriptionChefName,
    setState: setRestaurantChefDescriptionChefName,
    defaultValues: defaultRestaurantChefDescriptionChefName,
  } = useLocaleFormState('chef_name_', restaurant?.restaurant_chef_description)

  const {
    state: restaurantChefDescriptionContent,
    setState: setRestaurantChefDescriptionContent,
    defaultValues: defaultRestaurantChefDescriptionContent,
  } = useLocaleFormState('content_', restaurant?.restaurant_chef_description)

  const [isBasicInfoSubmitting, setIsBasicInfoSubmitting] = useState(false)
  const [isNewsSubmitting, setIsNewsSubmitting] = useState(false)
  const [isAboutSubmitting, setIsAboutSubmitting] = useState(false)
  const [isStatusSubmitting, setIsStatusSubmitting] = useState(false)
  const [isFacilitySubmitting, setIsFacilitySubmitting] = useState(false)
  const [isLinkSubmitting, setIsLinkSubmitting] = useState(false)
  const [isRestaurantSubmitting, setIsRestaurantSubmitting] = useState(false)

  const [restaurantRecommendedProducts, setRestaurantRecommendedProducts] =
    useFormState(
      (restaurant?.restaurant_recommended_products
        ?.map((value) => {
          if (value.table_product != null) {
            return {
              table_product_id: value.table_product.id,
              name: value.table_product.name,
            }
          }
          if (value.restaurant_course != null) {
            return {
              restaurant_course_id: value.restaurant_course.id,
              name: value.restaurant_course.title,
            }
          }
          return null
        })
        .filter((value) => value) ?? []) as Array<
        | {
            table_product_id: string
            name: string
          }
        | {
            restaurant_course_id: number
            name: string
          }
      >
    )

  const changeRequestDisabled =
    Object.entries(changedNames).every(([, value]) => value === '') &&
    phoneNumber === restaurant?.national_phone_number &&
    address === restaurant?.address &&
    openAt === restaurant?.open_at

  const alertInformationChangeRequest = () => {
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })
    alert(t('本当に変更をリクエストしますか？'), '', [
      {
        text: 'いいえ',
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: 'はい',
        onPress: async () => {
          setIsBasicInfoSubmitting(true)
          if (hasIncompleteChanges(name, defaultName)) {
            if (!(await confirmSaveIncompleteChangesAsync(alert))) {
              setIsBasicInfoSubmitting(false)
              return
            }
          }

          const informationChangeRequestParams: {
            changed_name?: string
            changed_yomi?: string
            changed_name_en?: string
            changed_name_ko?: string
            changed_name_zh_cn?: string
            changed_name_zh_tw?: string
            changed_name_zh_hk?: string
            changed_phone_number?: string
            changed_address?: string
            changed_open_at?: string
          } = { ...changedNames }
          if (phoneNumber !== restaurant?.national_phone_number) {
            informationChangeRequestParams.changed_phone_number = phoneNumber
          }
          if (address !== restaurant?.address) {
            informationChangeRequestParams.changed_address = address
          }
          if (openAt !== restaurant?.open_at) {
            informationChangeRequestParams.changed_open_at = openAt
          }
          onChangeRequest({ informationChangeRequestParams }).finally(() => {
            setIsBasicInfoSubmitting(false)
          })
        },
      },
    ])
  }

  // お店についてのセクションの保存ボタン
  const onPressSaveRestaurantDescriptions = async () => {
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })
    setIsRestaurantSubmitting(true)
    const warnIncompleteChanges = [
      [foodDescriptionContent, defaultFoodDescriptionContent],
      [
        restaurantAtmosphereDescriptionContent,
        defaultRestaurantAtmosphereDescriptionContent,
      ],
      [
        restaurantHistoryDescriptionContent,
        defaultRestaurantHistoryDescriptionContent,
      ],
      [
        restaurantChefDescriptionChefName,
        defaultRestaurantChefDescriptionChefName,
      ],
      [
        restaurantChefDescriptionContent,
        defaultRestaurantChefDescriptionContent,
      ],
    ].some(([content, defaultContent]) => {
      return (
        // check for partial changes and warn it on update only
        Object.values(defaultContent).some((value) => (value ?? '') !== '') &&
        hasIncompleteChanges(content, defaultContent)
      )
    })

    if (
      warnIncompleteChanges &&
      !(await confirmSaveIncompleteChangesAsync(alert))
    ) {
      setIsRestaurantSubmitting(false)
      return
    }

    onUpdate({
      params: {
        restaurant_food_description: _omitBy(
          {
            id: restaurant?.restaurant_food_description?.id,
            ...toImageParams(foodDescriptionImageState),
            ...toLocaleParams('content_', foodDescriptionContent),
          },
          _isUndefined
        ),
        restaurant_atmosphere_description: _omitBy(
          {
            id: restaurant?.restaurant_atmosphere_description?.id,
            ...toImageParams(restaurantAtmosphereDescriptionImageState),
            ...toLocaleParams(
              'content_',
              restaurantAtmosphereDescriptionContent
            ),
          },
          _isUndefined
        ),
        restaurant_history_description: _omitBy(
          {
            id: restaurant?.restaurant_history_description?.id,
            ...toImageParams(restaurantHistoryImageState),
            ...toLocaleParams('content_', restaurantHistoryDescriptionContent),
          },
          _isUndefined
        ),
        restaurant_chef_description: _omitBy(
          {
            id: restaurant?.restaurant_chef_description?.id,
            ...toImageParams(restaurantChefDescriptionImageState),
            ...toLocaleParams('chef_name_', restaurantChefDescriptionChefName),
            ...toLocaleParams('content_', restaurantChefDescriptionContent),
          },
          _isUndefined
        ),
        restaurant_recommended_products: restaurantRecommendedProducts,
      },
    }).finally(() => {
      setIsRestaurantSubmitting(false)
    })
  }

  if (restaurant == null) {
    return null
  }

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: Colors.bgBlack }}
        contentContainerStyle={{
          padding: 40,
        }}
      >
        <View>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>基本情報</Text>
          <ShadowBox style={{ marginTop: 24 }}>
            <TranslationFormList
              formLabel={<FormLabel value={t('店名')} required />}
              sortedLocales={sortedLocales}
              values={name}
              onChangeValues={setName}
              createTranslation={(params) => createTranslation(params)}
            >
              {({ locale }) => (
                <>
                  <ShopNameForm
                    formLabel={nameLocaleData[locale].label}
                    value={name[locale]}
                    placeholder={nameLocaleData[locale].label}
                    informationChange={informationChange?.[locale]}
                    onChangeText={(text) =>
                      setName((state) => ({ ...state, [locale]: text }))
                    }
                  />
                  {locale === 'ja' && (
                    <ShopNameForm
                      formLabel={'日本語 ヨミ'}
                      value={yomi}
                      placeholder={'カタカナ'}
                      informationChange={informationChange?.yomi}
                      onChangeText={(text) => setYomi(text)}
                    />
                  )}
                </>
              )}
            </TranslationFormList>
            <FormDivider
              style={{
                marginTop: 24,
              }}
            />
            <FormGroup
              formLabel={<FormLabel value="電話番号" required />}
              mode={width >= md ? 'inline' : 'vertical'}
              style={{ marginTop: 24 }}
            >
              <TextInput
                value={phoneNumber}
                onChangeText={(text) => setPhoneNumber(text)}
                disabled={
                  restaurant.requested_information_change_request !== null
                }
              />
              {restaurant.requested_information_change_request &&
                restaurant.requested_information_change_request
                  ?.national_changed_phone_number != null && (
                  <Text
                    style={{
                      fontSize: 12,
                      color: Colors.secondaryBlack,
                      marginTop: 8,
                    }}
                  >
                    「
                    {
                      restaurant.requested_information_change_request
                        .national_phone_number
                    }
                    」から「
                    {
                      restaurant.requested_information_change_request
                        .national_changed_phone_number
                    }
                    」への変更をリクエスト中
                  </Text>
                )}
            </FormGroup>
            <FormGroup
              formLabel={<FormLabel value="住所" required />}
              mode={width >= md ? 'inline' : 'vertical'}
              style={{ marginTop: 24 }}
            >
              <TextInput
                value={address}
                onChangeText={(text) => setAddress(text)}
                disabled={
                  restaurant.requested_information_change_request !== null
                }
              />
              {restaurant.requested_information_change_request &&
                restaurant.requested_information_change_request
                  ?.changed_address != null && (
                  <Text
                    style={{
                      fontSize: 12,
                      color: Colors.secondaryBlack,
                      marginTop: 8,
                    }}
                  >
                    「{restaurant.requested_information_change_request.address}
                    」から「
                    {
                      restaurant.requested_information_change_request
                        .changed_address
                    }
                    」への変更をリクエスト中
                  </Text>
                )}
            </FormGroup>
            <FormDivider style={{ marginTop: 24 }} />
            <FormGroup
              formLabel={<FormLabel value="オープン日" />}
              mode={width >= md ? 'inline' : 'vertical'}
              style={{ marginTop: 24 }}
            >
              {openDateSelectInput}
              {restaurant.requested_information_change_request &&
                restaurant.requested_information_change_request?.open_at !=
                  null && (
                  <Text
                    style={{
                      fontSize: 12,
                      color: Colors.secondaryBlack,
                      marginTop: 8,
                    }}
                  >
                    「{restaurant.requested_information_change_request.open_at}
                    」から「
                    {
                      restaurant.requested_information_change_request
                        .changed_open_at
                    }
                    」への変更をリクエスト中
                  </Text>
                )}
            </FormGroup>

            <Button
              mode="contained"
              height={44}
              style={[{ marginTop: 32, width: '100%' }]}
              onPress={() => alertInformationChangeRequest()}
              disabled={
                changeRequestDisabled ||
                restaurant.requested_information_change_request !== null ||
                isBasicInfoSubmitting
              }
              loading={isBasicInfoSubmitting}
            >
              {restaurant.requested_information_change_request
                ? '審査中'
                : '変更をリクエスト'}
            </Button>
          </ShadowBox>
        </View>

        <View style={{ marginTop: 40 }}>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>概要</Text>
          <ShadowBox style={{ marginTop: 24 }}>
            <URLWithPrefixForm
              formLabel={t('公式動画')}
              prefix="https://www.youtube.com/watch?v="
              value={youtubeVideoId}
              onChangeText={setYoutubeVideoId}
            />
            <FormGroup
              style={{ marginTop: 24 }}
              mode="vertical"
              formLabel={
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <FormLabel value="店舗の紹介" />
                  <TranslationButton
                    disabled={Object.values(descriptionValues).every(
                      (value) => value === ''
                    )}
                    onPress={() => setIsDescriptionTranslationModalOpen(true)}
                  />
                  <TranslationModal
                    isVisible={isDescriptionTranslationModalOpen}
                    onClose={() => setIsDescriptionTranslationModalOpen(false)}
                    initialLocale={sortedLocales[0]}
                    values={descriptionValues}
                    onTranslate={async ({ locale, locales }) => {
                      const {
                        data: descriptionTitleData,
                        error: descriptionTitleError,
                      } = await createTranslation({
                        text: descriptionTitle[locale as SupportedLocale],
                        source_lang: locale,
                        target_langs: locales,
                      })

                      const {
                        data: descriptionContentData,
                        error: descriptionContentError,
                      } = await createTranslation({
                        text: descriptionContent[locale as SupportedLocale],
                        source_lang: locale,
                        target_langs: locales,
                      })

                      if (descriptionTitleError != null) {
                        onError(descriptionTitleError)
                        return { error: descriptionTitleError }
                      }

                      if (descriptionContentError != null) {
                        onError(descriptionContentError)
                        return { error: descriptionContentError }
                      }

                      setDescriptionTitle((value) => ({
                        ...value,
                        ...descriptionTitleData!.translations,
                      }))

                      setDescriptionContent((value) => ({
                        ...value,
                        ...descriptionContentData!.translations,
                      }))

                      setIsDescriptionExpanded(true)
                      setIsDescriptionTranslationModalOpen(false)
                      return {}
                    }}
                  />
                </View>
              }
            >
              <>
                {supportedLocales
                  .slice(0, isDescriptionExpanded ? supportedLocales.length : 1)
                  .map((locale: SupportedLocale, index) => (
                    <React.Fragment key={locale}>
                      {index > 0 && (
                        <Divider
                          style={{
                            marginVertical: 24,
                          }}
                        />
                      )}
                      <FormGroup
                        formLabel={
                          <FormLabel
                            value={descriptionLocaleData[locale].label}
                          />
                        }
                        mode={width < sm ? 'vertical' : 'inline'}
                        containerStyle={{
                          marginTop: width < sm ? 8 : 0,
                        }}
                      >
                        <TextInput
                          placeholder={descriptionLocaleData[locale].title}
                          value={descriptionTitle[locale]}
                          onChangeText={(text) =>
                            setDescriptionTitle((state) => ({
                              ...state,
                              [locale]: text,
                            }))
                          }
                        />
                        <TextInput
                          placeholder={descriptionLocaleData[locale].content}
                          value={descriptionContent[locale]}
                          onChangeText={(text) =>
                            setDescriptionContent((state) => ({
                              ...state,
                              [locale]: text,
                            }))
                          }
                          multiline
                          style={{
                            marginTop: width < sm ? 8 : 24,
                            height: 120,
                          }}
                        />
                      </FormGroup>
                    </React.Fragment>
                  ))}
              </>
              <LanguageExpandToggleButton
                isExpanded={isDescriptionExpanded}
                onPress={() => {
                  setIsDescriptionExpanded((value) => !value)
                }}
                style={{ marginTop: 24 }}
              />
              <Button
                mode="contained"
                height={44}
                style={[{ marginTop: 32, width: '100%' }]}
                disabled={isAboutSubmitting}
                loading={isAboutSubmitting}
                onPress={async () => {
                  const alert = Platform.select({
                    web: alertRef.current?.alert,
                    default: Alert.alert,
                  })
                  setIsAboutSubmitting(true)
                  if (
                    hasIncompleteChanges(
                      descriptionValues,
                      defaultDescriptionValues
                    )
                  ) {
                    if (!(await confirmSaveIncompleteChangesAsync(alert))) {
                      setIsAboutSubmitting(false)
                      return
                    }
                  }
                  onUpdate({
                    params: {
                      description: supportedLocales
                        .map((supportedLocale) => {
                          const locale = supportedLocale
                            .toLowerCase()
                            .replace(/-/, '_')
                          return {
                            [`title_i18n_${locale}`]:
                              descriptionTitle[supportedLocale],
                            [`content_i18n_${locale}`]:
                              descriptionContent[supportedLocale],
                          }
                        })
                        .reduce((acc, item) => ({ ...acc, ...item }), {}),
                      restaurant_introduction_videos:
                        youtubeVideoId !== ''
                          ? [
                              {
                                video_url: `https://www.youtube.com/watch?v=${youtubeVideoId}`,
                              },
                            ]
                          : [],
                    },
                  }).finally(() => {
                    setIsAboutSubmitting(false)
                  })
                }}
              >
                保存する
              </Button>
            </FormGroup>
          </ShadowBox>
        </View>

        <View style={{ marginTop: 40 }}>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>お知らせ</Text>
          <ShadowBox style={{ marginTop: 24 }}>
            <TranslationFormList
              formLabel={<FormLabel value={t('店舗からのお知らせ')} />}
              sortedLocales={sortedLocales}
              values={reservationInfo}
              onChangeValues={setReservationInfo}
              createTranslation={(params) => createTranslation(params)}
            >
              {({ locale }) => (
                <TranslationFormItem
                  key={locale}
                  formLabel={
                    <FormLabel value={getTranslationLocaleLabel(locale)} />
                  }
                >
                  <TextInput
                    placeholder={reservationInfoLocaleData[locale].content}
                    value={reservationInfo[locale]}
                    onChangeText={(text) =>
                      setReservationInfo((state) => ({
                        ...state,
                        [locale]: text,
                      }))
                    }
                    multiline
                    style={{
                      height: 120,
                    }}
                  />
                </TranslationFormItem>
              )}
            </TranslationFormList>

            <Button
              mode="contained"
              height={44}
              style={[{ marginTop: 32, width: '100%' }]}
              disabled={isNewsSubmitting}
              loading={isNewsSubmitting}
              onPress={async () => {
                const alert = Platform.select({
                  web: alertRef.current?.alert,
                  default: Alert.alert,
                })
                setIsNewsSubmitting(true)
                if (
                  hasIncompleteChanges(reservationInfo, defaultReservationInfo)
                ) {
                  if (!(await confirmSaveIncompleteChangesAsync(alert))) {
                    setIsNewsSubmitting(false)
                    return
                  }
                }

                onUpdate({
                  params: {
                    reservation_info: supportedLocales
                      .map((supportedLocale) => {
                        const locale = supportedLocale
                          .toLowerCase()
                          .replace(/-/, '_')
                        return {
                          [`content_i18n_${locale}`]:
                            reservationInfo[supportedLocale],
                        }
                      })
                      .reduce((acc, item) => ({ ...acc, ...item }), {}),
                  },
                }).finally(() => {
                  setIsNewsSubmitting(false)
                })
              }}
            >
              保存する
            </Button>
          </ShadowBox>
        </View>

        {openStatus && (
          <View style={{ marginTop: 40 }}>
            <Text style={{ fontSize: 22, fontWeight: '600' }}>営業状況</Text>
            <ShadowBox style={{ marginTop: 24 }}>
              <FormGroup
                formLabel={
                  <FormLabel
                    value={getOpenStatusLabel(openStatus)}
                    labelStyle={{ color: getOpenStatusColor(openStatus) }}
                  />
                }
                mode={isApp || width < md ? 'vertical' : 'inline'}
                style={{ marginTop: 24 }}
              >
                {openStatus === 'open' ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <Button
                      mode={'outline'}
                      variant={'secondary'}
                      height={48}
                      style={[
                        {
                          flexGrow: 1,
                        },
                        { marginRight: 24 },
                      ]}
                      onPress={() => setOpenStatus('temporarily_closed')}
                    >
                      休業マークをつける
                    </Button>
                    <Button
                      mode={'outline'}
                      variant={'secondary'}
                      height={48}
                      style={{
                        flexGrow: 1,
                      }}
                      onPress={() => setOpenStatus('permanently_closed')}
                    >
                      閉店マークをつける
                    </Button>
                  </View>
                ) : (
                  <Button
                    mode={'outline'}
                    variant={'secondary'}
                    height={48}
                    style={{
                      flexGrow: 1,
                    }}
                    onPress={() => setOpenStatus('open')}
                  >
                    営業を再開する
                  </Button>
                )}
              </FormGroup>
              <Button
                mode="contained"
                height={44}
                style={[{ marginTop: 32, width: '100%' }]}
                disabled={isStatusSubmitting}
                loading={isStatusSubmitting}
                onPress={() => {
                  setIsStatusSubmitting(true)
                  onUpdate({
                    params: {
                      open_status: openStatus,
                    },
                  }).finally(() => {
                    setIsStatusSubmitting(false)
                  })
                }}
              >
                保存する
              </Button>
            </ShadowBox>
          </View>
        )}
        <View style={{ marginTop: 40 }}>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>受賞歴</Text>
          <ShadowBox style={{ marginTop: 24 }}>
            <Text style={{ fontWeight: '600' }}>ミシュラン</Text>
            <View
              style={{
                paddingBottom: 24,
                borderBottomWidth: 0.5,
                borderBottomColor: Colors.black20,
              }}
            >
              <MichelinList michelinAwards={michelinAwards || []} />
            </View>
            <MichelinAddForm
              requestMichelinAwards={(awards) =>
                onMichelinAwardAddRequest({ awards })
              }
            />
          </ShadowBox>
          <ShadowBox style={{ marginTop: 24 }}>
            <Text style={{ fontWeight: '600' }}>食べログ</Text>
            <View>
              <TabelogList tablelogAwards={tabelogAwards || []} />
            </View>
            <TabelogAddForm requestTabelogAwards={onTabelogAwardAddRequest} />
          </ShadowBox>
        </View>
        <View style={{ marginTop: 40 }}>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>公式写真</Text>
          <ShadowBox style={{ marginTop: 24 }}>
            <FormGroup
              formLabel={<FormLabel value="雰囲気" />}
              mode={width >= md ? 'inline' : 'vertical'}
              style={{ marginTop: 24 }}
            >
              <View
                style={{
                  flexWrap: 'wrap',
                  flex: 1,
                  flexDirection: 'row',
                  gap: 24,
                }}
              >
                {restaurant.atmosphere_images
                  ?.slice(0, 5)
                  .map((atmosphereImage) => (
                    <SelectedImage
                      source={{
                        uri: atmosphereImage.image.md,
                      }}
                      onRemovePress={() =>
                        onDestroyImage({
                          restaurantImageId: atmosphereImage.id,
                        })
                      }
                      key={atmosphereImage.id}
                    />
                  ))}
                {(restaurant.atmosphere_images?.length ?? 0) < 5 && (
                  <MultipleSelectImage
                    isLoading={isAddingAtmosphereImage}
                    onSelectImages={(images) =>
                      images.map((image) =>
                        onCreateImage({ image, category: 'atmosphere' })
                      )
                    }
                  />
                )}
              </View>
            </FormGroup>
            <FormGroup
              formLabel={<FormLabel value="料理" />}
              mode={width >= md ? 'inline' : 'vertical'}
              style={{ marginTop: 24 }}
            >
              <View
                style={{
                  flexWrap: 'wrap',
                  flex: 1,
                  flexDirection: 'row',
                  gap: 24,
                }}
              >
                {restaurant.meal_images?.slice(0, 5).map((mealImage) => (
                  <SelectedImage
                    source={{
                      uri: mealImage.image.md,
                    }}
                    onRemovePress={() =>
                      onDestroyImage({
                        restaurantImageId: mealImage.id,
                      })
                    }
                    key={mealImage.id}
                  />
                ))}
                {(restaurant.meal_images?.length ?? 0) < 5 && (
                  <MultipleSelectImage
                    isLoading={isAddingMealImage}
                    onSelectImages={(images) =>
                      images.map((image) =>
                        onCreateImage({ image, category: 'meal' })
                      )
                    }
                  />
                )}
              </View>
            </FormGroup>
          </ShadowBox>
        </View>
        <View style={{ marginTop: 40 }}>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>設備</Text>
          <ShadowBox style={{ marginTop: 24 }}>
            <FormGroup
              formLabel={<FormLabel value="駐車場" />}
              mode={width >= md ? 'inline' : 'vertical'}
            >
              <AvailabilityRadio
                value={parkingStatus}
                onChange={setParkingStatus}
              />
              <TextInput
                value={parkingRemarks}
                onChangeText={(text) => setParkingRemarks(text)}
                placeholder="備考"
                multiline
                style={{ height: 96, marginTop: 18 }}
              />
            </FormGroup>
            <FormGroup
              formLabel={<FormLabel value="バリアフリー" />}
              mode={width >= md ? 'inline' : 'vertical'}
              style={{ marginTop: 24 }}
            >
              <AvailabilityRadio
                value={barrierFreeStatus}
                onChange={setBarrierFreeStatus}
              />
              <TextInput
                value={barrierFreeRemarks}
                onChangeText={(text) => setBarrierFreeRemarks(text)}
                placeholder="備考"
                multiline
                style={{ height: 96, marginTop: 18 }}
              />
            </FormGroup>
            <FormGroup
              formLabel={<FormLabel value="Wi-Fi" />}
              mode={width >= md ? 'inline' : 'vertical'}
              style={{ marginTop: 24, marginBottom: 12 }}
            >
              <AvailabilityRadio value={wifiStatus} onChange={setWifiStatus} />
            </FormGroup>
            <FormGroup
              formLabel={<FormLabel value="電源" />}
              mode={width >= md ? 'inline' : 'vertical'}
              style={{ marginTop: 24, marginBottom: 12 }}
            >
              <AvailabilityRadio
                value={powerStatus}
                onChange={setPowerStatus}
              />
            </FormGroup>
            <FormGroup
              formLabel={<FormLabel value="ウォシュレット" />}
              mode={width >= md ? 'inline' : 'vertical'}
              style={{ marginVertical: 24 }}
            >
              <AvailabilityRadio
                value={washletStatus}
                onChange={setWashletStatus}
              />
            </FormGroup>
            <FormGroup
              formLabel={<FormLabel value="タバコ" />}
              mode={width >= md ? 'inline' : 'vertical'}
              style={{ marginVertical: 24 }}
            >
              <SmokingStatusRadio
                value={smokingStatus}
                onChange={setSmokingStatus}
              />
            </FormGroup>
            <Button
              mode="contained"
              height={44}
              style={[{ marginTop: 32, width: '100%' }]}
              disabled={isFacilitySubmitting}
              loading={isFacilitySubmitting}
              onPress={() => {
                setIsFacilitySubmitting(true)
                onUpdate({
                  params: {
                    facility: {
                      parking_status: parkingStatus,
                      parking_remarks: parkingRemarks,
                      barrier_free_status: barrierFreeStatus,
                      barrier_free_remarks: barrierFreeRemarks,
                      wifi_status: wifiStatus,
                      power_status: powerStatus,
                      washlet_status: washletStatus,
                    },
                    smoking_status: smokingStatus,
                  },
                }).finally(() => {
                  setIsFacilitySubmitting(false)
                })
              }}
            >
              保存する
            </Button>
          </ShadowBox>
        </View>
        <View style={{ marginTop: 40 }}>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>リンク</Text>
          <ShadowBox style={{ marginTop: 24 }}>
            <URLWithPrefixForm
              formLabel="Instagram"
              prefix="https://instagram.com/"
              value={instagramUsername}
              onChangeText={setInstagramUsername}
            />
            <URLWithPrefixForm
              formLabel="X"
              prefix="https://x.com/"
              value={twitterUsername}
              formGroupStyle={{ marginTop: 24 }}
              onChangeText={setTwitterUsername}
            />
            <URLWithPrefixForm
              formLabel="Facebook"
              prefix="https://facebook.com/"
              value={facebookUsername}
              formGroupStyle={{ marginTop: 24 }}
              onChangeText={setFacebookUsername}
            />
            <FormGroup
              formLabel={<FormLabel value="HP" />}
              mode={width >= md ? 'inline' : 'vertical'}
              style={{ marginTop: 24 }}
            >
              <TextInput
                value={hpUrl}
                onChangeText={(text) => setHpUrl(text)}
              />
            </FormGroup>
            <Button
              mode="contained"
              height={44}
              style={[{ marginTop: 32, width: '100%' }]}
              disabled={isLinkSubmitting}
              loading={isLinkSubmitting}
              onPress={() => {
                setIsLinkSubmitting(true)
                onUpdate({
                  params: {
                    instagram_username: instagramUsername,
                    twitter_username: twitterUsername,
                    facebook_username: facebookUsername,
                    hp_url: hpUrl,
                  },
                }).finally(() => {
                  setIsLinkSubmitting(false)
                })
              }}
            >
              保存する
            </Button>
          </ShadowBox>
        </View>
        <View style={{ marginTop: 40 }}>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>お店について</Text>
          <ShadowBox style={{ marginTop: 24 }}>
            <View>
              <Text
                style={{
                  fontSize: 22,
                  fontWeight: '600',
                }}
              >
                おすすめのメニュー
              </Text>
              <Text
                style={{
                  marginTop: 12,
                  fontSize: 14,
                }}
              >
                3つまで選択できます
              </Text>

              {restaurantRecommendedProducts.map((item, index) => {
                return (
                  <View
                    key={`${index}`}
                    style={{
                      marginTop: 24,
                    }}
                  >
                    <SelectOptionBox
                      name={item.name}
                      onPressDelete={() => {
                        setRestaurantRecommendedProducts((state) =>
                          state.filter((_, i) => i !== index)
                        )
                      }}
                    />
                  </View>
                )
              })}
              <Button
                mode="outline"
                height={48}
                style={{
                  marginTop: 24,
                }}
                onPress={() => {
                  setIsRecommendedProductCandidatesSearchModalOpen(true)
                }}
                disabled={restaurantRecommendedProducts.length >= 3}
              >
                {t('商品またはコースを選択')}
              </Button>
              {restaurant != null && (
                <RecommendedProductCandidatesSearchModal
                  isVisible={isRecommendedProductCandidatesSearchModalOpen}
                  onClose={() =>
                    setIsRecommendedProductCandidatesSearchModalOpen(false)
                  }
                  onPress={(value) => {
                    setRestaurantRecommendedProducts((state) => {
                      const alert = Platform.select({
                        web: alertRef.current?.alert,
                        default: Alert.alert,
                      })
                      if (value.table_product != null) {
                        if (
                          state.some((v) =>
                            'table_product_id' in v
                              ? v.table_product_id === value.table_product!.id
                              : false
                          )
                        ) {
                          alert(t('既に追加されています'))
                          return state
                        } else {
                          return [
                            ...state,
                            {
                              table_product_id: value.table_product.id,
                              name: value.name ?? '-',
                            },
                          ]
                        }
                      }
                      if (value.restaurant_course != null) {
                        if (
                          state.some((v) =>
                            'restaurant_course_id' in v
                              ? v.restaurant_course_id ===
                                value.restaurant_course!.id
                              : false
                          )
                        ) {
                          alert(t('既に追加されています'))
                          return state
                        } else {
                          return [
                            ...state,
                            {
                              restaurant_course_id: value.restaurant_course.id,
                              name: value.name ?? '-',
                            },
                          ]
                        }
                      }

                      return state
                    })
                  }}
                  restaurantId={restaurant?.id}
                />
              )}
            </View>
            <Divider
              style={{
                marginVertical: 24,
              }}
            />
            <View>
              <Text
                style={{
                  fontSize: 22,
                  fontWeight: '600',
                }}
              >
                お店の料理
              </Text>
              <FormGroup
                mode={width < sm ? 'vertical' : 'inline'}
                formLabel={
                  <View>
                    <FormLabel value={t('画像')} />
                    <Text
                      style={{
                        marginTop: 12,
                        fontSize: 14,
                      }}
                    >
                      {t('4:3の比率で表示します')}
                    </Text>
                  </View>
                }
                style={{ marginTop: formSpace }}
              >
                <View
                  style={[
                    { alignSelf: 'flex-start', flex: 1 },
                    width < sm && { marginTop: 16 },
                  ]}
                >
                  <View>
                    {(foodDescription?.image?.sm != null &&
                      !foodDescriptionImageState.remove) ||
                    foodDescriptionImageState.selectedImage != null ? (
                      <View style={{ width: 180, height: 120 }}>
                        <SelectedImage
                          width={180}
                          height={120}
                          source={{
                            uri:
                              foodDescriptionImageState.selectedImage ??
                              foodDescription?.image?.sm,
                          }}
                          onRemovePress={() => {
                            setFoodDescriptionImageState({
                              selectedImage: null,
                              remove: true,
                              imageId: foodDescription?.image_id,
                            })
                          }}
                        />
                      </View>
                    ) : (
                      <SelectImage
                        width={180}
                        height={120}
                        onSelectImage={(image) => {
                          setFoodDescriptionImageState({
                            selectedImage: image,
                            remove: false,
                          })
                        }}
                      />
                    )}
                  </View>
                </View>
              </FormGroup>
              <View style={{ marginTop: 24 }}>
                <TranslationFormList
                  formLabel={<FormLabel value={t('説明')} />}
                  showsFormDescription
                  formDescriptionText={t('{{text}}字以内で入力してください', {
                    text: '160',
                  })}
                  sortedLocales={sortedLocales}
                  values={foodDescriptionContent}
                  onChangeValues={setFoodDescriptionContent}
                  createTranslation={(params) => createTranslation(params)}
                >
                  {({ locale }) => (
                    <TranslationFormItem
                      key={locale}
                      formLabel={
                        <FormLabel value={getTranslationLocaleLabel(locale)} />
                      }
                    >
                      <TextInput
                        placeholder={''}
                        value={foodDescriptionContent[locale]}
                        onChangeText={(text) =>
                          setFoodDescriptionContent((value) => ({
                            ...value,
                            [locale]: text,
                          }))
                        }
                        autoCapitalize="none"
                        multiline
                        style={{
                          height: 120,
                        }}
                      />
                    </TranslationFormItem>
                  )}
                </TranslationFormList>
              </View>
            </View>
            <Divider
              style={{
                marginVertical: 24,
              }}
            />
            <View>
              <Text
                style={{
                  fontSize: 22,
                  fontWeight: '600',
                }}
              >
                お店の雰囲気
              </Text>
              <FormGroup
                mode={width < sm ? 'vertical' : 'inline'}
                formLabel={
                  <View>
                    <FormLabel value={t('画像')} />
                    <Text
                      style={{
                        marginTop: 12,
                        fontSize: 14,
                      }}
                    >
                      {t('4:3の比率で表示します')}
                    </Text>
                  </View>
                }
                style={{ marginTop: formSpace }}
              >
                <View
                  style={[
                    { alignSelf: 'flex-start', flex: 1 },
                    width < sm && { marginTop: 16 },
                  ]}
                >
                  <View>
                    {(restaurant.restaurant_atmosphere_description?.image?.sm !=
                      null &&
                      !restaurantAtmosphereDescriptionImageState.remove) ||
                    restaurantAtmosphereDescriptionImageState.selectedImage !=
                      null ? (
                      <View style={{ width: 180, height: 120 }}>
                        <SelectedImage
                          width={180}
                          height={120}
                          source={{
                            uri:
                              restaurantAtmosphereDescriptionImageState.selectedImage ??
                              restaurant.restaurant_atmosphere_description
                                ?.image?.sm,
                          }}
                          onRemovePress={() => {
                            setRestaurantAtmosphereDescriptionImageState({
                              selectedImage: null,
                              remove: true,
                              imageId:
                                restaurant.restaurant_atmosphere_description
                                  ?.image_id,
                            })
                          }}
                        />
                      </View>
                    ) : (
                      <SelectImage
                        width={180}
                        height={120}
                        onSelectImage={(image) => {
                          setRestaurantAtmosphereDescriptionImageState({
                            selectedImage: image,
                            remove: false,
                          })
                        }}
                      />
                    )}
                  </View>
                </View>
              </FormGroup>
              <View style={{ marginTop: 24 }}>
                <TranslationFormList
                  formLabel={<FormLabel value={t('説明')} />}
                  showsFormDescription
                  formDescriptionText={t('{{text}}字以内で入力してください', {
                    text: '160',
                  })}
                  sortedLocales={sortedLocales}
                  values={restaurantAtmosphereDescriptionContent}
                  onChangeValues={setRestaurantAtmosphereDescriptionContent}
                  createTranslation={(params) => createTranslation(params)}
                >
                  {({ locale }) => (
                    <TranslationFormItem
                      key={locale}
                      formLabel={
                        <FormLabel value={getTranslationLocaleLabel(locale)} />
                      }
                    >
                      <TextInput
                        placeholder={''}
                        value={restaurantAtmosphereDescriptionContent[locale]}
                        onChangeText={(text) =>
                          setRestaurantAtmosphereDescriptionContent(
                            (value) => ({
                              ...value,
                              [locale]: text,
                            })
                          )
                        }
                        autoCapitalize="none"
                        multiline
                        style={{
                          height: 120,
                        }}
                      />
                    </TranslationFormItem>
                  )}
                </TranslationFormList>
              </View>
            </View>
            <Divider
              style={{
                marginVertical: 24,
              }}
            />
            <View>
              <Text
                style={{
                  fontSize: 22,
                  fontWeight: '600',
                }}
              >
                お店の歴史
              </Text>
              <FormGroup
                mode={width < sm ? 'vertical' : 'inline'}
                formLabel={
                  <View>
                    <FormLabel value={t('画像')} />
                    <Text
                      style={{
                        marginTop: 12,
                        fontSize: 14,
                      }}
                    >
                      {t('4:3の比率で表示します')}
                    </Text>
                  </View>
                }
                style={{ marginTop: formSpace }}
              >
                <View
                  style={[
                    { alignSelf: 'flex-start', flex: 1 },
                    width < sm && { marginTop: 16 },
                  ]}
                >
                  <View>
                    {(restaurant?.restaurant_history_description?.image?.sm !=
                      null &&
                      !restaurantHistoryImageState.remove) ||
                    restaurantHistoryImageState.selectedImage != null ? (
                      <View style={{ width: 180, height: 120 }}>
                        <SelectedImage
                          width={180}
                          height={120}
                          source={{
                            uri:
                              restaurantHistoryImageState.selectedImage ??
                              restaurant?.restaurant_history_description?.image
                                ?.sm,
                          }}
                          onRemovePress={() => {
                            setRestaurantHistoryImageState({
                              selectedImage: null,
                              remove: true,
                              imageId:
                                restaurant.restaurant_history_description
                                  ?.image_id,
                            })
                          }}
                        />
                      </View>
                    ) : (
                      <SelectImage
                        width={180}
                        height={120}
                        onSelectImage={(image) => {
                          setRestaurantHistoryImageState({
                            selectedImage: image,
                            remove: false,
                          })
                        }}
                      />
                    )}
                  </View>
                </View>
              </FormGroup>
              <View style={{ marginTop: 24 }}>
                <TranslationFormList
                  formLabel={<FormLabel value={t('説明')} />}
                  showsFormDescription
                  formDescriptionText={t('{{text}}字以内で入力してください', {
                    text: '160',
                  })}
                  sortedLocales={sortedLocales}
                  values={restaurantHistoryDescriptionContent}
                  onChangeValues={setRestaurantHistoryDescriptionContent}
                  createTranslation={(params) => createTranslation(params)}
                >
                  {({ locale }) => (
                    <TranslationFormItem
                      key={locale}
                      formLabel={
                        <FormLabel value={getTranslationLocaleLabel(locale)} />
                      }
                    >
                      <TextInput
                        placeholder={''}
                        value={restaurantHistoryDescriptionContent[locale]}
                        onChangeText={(text) =>
                          setRestaurantHistoryDescriptionContent((value) => ({
                            ...value,
                            [locale]: text,
                          }))
                        }
                        autoCapitalize="none"
                        multiline
                        style={{
                          height: 120,
                        }}
                      />
                    </TranslationFormItem>
                  )}
                </TranslationFormList>
              </View>
            </View>
            <Divider
              style={{
                marginVertical: 24,
              }}
            />
            <View>
              <Text
                style={{
                  fontSize: 22,
                  fontWeight: '600',
                }}
              >
                料理人
              </Text>
              <FormGroup
                mode={width < sm ? 'vertical' : 'inline'}
                formLabel={
                  <View>
                    <FormLabel value={t('画像')} />
                    <Text
                      style={{
                        marginTop: 12,
                        fontSize: 14,
                      }}
                    >
                      {t('1:1の比率で表示します')}
                    </Text>
                  </View>
                }
                style={{ marginTop: formSpace }}
              >
                <View
                  style={[
                    { alignSelf: 'flex-start', flex: 1 },
                    width < sm && { marginTop: 16 },
                  ]}
                >
                  <View>
                    {(restaurant?.restaurant_chef_description?.image?.sm !=
                      null &&
                      !restaurantChefDescriptionImageState.remove) ||
                    restaurantChefDescriptionImageState.selectedImage !=
                      null ? (
                      <View style={{ width: 120, height: 120 }}>
                        <SelectedImage
                          width={120}
                          height={120}
                          source={{
                            uri:
                              restaurantChefDescriptionImageState.selectedImage ??
                              restaurant?.restaurant_chef_description?.image
                                ?.sm,
                          }}
                          onRemovePress={() => {
                            setRestaurantChefDescriptionImageState({
                              selectedImage: null,
                              remove: true,
                              imageId:
                                restaurant.restaurant_chef_description
                                  ?.image_id,
                            })
                          }}
                        />
                      </View>
                    ) : (
                      <SelectImage
                        width={120}
                        height={120}
                        onSelectImage={(image) => {
                          setRestaurantChefDescriptionImageState({
                            selectedImage: image,
                            remove: false,
                          })
                        }}
                      />
                    )}
                  </View>
                </View>
              </FormGroup>
              <View style={{ marginTop: 24 }}>
                <TranslationFormList
                  formLabel={<FormLabel value={t('名前')} />}
                  sortedLocales={sortedLocales}
                  values={restaurantChefDescriptionChefName}
                  onChangeValues={setRestaurantChefDescriptionChefName}
                  createTranslation={(params) => createTranslation(params)}
                >
                  {({ locale }) => (
                    <TranslationFormItem
                      key={locale}
                      formLabel={
                        <FormLabel value={getTranslationLocaleLabel(locale)} />
                      }
                    >
                      <TextInput
                        placeholder={''}
                        value={restaurantChefDescriptionChefName[locale]}
                        onChangeText={(text) =>
                          setRestaurantChefDescriptionChefName((value) => ({
                            ...value,
                            [locale]: text,
                          }))
                        }
                        autoCapitalize="none"
                        multiline
                        style={{
                          height: 120,
                        }}
                      />
                    </TranslationFormItem>
                  )}
                </TranslationFormList>
              </View>
              <View style={{ marginTop: 24 }}>
                <TranslationFormList
                  formLabel={<FormLabel value={t('説明')} />}
                  showsFormDescription
                  formDescriptionText={t('{{text}}字以内で入力してください', {
                    text: '200',
                  })}
                  sortedLocales={sortedLocales}
                  values={restaurantChefDescriptionContent}
                  onChangeValues={setRestaurantChefDescriptionContent}
                  createTranslation={(params) => createTranslation(params)}
                >
                  {({ locale }) => (
                    <TranslationFormItem
                      key={locale}
                      formLabel={
                        <FormLabel value={getTranslationLocaleLabel(locale)} />
                      }
                    >
                      <TextInput
                        placeholder={''}
                        value={restaurantChefDescriptionContent[locale]}
                        onChangeText={(text) =>
                          setRestaurantChefDescriptionContent((value) => ({
                            ...value,
                            [locale]: text,
                          }))
                        }
                        autoCapitalize="none"
                        multiline
                        style={{
                          height: 120,
                        }}
                      />
                    </TranslationFormItem>
                  )}
                </TranslationFormList>
              </View>
            </View>
            <Button
              mode="contained"
              height={44}
              style={[{ marginTop: 32, width: '100%' }]}
              disabled={isRestaurantSubmitting}
              loading={isRestaurantSubmitting}
              onPress={onPressSaveRestaurantDescriptions}
            >
              保存する
            </Button>
          </ShadowBox>
        </View>
      </ScrollView>
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents="box-none"
      >
        <AlertProvider ref={alertRef} />
      </View>
    </>
  )
}
