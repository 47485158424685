declare global {
  interface Window {
    gtag: (...args: any[]) => void
    dataLayer: any[]
  }
}

type GAEventParams = {
  event: string
  step?: string
  [key: string]: any
}

export const sendGAEvent = (params: GAEventParams) => {
  if (typeof window === 'undefined' || window.gtag == null) {
    console.warn('gtag is not defined')
    return
  }

  window.gtag('event', params.event, {
    ...params,
    send_to: process.env.NEXT_PUBLIC_AR_GA4_ID,
  })
}
