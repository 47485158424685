import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair'
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink'
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends'
import { SafeAreaView, View, Text, StyleSheet, Platform } from 'react-native'

import { Alert, AlertMethods } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import {
  RestaurantReservation,
  useRestaurantReservation,
} from '../../models/RestaurantReservation'
import { useTableSeats } from '../../models/TableSeat'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { mutate, swrKey } from '../../modules/swr'
import { getFormatTime, toSeconds } from '../../modules/time'
import { useComponentSize } from '../../modules/useComponentSize'

import { getReservationText } from './Chart/utils'

type Props = {
  restaurantId: number
  editModeReservation: RestaurantReservation
  onEndEditMode: () => void
  handleUpdateReservation: (
    updatedReservation: RestaurantReservation
  ) => Promise<void>
  onUpdateEditModeReservation: (
    updatedReservation: RestaurantReservation
  ) => void
  isChangeSeatPage?: boolean
  isDisabled?: boolean
  onChangeHeight?: (height: number) => void
  alertRef: React.RefObject<AlertMethods>
}

type EditMode = 'none' | 'numberOfPeople' | 'addSeat' | 'changeSeat'

type ActionType =
  | 'detail' // 詳細を見る
  | 'changeNumberOfPeople' // 人数を変更する
  | 'editSeat' // 席を変更する
  | 'finalize' // 変更を確定する

function useEditAction(
  r: RestaurantReservation,
  before: RestaurantReservation | undefined,
  handleUpdateReservation: Props['handleUpdateReservation'],
  onUpdateEditModeReservation: Props['onUpdateEditModeReservation'],
  isChangeSeatPage: boolean,
  onEndEditMode: () => void,
  alertRef: React.RefObject<AlertMethods>
) {
  const token = useToken()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const [editMode, setEditMode] = useState<EditMode>('none')
  const [diffAdultPartySize, setDiffAdultPartySize] = useState(0)
  const [diffChildPartySize, setDiffChildPartySize] = useState(0)

  useEffect(() => {
    if (isChangeSeatPage) {
      setEditMode('changeSeat')
    }
  }, [isChangeSeatPage])

  const increaseAdults = () => {
    onUpdateEditModeReservation({
      ...r,
      party_size: r.party_size + 1,
      adult_party_size: r.adult_party_size + 1,
    })
    setDiffAdultPartySize((prev) => prev + 1)
  }

  const decreaseAdults = () => {
    onUpdateEditModeReservation({
      ...r,
      party_size: r.party_size === 0 ? 0 : r.party_size - 1,
      adult_party_size: r.adult_party_size === 0 ? 0 : r.adult_party_size - 1,
    })
    setDiffAdultPartySize((prev) => prev - 1)
  }

  const increaseChildren = () => {
    onUpdateEditModeReservation({
      ...r,
      party_size: r.party_size + 1,
      child_party_size: r.child_party_size + 1,
    })
    setDiffChildPartySize((prev) => prev + 1)
  }

  const decreaseChildren = () => {
    onUpdateEditModeReservation({
      ...r,
      party_size: r.party_size === 0 ? 0 : r.party_size - 1,
      child_party_size: r.child_party_size === 0 ? 0 : r.child_party_size - 1,
    })
    setDiffChildPartySize((prev) => prev - 1)
  }

  const resetPartySize = () => {
    onUpdateEditModeReservation({
      ...r,
      party_size: r.party_size - diffAdultPartySize - diffChildPartySize,
      adult_party_size: r.adult_party_size - diffAdultPartySize,
      child_party_size: r.child_party_size - diffChildPartySize,
    })
  }

  const resetEditMode = () => {
    setEditMode('none')
  }

  const editAction = async ({
    restaurantId,
    reservationId,
    actionType,
  }: {
    restaurantId: number
    reservationId: string
    actionType: ActionType
  }) => {
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })

    switch (actionType) {
      case 'detail':
        onEndEditMode()
        if (Platform.OS === 'web') {
          navigate(`/restaurants/${restaurantId}/reservations/${reservationId}`)
        } else {
          navigation.navigate('ReservationsShow', {
            restaurantReservationId: reservationId,
          })
        }
        break
      case 'changeNumberOfPeople':
        setEditMode('numberOfPeople')
        break
      case 'editSeat': {
        const isChangedPartySize =
          r.adult_party_size !== before?.adult_party_size ||
          r.child_party_size !== before?.child_party_size
        const isChangedTime =
          r.start_at !== before?.start_at || r.end_at !== before?.end_at

        const moveToChangeSeatPage = () => {
          const editedSeatIds = r.table_seats.map((seat) => seat.id)
          if (Platform.OS === 'web') {
            navigate(
              `/restaurants/${restaurantId}/reservations/${reservationId}/change_seat?editedSeatIds=${editedSeatIds.join(',')}`
            )
          } else {
            navigation.navigate('ChangeSeatForm', {
              restaurantReservationId: reservationId,
              editedSeatIds,
            })
          }
        }
        if (!isChangedPartySize && !isChangedTime) {
          onEndEditMode()
          moveToChangeSeatPage()
        } else {
          alert(
            t('予約人数や時間が変更されています'),
            t('予約時間、予約人数の変更を確定して席を変更しますか？'),
            [
              {
                text: t('いいえ'),
                onPress: () => {},
                style: 'cancel',
              },
              {
                text: t('はい'),
                onPress: () => {
                  handleUpdateReservation(r).then(() => {
                    mutate(
                      swrKey(
                        token,
                        `/reservation_book/restaurants/${restaurantId}/reservations/${reservationId}`
                      )
                    ).then(() => {
                      onEndEditMode()
                      moveToChangeSeatPage()
                    })
                  })
                },
              },
            ]
          )
        }
        break
      }
      case 'finalize':
        await handleUpdateReservation(r)
        break
      default: {
        const never: never = actionType
        throw new Error(`Invalid actionType: ${never}`)
      }
    }
  }

  return {
    editMode,
    editAction,
    increaseAdults,
    decreaseAdults,
    increaseChildren,
    decreaseChildren,
    resetPartySize,
    resetEditMode,
  }
}

export function EditReservationFloatCard({
  restaurantId,
  editModeReservation,
  onEndEditMode,
  handleUpdateReservation,
  onUpdateEditModeReservation,
  isChangeSeatPage = false,
  isDisabled = false,
  onChangeHeight,
  alertRef,
}: Props) {
  const [size, isReady, onLayout] = useComponentSize()
  const name = getReservationText(editModeReservation)
  const startAt = dayjs(editModeReservation.start_at)
  const startTime = getFormatTime(toSeconds(startAt.hour(), startAt.minute()))
  const endAt = dayjs(editModeReservation.end_at)
  const endTime = getFormatTime(toSeconds(endAt.hour(), endAt.minute()))
  const partySize = editModeReservation.party_size
  const childPartySize = editModeReservation.child_party_size
  const tableNames = [...editModeReservation.table_seats]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((seat) => seat.name)
    .join(',')

  const { width, md } = useResponsive()
  const isSp = width < md

  const { restaurantReservation } = useRestaurantReservation(
    restaurantId,
    editModeReservation.id
  )
  const { tableSeats } = useTableSeats(restaurantId, {})

  const {
    editMode,
    editAction,
    increaseAdults,
    decreaseAdults,
    increaseChildren,
    decreaseChildren,
    resetPartySize,
    resetEditMode,
  } = useEditAction(
    editModeReservation,
    restaurantReservation,
    handleUpdateReservation,
    onUpdateEditModeReservation,
    isChangeSeatPage,
    onEndEditMode,
    alertRef
  )

  // 何も編集されていない場合はtrueになる
  const isUnmodified = useMemo(() => {
    // 人数変更モードのときに、編集前の人数と編集中の人数が一致しているかどうかを判断する
    if (editMode === 'numberOfPeople') {
      return (
        restaurantReservation?.adult_party_size ===
          editModeReservation.adult_party_size &&
        restaurantReservation?.child_party_size ===
          editModeReservation.child_party_size
      )
    }

    // 席追加モードのときに、編集前の席と編集中の席が一致しているかどうかを判断する
    if (editMode === 'addSeat') {
      return (
        restaurantReservation?.table_seats.length ===
        editModeReservation.table_seats.length
      )
    }

    // 席変更モードのときに、編集前の席と編集中の席が一致しているかどうかを判断する
    if (editMode === 'changeSeat') {
      return (
        restaurantReservation?.table_seats.every((seat) =>
          editModeReservation.table_seats.some((s) => s.id === seat.id)
        ) &&
        editModeReservation.table_seats.every((seat) =>
          restaurantReservation?.table_seats.some((s) => s.id === seat.id)
        )
      )
    }

    // 通常モードのときに席の移動, 時間の移動がないかどうかを判断する
    if (editMode === 'none') {
      return (
        // 席の数が変更前後で同じで、変更前の席がすべて変更後に含まれる場合は変更がない
        restaurantReservation?.table_seats?.length ===
          editModeReservation.table_seats.length &&
        restaurantReservation?.table_seats.every((seat) =>
          editModeReservation.table_seats.some((s) => s.id === seat.id)
        ) &&
        editModeReservation.end_at === restaurantReservation?.end_at &&
        editModeReservation.start_at === restaurantReservation?.start_at
      )
    }

    return false
  }, [editMode, editModeReservation, restaurantReservation])

  const handleEditAction = useCallback(
    (actionType: ActionType) => {
      editAction({
        restaurantId,
        reservationId: editModeReservation.id,
        actionType,
      })
    },
    [editAction, restaurantId, editModeReservation.id]
  )

  useEffect(() => {
    if (isReady && size != null) {
      onChangeHeight?.(size.height)
    }
  }, [isReady, size, onChangeHeight])

  return (
    <View onLayout={onLayout}>
      {/* 人数変更モード */}
      {editMode === 'numberOfPeople' ? (
        <SafeAreaView style={isSp ? spStyle.root : style.root}>
          <View style={isSp ? spStyle.infoContainer : style.infoContainer}>
            <View style={isSp ? spStyle.row : style.row}>
              <Text style={isSp ? spStyle.name : style.name}>{name}</Text>
            </View>
            <View style={isSp ? spStyle.row : style.row}>
              <Text style={isSp ? spStyle.name : style.name}>{t('大人')}</Text>
              <View style={[isSp ? spStyle.row : style.row, { gap: 16 }]}>
                <TouchableOpacity
                  style={style.plusMinusIcon}
                  onPressMinInterval={0}
                  onPress={decreaseAdults}
                >
                  <FontAwesomeIcon
                    icon={faMinus}
                    size={24}
                    color={Colors.primary}
                  />
                </TouchableOpacity>
                <Text
                  style={
                    isSp ? spStyle.name : { fontSize: 14, fontWeight: 600 }
                  }
                >
                  {editModeReservation.adult_party_size}
                  <Text style={{ fontSize: 14, fontWeight: 300 }}>
                    {t('人')}
                  </Text>
                </Text>
                <TouchableOpacity
                  style={style.plusMinusIcon}
                  onPress={increaseAdults}
                  onPressMinInterval={0}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    size={24}
                    color={Colors.primary}
                  />
                </TouchableOpacity>
              </View>
            </View>
            <View style={isSp ? spStyle.row : style.row}>
              <Text style={isSp ? spStyle.name : style.name}>{t('子供')}</Text>
              <View style={[isSp ? spStyle.row : style.row, { gap: 16 }]}>
                <TouchableOpacity
                  style={style.plusMinusIcon}
                  onPress={decreaseChildren}
                  onPressMinInterval={0}
                >
                  <FontAwesomeIcon
                    icon={faMinus}
                    size={24}
                    color={Colors.primary}
                  />
                </TouchableOpacity>
                <Text
                  style={
                    isSp ? spStyle.name : { fontSize: 14, fontWeight: 600 }
                  }
                >
                  {editModeReservation.child_party_size}
                  <Text style={{ fontSize: 14, fontWeight: 300 }}>
                    {t('人')}
                  </Text>
                </Text>
                <TouchableOpacity
                  style={style.plusMinusIcon}
                  onPress={increaseChildren}
                  onPressMinInterval={0}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    size={24}
                    color={Colors.primary}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <View
            style={width < md ? spStyle.buttonContainer : style.buttonContainer}
          >
            <Button
              style={style.button}
              mode="outline"
              variant="primary"
              textStyle={{ color: Colors.primary }}
              onPress={() => {
                resetPartySize()
                resetEditMode()
              }}
            >
              {t('キャンセル')}
            </Button>
            <Button
              style={style.button}
              mode="contained"
              variant="primary"
              textStyle={{ color: Colors.white }}
              textBold
              onPress={() => {
                const alert = Platform.select({
                  web: alertRef.current?.alert,
                  default: Alert.alert,
                })
                if (editModeReservation.table_seats.length > 0) {
                  const maxPartySize = editModeReservation.table_seats.reduce(
                    (acc, seat) => {
                      const tableSeat = tableSeats.find((s) => s.id === seat.id)
                      return acc + (tableSeat?.max_party_size ?? 0)
                    },
                    0
                  )
                  if (editModeReservation.party_size > maxPartySize) {
                    alert(
                      t('予約人数が席の定員を超えています'),
                      t('変更してよろしいですか？'),
                      [
                        {
                          text: t('いいえ'),
                          onPress: () => {},
                          style: 'cancel',
                        },
                        {
                          text: t('はい'),
                          onPress: () => {
                            handleUpdateReservation(editModeReservation)
                          },
                        },
                      ]
                    )
                    return
                  }
                  handleUpdateReservation(editModeReservation)
                }
              }}
              disabled={isUnmodified || isDisabled}
            >
              {t('変更する')}
            </Button>
          </View>
        </SafeAreaView>
      ) : editMode === 'addSeat' || editMode === 'changeSeat' ? (
        // 席変更モード
        <SafeAreaView
          style={[isSp ? spStyle.root : style.root, { minHeight: 184 }]}
        >
          <View style={isSp ? spStyle.infoContainer : style.infoContainer}>
            <View style={isSp ? spStyle.row : style.row}>
              <Text style={isSp ? spStyle.name : style.name}>{name}</Text>
            </View>
            <View style={isSp ? spStyle.row : style.row}>
              <Text style={isSp ? spStyle.infoText : style.infoText}>
                {tableNames === '' ? t('なし') : tableNames}
              </Text>
            </View>
          </View>
          <View
            style={width < md ? spStyle.buttonContainer : style.buttonContainer}
          >
            <Button
              style={style.button}
              mode="outline"
              variant="primary"
              textStyle={{ color: Colors.primary }}
              onPress={onEndEditMode}
            >
              {t('キャンセル')}
            </Button>
            <Button
              style={style.button}
              mode="contained"
              variant="primary"
              textStyle={{ color: Colors.white }}
              textBold
              disabled={isUnmodified || isDisabled}
              onPress={() => {
                handleUpdateReservation(editModeReservation)
              }}
            >
              {t('変更する')}
            </Button>
          </View>
        </SafeAreaView>
      ) : (
        <SafeAreaView style={isSp ? spStyle.root : style.root}>
          {/* 通常モード */}
          <View style={isSp ? spStyle.infoContainer : style.infoContainer}>
            {/* 予約名を表示 */}
            <View style={isSp ? spStyle.row : style.row}>
              <Text style={isSp ? spStyle.name : style.name}>{name}</Text>
              <TouchableOpacity
                style={isSp ? spStyle.row : style.row}
                onPress={() => handleEditAction('detail')}
              >
                <FontAwesomeIcon
                  icon={faExternalLink}
                  size={14}
                  color={Colors.primary}
                />
                <Text style={[style.link, { fontSize: isSp ? 16 : 14 }]}>
                  {t('詳細を見る')}
                </Text>
              </TouchableOpacity>
            </View>

            {/* 時間を表示 */}
            <View style={isSp ? spStyle.row : style.row}>
              <View style={isSp ? spStyle.row : style.row}>
                <FontAwesomeIcon
                  icon={faClock}
                  size={18}
                  color={Colors.black50}
                />
                <Text
                  style={isSp ? spStyle.infoText : style.infoText}
                >{`${startTime} - ${endTime}`}</Text>
              </View>
            </View>

            {/* 人数を表示 */}
            <View style={isSp ? spStyle.row : style.row}>
              <View style={isSp ? spStyle.row : style.row}>
                <FontAwesomeIcon
                  icon={faUserFriends}
                  size={18}
                  color={Colors.black50}
                />
                <Text style={isSp ? spStyle.infoText : style.infoText}>
                  {`${partySize}${t('名様')}`}
                  {childPartySize > 0 &&
                    `${t('(お子様')}${childPartySize}${t('名)')}`}
                </Text>
              </View>
              <TouchableOpacity
                style={isSp ? spStyle.row : style.row}
                onPress={() => handleEditAction('changeNumberOfPeople')}
              >
                <Text style={style.link}>{t('人数を変更')}</Text>
              </TouchableOpacity>
            </View>

            {/* テーブルを表示 */}
            <View
              style={
                isSp
                  ? [spStyle.row, { alignItems: 'flex-start' }]
                  : [style.row, { alignItems: 'flex-start' }]
              }
            >
              <View
                style={[
                  isSp ? [spStyle.row, { flex: 1 }] : style.row,
                  { alignItems: 'flex-start' },
                ]}
              >
                <View style={{ minWidth: 18, paddingTop: 4 }}>
                  <FontAwesomeIcon
                    icon={faChair}
                    size={18}
                    color={Colors.black50}
                  />
                </View>
                <Text
                  numberOfLines={0}
                  style={
                    isSp ? [spStyle.infoText, { flex: 1 }] : style.infoText
                  }
                >
                  {tableNames === '' ? t('なし') : tableNames}
                </Text>
              </View>
              <TouchableOpacity
                style={[
                  isSp ? spStyle.row : style.row,
                  { minWidth: 80, justifyContent: 'flex-end' },
                ]}
                onPress={() => handleEditAction('editSeat')}
              >
                <Text style={style.link}>{t('席を変更')}</Text>
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={width < md ? spStyle.buttonContainer : style.buttonContainer}
          >
            <Button
              style={style.button}
              mode="outline"
              variant="primary"
              textStyle={{ color: Colors.primary }}
              onPress={onEndEditMode}
            >
              {t('キャンセル')}
            </Button>
            <Button
              style={style.button}
              mode="contained"
              variant="primary"
              textStyle={{ color: Colors.white }}
              textBold
              onPress={() => handleUpdateReservation(editModeReservation)}
              disabled={isUnmodified || isDisabled}
            >
              {t('変更する')}
            </Button>
          </View>
        </SafeAreaView>
      )}
    </View>
  )
}

const style = StyleSheet.create({
  root: {
    position: 'absolute',
    bottom: 32,
    right: 36,
    width: 320,
    minHeight: 224,
    borderRadius: 8,
    gap: 8,
    backgroundColor: Colors.white,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 16,
    elevation: 0,
  },
  infoContainer: {
    flex: 1,
    gap: 8,
    padding: 16,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8,
    flexShrink: 1,
  },
  name: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 24,
    color: Colors.black,
  },
  link: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 24,
    color: Colors.primary,
  },
  infoText: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 24,
    color: Colors.black,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  button: {
    flex: 1,
    height: 48,
  },
  plusMinusIcon: {
    width: 48,
    height: 48,
    borderRadius: 24,
    backgroundColor: '#AB935A1A',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const spStyle = StyleSheet.create({
  root: {
    minHeight: 250,
    gap: 8,
    backgroundColor: Colors.white,
    borderTopWidth: 0.5,
    borderTopColor: Colors.black16,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8,
  },
  infoContainer: {
    gap: 8,
    padding: 16,
  },
  name: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 27,
    color: Colors.black,
  },
  link: {
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 24,
    color: Colors.primary,
  },
  infoText: {
    fontSize: 18,
    fontWeight: 300,
    lineHeight: 24,
    color: Colors.black,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    paddingHorizontal: 16,
  },
  plusMinusIcon: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: '#AB935A1A',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
