import React, { useMemo, useRef } from 'react'

import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import produce from 'immer'
import { range, times } from 'lodash'
import { Platform, View } from 'react-native'

import { toDoubleDigits } from '@hello-ai/ar_shared/src//modules/number'
import {
  Alert,
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  Checkbox,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import { RadioIcon } from '@hello-ai/ar_shared/src/components/Radio'
import {
  SelectInput,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Wrap } from '@hello-ai/ar_shared/src/components/Wrap'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import {
  SupportedLocale,
  supportedLocales,
} from '@hello-ai/ar_shared/src/modules/locale'
import {
  getFormatTime,
  toHoursAndMinutes,
  toSeconds,
} from '@hello-ai/ar_shared/src/modules/time'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { useSafeAreaInsets } from '@hello-ai/ar_shared/src/modules/useSafeAreaInsets'
import { RestaurantBusinessTime as RestaurantBusinessTimeModel } from '@hello-ai/ar_shared/src/types/ForR/RestaurantBusinessTime'
import {
  RestaurantBusinessTimeReservationPeriodPolicySinceType,
  RestaurantBusinessTimeReservationPeriodPolicyUntilType,
} from '@hello-ai/ar_shared/src/types/ForR/RestaurantBusinessTimeReservationPeriodPolicy'
import { RestaurantBusinessTimeReservationTime } from '@hello-ai/ar_shared/src/types/ForR/RestaurantBusinessTimeReservationTime'

import { displayToastError } from '../Shared/Toast'
import {
  confirmSaveIncompleteChangesAsync,
  getTranslationLocaleLabel,
  hasIncompleteChanges,
  LocaleValue,
  TranslationFormItem,
  TranslationFormList,
} from '../Translation'

function CardWrap({
  children,
  title,
}: {
  children: React.ReactNode
  title: string
}) {
  return (
    <View style={{ gap: 16 }}>
      <Text style={{ fontSize: 22, fontWeight: '600' }}>{title}</Text>
      {children}
    </View>
  )
}

const formRadioWidth = 200

const maxEndHours = 30

const hours = times(maxEndHours, (i) => {
  return {
    label: toDoubleDigits(i),
    value: i,
  }
})

const mins = times(4, (i) => {
  const min = i * 15
  return {
    label: toDoubleDigits(min),
    value: min,
  }
})

const stayingHours = times(6, (i) => {
  return {
    label: t('{{text}}時間', {
      text: i,
    }),
    value: i,
  }
})

const stayingMins = times(4, (i) => {
  const min = i * 15
  return {
    label: t('{{text}}分', {
      text: min,
    }),
    value: min,
  }
})

const sinceMonthBeforeItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('ヶ月前'),
    value: undefined,
  },
  ...range(0, 36 + 1).map((value) => ({
    label: t('{{month}}ヶ月前', {
      month: value,
    }),
    value,
  })),
]

const sinceDayBeforeItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('日前'),
    value: undefined,
  },
  ...range(0, 30 + 1).map((value) => ({
    // 31日は1ヶ月で設定できるので、日付の最大は30日で足りる
    label: t('{{text}}日前', {
      text: value,
    }),
    value,
  })),
]

const sinceSpecificDayItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('日'),
    value: undefined,
  },
  ...range(0, 30 + 1).map((value) => ({
    // 31日は1ヶ月で設定できるので、日付の最大は30日で足りる
    label: t('{{text}}日', {
      text: value,
    }),
    value,
  })),
]

const sinceTimeItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('時'),
    value: undefined,
  },
  ...range(0, 23 + 1).flatMap((hours) =>
    range(0, 60, 15).map((mins) => ({
      label: getFormatTime(toSeconds(hours, mins)),
      value: toSeconds(hours, mins),
    }))
  ),
]

const sinceSpecificTimeItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('時'),
    value: undefined,
  },
  ...range(0, 23 + 1).flatMap((hours) =>
    range(0, 60, 15).map((mins) => ({
      label: getFormatTime(toSeconds(hours, mins)),
      value: toSeconds(hours, mins),
    }))
  ),
]

const untilDayBeforeItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('日前'),
    value: undefined,
  },
  ...range(0, 180 + 1).map((value) => {
    return value === 0
      ? { label: t('当日'), value }
      : value === 1
        ? { label: t('前日'), value }
        : {
            label: t('{{text}}日前', {
              text: value,
            }),
            value,
          }
  }),
]

function getTimeBeforeLabel(hours: number, mins: number) {
  let str = ''

  if (hours > 0) {
    str += t('{{text}}時間', {
      text: hours,
    })
  }
  if (mins > 0 || (hours === 0 && mins === 0)) {
    str += t('{{text}}分', {
      text: mins,
    })
  }
  str += t('前')
  return str
}

const untilTimeBeforeItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('時間前'),
    value: undefined,
  },
  ...range(0, 23 + 1).flatMap((hours) =>
    range(0, 60, 15).map((mins) => ({
      label: getTimeBeforeLabel(hours, mins),
      value: toSeconds(hours, mins),
    }))
  ),
]

const untilSpecificTimeItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('時'),
    value: undefined,
  },
  ...range(0, 23 + 1).flatMap((hours) =>
    range(0, 60, 15).map((mins) => ({
      label: getFormatTime(toSeconds(hours, mins)),
      value: toSeconds(hours, mins),
    }))
  ),
]

const reservationTimeItems: Array<SelectItem<number>> = [
  ...range(0, maxEndHours).flatMap((hours) =>
    range(0, 60, 15).map((mins) => ({
      label: getFormatTime(toSeconds(hours, mins)),
      value: toSeconds(hours, mins),
    }))
  ),
]

export function RestaurantBusinessTimeForm({
  restaurantBusinessTime,
  onCreateOrUpdate,
  onDestroy,
  children,
  sortedLocales,
  createTranslation,
}: {
  restaurantBusinessTime?: RestaurantBusinessTimeModel
  onCreateOrUpdate: (params: any) => Promise<void>
  onDestroy: () => void
  children?: React.ReactNode
  sortedLocales: SupportedLocale[]
  createTranslation: (params: {
    text: string
    source_lang: string
    target_langs: string[]
  }) => Promise<{
    data?: {
      translations: Record<SupportedLocale, string>
    }
    error?: unknown
  }>
}) {
  const { width, sm } = useResponsive()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const alertRef = useRef<AlertMethods>(null)
  const insets = useSafeAreaInsets()

  // 画面幅がsmブレークポイント未満かどうか
  const isSmallScreen = width < sm

  const defaultName: { [key in SupportedLocale]: string } = useMemo(
    () => ({
      ja:
        restaurantBusinessTime?.name_i18n_ja ??
        restaurantBusinessTime?.name ??
        '',
      en: restaurantBusinessTime?.name_i18n_en ?? '',
      ko: restaurantBusinessTime?.name_i18n_ko ?? '',
      'zh-CN': restaurantBusinessTime?.name_i18n_zh_cn ?? '',
      'zh-TW': restaurantBusinessTime?.name_i18n_zh_tw ?? '',
      'zh-HK': restaurantBusinessTime?.name_i18n_zh_hk ?? '',
      th: restaurantBusinessTime?.name_i18n_th ?? '',
    }),
    [
      restaurantBusinessTime?.name,
      restaurantBusinessTime?.name_i18n_ja,
      restaurantBusinessTime?.name_i18n_en,
      restaurantBusinessTime?.name_i18n_ko,
      restaurantBusinessTime?.name_i18n_zh_cn,
      restaurantBusinessTime?.name_i18n_zh_tw,
      restaurantBusinessTime?.name_i18n_zh_hk,
      restaurantBusinessTime?.name_i18n_th,
    ]
  )

  const [name, setName] = useFormState<LocaleValue>(defaultName)

  const [startHour, setStartHour] = useFormState(
    restaurantBusinessTime
      ? toHoursAndMinutes(restaurantBusinessTime.start_time).hours
      : 12
  )
  const [startMin, setStartMin] = useFormState(
    restaurantBusinessTime
      ? toHoursAndMinutes(restaurantBusinessTime.start_time).minutes
      : 0
  )
  const [lastOrderHour, setLastOrderHour] = useFormState(
    restaurantBusinessTime
      ? toHoursAndMinutes(restaurantBusinessTime.last_order_time).hours
      : 12
  )
  const [lastOrderMin, setLastOrderMin] = useFormState(
    restaurantBusinessTime
      ? toHoursAndMinutes(restaurantBusinessTime.last_order_time).minutes
      : 0
  )
  const [endHour, setEndHour] = useFormState(
    restaurantBusinessTime
      ? toHoursAndMinutes(restaurantBusinessTime.end_time).hours
      : 12
  )
  const [endMin, setEndMin] = useFormState(
    restaurantBusinessTime
      ? toHoursAndMinutes(restaurantBusinessTime.end_time).minutes
      : 0
  )
  const [stayingHour, setStayingHour] = useFormState(
    restaurantBusinessTime?.staying_time != null
      ? toHoursAndMinutes(restaurantBusinessTime.staying_time).hours
      : 2
  )
  const [stayingMin, setStayingMin] = useFormState(
    restaurantBusinessTime?.staying_time != null
      ? toHoursAndMinutes(restaurantBusinessTime.staying_time).minutes
      : 0
  )
  const [wdays, setWdays] = useFormState<number[]>(
    restaurantBusinessTime
      ? restaurantBusinessTime.wdays
      : [0, 1, 2, 3, 4, 5, 6, 7]
  )

  // restaurant_business_time_reservation_period_policy

  const [reservable, setReservable] = useFormState(
    restaurantBusinessTime?.reservable ?? true
  )

  // メニュー作成のための状態を追加 - 新規追加時のみ使用
  const [shouldCreateMenu, setShouldCreateMenu] = React.useState(true)
  const [menuName, setMenuName] = React.useState('')

  const [sinceType, setSinceType] =
    useFormState<RestaurantBusinessTimeReservationPeriodPolicySinceType>(
      restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
        ?.since_type ?? 'each_day'
    )

  const [sinceDayBefore, setSinceDayBefore] = useFormState<number | undefined>(
    restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
      ?.since_day_before ?? undefined
  )
  const [sinceMonthBefore, setSinceMonthBefore] = useFormState<
    number | undefined
  >(
    restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
      ?.since_month_before ?? undefined
  )
  const [sinceSpecificDay, setSinceSpecificDay] = useFormState<
    number | undefined
  >(
    restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
      ?.since_specific_day ?? undefined
  )
  const [sinceSpecificTime, setSinceSpecificTime] = useFormState<
    number | undefined
  >(
    restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
      ?.since_specific_time ?? undefined
  )

  const [untilType, setUntilType] =
    useFormState<RestaurantBusinessTimeReservationPeriodPolicyUntilType>(
      restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
        ?.until_type ?? 'offset'
    )

  const [untilDayBefore, setUntilDayBefore] = useFormState<number | undefined>(
    restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
      ?.until_day_before ?? undefined
  )

  const [untilTimeBefore, setUntilTimeBefore] = useFormState<
    number | undefined
  >(
    restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
      ?.until_time_before ?? undefined
  )

  const [untilSpecificTime, setUntilSpecificTime] = useFormState<
    number | undefined
  >(
    restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
      ?.until_specific_time ?? undefined
  )

  // 回転（予約可能時間帯）
  const [
    restaurantBusinessTimeReservationTimes,
    setRestaurantBusinessTimeReservationTimes,
  ] = useFormState<
    Array<{
      id?: RestaurantBusinessTimeReservationTime['id']
      start_time: RestaurantBusinessTimeReservationTime['start_time']
      end_time: RestaurantBusinessTimeReservationTime['start_time']
      _destroy?: boolean
    }>
  >(restaurantBusinessTime?.restaurant_business_time_reservation_times ?? [])

  const onChangeWdays = (wday: number, value: boolean) => {
    if (value) {
      if (!wdays.includes(wday)) {
        setWdays((wdays) => [...wdays, wday])
      }
    } else {
      setWdays((wdays) => wdays.filter((wd) => wd !== wday))
    }
  }

  const onChangeReservationTimeStartTime = (index: number, value: number) => {
    setRestaurantBusinessTimeReservationTimes(
      produce(restaurantBusinessTimeReservationTimes, (draft) => {
        draft[index].start_time = value
      })
    )
  }

  const onChangeReservationTimeEndTime = (index: number, value: number) => {
    setRestaurantBusinessTimeReservationTimes(
      produce(restaurantBusinessTimeReservationTimes, (draft) => {
        draft[index].end_time = value
      })
    )
  }

  const onPressRemoveReservationTime = (index: number) => {
    setRestaurantBusinessTimeReservationTimes(
      produce(restaurantBusinessTimeReservationTimes, (draft) => {
        draft[index]._destroy = true
      })
    )
  }

  const onPressAddReservationTime = () => {
    setRestaurantBusinessTimeReservationTimes(
      produce(restaurantBusinessTimeReservationTimes, (draft) => {
        draft.push({
          start_time: toSeconds(startHour, startMin),
          end_time: toSeconds(lastOrderHour, lastOrderMin),
        })
      })
    )
  }

  const onPress = async () => {
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })

    // メニュー名のバリデーション
    if (
      restaurantBusinessTime == null &&
      shouldCreateMenu &&
      !menuName.trim()
    ) {
      displayToastError(t('メニュー名を入力してください'))
      return
    }

    const startTime = toSeconds(startHour, startMin)
    const lastOrderTime = toSeconds(lastOrderHour, lastOrderMin)
    const endTime = toSeconds(endHour, endMin)
    const stayingTime =
      stayingHour != null && stayingMin != null
        ? toSeconds(stayingHour, stayingMin)
        : null

    let periodPolicyParams: any = {}

    if (
      sinceType === 'each_day' &&
      [sinceMonthBefore, sinceDayBefore, sinceSpecificTime].some(
        (value) => value !== undefined
      )
    ) {
      periodPolicyParams = {
        since_type: sinceType,
        since_month_before: sinceMonthBefore,
        since_day_before: sinceDayBefore,

        since_specific_time: sinceSpecificTime,
      }
    } else if (
      sinceType === 'bulk' &&
      [sinceMonthBefore, sinceSpecificDay, sinceSpecificTime].some(
        (value) => value !== undefined
      )
    ) {
      periodPolicyParams = {
        since_type: sinceType,
        since_month_before: sinceMonthBefore,
        since_specific_day: sinceSpecificDay,
        since_specific_time: sinceSpecificTime,
      }
    }

    if (
      untilType === 'offset' &&
      [untilDayBefore, untilTimeBefore].some((value) => value !== undefined)
    ) {
      periodPolicyParams = {
        ...periodPolicyParams,
        until_type: untilType,
        until_day_before: untilDayBefore,
        until_time_before: untilTimeBefore,
      }
    } else if (
      untilType === 'specific' &&
      [untilDayBefore, untilSpecificTime].some((value) => value !== undefined)
    ) {
      periodPolicyParams = {
        ...periodPolicyParams,
        until_type: untilType,
        until_day_before: untilDayBefore,
        until_specific_time: untilSpecificTime,
      }
    }

    const params = {
      name: name.ja,
      ...Object.fromEntries(
        supportedLocales.map((locale) => {
          return [
            `name_i18n_${locale.replaceAll('-', '_').toLowerCase()}`,
            name[locale],
          ]
        })
      ),
      start_time: startTime,
      last_order_time: lastOrderTime,
      end_time: endTime,
      staying_time: stayingTime,
      wdays,
      reservable,
      ...(Object.keys(periodPolicyParams).length > 0
        ? {
            restaurant_business_time_reservation_period_policy:
              periodPolicyParams,
          }
        : {}),
      restaurant_business_time_reservation_times:
        restaurantBusinessTimeReservationTimes,

      // メニュー作成情報を追加（新規作成時のみ）
      ...(restaurantBusinessTime == null
        ? {
            _should_create_menu: shouldCreateMenu,
            _menu_name: shouldCreateMenu ? menuName : '',
          }
        : {}),
    }

    if (restaurantBusinessTime) {
      if (hasIncompleteChanges(name, defaultName)) {
        if (!(await confirmSaveIncompleteChangesAsync(alert))) {
          return
        }
      }
    }

    return await onCreateOrUpdate(params)
  }

  const space = width < sm ? 24 : 32

  return (
    <View
      style={{
        position: 'relative',
        flex: 1,
      }}
    >
      <KeyboardAwareScrollView
        style={{
          height: '100%',
          paddingHorizontal: isSmallScreen ? 24 : 32,
          paddingTop: isSmallScreen ? 24 : 32,
        }}
      >
        <View style={{ gap: 32 }}>
          <CardWrap title={t('基本項目')}>
            <ShadowBox>
              <TranslationFormList
                formLabel={<FormLabel value={t('名前')} required />}
                showsFormDescription
                sortedLocales={sortedLocales}
                values={name}
                onChangeValues={setName}
                createTranslation={(params) => createTranslation(params)}
              >
                {({ locale }) => (
                  <TranslationFormItem
                    key={locale}
                    formLabel={
                      <FormLabel value={getTranslationLocaleLabel(locale)} />
                    }
                  >
                    <TextInput
                      placeholder={t('例：ランチ')}
                      value={name[locale]}
                      onChangeText={(text) =>
                        setName((value) => ({
                          ...value,
                          [locale]: text,
                        }))
                      }
                      autoCapitalize="none"
                    />
                  </TranslationFormItem>
                )}
              </TranslationFormList>
              <Divider
                style={{
                  marginVertical: 24,
                }}
              />
              <View
                style={[
                  { marginTop: space },
                  width >= sm && { flexDirection: 'row' },
                ]}
              >
                <View
                  style={[
                    width >= sm && {
                      flex: 1,
                      marginRight: 12,
                    },
                  ]}
                >
                  <Text style={{ fontWeight: '600' }}>{t('開始時間')}</Text>
                  <View
                    style={{
                      marginTop: 16,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <SelectInput
                      selectedValue={startHour}
                      setValue={setStartHour}
                      items={hours}
                      style={{ flex: 1 }}
                      containerStyle={{ height: 64 }}
                    />
                    <Text style={{ marginHorizontal: 8 }}>:</Text>
                    <SelectInput
                      selectedValue={startMin}
                      setValue={setStartMin}
                      items={mins}
                      style={{ flex: 1 }}
                      containerStyle={{ height: 64 }}
                    />
                  </View>
                </View>
                <View
                  style={[
                    width < sm
                      ? {
                          marginTop: space,
                        }
                      : {
                          flex: 1,
                          marginLeft: 12,
                        },
                  ]}
                >
                  <Text style={{ fontWeight: '600' }}>
                    {t('ラストオーダー時間')}
                  </Text>
                  <View
                    style={{
                      marginTop: 16,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <SelectInput
                      selectedValue={lastOrderHour}
                      setValue={setLastOrderHour}
                      items={hours}
                      style={{ flex: 1 }}
                      containerStyle={{ height: 64 }}
                    />
                    <Text style={{ marginHorizontal: 8 }}>:</Text>
                    <SelectInput
                      selectedValue={lastOrderMin}
                      setValue={setLastOrderMin}
                      items={mins}
                      style={{ flex: 1 }}
                      containerStyle={{ height: 64 }}
                    />
                  </View>
                </View>
                <View
                  style={[
                    width < sm
                      ? {
                          marginTop: space,
                        }
                      : {
                          flex: 1,
                          marginLeft: 12,
                        },
                  ]}
                >
                  <Text style={{ fontWeight: '600' }}>{t('終了時間')}</Text>
                  <View
                    style={{
                      marginTop: 16,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <SelectInput
                      selectedValue={endHour}
                      setValue={setEndHour}
                      items={hours}
                      style={{ flex: 1 }}
                      containerStyle={{ height: 64 }}
                    />
                    <Text style={{ marginHorizontal: 8 }}>:</Text>
                    <SelectInput
                      selectedValue={endMin}
                      setValue={setEndMin}
                      items={mins}
                      style={{ flex: 1 }}
                      containerStyle={{ height: 64 }}
                    />
                  </View>
                </View>
              </View>
              <View style={{ marginTop: space }}>
                <Text style={{ fontWeight: '600' }}>{t('滞在時間')}</Text>
                <View
                  style={{
                    marginTop: 16,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <SelectInput
                    selectedValue={stayingHour}
                    setValue={setStayingHour}
                    items={stayingHours}
                    style={{ flex: 1 }}
                    containerStyle={{ height: 64 }}
                  />
                  <Text style={{ marginHorizontal: 8 }}>:</Text>
                  <SelectInput
                    selectedValue={stayingMin}
                    setValue={setStayingMin}
                    items={stayingMins}
                    style={{ flex: 1 }}
                    containerStyle={{ height: 64 }}
                  />
                </View>
              </View>
              <View style={{ marginTop: space }}>
                <Text style={{ fontWeight: '600' }}>{t('曜日')}</Text>
                <View style={{ marginTop: 16 }}>
                  <Wrap verticalGap={12} horizontalGap={width < sm ? 12 : 16}>
                    {dayjs
                      .weekdays()
                      .concat(t('祝日'))
                      .map((label, wday) => (
                        <Checkbox
                          key={wday.toString()}
                          checked={wdays.includes(wday)}
                          onChange={(value) => {
                            onChangeWdays(wday, value)
                          }}
                          checkboxLabel={<CheckboxLabel value={label} />}
                        />
                      ))}
                  </Wrap>
                </View>
                <Text style={{ marginTop: 16, color: Colors.secondaryBlack }}>
                  {t('休日が祝日の場合は休日の設定が優先されます')}
                </Text>
              </View>
            </ShadowBox>
          </CardWrap>

          <CardWrap title={t('営業時間内の予約受付方法')}>
            <ShadowBox>
              <FormGroup
                formLabelContainerStyle={{
                  width: 200,
                }}
                mode={isSmallScreen ? 'vertical' : 'inline-expanded'}
                formLabel={
                  <FormLabel
                    value={t('予約受付方法を選択')}
                    labelStyle={{ width: 200 }}
                  />
                }
              >
                <View>
                  {/* 予約を受け付ける */}
                  <TouchableOpacity
                    onPress={() => {
                      setReservable(true)
                      setRestaurantBusinessTimeReservationTimes([])
                    }}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 16,
                    }}
                  >
                    <RadioIcon
                      selected={
                        reservable &&
                        restaurantBusinessTimeReservationTimes.filter(
                          (t) => !t._destroy
                        ).length === 0
                      }
                    />
                    <View style={{ marginLeft: 10, width: '100%' }}>
                      <Text
                        style={{
                          whiteSpace: 'normal',
                          maxWidth: '90%',
                          wordBreak: 'break-word',
                        }}
                      >
                        {t(
                          '予約を受け付ける（開始時間〜ラストオーダー時間で予約を受け付けます）'
                        )}
                      </Text>
                    </View>
                  </TouchableOpacity>

                  {/* 時間を指定して予約を受け付ける */}
                  <View>
                    <TouchableOpacity
                      onPress={() => {
                        setReservable(true)
                        if (
                          restaurantBusinessTimeReservationTimes.filter(
                            (t) => !t._destroy
                          ).length === 0
                        ) {
                          onPressAddReservationTime()
                        }
                      }}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginBottom:
                          reservable &&
                          restaurantBusinessTimeReservationTimes.filter(
                            (t) => !t._destroy
                          ).length > 0
                            ? 0
                            : 16,
                      }}
                    >
                      <RadioIcon
                        selected={
                          reservable &&
                          restaurantBusinessTimeReservationTimes.filter(
                            (t) => !t._destroy
                          ).length > 0
                        }
                      />
                      <View style={{ marginLeft: 10 }}>
                        <Text>{t('時間を指定して予約を受け付ける')}</Text>
                      </View>
                    </TouchableOpacity>

                    {/* 時間を指定して予約を受け付ける が選択されている場合のみ表示 */}
                    {reservable &&
                      restaurantBusinessTimeReservationTimes.filter(
                        (t) => !t._destroy
                      ).length > 0 && (
                        <View style={{ marginVertical: 16, gap: 16 }}>
                          {restaurantBusinessTimeReservationTimes.map(
                            (reservationTime, index) => {
                              if (reservationTime._destroy) return null

                              return (
                                <View
                                  key={`${index}`}
                                  style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <SelectInput
                                    items={reservationTimeItems}
                                    selectedValue={reservationTime.start_time}
                                    setValue={(value) => {
                                      onChangeReservationTimeStartTime(
                                        index,
                                        value
                                      )
                                    }}
                                  />
                                  <Text
                                    style={{
                                      marginHorizontal: 8,
                                    }}
                                  >
                                    〜
                                  </Text>
                                  <SelectInput
                                    items={reservationTimeItems}
                                    selectedValue={reservationTime.end_time}
                                    setValue={(value) => {
                                      onChangeReservationTimeEndTime(
                                        index,
                                        value
                                      )
                                    }}
                                  />
                                  <TouchableOpacity
                                    style={[{ marginLeft: 12 }]}
                                    onPress={() => {
                                      onPressRemoveReservationTime(index)
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTrashAlt}
                                      size={24}
                                      color={Colors.secondaryBlack}
                                    />
                                  </TouchableOpacity>
                                </View>
                              )
                            }
                          )}

                          <Button
                            style={{ marginTop: 16 }}
                            mode="outline"
                            height={48}
                            onPress={onPressAddReservationTime}
                          >
                            {t('予約可能時間帯を追加')}
                          </Button>
                        </View>
                      )}
                  </View>

                  {/* 予約を受け付けない */}
                  <TouchableOpacity
                    onPress={() => {
                      setReservable(false)
                    }}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <RadioIcon selected={!reservable} />
                    <View style={{ marginLeft: 10 }}>
                      <Text>{t('予約を受け付けない')}</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </FormGroup>

              <FormGroup
                mode="vertical"
                style={{ marginTop: 24 }}
                formLabel={<FormLabel value={t('予約受付開始')} />}
              >
                <View
                  style={
                    width >= sm && {
                      flexDirection: 'row',
                      alignItems: 'center',
                    }
                  }
                >
                  <TouchableOpacity
                    onPress={() => {
                      setSinceType('each_day')
                    }}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: formRadioWidth,
                    }}
                  >
                    <RadioIcon selected={sinceType === 'each_day'} />
                    <Text style={{ marginLeft: 10 }}>{t('毎日予約')}</Text>
                  </TouchableOpacity>
                  <SelectInput
                    items={sinceMonthBeforeItems}
                    selectedValue={sinceMonthBefore}
                    setValue={setSinceMonthBefore}
                    labelStyle={[
                      sinceMonthBefore === undefined && {
                        color: Colors.black60,
                      },
                    ]}
                    style={
                      width < sm && {
                        marginTop: 16,
                      }
                    }
                  />
                  <SelectInput
                    style={
                      width < sm
                        ? {
                            marginTop: 16,
                          }
                        : {
                            marginLeft: 24,
                          }
                    }
                    items={sinceDayBeforeItems}
                    selectedValue={sinceDayBefore}
                    setValue={setSinceDayBefore}
                    labelStyle={[
                      sinceDayBefore === undefined && {
                        color: Colors.black60,
                      },
                    ]}
                  />
                  <SelectInput
                    style={
                      width < sm
                        ? {
                            marginTop: 16,
                          }
                        : {
                            marginLeft: 24,
                          }
                    }
                    items={sinceTimeItems}
                    selectedValue={sinceSpecificTime}
                    setValue={setSinceSpecificTime}
                    labelStyle={[
                      sinceSpecificTime === undefined && {
                        color: Colors.black60,
                      },
                    ]}
                  />
                </View>
                <Text
                  style={{
                    marginLeft: width < sm ? 0 : formRadioWidth,
                    marginTop: 8,
                    color: Colors.black60,
                    fontSize: 14,
                  }}
                >
                  {t(
                    '例：1ヶ月前に設定した場合、1月10日 10:00時点で、2月9日10:00までの予約ができます'
                  )}
                </Text>
                <View
                  style={[
                    {
                      marginTop: 24,
                    },
                    width >= sm && {
                      flexDirection: 'row',
                      alignItems: 'center',
                    },
                  ]}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setSinceType('bulk')
                    }}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: formRadioWidth,
                    }}
                  >
                    <RadioIcon selected={sinceType === 'bulk'} />
                    <Text style={{ marginLeft: 10 }}>{t('一斉予約')}</Text>
                  </TouchableOpacity>

                  <SelectInput
                    items={sinceMonthBeforeItems}
                    selectedValue={sinceMonthBefore}
                    setValue={setSinceMonthBefore}
                    labelStyle={[
                      sinceMonthBefore === undefined && {
                        color: Colors.black60,
                      },
                    ]}
                    style={
                      width < sm && {
                        marginTop: 16,
                      }
                    }
                  />
                  <SelectInput
                    style={
                      width < sm
                        ? {
                            marginTop: 16,
                          }
                        : {
                            marginLeft: 24,
                          }
                    }
                    items={sinceSpecificDayItems}
                    selectedValue={sinceSpecificDay}
                    setValue={setSinceSpecificDay}
                    labelStyle={[
                      sinceSpecificDay === undefined && {
                        color: Colors.black60,
                      },
                    ]}
                  />
                  <SelectInput
                    style={
                      width < sm
                        ? {
                            marginTop: 16,
                          }
                        : {
                            marginLeft: 24,
                          }
                    }
                    items={sinceSpecificTimeItems}
                    selectedValue={sinceSpecificTime}
                    setValue={setSinceSpecificTime}
                    labelStyle={[
                      sinceSpecificTime === undefined && {
                        color: Colors.black60,
                      },
                    ]}
                  />
                </View>
                <Text
                  style={{
                    marginLeft: width < sm ? 0 : formRadioWidth,
                    marginTop: 8,
                    color: Colors.black60,
                    fontSize: 14,
                  }}
                >
                  {t(
                    '例：2ヶ月前 1日10:00に設定した場合、1月の予約枠が、2ヶ月前の1日10時（11月1日10:00）に開放されます'
                  )}
                </Text>
              </FormGroup>
              <FormGroup
                mode="vertical"
                style={{ marginTop: 24 }}
                formLabel={<FormLabel value={t('予約受付終了')} />}
              >
                <View
                  style={
                    width >= sm && {
                      flexDirection: 'row',
                      alignItems: 'center',
                    }
                  }
                >
                  <TouchableOpacity
                    onPress={() => {
                      setUntilType('offset')
                    }}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: formRadioWidth,
                    }}
                  >
                    <RadioIcon selected={untilType === 'offset'} />
                    <Text style={{ marginLeft: 10 }}>{t('〜前')}</Text>
                  </TouchableOpacity>
                  <SelectInput
                    items={untilDayBeforeItems}
                    selectedValue={untilDayBefore}
                    setValue={setUntilDayBefore}
                    labelStyle={[
                      untilDayBefore === undefined && {
                        color: Colors.black60,
                      },
                    ]}
                    style={
                      width < sm && {
                        marginTop: 16,
                      }
                    }
                  />
                  <SelectInput
                    style={
                      width < sm
                        ? {
                            marginTop: 16,
                          }
                        : {
                            marginLeft: 24,
                          }
                    }
                    items={untilTimeBeforeItems}
                    selectedValue={untilTimeBefore}
                    setValue={setUntilTimeBefore}
                    labelStyle={[
                      untilTimeBefore === undefined && {
                        color: Colors.black60,
                      },
                    ]}
                  />
                </View>
                <View>
                  <Text
                    style={{
                      marginLeft: width < sm ? 0 : formRadioWidth,
                      marginTop: 8,
                      color: Colors.black60,
                      fontSize: 14,
                    }}
                  >
                    {t(
                      '例：前日1時間前に設定した場合、1月10日 10:00の予約を、1月9日09:00に締め切ります'
                    )}
                  </Text>
                </View>
                <View
                  style={[
                    {
                      marginTop: 24,
                    },
                    width >= sm && {
                      flexDirection: 'row',
                      alignItems: 'center',
                    },
                  ]}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setUntilType('specific')
                    }}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      width: formRadioWidth,
                    }}
                  >
                    <RadioIcon selected={untilType === 'specific'} />
                    <Text style={{ marginLeft: 10 }}>{t('〜日前〜時')}</Text>
                  </TouchableOpacity>
                  <SelectInput
                    items={untilDayBeforeItems}
                    selectedValue={untilDayBefore}
                    setValue={setUntilDayBefore}
                    labelStyle={[
                      untilDayBefore === undefined && {
                        color: Colors.black60,
                      },
                    ]}
                    style={
                      width < sm && {
                        marginTop: 16,
                      }
                    }
                  />
                  <SelectInput
                    style={
                      width < sm
                        ? {
                            marginTop: 16,
                          }
                        : {
                            marginLeft: 24,
                          }
                    }
                    items={untilSpecificTimeItems}
                    selectedValue={untilSpecificTime}
                    setValue={setUntilSpecificTime}
                    labelStyle={[
                      untilSpecificTime === undefined && {
                        color: Colors.black60,
                      },
                    ]}
                  />
                </View>
                <View>
                  <Text
                    style={{
                      marginLeft: width < sm ? 0 : formRadioWidth,
                      marginTop: 8,
                      color: Colors.black60,
                      fontSize: 14,
                    }}
                  >
                    {t(
                      '例：前日18:00に設定した場合、1月10日の予約枠を、1月9日18:00に締め切ります'
                    )}
                  </Text>
                </View>
              </FormGroup>
            </ShadowBox>
          </CardWrap>

          {/* メニュー作成セクションを追加 - 新規作成時のみ表示 */}
          {restaurantBusinessTime == null && (
            <CardWrap title={t('メニューの作成')}>
              <ShadowBox>
                <FormGroup
                  mode="vertical"
                  formLabel={
                    <FormLabel
                      value={t('営業時間とメニューをまとめて登録しましょう')}
                    />
                  }
                  containerStyle={{
                    marginTop: 8,
                  }}
                >
                  <View>
                    <Text
                      style={{
                        marginBottom: 20,
                        fontSize: 14,
                        fontWeight: '300',
                      }}
                    >
                      {t(
                        '登録した営業時間をもとにメニューを自動で作成し、紐づけます。\n自動で作成されたメニューは後から自由に編集・削除ができますので、まずは気軽に作成してみてください。'
                      )}
                    </Text>

                    <View
                      style={{
                        flexDirection: isSmallScreen ? 'column' : 'row',
                        gap: 16,
                      }}
                    >
                      {/* メニューを作成する */}
                      <TouchableOpacity
                        onPress={() => setShouldCreateMenu(true)}
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <RadioIcon selected={shouldCreateMenu} />
                        <View
                          style={{
                            marginLeft: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: 4,
                          }}
                        >
                          <Text>{t('メニューを自動で作成する')}</Text>
                          <View
                            style={{
                              backgroundColor: Colors.accentBg,
                              paddingHorizontal: 6,
                              borderRadius: 2,
                            }}
                          >
                            <Text
                              style={{
                                fontWeight: '600',
                                color: Colors.accent,
                                fontSize: 12,
                              }}
                            >
                              {t('推奨')}
                            </Text>
                          </View>
                        </View>
                      </TouchableOpacity>

                      {/* メニューを作成しない */}
                      <TouchableOpacity
                        onPress={() => setShouldCreateMenu(false)}
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <RadioIcon selected={!shouldCreateMenu} />
                        <View style={{ marginLeft: 10 }}>
                          <Text>{t('メニューは後で作成する')}</Text>
                        </View>
                      </TouchableOpacity>
                    </View>

                    {shouldCreateMenu && (
                      <View
                        style={{
                          marginTop: 24,
                          flexDirection: 'column',
                          justifyContent: 'center',
                          gap: 12,
                        }}
                      >
                        <FormLabel value={t('メニュー名')} required />
                        <TextInput
                          value={menuName}
                          onChangeText={setMenuName}
                          placeholder={t('例：ランチメニュー')}
                        />
                        <View
                          style={{
                            flexDirection: width < sm ? 'column' : 'row',
                            alignItems: width < sm ? 'stretch' : 'center',
                            gap: 12,
                            backgroundColor: Colors.accentBg,
                            padding: 12,
                            borderRadius: 4,
                          }}
                        >
                          {width >= sm && (
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              color={Colors.accent}
                              size={16}
                            />
                          )}
                          <Text
                            style={{
                              fontSize: 14,
                              fontWeight: '600',
                              color: Colors.accent,
                              flex: width < sm ? undefined : 1,
                            }}
                          >
                            {t(
                              '営業時間名「{{businessTimeName}}」から「{{businessTimeName}}メニュー」を自動入力できます',
                              {
                                businessTimeName: name.ja || t('営業時間名'),
                              }
                            )}
                          </Text>

                          <TouchableOpacity
                            onPress={() => {
                              // 営業時間名 + 「メニュー」をセット
                              const japaneseBusinessTimeName = name.ja || ''
                              setMenuName(
                                `${japaneseBusinessTimeName}${t('メニュー')}`
                              )
                            }}
                            style={{
                              backgroundColor: Colors.accentBg,
                              border: `1px solid ${Colors.accent}`,
                              borderRadius: 4,
                              paddingHorizontal: 16,
                              paddingVertical: 6,
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 14,
                                color: Colors.accent,
                                fontWeight: '600',
                              }}
                            >
                              {t('自動入力する')}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    )}
                  </View>
                </FormGroup>
              </ShadowBox>
            </CardWrap>
          )}

          {children}
        </View>
        {/* stickyにしたいがため、余白を追加しているがより良いあり方があればそちらを推奨する */}
        <View
          style={
            Platform.OS === 'web'
              ? {
                  height: isSmallScreen ? 24 : 32,
                }
              : { height: isSmallScreen ? 24 * 2 : 32 * 2 }
          }
        />
      </KeyboardAwareScrollView>

      <View
        style={[
          {
            position: 'sticky',
            bottom: 0,
            width: '100%',
            paddingVertical: width < sm ? 16 : 12,
            paddingHorizontal: 24,
            paddingBottom:
              Platform.OS === 'web' ? undefined : insets.bottom || 24,
            backgroundColor: Colors.white,
            flexDirection: 'row',
            justifyContent: 'center',
            gap: width > sm ? 16 : 12,
            borderTopWidth: 1,
            borderColor: Colors.black10,
          },
        ]}
      >
        {restaurantBusinessTime != null && (
          <Button
            mode="outline"
            variant="danger-secondary"
            style={{
              height: 48,
              maxWidth: isSmallScreen ? '100%' : 296,
              flex: 1,
            }}
            onPress={() => onDestroy()}
          >
            {t('削除する')}
          </Button>
        )}
        <Button
          onPress={() => {
            setIsSubmitting(true)
            onPress().finally(() => setIsSubmitting(false))
          }}
          disabled={isSubmitting}
          loading={isSubmitting}
          style={{
            height: 48,
            maxWidth: isSmallScreen ? '100%' : 296,
            flex: 1,
          }}
        >
          {restaurantBusinessTime != null ? t('更新する') : t('作成する')}
        </Button>
      </View>
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents="box-none"
      >
        <AlertProvider ref={alertRef} />
      </View>
    </View>
  )
}
