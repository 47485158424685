import type { FC } from 'react'

import { AllergyIcon } from './Allergy'
import { MemoIcon } from './Memo'
import { PhoneIcon } from './Phone'

export type IconType =
  | string
  | FC<{ size?: number; color?: string; strokeColor?: string }>

export const ICONS = {
  phone: PhoneIcon,
  allergy: AllergyIcon,
  memo: MemoIcon,
} as const satisfies Record<string, IconType>
