import type React from 'react'

import { StyleSheet, Text, View } from '@react-pdf/renderer'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { numberWithDelimiter } from '@hello-ai/ar_shared/src/modules/numberWithDelimiter'
import { Customer as _Customer } from '@hello-ai/ar_shared/src/types/ForR/Customer'

import { ICONS, type IconType } from './icons'

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    borderRadius: 4,
  },
  header: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderColor: Colors.border,
    backgroundColor: Colors.black50,
    height: 24,
  },
  headerCell: {
    flexDirection: 'row',
    paddingVertical: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerText: {
    fontSize: 10,
    color: Colors.white,
    fontFamily: 'NotoSansJPBold',
  },
  row: {
    flexDirection: 'row',
    paddingVertical: 8,
    paddingHorizontal: 4,
    borderLeftWidth: 0.5,
    borderRightWidth: 0.5,
    alignItems: 'center',
    borderBottomWidth: 0.5,
    borderColor: Colors.border,
    backgroundColor: Colors.white,
  },
  thinText: {
    fontFamily: 'NotoSansJPRegular',
    color: Colors.black60,
    fontSize: 8,
    flexWrap: 'wrap',
    width: 140,
  },
  wrapText: {
    fontFamily: 'NotoSansJPRegular',
    color: Colors.black60,
    fontSize: 8,
    flexWrap: 'wrap',
    width: '100%',
    hyphens: 'none',
  },
  boldText: {
    fontSize: 10,
    fontFamily: 'NotoSansJPBold',
    color: Colors.black,
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 4,
    marginTop: 4,
  },
  iconText: {
    width: 16,
    textAlign: 'center',
    fontSize: 14,
    color: Colors.black50,
  },
  emptyMessage: {
    paddingVertical: 32,
    alignItems: 'center',
  },
})

const isNullOrEmpty = (value: string | null | undefined): value is null => {
  return value == null || value === ''
}

export interface RestaurantCourse {
  title: string
  price: number
  price_str: string
}

export interface EllipsisCourse {
  id: number
  party_size: number
  restaurant_course: RestaurantCourse
}

export type Customer = Pick<
  _Customer,
  | 'last_name'
  | 'first_name'
  | 'phone_number_format'
  | 'allergy'
  | 'restaurant_customer_note'
  | 'restaurant_customer_notes'
  | 'reservation_first_name'
  | 'reservation_last_name'
>

export interface ReservationData {
  id: string
  start_at: string
  end_at: string
  party_size: number
  child_party_size: number
  customers: Customer[]
  table_seats?: {
    name: string
  }[]
  memo: string
  reservation_courses: EllipsisCourse[]
  reservation_child_details?: { age: number }[]
}

function Header({ children }: { children: React.ReactNode }) {
  return <View style={styles.header}>{children}</View>
}

function HeaderCell({ width, children }: { width: number; children: string }) {
  return (
    <View style={[styles.headerCell, { width }]}>
      <Text style={styles.headerText}>{children}</Text>
    </View>
  )
}

function InfoRow({ icon, text }: { icon: keyof typeof ICONS; text: string }) {
  const trimmedText = text ? text.trimStart() : text
  return (
    <View style={styles.infoRow}>
      <View style={{ marginTop: 1 }}>{renderIcon(ICONS[icon])}</View>
      <Text style={styles.wrapText}>{trimmedText}</Text>
    </View>
  )
}

const renderIcon = (icon: IconType) => {
  if (typeof icon === 'string') {
    return <Text>{icon}</Text>
  }
  const IconComponent = icon
  return <IconComponent size={10} />
}

export function ReservationListView({
  restaurantReservation,
}: {
  restaurantReservation: ReservationData
}) {
  const customer = restaurantReservation.customers[0] ?? {
    id: '',
    last_name: '',
    first_name: '',
    reservation_last_name: '',
    reservation_first_name: '',
    phone_number_format: '',
    allergy: '',
    restaurant_customer_notes: [],
    restaurant_customer_note: {
      id: '',
      content: '',
    },
  }

  return (
    <View style={styles.row}>
      {/* 予約時間 */}
      <View style={{ width: 80, paddingHorizontal: 8 }}>
        <Text style={styles.boldText}>
          {dayjs(restaurantReservation.start_at).format('HH:mm')}
        </Text>
        <Text style={styles.thinText}>
          〜{dayjs(restaurantReservation.end_at).format('HH:mm')}
        </Text>
      </View>

      {/* 予約者情報 */}
      <View style={{ width: 160, paddingHorizontal: 8 }}>
        <Text style={styles.boldText}>
          {(() => {
            // 漢字名がある場合
            if (
              !isNullOrEmpty(customer.last_name) &&
              !isNullOrEmpty(customer.first_name)
            ) {
              return t('{{last_name}} {{first_name}} 様', {
                last_name: customer.last_name,
                first_name: customer.first_name,
              })
            }
            // ひらがな名がある場合
            if (
              !isNullOrEmpty(customer.reservation_last_name) &&
              !isNullOrEmpty(customer.reservation_first_name)
            ) {
              return t('{{last_name}} {{first_name}} 様', {
                last_name: customer.reservation_last_name,
                first_name: customer.reservation_first_name,
              })
            }
            // どちらかある場合
            if (
              !isNullOrEmpty(customer.last_name) ||
              !isNullOrEmpty(customer.first_name)
            ) {
              return t('{{last_name}} {{first_name}} 様', {
                last_name: customer.last_name ?? '',
                first_name: customer.first_name ?? '',
              })
            }
            // どちらかある場合
            if (
              !isNullOrEmpty(customer.reservation_last_name) ||
              !isNullOrEmpty(customer.reservation_first_name)
            ) {
              return t('{{last_name}} {{first_name}} 様', {
                last_name: customer.reservation_last_name ?? '',
                first_name: customer.reservation_first_name ?? '',
              })
            }
            // どちらもない場合
            return t('不明')
          })()}
        </Text>
        <InfoRow
          icon="phone"
          text={
            isNullOrEmpty(customer.phone_number_format)
              ? t('未記入')
              : customer.phone_number_format
          }
        />
        <InfoRow
          icon="allergy"
          text={
            isNullOrEmpty(customer.allergy) ? t('未記入') : customer.allergy
          }
        />
        <View>
          <InfoRow
            icon="memo"
            text={
              customer.restaurant_customer_notes != null &&
              customer.restaurant_customer_notes.length > 0
                ? customer.restaurant_customer_notes
                    .map((note) => note.content)
                    .join('\n')
                : isNullOrEmpty(customer.restaurant_customer_note?.content)
                  ? t('未記入')
                  : customer.restaurant_customer_note?.content
            }
          />
        </View>
      </View>

      {/* 人数・席 */}
      <View style={{ width: 120, paddingHorizontal: 8 }}>
        <Text style={styles.thinText}>
          {t('{{count}}人', { count: restaurantReservation.party_size })}
        </Text>
        {restaurantReservation.child_party_size > 0 && (
          <Text style={styles.thinText}>
            {t('(子{{childCount}}名)', {
              childCount: restaurantReservation.child_party_size,
            })}
          </Text>
        )}
        {restaurantReservation.table_seats &&
        restaurantReservation.table_seats.length > 0 ? (
          <>
            {restaurantReservation.table_seats.map((seat) => (
              <Text key={seat.name} style={styles.thinText}>
                {seat.name}
              </Text>
            ))}
          </>
        ) : (
          <Text style={styles.thinText}>{t('座席指定なし')}</Text>
        )}
      </View>

      {/* 備考 */}
      <View style={{ width: 168, paddingHorizontal: 8, gap: 4, marginLeft: 8 }}>
        {restaurantReservation.reservation_courses.map((course) => (
          <View key={course.id}>
            <Text style={styles.wrapText}>
              {`${course.restaurant_course?.title}${
                course.restaurant_course?.price > 0
                  ? `¥${numberWithDelimiter(course.restaurant_course.price)}`
                  : ''
              }(${t('{{count}}名', { count: course.party_size })})`}
            </Text>
          </View>
        ))}
        {(restaurantReservation.memo ?? '').length > 0 && (
          <Text style={styles.wrapText}>{restaurantReservation.memo}</Text>
        )}
      </View>
    </View>
  )
}

export function TableHeader() {
  return (
    <Header>
      <HeaderCell width={80}>{t('予約時間')}</HeaderCell>
      <HeaderCell width={160}>{t('予約者情報')}</HeaderCell>
      <HeaderCell width={120}>{t('人数・席')}</HeaderCell>
      <HeaderCell width={160}>{t('備考')}</HeaderCell>
    </Header>
  )
}

export function Table({
  restaurantReservations,
}: {
  restaurantReservations: ReservationData[] | undefined
}) {
  return (
    <View>
      <TableHeader />
      {restaurantReservations?.map((restaurantReservation) => (
        <ReservationListView
          key={restaurantReservation.id}
          restaurantReservation={restaurantReservation}
        />
      ))}
      {restaurantReservations !== undefined &&
        restaurantReservations.length === 0 && (
          <View style={styles.emptyMessage}>
            <Text style={styles.thinText}>{t('予約がありません')}</Text>
          </View>
        )}
    </View>
  )
}

export function ListView({ children }: { children: React.ReactNode }) {
  return <View style={styles.container}>{children}</View>
}
