/* eslint-disable react-compiler/react-compiler */
import type React from 'react'
import { useEffect, useRef } from 'react'

import { faArrowsAltH } from '@fortawesome/pro-solid-svg-icons/faArrowsAltH'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation'
import { faHistory } from '@fortawesome/pro-solid-svg-icons/faHistory'
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons/faPenToSquare'
import { faSortDown } from '@fortawesome/pro-solid-svg-icons/faSortDown'
import { faSortUp } from '@fortawesome/pro-solid-svg-icons/faSortUp'
import { faUtensils } from '@fortawesome/pro-solid-svg-icons/faUtensils'
import { Image } from 'expo-image'
import { View, Platform, useWindowDimensions } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  SharedValue,
} from 'react-native-reanimated'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { GOURMET_SITE_PROVIDER_SOURCE } from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import {
  isSiteControllerReservation,
  type RestaurantReservation as RestaurantReservationModel,
} from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'

import {
  Attribute,
  useRestaurantAttributes,
} from '../../../models/CustomerAttributes'
import { getTagIcon } from '../../../models/ReservationTag'
import { useIsRestaurantSmartPaymentAvailable } from '../../../models/Restaurant'
import { getFormatTime, toSeconds } from '../../../modules/time'
import { calculateVisitStatusComponentProps } from '../../Restaurant/Reservation/ListView/VisitStatusTag'

import {
  CELL_PADDING_Y,
  MIN_DURATION,
  HOUR_SP_WIDTH,
  HOUR_PC_WIDTH,
  HOURS,
} from './const'
import {
  ifScrollToTop,
  getCellWidth,
  shouldDisplayEllipsis,
  calculateIconMaxWidth,
  calculateIconCount,
  getCellHeight,
  getCellX,
  getCellY,
  ifScrollToBottom,
  setIntervalByRequestAnimationFrame,
  getReservationText,
  hasCustomerAttribute,
  ifScrollToStart,
  ifScrollToEnd,
  calcAdjustedReservationCellWidthAndMinutes,
  calcAdjustedStartOffsetXAndStartTime,
  calcAdjustedOffsetYAndIndex,
} from './utils'

import type { ViewStyle } from 'react-native'
import type { ReanimatedScrollEvent } from 'react-native-reanimated/lib/typescript/hook/commonTypes'

/**
 * 「連続している席」を1つの予約カードでまとめて表示するときの高さを計算する
 *
 * @param consecutiveCount - 連続している行数
 * @param isSp - スマホサイズかどうか
 */
function getReservationCardHeight(
  consecutiveCount: number,
  isSp: boolean
): number {
  const baseHeight = getCellHeight(isSp)
  if (consecutiveCount === 1) {
    return baseHeight
  }

  // 1つのセルの上下のPaddingを足した値
  const oneCellPadding = CELL_PADDING_Y * 2

  // カードが連続した分だけベースの高さを足す。paddingも連続した分だけ足すが、1カード分は不要なので、-1する
  const totalCellHeights =
    baseHeight * consecutiveCount + oneCellPadding * (consecutiveCount - 1)

  return totalCellHeights
}

type RestaurantReservation = Omit<
  RestaurantReservationModel,
  | 'id'
  | 'status'
  | 'cancel_fee'
  | 'table_seats'
  | 'restaurant_crew_member'
  | 'reservation'
>

// TODO: source={require('../../../assets/images/ar_icon.png')} が危険なので絶対パスに変更する
type Props = {
  onPress: ({
    absoluteX,
    absoluteY,
  }: {
    absoluteX: number
    absoluteY: number
  }) => void
  type: 'default' | 'unselectable'
  index: number
  date: dayjs.Dayjs
  restaurantReservation: RestaurantReservation
  restaurantId: number
  onDragEnd?: (updates: {
    index?: number
    startAtMinutes?: number
    endAtMinutes?: number
  }) => void
  isWarning?: boolean
  scrollRootRef?: React.RefObject<Animated.ScrollView>
  scrollHorizontalRef?: React.RefObject<Animated.ScrollView>
  latestRootScrollViewEvent?: SharedValue<ReanimatedScrollEvent | null>
  latestHorizontalScrollViewEvent?: SharedValue<ReanimatedScrollEvent | null>
  consecutiveCount?: number
  isEditMode: boolean
  shouldShowEditUpIcon: boolean
  shouldShowEditDownIcon: boolean
  seatIndex?: number
  onLongPressEditMode?: () => void
}

function ReservationContainer(props: Props) {
  const { width, sm } = useResponsive()
  const isRestaurantSmartPaymentAvailable =
    useIsRestaurantSmartPaymentAvailable(props.restaurantId)
  const { data: customerAttributes } = useRestaurantAttributes(
    props.restaurantId
  )

  return (
    <ReservationPresentational
      {...props}
      width={width}
      sm={sm}
      isRestaurantSmartPaymentAvailable={isRestaurantSmartPaymentAvailable}
      customerAttributes={customerAttributes ?? []}
    />
  )
}

type PresentationalProps = Omit<Props, 'restaurantId'> & {
  width: number
  sm: number
  isRestaurantSmartPaymentAvailable: boolean
  customerAttributes: Attribute[]
}

export function ReservationPresentational({
  onPress,
  type = 'default',
  index,
  restaurantReservation,
  onDragEnd,
  date,
  isWarning = false,
  scrollRootRef,
  scrollHorizontalRef,
  latestRootScrollViewEvent,
  latestHorizontalScrollViewEvent,
  consecutiveCount = 1,
  isEditMode,
  onLongPressEditMode,
  width,
  sm,
  isRestaurantSmartPaymentAvailable,
  customerAttributes,
  shouldShowEditDownIcon,
  shouldShowEditUpIcon,
}: PresentationalProps) {
  const { width: windowWidth } = useWindowDimensions()
  const startAt = dayjs(restaurantReservation.start_at)
  const dayDiff = startAt.diff(date, 'day')
  const startTime = toSeconds(dayDiff * 24 + startAt.hour(), startAt.minute())
  const endAt = dayjs(restaurantReservation.end_at)

  const hours = endAt.diff(startAt, 'minute') / 60
  // 高さは連続数によって変化
  const height = getReservationCardHeight(consecutiveCount, width < sm)
  const chartWidth =
    width < sm ? HOURS.length * HOUR_SP_WIDTH : HOURS.length * HOUR_PC_WIDTH

  // 25時などの場合は 1day ずれる
  const x = getCellX(
    dayDiff * 24 + startAt.hour() + startAt.minute() / 60,
    width < sm
  )

  // 幅を管理するための新しいsharedValue
  const cardWidth = useSharedValue(getCellWidth(hours, width < sm))
  // 水平方向の移動量を追跡するsharedValue
  const translateX = useSharedValue(0)
  // 開始位置を保存するsharedValue
  const startTranslateX = useSharedValue(0)

  const startY = useSharedValue(getCellY(index, width < sm))
  const offsetY = useSharedValue(getCellY(index, width < sm))
  const startX = useSharedValue(x)
  const offsetX = useSharedValue(x)
  const onStartScrollViewOffsetY = useSharedValue(0)
  const onStartHorizontalScrollViewOffsetX = useSharedValue(0)

  const pressGesture = Gesture.Tap()
    .runOnJS(true)
    .onEnd((e, success) => {
      if (success) {
        onPress(e)
      }
    })
  pressGesture.enabled(type !== 'unselectable')
  const isDragging = useSharedValue(false)
  const isTimeDragging = useSharedValue(false)
  const cancelIntervalY = useRef<(() => void) | null>(null)
  const cancelIntervalX = useRef<(() => void) | null>(null)
  const cancelTimePanIntervalX = useRef<(() => void) | null>(null)
  const panGesture = Gesture.Pan()
    //  予約チップのrerender（再フェッチなど）とGestureのactive状態が同時に発生すると画面が固まるため、jsスレッド上で動かす
    .runOnJS(true)
    .activateAfterLongPress(isEditMode ? 100 : 350)
    .onStart(() => {
      if (!isEditMode) {
        onLongPressEditMode?.()
      }
      if (isTimeDragging.get()) return

      isDragging.set(true)
      onStartScrollViewOffsetY.set(
        latestRootScrollViewEvent?.get()?.contentOffset.y ?? 0
      )
      onStartHorizontalScrollViewOffsetX.set(
        latestHorizontalScrollViewEvent?.get()?.contentOffset.x ?? 0
      )
    })
    .onUpdate((e) => {
      if (isTimeDragging.get()) return
      if (!isDragging.get()) return
      const absoluteY = e.absoluteY
      const absoluteX = e.absoluteX

      const rootScrollViewOffsetYValue =
        latestRootScrollViewEvent?.get()?.contentOffset.y ?? 0
      const horizontalScrollViewOffsetXValue =
        latestHorizontalScrollViewEvent?.get()?.contentOffset.x ?? 0

      let biasY = 0
      let biasX = 0
      const isScrollToTop = ifScrollToTop(latestRootScrollViewEvent?.get())
      const isCloseToBottom = ifScrollToBottom(latestRootScrollViewEvent?.get())
      const isScrollToStart = ifScrollToStart(
        latestHorizontalScrollViewEvent?.get()
      )
      const isScrollToEnd = ifScrollToEnd(
        latestHorizontalScrollViewEvent?.get()
      )

      if (!isScrollToTop && absoluteY < 140) {
        biasY = -10
      } else if (!isCloseToBottom && absoluteY > 700) {
        biasY = 10
      }

      const chartAbsoluteStartX =
        Platform.OS === 'web'
          ? width < sm
            ? 100
            : 440
          : width < sm
            ? 100
            : 180
      if (!isScrollToStart && absoluteX < chartAbsoluteStartX) {
        biasX = -10
      } else if (
        !isScrollToEnd &&
        absoluteX > windowWidth - (width < sm ? 20 : 40)
      ) {
        biasX = 10
      }

      const y =
        startY.get() +
        e.translationY +
        rootScrollViewOffsetYValue -
        onStartScrollViewOffsetY.get()
      const { offsetY: newHeight } = calcAdjustedOffsetYAndIndex(y, width < sm)
      offsetY.set(newHeight)

      const next =
        startX.get() +
        e.translationX +
        horizontalScrollViewOffsetXValue -
        onStartHorizontalScrollViewOffsetX.get()
      const nextEnd = next + cardWidth.get()

      if (next < 0) {
        offsetX.set(0)
        return
      } else if (nextEnd >= chartWidth) {
        offsetX.set(chartWidth - cardWidth.get())
        return
      } else {
        const { offsetX: nextX } = calcAdjustedStartOffsetXAndStartTime(
          next,
          width < sm
        )
        offsetX.set(nextX)
      }

      if (biasY !== 0) {
        if (cancelIntervalY.current == null) {
          let loopBias = biasY
          let counter = 0
          cancelIntervalY.current = setIntervalByRequestAnimationFrame(() => {
            scrollRootRef?.current?.scrollTo({
              y: rootScrollViewOffsetYValue + loopBias,
              animated: false,
            })
            offsetY.set(
              startY.get() +
                e.translationY +
                rootScrollViewOffsetYValue -
                onStartScrollViewOffsetY.get() +
                loopBias
            )

            counter += 1
            loopBias += (biasY / 4) * counter
          }, 10)
        }
      } else {
        cancelIntervalY.current?.()
        cancelIntervalY.current = null
      }

      if (biasX !== 0) {
        if (cancelIntervalX.current == null) {
          let loopBias = biasX
          let counter = 0
          cancelIntervalX.current = setIntervalByRequestAnimationFrame(() => {
            scrollHorizontalRef?.current?.scrollTo({
              x: horizontalScrollViewOffsetXValue + loopBias,
              animated: false,
            })
            const next =
              startX.get() +
              e.translationX +
              horizontalScrollViewOffsetXValue -
              onStartHorizontalScrollViewOffsetX.get() +
              loopBias
            if (next < 0) {
              offsetX.set(0)
              return
            }

            if (next + cardWidth.get() > chartWidth) {
              offsetX.set(chartWidth - cardWidth.get())
              return
            }

            offsetX.set(next)
            counter += 1
            loopBias += (biasX / 4) * counter
          }, 10)
        }
      } else {
        cancelIntervalX.current?.()
        cancelIntervalX.current = null
      }
    })
    .onEnd(() => {
      if (isTimeDragging.get()) return

      const { offsetX: newOffsetX, startTime: adjustedMinutes } =
        calcAdjustedStartOffsetXAndStartTime(offsetX.get(), width < sm)
      offsetX.set(newOffsetX)

      const { offsetY: newHeight, index: nextIndex } =
        calcAdjustedOffsetYAndIndex(offsetY.get(), width < sm)
      offsetY.set(newHeight)

      if (onDragEnd != null) {
        const updates: Parameters<NonNullable<typeof onDragEnd>>[0] = {
          startAtMinutes: adjustedMinutes,
          endAtMinutes: adjustedMinutes + hours * 60,
        }
        if (nextIndex !== index) {
          updates.index = nextIndex
        }
        onDragEnd(updates)
      }
    })
    .onFinalize(() => {
      isDragging.set(false)
      cancelIntervalY.current?.()
      cancelIntervalY.current = null
      cancelIntervalX.current?.()
      cancelIntervalX.current = null
    })

  const timePanGesture = Gesture.Pan()
    .runOnJS(true)
    .enabled(isEditMode)
    .activateAfterLongPress(0)
    .onBegin(() => {
      isTimeDragging.set(true)
    })
    .onStart(() => {
      // CAUTION: Must not delete next line, this is required to prevent latestHorizontalScrollViewEvent.get() from being null
      latestHorizontalScrollViewEvent?.get()

      const startHorizontalScrollViewOffsetX =
        latestHorizontalScrollViewEvent?.get()?.contentOffset.x ?? 0
      startTranslateX.set(translateX.get())
      onStartHorizontalScrollViewOffsetX.set(startHorizontalScrollViewOffsetX)
    })
    .onUpdate((e) => {
      const horizontalScrollViewOffsetXValue =
        latestHorizontalScrollViewEvent?.get()?.contentOffset.x ?? 0
      const absoluteX = e.absoluteX
      translateX.set(startTranslateX.get() + e.translationX)
      const minWidth = getCellWidth(MIN_DURATION, width < sm)

      const { width: newWidth } = calcAdjustedReservationCellWidthAndMinutes(
        Math.max(
          minWidth,
          getCellWidth(hours, width < sm) +
            e.translationX +
            horizontalScrollViewOffsetXValue -
            onStartHorizontalScrollViewOffsetX.get()
        ),
        width < sm
      )

      const isScrollToStart = ifScrollToStart(
        latestHorizontalScrollViewEvent?.get()
      )
      const isScrollToEnd = ifScrollToEnd(
        latestHorizontalScrollViewEvent?.get()
      )
      let biasX = 0

      const chartAbsoluteStartX = Platform.OS === 'web' ? 440 : 180
      if (!isScrollToEnd && absoluteX > windowWidth - (width < sm ? 20 : 40)) {
        biasX = 10
      } else if (
        !isScrollToStart &&
        absoluteX < chartAbsoluteStartX &&
        cardWidth.get() > 27
      ) {
        biasX = -10
      }

      cardWidth.set(
        newWidth + offsetX.get() + 20 < chartWidth
          ? newWidth
          : chartWidth - offsetX.get() - 20
      )

      if (biasX !== 0) {
        if (cancelTimePanIntervalX.current == null) {
          let loopBias = biasX
          cancelTimePanIntervalX.current = setIntervalByRequestAnimationFrame(
            () => {
              scrollHorizontalRef?.current?.scrollTo({
                x: horizontalScrollViewOffsetXValue + loopBias,
                animated: false,
              })
              const newWidth = Math.max(
                minWidth,
                getCellWidth(hours, width < sm) +
                  e.translationX +
                  loopBias +
                  horizontalScrollViewOffsetXValue -
                  onStartHorizontalScrollViewOffsetX.get()
              )

              if (newWidth <= minWidth) {
                cardWidth.set(minWidth)
                return
              }

              if (newWidth + offsetX.get() + 15 > chartWidth) {
                cardWidth.set(chartWidth - offsetX.get() - 15)
                return
              }
              cardWidth.set(newWidth)
              loopBias += biasX
            },
            10
          )
        }
      } else {
        cancelTimePanIntervalX.current?.()
        cancelTimePanIntervalX.current = null
      }
    })
    .onEnd((_) => {
      const { width: newWidth, minutes: adjustedMinutes } =
        calcAdjustedReservationCellWidthAndMinutes(cardWidth.get(), width < sm)
      cardWidth.set(newWidth)

      // 5. 親コンポーネントに変更を通知
      if (onDragEnd) {
        let startHour: number
        if (date.format('YYYY-MM-DD') === startAt.format('YYYY-MM-DD')) {
          startHour = startAt.hour() + startAt.minute() / 60
        } else {
          startHour = startAt.hour() + 24 + startAt.minute() / 60
        }

        const startMinutesFromMidnight = startHour * 60

        // 新しい終了時間（分）を計算 = 開始時間（分）+ 調整後の時間（分）
        const newEndMinutesFromMidnight =
          startMinutesFromMidnight + adjustedMinutes
        onDragEnd({ endAtMinutes: newEndMinutesFromMidnight })
      }
    })
    .onFinalize(() => {
      isTimeDragging.set(false)
      cancelTimePanIntervalX.current?.()
      cancelTimePanIntervalX.current = null
    })
    .blocksExternalGesture(panGesture, pressGesture)

  const dragGesture = Gesture.Simultaneous(panGesture)
  const pressAndPanGesture = Gesture.Race(pressGesture, dragGesture)

  const dragAnimatedStyle = useAnimatedStyle((): ViewStyle => {
    const translateY = offsetY.get()
    const translateX = offsetX.get()
    const cursor =
      isDragging.get() || isTimeDragging.get() ? 'grabbing' : 'grab'
    const isWeb = Platform.OS === 'web'
    return {
      transform: [{ translateX }, { translateY }],
      width: cardWidth.get(), // 動的な幅を適用
      // ViewStyleがcursorのgrabbing/grabをサポートしていないため、@ts-ignoreで無理やり設定
      // @ts-ignore
      cursor: isWeb ? cursor : undefined,
    }
  }, [offsetX, offsetY, isDragging, cardWidth])
  const draggingOpacityStyle = useAnimatedStyle(() => ({
    opacity: isDragging.get() ? 0.5 : 1,
  }))
  const draggingIconStyle = useAnimatedStyle(() => ({
    display: isEditMode ? 'flex' : 'none',
  }))
  const draggingFrontStyle = useAnimatedStyle(() => ({
    zIndex: isEditMode || isDragging.get() ? 9999 : 1,
  }))

  const { backgroundColor, textColor } = calculateVisitStatusComponentProps(
    restaurantReservation.visit_status ?? 'reserved'
  )

  const cellWidth = getCellWidth(hours, width < sm)
  const prevIndex = usePrevious(index)
  // index変更時は位置を更新する
  useEffect(() => {
    if (prevIndex !== index) {
      offsetY.set(getCellY(index, width < sm))
    }
  }, [prevIndex, index, offsetY, sm, width])

  useEffect(() => {
    cardWidth.set(getCellWidth(hours, width < sm))
    startX.set(x)
    offsetX.set(x)
  }, [x, startX, offsetX, hours, width, sm, cardWidth])

  return (
    <GestureDetector gesture={pressAndPanGesture}>
      <Animated.View
        style={[
          {
            backgroundColor: isEditMode ? Colors.caution : backgroundColor,
            zIndex: isEditMode ? 9999 : 1,
            position: 'absolute',
            borderRadius: 4,
            paddingVertical: 4,
            paddingHorizontal: 8,
            height,
            // 静的な幅の設定は削除し、dragAnimatedStyleで動的に設定
          },
          dragAnimatedStyle,
          draggingFrontStyle,
          isWarning && {
            opacity: 0.4,
          },
        ]}
      >
        <View
          style={{
            top: 2,
            right: 2,
            position: 'absolute',
            flexDirection: 'row',
            gap: 2,
          }}
        >
          {hours > MIN_DURATION && (
            <>
              {isWarning && (
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  size={16}
                  color={Colors.caution}
                />
              )}
              {restaurantReservation.has_previous_table_order && (
                <FontAwesomeIcon
                  icon={faHistory}
                  size={16}
                  color={Colors.white}
                />
              )}
            </>
          )}
        </View>
        <Animated.View
          style={[
            {
              flexDirection: 'column',
              justifyContent: 'center',
              position: 'relative',
            },
            draggingOpacityStyle,
          ]}
        >
          {hours > MIN_DURATION && (
            <View
              style={{
                paddingVertical: width < sm && consecutiveCount === 1 ? 2 : 0,
              }}
            >
              <TimeAndPartySizeAndGourmetSite
                restaurantReservation={restaurantReservation}
                width={width}
                sm={sm}
                startTime={startTime}
                isEditMode={isEditMode}
              />
              <NameAndIcons
                restaurantReservation={restaurantReservation}
                textColor={isEditMode ? Colors.white : textColor}
                cellWidth={cellWidth - 16} // padding: 8px * 2
                height={consecutiveCount > 1 ? height - 28 : 21} // reservationText: 18px + padding: 4px * 2 + gap: 2px
                hours={hours}
                consecutiveCount={consecutiveCount}
                isRestaurantSmartPaymentAvailable={
                  isRestaurantSmartPaymentAvailable
                }
                customerAttributes={customerAttributes}
              />
            </View>
          )}
          {shouldShowEditUpIcon && (
            <Animated.View
              style={[
                {
                  width: '100%',
                  height: 16,
                  position: 'absolute',
                  top: -20,
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                draggingIconStyle,
              ]}
            >
              <FontAwesomeIcon icon={faSortUp} size={16} color={Colors.black} />
            </Animated.View>
          )}
          {shouldShowEditDownIcon && (
            <Animated.View
              style={[
                {
                  width: '100%',
                  height: 16,
                  position: 'absolute',
                  top: height - 4,
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                draggingIconStyle,
              ]}
            >
              <FontAwesomeIcon
                icon={faSortDown}
                size={16}
                color={Colors.black}
              />
            </Animated.View>
          )}
        </Animated.View>

        <GestureDetector gesture={timePanGesture}>
          <Animated.View
            style={[
              {
                width: 32,
                height: 32,
                borderRadius: 32,
                position: 'absolute',
                top: (height - 32) / 2,
                right: -16,
                alignItems: 'center',
                justifyContent: 'center',
                borderColor: Colors.caution,
                borderWidth: 2,
                backgroundColor: 'white',
                zIndex: 10000,
              },
              draggingIconStyle,
            ]}
          >
            <FontAwesomeIcon icon={faArrowsAltH} color={Colors.caution} />
          </Animated.View>
        </GestureDetector>
      </Animated.View>
    </GestureDetector>
  )
}

function TimeAndPartySizeAndGourmetSite(props: {
  restaurantReservation: RestaurantReservation
  width: number
  sm: number
  startTime: number
  isEditMode: boolean
}) {
  const { restaurantReservation, width, sm, startTime, isEditMode } = props

  const isReservedByAR =
    restaurantReservation.source === 'autoreserve' &&
    restaurantReservation.source_by === 'auto'

  const { backgroundColor, textColor } = calculateVisitStatusComponentProps(
    restaurantReservation.visit_status ?? 'reserved'
  )

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Text
        style={{
          flex: 1,
          color: isEditMode ? Colors.white : textColor,
          fontSize: 12,
          lineHeight: 18,
          alignSelf: 'flex-start',
        }}
        numberOfLines={1}
      >
        {width >= sm && `${getFormatTime(startTime)} - `}
        {t('{{party_size}}({{adult_party_size}},{{child_party_size}})', {
          party_size: t('{{count}}人', {
            count: restaurantReservation.party_size,
          }),
          adult_party_size: t('大人{{count}}', {
            count: restaurantReservation.adult_party_size,
          }),
          child_party_size: t('子供{{count}}', {
            count: restaurantReservation.child_party_size,
          }),
        })}
      </Text>
      <View style={{ flexShrink: 0, paddingVertical: 1 }}>
        {isSiteControllerReservation(restaurantReservation) && (
          <View
            style={{
              backgroundColor: isEditMode ? Colors.caution : backgroundColor,
              borderRadius: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingHorizontal: 4,
              borderWidth: 1,
              borderColor: isEditMode ? Colors.white : textColor,
              height: 16,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                lineHeight: 16,
                fontWeight: '300',
                color: isEditMode ? Colors.white : textColor,
              }}
            >
              {
                GOURMET_SITE_PROVIDER_SOURCE[
                  restaurantReservation
                    .reservation_site_controller_parsed_course
                    .site_controller_parsed_course.site
                ].simplifiedLabel
              }
            </Text>
          </View>
        )}
        {isReservedByAR && (
          <Image
            style={{
              height: 16,
              width: 16,
            }}
            source={require('../../../assets/images/ar_icon.png')}
          />
        )}
      </View>
    </View>
  )
}

function NameAndIcons(props: {
  restaurantReservation: RestaurantReservation
  textColor: string
  cellWidth: number
  height: number
  hours: number
  consecutiveCount: number
  customerAttributes: Attribute[]
  isRestaurantSmartPaymentAvailable: boolean
}) {
  const {
    restaurantReservation,
    textColor,
    cellWidth,
    height,
    hours,
    consecutiveCount,
    customerAttributes,
    isRestaurantSmartPaymentAvailable,
  } = props

  const reservationText = getReservationText(restaurantReservation)

  const isVip = restaurantReservation.customers.some((customer) =>
    hasCustomerAttribute(customer, customerAttributes, t('VIP'))
  )

  const needAttention = restaurantReservation.customers.some((customer) =>
    hasCustomerAttribute(customer, customerAttributes, t('要注意'))
  )

  const iconCount = calculateIconCount(
    restaurantReservation,
    isVip,
    needAttention
  )

  const iconMaxWidth = calculateIconMaxWidth(hours, iconCount, isVip, cellWidth)

  const hideReservationText = shouldDisplayEllipsis(iconCount, isVip, cellWidth)

  return (
    <View
      style={{
        flexDirection: consecutiveCount > 1 ? 'column' : 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height,
      }}
    >
      {(consecutiveCount > 1 || !hideReservationText) && (
        <Text
          style={{
            fontSize: 14,
            fontWeight: '600',
            color: textColor,
            flex: 1,
            alignSelf: 'flex-start',
            height: consecutiveCount > 1 ? 'auto' : height,
          }}
          numberOfLines={1}
        >
          {reservationText}
        </Text>
      )}

      {/* icons */}
      <View
        style={[
          {
            flexDirection: 'row',
            alignItems: 'center',
            gap: 2,
            flexShrink: 0,
            justifyContent: consecutiveCount > 1 ? 'flex-end' : 'flex-start',
            alignSelf: 'flex-start',
            width: consecutiveCount > 1 ? '100%' : iconMaxWidth,
            height: consecutiveCount > 1 ? 18 : height,
          },
        ]}
      >
        <View
          style={[
            {
              flexDirection: 'row',
              alignItems: 'center',
              alignSelf: 'center',
              gap: 2,
              flexShrink: 0,
              maxWidth: iconMaxWidth,
              overflow: 'hidden',
            },
          ]}
        >
          {isVip && (
            <View style={{ width: 28, height: 16 }}>
              <Image
                style={{
                  height: 16,
                  width: 28,
                }}
                source={require('../../../assets/images/chart_vip.png')}
              />
            </View>
          )}
          {needAttention && (
            <View style={{ width: 16, height: 16 }}>
              <Image
                style={{
                  height: 16,
                  width: 16,
                }}
                source={require('../../../assets/images/chart_caution.png')}
              />
            </View>
          )}
          {restaurantReservation.smart_payment != null &&
            isRestaurantSmartPaymentAvailable && (
              <View
                style={{
                  width: 16,
                  height: 16,
                  backgroundColor: Colors.primary,
                  borderColor: Colors.white,
                  borderWidth: 1,
                  borderRadius: 16,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                    lineHeight: 14,
                    fontWeight: '600',
                    color: Colors.white,
                  }}
                >
                  {t('ス')}
                </Text>
              </View>
            )}
          {!!restaurantReservation.memo && (
            <View style={{ width: 16 }}>
              <FontAwesomeIcon icon={faPenToSquare} size={16} color={'white'} />
            </View>
          )}
          {restaurantReservation.reservation_courses !== undefined &&
            restaurantReservation.reservation_courses.length > 0 && (
              <View style={{ width: 16 }}>
                <FontAwesomeIcon
                  icon={faUtensils}
                  size={16}
                  color={Colors.white}
                />
              </View>
            )}
          {restaurantReservation.reservation_tags?.map((tag) => {
            const icon = getTagIcon(tag.name)
            if (icon === undefined) return null
            return (
              <View key={tag.id} style={{ width: 16 }}>
                <FontAwesomeIcon icon={icon} size={16} color={Colors.white} />
              </View>
            )
          })}
        </View>
        {hideReservationText && (
          <Text
            style={{
              color: Colors.white,
              fontSize: 10,
              fontWeight: '600',
            }}
          >
            ･･･
          </Text>
        )}
      </View>
    </View>
  )
}

export { ReservationContainer as Reservation }
