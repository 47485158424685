import type React from 'react'

import { Path, Svg } from '@react-pdf/renderer'

type AllergyIconProps = {
  size?: number
  color?: string
}

export function AllergyIcon({
  size = 16,
  color = '#444444',
}: AllergyIconProps): JSX.Element {
  return (
    <Svg width={size} height={size} viewBox="0 0 16 16">
      <Path
        d="M0 8C0 3.58065 3.58065 0 8 0C12.4194 0 16 3.58065 16 8C16 12.4194 12.4194 16 8 16C3.58065 16 0 12.4194 0 8ZM11.6129 6.45157C11.6129 5.8806 11.1516 5.41931 10.5806 5.41931C10.0096 5.41931 9.54834 5.8806 9.54834 6.45157C9.54834 7.02254 10.0096 7.48383 10.5806 7.48383C11.1516 7.48383 11.6129 7.02254 11.6129 6.45157ZM5.41947 5.41943C5.99043 5.41943 6.45172 5.88072 6.45172 6.45169C6.45172 7.02266 5.99043 7.48395 5.41947 7.48395C4.8485 7.48395 4.38721 7.02266 4.38721 6.45169C4.38721 5.88072 4.8485 5.41943 5.41947 5.41943ZM8.00018 11.0968C9.12599 11.0968 10.1873 11.5936 10.9099 12.4581C11.3453 12.9839 12.1389 12.3226 11.7002 11.8001C10.7841 10.6968 9.43244 10.0646 8.00018 10.0646C6.56793 10.0646 5.21631 10.6968 4.29696 11.7968C3.86147 12.3226 4.65502 12.9839 5.09051 12.4581C5.81309 11.5936 6.87438 11.0968 8.00018 11.0968Z"
        fillRule="evenodd"
        fill={color}
        fillOpacity={0.6}
      />
    </Svg>
  )
}
