import { createApiTableClient } from '@hello-ai/api_table'

import instance from './axios'
import config from './config'

let client = createApiTableClient(instance)
config.subscribe(() => {
  client = createApiTableClient(instance)
})

export { client }
