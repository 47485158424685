import React from 'react'

import dayjs from 'dayjs'
import { View } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import {
  Payment,
  PaymentStatusType,
  PaymentStatusTypes,
  getPaymentStatusLabel,
} from 'models/Payment'
import numberWithDelimiter from 'modules/numberWithDelimiter'

const getPaymentStatusColor = (status?: PaymentStatusType) => {
  switch (status) {
    case PaymentStatusTypes.Paid:
      return Colors.success
    case PaymentStatusTypes.Pending:
    case PaymentStatusTypes.InTransit:
      return Colors.primary
    case PaymentStatusTypes.Canceled:
      return Colors.caution
    case PaymentStatusTypes.Failed:
      return Colors.caution
    default:
      return Colors.black60
  }
}

export default function PaymentContent({ payment }: { payment: Payment }) {
  const depositAmount = numberWithDelimiter(payment?.deposit_amount ?? 0)

  return (
    <View>
      {payment?.status ? (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 8,
          }}
        >
          <Text style={{ fontSize: 20, fontWeight: '600' }}>
            {payment?.arrival_date != null
              ? // eslint-disable-next-line ar-i18n/require-translation-ja
                dayjs(payment?.arrival_date).format('YYYY年MM月DD日')
              : '-'}
          </Text>
          <View
            style={{
              marginLeft: 16,
              height: 24,
              paddingHorizontal: 8,
              justifyContent: 'center',
              backgroundColor: getPaymentStatusColor(payment?.status),
            }}
          >
            <Text
              style={{
                fontSize: 14,
                lineHeight: 14,
                color: 'white',
              }}
            >
              {getPaymentStatusLabel(payment?.status)}
            </Text>
          </View>
          <View style={{ marginLeft: 16 }}>
            <Text style={{ fontSize: 16, fontWeight: '600' }}>
              {t('{{price}}円', {
                price: depositAmount,
              })}
            </Text>
          </View>
        </View>
      ) : (
        <Text style={{ fontSize: 28, fontWeight: '600' }}>{t('未定')}</Text>
      )}
    </View>
  )
}
