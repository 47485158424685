import React, { useState } from 'react'

import { View } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import CountryModal, {
  CountryCodes,
} from '@hello-ai/ar_shared/src/components/CountryModal'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import PhoneNumberInput from 'components/Shared/PhoneNumberInput'
import useReCaptcha, {
  GoogleReCaptchaScript,
} from 'components/Shared/ReCaptcha'

import { sendGAEvent } from '../../../modules/analytics'
import { StepItem } from '../Stepper'
import { GA_SIGNUP_STEPS } from '../const'

const GOOGLE_RECAPTCHA_CONTAINER_ID = 'google-recaptcha-container'
interface VerifyPhoneFormProps {
  onPressSubmit: (
    type: 'sms' | 'call',
    params: {
      captchaToken: string
      dialCode: string
      phoneNumber: string
    }
  ) => void
  defaultValues: {
    dialCode?: string
    phoneNumber?: string
  }
}

export default function SendCodeForm({
  onPressSubmit,
  defaultValues,
}: VerifyPhoneFormProps) {
  const { width, sm } = useResponsive()

  const [isVisibleCountryModal, setIsVisibleCountryModal] = useState(false)
  const [country, setCountry] = useState(
    CountryCodes.find((c) => {
      if (defaultValues.dialCode === c.dial_code) return true
      return c.code === 'JP'
    }) ?? CountryCodes[0]
  )
  const [phoneNumber, setPhoneNumber] = useState<string>(
    defaultValues.phoneNumber ?? ''
  )
  const [captchaToken, setCaptchaToken] = useState<string | null>(null)

  const successCallback = (response: string) => {
    setCaptchaToken(response)
  }
  const expiredCallback = () => setCaptchaToken(null)
  const errorCallback = () => setCaptchaToken(null)

  useReCaptcha({
    containerId: GOOGLE_RECAPTCHA_CONTAINER_ID,
    successCallback,
    expiredCallback,
    size: 'normal',
    theme: 'light',
    badge: 'inline',
    errorCallback,
  })

  const disabledSubmitButton = captchaToken === null || phoneNumber === ''

  const _onPressSubmit = () => {
    if (disabledSubmitButton) return

    // GAイベント送信
    sendGAEvent({ event: 'click', step: GA_SIGNUP_STEPS.PHONE_REGISTER })

    onPressSubmit('sms', {
      captchaToken,
      dialCode: country.dial_code,
      phoneNumber,
    })
  }

  return (
    <>
      <StepItem
        stepNumber={2}
        state="selected"
        labelTextStyle={{
          fontSize: 22,
          fontWeight: '600',
          lineHeight: 33,
        }}
        hiddenCircle={width < sm}
      >
        {t('電話番号を登録します')}
      </StepItem>
      <Text
        style={{
          marginVertical: width < sm ? 32 : 40,
          textAlign: 'center',
          fontSize: width < sm ? 14 : 18,
          lineHeight: width < sm ? 21 : 27,
          fontWeight: '300',
          width: '100%',
        }}
      >
        {t(
          '続いて電話番号の登録をおこないます。オーナー様ご自身の電話番号を入力してください。'
        )}
      </Text>

      <View
        style={{
          width: '100%',
          paddingVertical: 24,
          paddingHorizontal: 24,
          justifyContent: 'center',
          alignItems: 'center',
          borderColor: Colors.border,
          borderWidth: 1,
          borderRadius: 8,
        }}
      >
        <FormGroup
          formLabel={
            <FormLabel
              value={t('電話番号')}
              labelStyle={[width < sm && { fontSize: 14 }]}
            />
          }
          mode="vertical"
          style={{
            width: '100%',
            marginBottom: width < sm ? 24 : 40,
          }}
          containerStyle={{
            marginTop: width < sm ? 4 : 8,
          }}
        >
          <PhoneNumberInput
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            country={country}
            setCountry={setCountry}
            onPressEnter={_onPressSubmit}
          />
        </FormGroup>
        <GoogleReCaptchaScript />
        <div
          id={GOOGLE_RECAPTCHA_CONTAINER_ID}
          style={{
            marginBottom: width < sm ? 24 : 40,
          }}
        />
        <Button
          disabled={disabledSubmitButton}
          style={[{ marginBottom: 24 }, width < sm && { width: '100%' }]}
          textStyle={{
            fontSize: width < sm ? 14 : 16,
            fontWeight: '600',
            lineHeight: width < sm ? 21 : 24,
          }}
          variant="primary"
          mode="contained"
          onPress={_onPressSubmit}
        >
          {t('SMSで認証コードを送信する')}
        </Button>
        <View style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Button
            variant="primary"
            mode="text"
            disabled={disabledSubmitButton}
            onPress={() => {
              if (captchaToken == null) return

              // GAイベント送信
              sendGAEvent({ event: 'click', step: 'phone_register' })

              onPressSubmit('call', {
                captchaToken,
                dialCode: country.dial_code,
                phoneNumber,
              })
            }}
            style={{ height: 'auto' }}
            textStyle={{
              fontSize: width < sm ? 14 : 16,
              fontWeight: '300',
              lineHeight: width < sm ? 24 : 18,
            }}
          >
            {t('音声通話でコードを取得する')}
          </Button>
        </View>
      </View>
      <CountryModal
        style={{
          backgroundColor: Colors.white,
        }}
        contentContainerStyle={{
          height: '100%',
        }}
        isVisible={isVisibleCountryModal}
        onClose={() => setIsVisibleCountryModal(false)}
        defaultSelectedCountry={country}
        onPressCountry={(country) => {
          setCountry(country)
          setIsVisibleCountryModal(false)
        }}
      />
    </>
  )
}
