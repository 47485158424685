import type { AspidaClient, BasicHeaders } from 'aspida';
import { dataToURLString } from 'aspida';
import type { Methods as Methods_h32p34 } from './business_users/auth/_provider@string/callback';
import type { Methods as Methods_119nakd } from './business_users/login_token_sessions';
import type { Methods as Methods_ffukpu } from './freee/auth/authorization_url';
import type { Methods as Methods_1px6dbt } from './freee/auth/revoke';
import type { Methods as Methods_u1iu9r } from './freee/companies';
import type { Methods as Methods_4doldb } from './freee/connection_status';
import type { Methods as Methods_1htx3ws } from './freee/sections';
import type { Methods as Methods_m7ico2 } from './freee/walletables';
import type { Methods as Methods_1ho5mdp } from './orders/_id@string';
import type { Methods as Methods_1pphpj8 } from './owner/_owner_id@number/reports/_report_type@string/_date@string';
import type { Methods as Methods_1aqyuyo } from './owners/_owner_id@number/sales_dashboard/_report_type@string';
import type { Methods as Methods_1amht6s } from './reservation_book/entry_plan_invitations/_token@string';
import type { Methods as Methods_1rsblrp } from './reservation_book/entry_plan_invitations/_token@string/accept';
import type { Methods as Methods_1lze2l5 } from './reservation_book/restaurants/_restaurant_id@number/reservation_requests/_reservation_element_id@number';
import type { Methods as Methods_1co6283 } from './reservation_book/restaurants/_restaurant_id@number/reservations';
import type { Methods as Methods_kmzvoc } from './reservation_book/restaurants/_restaurant_id@number/reservations/_reservation_id@string';
import type { Methods as Methods_1sgu2k4 } from './reservation_book/restaurants/_restaurant_id@number/reservations/_reservation_id@string/reservation_update_histories';
import type { Methods as Methods_113axsy } from './reservation_book/restaurants/_restaurant_id@number/reservations/_reservation_id@string/smart_payment/cancel';
import type { Methods as Methods_14hslcq } from './reservation_book/restaurants/_restaurant_id@number/reservations/_reservation_id@string/smart_payment/capture';
import type { Methods as Methods_vc0d4c } from './reservation_book/restaurants/_restaurant_id@number/reservations/_reservation_id@string/smart_payment/receipt';
import type { Methods as Methods_p2aw6u } from './reservation_book/restaurants/_restaurant_id@number/restaurant_courses';
import type { Methods as Methods_ip7nvo } from './reservation_book/restaurants/_restaurant_id@number/seat_statuses';
import type { Methods as Methods_za4e1x } from './reservation_book/restaurants/_restaurant_id@number/smart_payments';
import type { Methods as Methods_1sv7onk } from './reservation_book/restaurants/_restaurant_id@string/reservation_request_count';
import type { Methods as Methods_11qvwwz } from './restaurants/_restaurant_id@number/broadcast_messages';
import type { Methods as Methods_1nwdj7h } from './restaurants/_restaurant_id@number/broadcast_messages/_id@string';
import type { Methods as Methods_1ayg74h } from './restaurants/_restaurant_id@number/broadcast_messages/number_of_customers';
import type { Methods as Methods_1vsxj72 } from './restaurants/_restaurant_id@number/broadcast_messages/number_of_targets';
import type { Methods as Methods_311p0n } from './restaurants/_restaurant_id@number/cti_devices';
import type { Methods as Methods_1wef89h } from './restaurants/_restaurant_id@number/cti_devices/_cti_device_id@number';
import type { Methods as Methods_1xn18ib } from './restaurants/_restaurant_id@number/customers/_customer_id@string';
import type { Methods as Methods_1onvpjs } from './restaurants/_restaurant_id@number/floors';
import type { Methods as Methods_5vmly9 } from './restaurants/_restaurant_id@number/floors/_floor_id@string';
import type { Methods as Methods_185shua } from './restaurants/_restaurant_id@number/floors/_floor_id@string/positions';
import type { Methods as Methods_16reaok } from './restaurants/_restaurant_id@number/floors/_floor_id@string/seats';
import type { Methods as Methods_1wgvpme } from './restaurants/_restaurant_id@number/line_message_channels';
import type { Methods as Methods_1vnkm1m } from './restaurants/_restaurant_id@number/line_notification_setting';
import type { Methods as Methods_qn4evz } from './restaurants/_restaurant_id@number/products';
import type { Methods as Methods_1bpsyux } from './restaurants/_restaurant_id@number/products/statuses_bulk_update';
import type { Methods as Methods_10wkgnu } from './restaurants/_restaurant_id@number/reservation_activity_notifications';
import type { Methods as Methods_6nfnqd } from './restaurants/_restaurant_id@number/reservation_activity_notifications/statuses';
import type { Methods as Methods_1iv66nu } from './restaurants/_restaurant_id@number/reservation_cancel_fees';
import type { Methods as Methods_1ryrk9a } from './restaurants/_restaurant_id@number/restaurant_course_archives/_id@number';
import type { Methods as Methods_qo68gf } from './restaurants/_restaurant_id@number/restaurant_course_bulk_update';
import type { Methods as Methods_dap3cx } from './restaurants/_restaurant_id@number/restaurant_courses';
import type { Methods as Methods_9jzxmb } from './restaurants/_restaurant_id@number/restaurant_courses/_id@number';
import type { Methods as Methods_1ynfmxz } from './restaurants/_restaurant_id@number/restaurant_courses/_restaurant_course_id@number/restaurant_course_business_times';
import type { Methods as Methods_15kczvl } from './restaurants/_restaurant_id@number/restaurant_courses/_restaurant_course_id@number/restaurant_course_business_times/bulk_create';
import type { Methods as Methods_15hymo6 } from './restaurants/_restaurant_id@number/restaurant_courses/_restaurant_course_id@number/restaurant_course_business_times/bulk_delete';
import type { Methods as Methods_1l0xkuz } from './restaurants/_restaurant_id@number/restaurant_setting';
import type { Methods as Methods_1p95naf } from './restaurants/_restaurant_id@number/seats';
import type { Methods as Methods_1938y0s } from './restaurants/_restaurant_id@number/unread_message_count';
import type { Methods as Methods_zmpk9j } from './restaurants/_restaurant_id@number/visit_status_settings';
import type { Methods as Methods_uf7f9d } from './restaurants/_restaurant_id@number/visit_status_settings/_visit_status_setting_id@number';
import type { Methods as Methods_eqa4uw } from './seats/_id@string';

const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? 'http://localhost:3001' : baseURL).replace(/\/$/, '');
  const PATH0 = '/business_users/auth';
  const PATH1 = '/callback';
  const PATH2 = '/business_users/login_token_sessions';
  const PATH3 = '/freee/auth/authorization_url';
  const PATH4 = '/freee/auth/revoke';
  const PATH5 = '/freee/companies';
  const PATH6 = '/freee/connection_status';
  const PATH7 = '/freee/sections';
  const PATH8 = '/freee/walletables';
  const PATH9 = '/orders';
  const PATH10 = '/owner';
  const PATH11 = '/reports';
  const PATH12 = '/owners';
  const PATH13 = '/sales_dashboard';
  const PATH14 = '/reservation_book/entry_plan_invitations';
  const PATH15 = '/accept';
  const PATH16 = '/reservation_book/restaurants';
  const PATH17 = '/reservation_requests';
  const PATH18 = '/reservations';
  const PATH19 = '/reservation_update_histories';
  const PATH20 = '/smart_payment/cancel';
  const PATH21 = '/smart_payment/capture';
  const PATH22 = '/smart_payment/receipt';
  const PATH23 = '/restaurant_courses';
  const PATH24 = '/seat_statuses';
  const PATH25 = '/smart_payments';
  const PATH26 = '/reservation_request_count';
  const PATH27 = '/restaurants';
  const PATH28 = '/broadcast_messages';
  const PATH29 = '/broadcast_messages/number_of_customers';
  const PATH30 = '/broadcast_messages/number_of_targets';
  const PATH31 = '/cti_devices';
  const PATH32 = '/customers';
  const PATH33 = '/floors';
  const PATH34 = '/positions';
  const PATH35 = '/seats';
  const PATH36 = '/line_message_channels';
  const PATH37 = '/line_notification_setting';
  const PATH38 = '/products';
  const PATH39 = '/products/statuses_bulk_update';
  const PATH40 = '/reservation_activity_notifications';
  const PATH41 = '/reservation_activity_notifications/statuses';
  const PATH42 = '/reservation_cancel_fees';
  const PATH43 = '/restaurant_course_archives';
  const PATH44 = '/restaurant_course_bulk_update';
  const PATH45 = '/restaurant_course_business_times';
  const PATH46 = '/restaurant_course_business_times/bulk_create';
  const PATH47 = '/restaurant_course_business_times/bulk_delete';
  const PATH48 = '/restaurant_setting';
  const PATH49 = '/unread_message_count';
  const PATH50 = '/visit_status_settings';
  const GET = 'GET';
  const POST = 'POST';
  const PUT = 'PUT';
  const DELETE = 'DELETE';
  const PATCH = 'PATCH';

  return {
    business_users: {
      auth: {
        _provider: (val2: string) => {
          const prefix2 = `${PATH0}/${val2}`;

          return {
            callback: {
              /**
               * @returns 認証成功
               */
              post: (option: { body: Methods_h32p34['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_h32p34['post']['resBody'], BasicHeaders, Methods_h32p34['post']['status']>(prefix, `${prefix2}${PATH1}`, POST, option).json(),
              /**
               * @returns 認証成功
               */
              $post: (option: { body: Methods_h32p34['post']['reqBody'], config?: T | undefined }) =>
                fetch<Methods_h32p34['post']['resBody'], BasicHeaders, Methods_h32p34['post']['status']>(prefix, `${prefix2}${PATH1}`, POST, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH1}`,
            },
          };
        },
      },
      login_token_sessions: {
        /**
         * @returns ログイン成功
         */
        post: (option: { body: Methods_119nakd['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_119nakd['post']['resBody'], BasicHeaders, Methods_119nakd['post']['status']>(prefix, PATH2, POST, option).json(),
        /**
         * @returns ログイン成功
         */
        $post: (option: { body: Methods_119nakd['post']['reqBody'], config?: T | undefined }) =>
          fetch<Methods_119nakd['post']['resBody'], BasicHeaders, Methods_119nakd['post']['status']>(prefix, PATH2, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH2}`,
      },
    },
    freee: {
      auth: {
        authorization_url: {
          /**
           * @returns freeeの認証URLを返す
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_ffukpu['get']['resBody'], BasicHeaders, Methods_ffukpu['get']['status']>(prefix, PATH3, GET, option).json(),
          /**
           * @returns freeeの認証URLを返す
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_ffukpu['get']['resBody'], BasicHeaders, Methods_ffukpu['get']['status']>(prefix, PATH3, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH3}`,
        },
        revoke: {
          delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods_1px6dbt['delete']['status']>(prefix, PATH4, DELETE, option).send(),
          $delete: (option?: { config?: T | undefined } | undefined) =>
            fetch<void, BasicHeaders, Methods_1px6dbt['delete']['status']>(prefix, PATH4, DELETE, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH4}`,
        },
      },
      companies: {
        /**
         * オーナーがログインしている場合に、freeeと連携済みであれば事業所一覧を返す。
         * 未連携なら403を返す。
         * @returns 正常に事業所一覧を返す
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_u1iu9r['get']['resBody'], BasicHeaders, Methods_u1iu9r['get']['status']>(prefix, PATH5, GET, option).json(),
        /**
         * オーナーがログインしている場合に、freeeと連携済みであれば事業所一覧を返す。
         * 未連携なら403を返す。
         * @returns 正常に事業所一覧を返す
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_u1iu9r['get']['resBody'], BasicHeaders, Methods_u1iu9r['get']['status']>(prefix, PATH5, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH5}`,
      },
      connection_status: {
        /**
         * オーナーもしくはビジネスユーザーがログインしている場合に、 freee 連携が完了しているかどうかを返します。
         * @returns 成功時に connected フラグを返す
         */
        get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_4doldb['get']['resBody'], BasicHeaders, Methods_4doldb['get']['status']>(prefix, PATH6, GET, option).json(),
        /**
         * オーナーもしくはビジネスユーザーがログインしている場合に、 freee 連携が完了しているかどうかを返します。
         * @returns 成功時に connected フラグを返す
         */
        $get: (option?: { config?: T | undefined } | undefined) =>
          fetch<Methods_4doldb['get']['resBody'], BasicHeaders, Methods_4doldb['get']['status']>(prefix, PATH6, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH6}`,
      },
      sections: {
        /**
         * オーナーでログインしている場合、指定した事業所(company_id)の部門一覧を返す。
         * freee未連携の場合は403を返す。
         * @returns 部門一覧を返す
         */
        get: (option: { query: Methods_1htx3ws['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1htx3ws['get']['resBody'], BasicHeaders, Methods_1htx3ws['get']['status']>(prefix, PATH7, GET, option).json(),
        /**
         * オーナーでログインしている場合、指定した事業所(company_id)の部門一覧を返す。
         * freee未連携の場合は403を返す。
         * @returns 部門一覧を返す
         */
        $get: (option: { query: Methods_1htx3ws['get']['query'], config?: T | undefined }) =>
          fetch<Methods_1htx3ws['get']['resBody'], BasicHeaders, Methods_1htx3ws['get']['status']>(prefix, PATH7, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_1htx3ws['get']['query'] } | undefined) =>
          `${prefix}${PATH7}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
      walletables: {
        /**
         * type=wallet の口座のみ取得し、「Respo用口座」が無ければ (id: null, name: "Respo用口座") を追加する。
         * company_id はクエリパラメータとして必須。
         * @returns 正常に口座一覧を返す
         */
        get: (option: { query: Methods_m7ico2['get']['query'], config?: T | undefined }) =>
          fetch<Methods_m7ico2['get']['resBody'], BasicHeaders, Methods_m7ico2['get']['status']>(prefix, PATH8, GET, option).json(),
        /**
         * type=wallet の口座のみ取得し、「Respo用口座」が無ければ (id: null, name: "Respo用口座") を追加する。
         * company_id はクエリパラメータとして必須。
         * @returns 正常に口座一覧を返す
         */
        $get: (option: { query: Methods_m7ico2['get']['query'], config?: T | undefined }) =>
          fetch<Methods_m7ico2['get']['resBody'], BasicHeaders, Methods_m7ico2['get']['status']>(prefix, PATH8, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get' | undefined; query: Methods_m7ico2['get']['query'] } | undefined) =>
          `${prefix}${PATH8}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
      },
    },
    orders: {
      _id: (val1: string) => {
        const prefix1 = `${PATH9}/${val1}`;

        return {
          /**
           * @returns order details
           */
          get: (option?: { query?: Methods_1ho5mdp['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1ho5mdp['get']['resBody'], BasicHeaders, Methods_1ho5mdp['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * @returns order details
           */
          $get: (option?: { query?: Methods_1ho5mdp['get']['query'] | undefined, config?: T | undefined } | undefined) =>
            fetch<Methods_1ho5mdp['get']['resBody'], BasicHeaders, Methods_1ho5mdp['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          $path: (option?: { method?: 'get' | undefined; query: Methods_1ho5mdp['get']['query'] } | undefined) =>
            `${prefix}${prefix1}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
        };
      },
    },
    owner: {
      _owner_id: (val1: number) => {
        const prefix1 = `${PATH10}/${val1}`;

        return {
          reports: {
            _report_type: (val3: string) => {
              const prefix3 = `${prefix1}${PATH11}/${val3}`;

              return {
                _date: (val4: string) => {
                  const prefix4 = `${prefix3}/${val4}`;

                  return {
                    /**
                     * @returns A list of reports
                     */
                    get: (option?: { query?: Methods_1pphpj8['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                      fetch<Methods_1pphpj8['get']['resBody'], BasicHeaders, Methods_1pphpj8['get']['status']>(prefix, prefix4, GET, option).json(),
                    /**
                     * @returns A list of reports
                     */
                    $get: (option?: { query?: Methods_1pphpj8['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                      fetch<Methods_1pphpj8['get']['resBody'], BasicHeaders, Methods_1pphpj8['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get' | undefined; query: Methods_1pphpj8['get']['query'] } | undefined) =>
                      `${prefix}${prefix4}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
                  };
                },
              };
            },
          },
        };
      },
    },
    owners: {
      _owner_id: (val1: number) => {
        const prefix1 = `${PATH12}/${val1}`;

        return {
          sales_dashboard: {
            _report_type: (val3: string) => {
              const prefix3 = `${prefix1}${PATH13}/${val3}`;

              return {
                /**
                 * @returns A financial report
                 */
                get: (option: { query: Methods_1aqyuyo['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_1aqyuyo['get']['resBody'], BasicHeaders, Methods_1aqyuyo['get']['status']>(prefix, prefix3, GET, option).json(),
                /**
                 * @returns A financial report
                 */
                $get: (option: { query: Methods_1aqyuyo['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_1aqyuyo['get']['resBody'], BasicHeaders, Methods_1aqyuyo['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_1aqyuyo['get']['query'] } | undefined) =>
                  `${prefix}${prefix3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              };
            },
          },
        };
      },
    },
    reservation_book: {
      entry_plan_invitations: {
        _token: (val2: string) => {
          const prefix2 = `${PATH14}/${val2}`;

          return {
            accept: {
              post: (option?: { config?: T | undefined } | undefined) =>
                fetch<void, BasicHeaders, Methods_1rsblrp['post']['status']>(prefix, `${prefix2}${PATH15}`, POST, option).send(),
              $post: (option?: { config?: T | undefined } | undefined) =>
                fetch<void, BasicHeaders, Methods_1rsblrp['post']['status']>(prefix, `${prefix2}${PATH15}`, POST, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH15}`,
            },
            /**
             * @returns 取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1amht6s['get']['resBody'], BasicHeaders, Methods_1amht6s['get']['status']>(prefix, prefix2, GET, option).json(),
            /**
             * @returns 取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1amht6s['get']['resBody'], BasicHeaders, Methods_1amht6s['get']['status']>(prefix, prefix2, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix2}`,
          };
        },
      },
      restaurants: {
        _restaurant_id_number: (val2: number) => {
          const prefix2 = `${PATH16}/${val2}`;

          return {
            reservation_requests: {
              _reservation_element_id: (val4: number) => {
                const prefix4 = `${prefix2}${PATH17}/${val4}`;

                return {
                  /**
                   * @returns OK
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1lze2l5['get']['resBody'], BasicHeaders, Methods_1lze2l5['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * @returns OK
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1lze2l5['get']['resBody'], BasicHeaders, Methods_1lze2l5['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
            },
            reservations: {
              _reservation_id: (val4: string) => {
                const prefix4 = `${prefix2}${PATH18}/${val4}`;

                return {
                  reservation_update_histories: {
                    /**
                     * @returns 予約更新履歴一覧取得成功
                     */
                    get: (option?: { query?: Methods_1sgu2k4['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                      fetch<Methods_1sgu2k4['get']['resBody'], BasicHeaders, Methods_1sgu2k4['get']['status']>(prefix, `${prefix4}${PATH19}`, GET, option).json(),
                    /**
                     * @returns 予約更新履歴一覧取得成功
                     */
                    $get: (option?: { query?: Methods_1sgu2k4['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                      fetch<Methods_1sgu2k4['get']['resBody'], BasicHeaders, Methods_1sgu2k4['get']['status']>(prefix, `${prefix4}${PATH19}`, GET, option).json().then(r => r.body),
                    $path: (option?: { method?: 'get' | undefined; query: Methods_1sgu2k4['get']['query'] } | undefined) =>
                      `${prefix}${prefix4}${PATH19}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
                  },
                  smart_payment: {
                    cancel: {
                      /**
                       * @returns canceled
                       */
                      post: (option?: { config?: T | undefined } | undefined) =>
                        fetch<Methods_113axsy['post']['resBody'], BasicHeaders, Methods_113axsy['post']['status']>(prefix, `${prefix4}${PATH20}`, POST, option).json(),
                      /**
                       * @returns canceled
                       */
                      $post: (option?: { config?: T | undefined } | undefined) =>
                        fetch<Methods_113axsy['post']['resBody'], BasicHeaders, Methods_113axsy['post']['status']>(prefix, `${prefix4}${PATH20}`, POST, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix4}${PATH20}`,
                    },
                    capture: {
                      /**
                       * @returns captured
                       */
                      post: (option?: { config?: T | undefined } | undefined) =>
                        fetch<Methods_14hslcq['post']['resBody'], BasicHeaders, Methods_14hslcq['post']['status']>(prefix, `${prefix4}${PATH21}`, POST, option).json(),
                      /**
                       * @returns captured
                       */
                      $post: (option?: { config?: T | undefined } | undefined) =>
                        fetch<Methods_14hslcq['post']['resBody'], BasicHeaders, Methods_14hslcq['post']['status']>(prefix, `${prefix4}${PATH21}`, POST, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix4}${PATH21}`,
                    },
                    receipt: {
                      /**
                       * @returns receipt
                       */
                      get: (option?: { config?: T | undefined } | undefined) =>
                        fetch<Methods_vc0d4c['get']['resBody'], BasicHeaders, Methods_vc0d4c['get']['status']>(prefix, `${prefix4}${PATH22}`, GET, option).json(),
                      /**
                       * @returns receipt
                       */
                      $get: (option?: { config?: T | undefined } | undefined) =>
                        fetch<Methods_vc0d4c['get']['resBody'], BasicHeaders, Methods_vc0d4c['get']['status']>(prefix, `${prefix4}${PATH22}`, GET, option).json().then(r => r.body),
                      /**
                       * @returns object created
                       */
                      post: (option: { body: Methods_vc0d4c['post']['reqBody'], config?: T | undefined }) =>
                        fetch<Methods_vc0d4c['post']['resBody'], BasicHeaders, Methods_vc0d4c['post']['status']>(prefix, `${prefix4}${PATH22}`, POST, option).json(),
                      /**
                       * @returns object created
                       */
                      $post: (option: { body: Methods_vc0d4c['post']['reqBody'], config?: T | undefined }) =>
                        fetch<Methods_vc0d4c['post']['resBody'], BasicHeaders, Methods_vc0d4c['post']['status']>(prefix, `${prefix4}${PATH22}`, POST, option).json().then(r => r.body),
                      $path: () => `${prefix}${prefix4}${PATH22}`,
                    },
                  },
                  /**
                   * @returns 取得成功
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_kmzvoc['get']['resBody'], BasicHeaders, Methods_kmzvoc['get']['status']>(prefix, prefix4, GET, option).json(),
                  /**
                   * @returns 取得成功
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_kmzvoc['get']['resBody'], BasicHeaders, Methods_kmzvoc['get']['status']>(prefix, prefix4, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix4}`,
                };
              },
              /**
               * @returns 取得成功
               */
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1co6283['get']['resBody'], BasicHeaders, Methods_1co6283['get']['status']>(prefix, `${prefix2}${PATH18}`, GET, option).json(),
              /**
               * @returns 取得成功
               */
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1co6283['get']['resBody'], BasicHeaders, Methods_1co6283['get']['status']>(prefix, `${prefix2}${PATH18}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix2}${PATH18}`,
            },
            restaurant_courses: {
              /**
               * @returns OK
               */
              get: (option: { query: Methods_p2aw6u['get']['query'], config?: T | undefined }) =>
                fetch<Methods_p2aw6u['get']['resBody'], BasicHeaders, Methods_p2aw6u['get']['status']>(prefix, `${prefix2}${PATH23}`, GET, option).json(),
              /**
               * @returns OK
               */
              $get: (option: { query: Methods_p2aw6u['get']['query'], config?: T | undefined }) =>
                fetch<Methods_p2aw6u['get']['resBody'], BasicHeaders, Methods_p2aw6u['get']['status']>(prefix, `${prefix2}${PATH23}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_p2aw6u['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH23}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
            seat_statuses: {
              /**
               * @returns A list of table seat status
               */
              get: (option?: { query?: Methods_ip7nvo['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_ip7nvo['get']['resBody'], BasicHeaders, Methods_ip7nvo['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json(),
              /**
               * @returns A list of table seat status
               */
              $get: (option?: { query?: Methods_ip7nvo['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_ip7nvo['get']['resBody'], BasicHeaders, Methods_ip7nvo['get']['status']>(prefix, `${prefix2}${PATH24}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_ip7nvo['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH24}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
            smart_payments: {
              /**
               * @returns スマート決済一覧
               */
              get: (option: { query: Methods_za4e1x['get']['query'], config?: T | undefined }) =>
                fetch<Methods_za4e1x['get']['resBody'], BasicHeaders, Methods_za4e1x['get']['status']>(prefix, `${prefix2}${PATH25}`, GET, option).json(),
              /**
               * @returns スマート決済一覧
               */
              $get: (option: { query: Methods_za4e1x['get']['query'], config?: T | undefined }) =>
                fetch<Methods_za4e1x['get']['resBody'], BasicHeaders, Methods_za4e1x['get']['status']>(prefix, `${prefix2}${PATH25}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_za4e1x['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH25}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
          };
        },
        _restaurant_id_string: (val2: string) => {
          const prefix2 = `${PATH16}/${val2}`;

          return {
            reservation_request_count: {
              /**
               * @returns 予約リクエスト数
               */
              get: (option?: { query?: Methods_1sv7onk['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_1sv7onk['get']['resBody'], BasicHeaders, Methods_1sv7onk['get']['status']>(prefix, `${prefix2}${PATH26}`, GET, option).json(),
              /**
               * @returns 予約リクエスト数
               */
              $get: (option?: { query?: Methods_1sv7onk['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                fetch<Methods_1sv7onk['get']['resBody'], BasicHeaders, Methods_1sv7onk['get']['status']>(prefix, `${prefix2}${PATH26}`, GET, option).json().then(r => r.body),
              $path: (option?: { method?: 'get' | undefined; query: Methods_1sv7onk['get']['query'] } | undefined) =>
                `${prefix}${prefix2}${PATH26}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
            },
          };
        },
      },
    },
    restaurants: {
      _restaurant_id: (val1: number) => {
        const prefix1 = `${PATH27}/${val1}`;

        return {
          broadcast_messages: {
            _id: (val3: string) => {
              const prefix3 = `${prefix1}${PATH28}/${val3}`;

              return {
                /**
                 * @returns メッセージ
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1nwdj7h['get']['resBody'], BasicHeaders, Methods_1nwdj7h['get']['status']>(prefix, prefix3, GET, option).json(),
                /**
                 * @returns メッセージ
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1nwdj7h['get']['resBody'], BasicHeaders, Methods_1nwdj7h['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
                patch: (option: { body: Methods_1nwdj7h['patch']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_1nwdj7h['patch']['resBody'], BasicHeaders, Methods_1nwdj7h['patch']['status']>(prefix, prefix3, PATCH, option).json(),
                $patch: (option: { body: Methods_1nwdj7h['patch']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_1nwdj7h['patch']['resBody'], BasicHeaders, Methods_1nwdj7h['patch']['status']>(prefix, prefix3, PATCH, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}`,
              };
            },
            number_of_customers: {
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1ayg74h['get']['resBody'], BasicHeaders, Methods_1ayg74h['get']['status']>(prefix, `${prefix1}${PATH29}`, GET, option).json(),
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1ayg74h['get']['resBody'], BasicHeaders, Methods_1ayg74h['get']['status']>(prefix, `${prefix1}${PATH29}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix1}${PATH29}`,
            },
            number_of_targets: {
              get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1vsxj72['get']['resBody'], BasicHeaders, Methods_1vsxj72['get']['status']>(prefix, `${prefix1}${PATH30}`, GET, option).json(),
              $get: (option?: { config?: T | undefined } | undefined) =>
                fetch<Methods_1vsxj72['get']['resBody'], BasicHeaders, Methods_1vsxj72['get']['status']>(prefix, `${prefix1}${PATH30}`, GET, option).json().then(r => r.body),
              $path: () => `${prefix}${prefix1}${PATH30}`,
            },
            /**
             * @returns メッセージ一覧
             */
            get: (option?: { query?: Methods_11qvwwz['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_11qvwwz['get']['resBody'], BasicHeaders, Methods_11qvwwz['get']['status']>(prefix, `${prefix1}${PATH28}`, GET, option).json(),
            /**
             * @returns メッセージ一覧
             */
            $get: (option?: { query?: Methods_11qvwwz['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_11qvwwz['get']['resBody'], BasicHeaders, Methods_11qvwwz['get']['status']>(prefix, `${prefix1}${PATH28}`, GET, option).json().then(r => r.body),
            post: (option: { body: Methods_11qvwwz['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_11qvwwz['post']['resBody'], BasicHeaders, Methods_11qvwwz['post']['status']>(prefix, `${prefix1}${PATH28}`, POST, option).json(),
            $post: (option: { body: Methods_11qvwwz['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_11qvwwz['post']['resBody'], BasicHeaders, Methods_11qvwwz['post']['status']>(prefix, `${prefix1}${PATH28}`, POST, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_11qvwwz['get']['query'] } | undefined) =>
              `${prefix}${prefix1}${PATH28}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          cti_devices: {
            _cti_device_id: (val3: number) => {
              const prefix3 = `${prefix1}${PATH31}/${val3}`;

              return {
                /**
                 * @returns 更新されたCTI機器
                 */
                patch: (option: { body: Methods_1wef89h['patch']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_1wef89h['patch']['resBody'], BasicHeaders, Methods_1wef89h['patch']['status']>(prefix, prefix3, PATCH, option).json(),
                /**
                 * @returns 更新されたCTI機器
                 */
                $patch: (option: { body: Methods_1wef89h['patch']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_1wef89h['patch']['resBody'], BasicHeaders, Methods_1wef89h['patch']['status']>(prefix, prefix3, PATCH, option).json().then(r => r.body),
                delete: (option?: { config?: T | undefined } | undefined) =>
                  fetch<void, BasicHeaders, Methods_1wef89h['delete']['status']>(prefix, prefix3, DELETE, option).send(),
                $delete: (option?: { config?: T | undefined } | undefined) =>
                  fetch<void, BasicHeaders, Methods_1wef89h['delete']['status']>(prefix, prefix3, DELETE, option).send().then(r => r.body),
                $path: () => `${prefix}${prefix3}`,
              };
            },
            /**
             * @returns 登録済みのCTI機器の配列
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_311p0n['get']['resBody'], BasicHeaders, Methods_311p0n['get']['status']>(prefix, `${prefix1}${PATH31}`, GET, option).json(),
            /**
             * @returns 登録済みのCTI機器の配列
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_311p0n['get']['resBody'], BasicHeaders, Methods_311p0n['get']['status']>(prefix, `${prefix1}${PATH31}`, GET, option).json().then(r => r.body),
            /**
             * @returns 登録されたCTI機器
             */
            post: (option: { body: Methods_311p0n['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_311p0n['post']['resBody'], BasicHeaders, Methods_311p0n['post']['status']>(prefix, `${prefix1}${PATH31}`, POST, option).json(),
            /**
             * @returns 登録されたCTI機器
             */
            $post: (option: { body: Methods_311p0n['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_311p0n['post']['resBody'], BasicHeaders, Methods_311p0n['post']['status']>(prefix, `${prefix1}${PATH31}`, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH31}`,
          },
          customers: {
            _customer_id: (val3: string) => {
              const prefix3 = `${prefix1}${PATH32}/${val3}`;

              return {
                /**
                 * @returns A customer
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1xn18ib['get']['resBody'], BasicHeaders, Methods_1xn18ib['get']['status']>(prefix, prefix3, GET, option).json(),
                /**
                 * @returns A customer
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1xn18ib['get']['resBody'], BasicHeaders, Methods_1xn18ib['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}`,
              };
            },
          },
          floors: {
            _floor_id: (val3: string) => {
              const prefix3 = `${prefix1}${PATH33}/${val3}`;

              return {
                positions: {
                  /**
                   * @returns Floor updated successfully
                   */
                  put: (option: { body: Methods_185shua['put']['reqBody'], config?: T | undefined }) =>
                    fetch<Methods_185shua['put']['resBody'], BasicHeaders, Methods_185shua['put']['status']>(prefix, `${prefix3}${PATH34}`, PUT, option).json(),
                  /**
                   * @returns Floor updated successfully
                   */
                  $put: (option: { body: Methods_185shua['put']['reqBody'], config?: T | undefined }) =>
                    fetch<Methods_185shua['put']['resBody'], BasicHeaders, Methods_185shua['put']['status']>(prefix, `${prefix3}${PATH34}`, PUT, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH34}`,
                },
                seats: {
                  /**
                   * @returns Floor updated successfully
                   */
                  get: (option?: { query?: Methods_16reaok['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                    fetch<Methods_16reaok['get']['resBody'], BasicHeaders, Methods_16reaok['get']['status']>(prefix, `${prefix3}${PATH35}`, GET, option).json(),
                  /**
                   * @returns Floor updated successfully
                   */
                  $get: (option?: { query?: Methods_16reaok['get']['query'] | undefined, config?: T | undefined } | undefined) =>
                    fetch<Methods_16reaok['get']['resBody'], BasicHeaders, Methods_16reaok['get']['status']>(prefix, `${prefix3}${PATH35}`, GET, option).json().then(r => r.body),
                  $path: (option?: { method?: 'get' | undefined; query: Methods_16reaok['get']['query'] } | undefined) =>
                    `${prefix}${prefix3}${PATH35}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
                },
                /**
                 * @returns Floor fetched successfully
                 */
                get: (option: { query: Methods_5vmly9['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_5vmly9['get']['resBody'], BasicHeaders, Methods_5vmly9['get']['status']>(prefix, prefix3, GET, option).json(),
                /**
                 * @returns Floor fetched successfully
                 */
                $get: (option: { query: Methods_5vmly9['get']['query'], config?: T | undefined }) =>
                  fetch<Methods_5vmly9['get']['resBody'], BasicHeaders, Methods_5vmly9['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
                /**
                 * @returns Floor updated successfully
                 */
                put: (option: { body: Methods_5vmly9['put']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_5vmly9['put']['resBody'], BasicHeaders, Methods_5vmly9['put']['status']>(prefix, prefix3, PUT, option).json(),
                /**
                 * @returns Floor updated successfully
                 */
                $put: (option: { body: Methods_5vmly9['put']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_5vmly9['put']['resBody'], BasicHeaders, Methods_5vmly9['put']['status']>(prefix, prefix3, PUT, option).json().then(r => r.body),
                delete: (option?: { config?: T | undefined } | undefined) =>
                  fetch<void, BasicHeaders, Methods_5vmly9['delete']['status']>(prefix, prefix3, DELETE, option).send(),
                $delete: (option?: { config?: T | undefined } | undefined) =>
                  fetch<void, BasicHeaders, Methods_5vmly9['delete']['status']>(prefix, prefix3, DELETE, option).send().then(r => r.body),
                $path: (option?: { method?: 'get' | undefined; query: Methods_5vmly9['get']['query'] } | undefined) =>
                  `${prefix}${prefix3}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
              };
            },
            /**
             * @returns FloorList fetched successfully
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1onvpjs['get']['resBody'], BasicHeaders, Methods_1onvpjs['get']['status']>(prefix, `${prefix1}${PATH33}`, GET, option).json(),
            /**
             * @returns FloorList fetched successfully
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1onvpjs['get']['resBody'], BasicHeaders, Methods_1onvpjs['get']['status']>(prefix, `${prefix1}${PATH33}`, GET, option).json().then(r => r.body),
            /**
             * @returns Floor created successfully
             */
            post: (option: { body: Methods_1onvpjs['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1onvpjs['post']['resBody'], BasicHeaders, Methods_1onvpjs['post']['status']>(prefix, `${prefix1}${PATH33}`, POST, option).json(),
            /**
             * @returns Floor created successfully
             */
            $post: (option: { body: Methods_1onvpjs['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1onvpjs['post']['resBody'], BasicHeaders, Methods_1onvpjs['post']['status']>(prefix, `${prefix1}${PATH33}`, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH33}`,
          },
          line_message_channels: {
            /**
             * @returns 一覧取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1wgvpme['get']['resBody'], BasicHeaders, Methods_1wgvpme['get']['status']>(prefix, `${prefix1}${PATH36}`, GET, option).json(),
            /**
             * @returns 一覧取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1wgvpme['get']['resBody'], BasicHeaders, Methods_1wgvpme['get']['status']>(prefix, `${prefix1}${PATH36}`, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH36}`,
          },
          line_notification_setting: {
            /**
             * @returns 通知先設定成功
             */
            put: (option: { body: Methods_1vnkm1m['put']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1vnkm1m['put']['resBody'], BasicHeaders, Methods_1vnkm1m['put']['status']>(prefix, `${prefix1}${PATH37}`, PUT, option).json(),
            /**
             * @returns 通知先設定成功
             */
            $put: (option: { body: Methods_1vnkm1m['put']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1vnkm1m['put']['resBody'], BasicHeaders, Methods_1vnkm1m['put']['status']>(prefix, `${prefix1}${PATH37}`, PUT, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH37}`,
          },
          products: {
            statuses_bulk_update: {
              patch: (option: { body: Methods_1bpsyux['patch']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_1bpsyux['patch']['status']>(prefix, `${prefix1}${PATH39}`, PATCH, option).send(),
              $patch: (option: { body: Methods_1bpsyux['patch']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_1bpsyux['patch']['status']>(prefix, `${prefix1}${PATH39}`, PATCH, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix1}${PATH39}`,
            },
            /**
             * @returns A list of products
             */
            get: (option?: { query?: Methods_qn4evz['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_qn4evz['get']['resBody'], BasicHeaders, Methods_qn4evz['get']['status']>(prefix, `${prefix1}${PATH38}`, GET, option).json(),
            /**
             * @returns A list of products
             */
            $get: (option?: { query?: Methods_qn4evz['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_qn4evz['get']['resBody'], BasicHeaders, Methods_qn4evz['get']['status']>(prefix, `${prefix1}${PATH38}`, GET, option).json().then(r => r.body),
            /**
             * @returns 作成成功
             */
            post: (option: { body: Methods_qn4evz['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_qn4evz['post']['resBody'], BasicHeaders, Methods_qn4evz['post']['status']>(prefix, `${prefix1}${PATH38}`, POST, option).json(),
            /**
             * @returns 作成成功
             */
            $post: (option: { body: Methods_qn4evz['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_qn4evz['post']['resBody'], BasicHeaders, Methods_qn4evz['post']['status']>(prefix, `${prefix1}${PATH38}`, POST, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_qn4evz['get']['query'] } | undefined) =>
              `${prefix}${prefix1}${PATH38}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          reservation_activity_notifications: {
            statuses: {
              put: (option: { body: Methods_6nfnqd['put']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_6nfnqd['put']['status']>(prefix, `${prefix1}${PATH41}`, PUT, option).send(),
              $put: (option: { body: Methods_6nfnqd['put']['reqBody'], config?: T | undefined }) =>
                fetch<void, BasicHeaders, Methods_6nfnqd['put']['status']>(prefix, `${prefix1}${PATH41}`, PUT, option).send().then(r => r.body),
              $path: () => `${prefix}${prefix1}${PATH41}`,
            },
            /**
             * @returns 予約アクティビティ通知一覧取得成功
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_10wkgnu['get']['resBody'], BasicHeaders, Methods_10wkgnu['get']['status']>(prefix, `${prefix1}${PATH40}`, GET, option).json(),
            /**
             * @returns 予約アクティビティ通知一覧取得成功
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_10wkgnu['get']['resBody'], BasicHeaders, Methods_10wkgnu['get']['status']>(prefix, `${prefix1}${PATH40}`, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH40}`,
          },
          reservation_cancel_fees: {
            /**
             * @returns キャンセル料一覧
             */
            get: (option?: { query?: Methods_1iv66nu['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1iv66nu['get']['resBody'], BasicHeaders, Methods_1iv66nu['get']['status']>(prefix, `${prefix1}${PATH42}`, GET, option).json(),
            /**
             * @returns キャンセル料一覧
             */
            $get: (option?: { query?: Methods_1iv66nu['get']['query'] | undefined, config?: T | undefined } | undefined) =>
              fetch<Methods_1iv66nu['get']['resBody'], BasicHeaders, Methods_1iv66nu['get']['status']>(prefix, `${prefix1}${PATH42}`, GET, option).json().then(r => r.body),
            $path: (option?: { method?: 'get' | undefined; query: Methods_1iv66nu['get']['query'] } | undefined) =>
              `${prefix}${prefix1}${PATH42}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`,
          },
          restaurant_course_archives: {
            _id: (val3: number) => {
              const prefix3 = `${prefix1}${PATH43}/${val3}`;

              return {
                /**
                 * @returns 更新結果
                 */
                patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1ryrk9a['patch']['resBody'], BasicHeaders, Methods_1ryrk9a['patch']['status']>(prefix, prefix3, PATCH, option).json(),
                /**
                 * @returns 更新結果
                 */
                $patch: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_1ryrk9a['patch']['resBody'], BasicHeaders, Methods_1ryrk9a['patch']['status']>(prefix, prefix3, PATCH, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}`,
              };
            },
          },
          restaurant_course_bulk_update: {
            patch: (option: { body: Methods_qo68gf['patch']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_qo68gf['patch']['status']>(prefix, `${prefix1}${PATH44}`, PATCH, option).send(),
            $patch: (option: { body: Methods_qo68gf['patch']['reqBody'], config?: T | undefined }) =>
              fetch<void, BasicHeaders, Methods_qo68gf['patch']['status']>(prefix, `${prefix1}${PATH44}`, PATCH, option).send().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH44}`,
          },
          restaurant_courses: {
            _id: (val3: number) => {
              const prefix3 = `${prefix1}${PATH23}/${val3}`;

              return {
                /**
                 * @returns 一件のコース
                 */
                get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_9jzxmb['get']['resBody'], BasicHeaders, Methods_9jzxmb['get']['status']>(prefix, prefix3, GET, option).json(),
                /**
                 * @returns 一件のコース
                 */
                $get: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_9jzxmb['get']['resBody'], BasicHeaders, Methods_9jzxmb['get']['status']>(prefix, prefix3, GET, option).json().then(r => r.body),
                /**
                 * @returns 更新結果
                 */
                patch: (option: { body: Methods_9jzxmb['patch']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_9jzxmb['patch']['resBody'], BasicHeaders, Methods_9jzxmb['patch']['status']>(prefix, prefix3, PATCH, option).json(),
                /**
                 * @returns 更新結果
                 */
                $patch: (option: { body: Methods_9jzxmb['patch']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_9jzxmb['patch']['resBody'], BasicHeaders, Methods_9jzxmb['patch']['status']>(prefix, prefix3, PATCH, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}`,
              };
            },
            _restaurant_course_id: (val3: number) => {
              const prefix3 = `${prefix1}${PATH23}/${val3}`;

              return {
                restaurant_course_business_times: {
                  bulk_create: {
                    /**
                     * @returns created
                     */
                    post: (option: { body: Methods_15kczvl['post']['reqBody'], config?: T | undefined }) =>
                      fetch<Methods_15kczvl['post']['resBody'], BasicHeaders, Methods_15kczvl['post']['status']>(prefix, `${prefix3}${PATH46}`, POST, option).json(),
                    /**
                     * @returns created
                     */
                    $post: (option: { body: Methods_15kczvl['post']['reqBody'], config?: T | undefined }) =>
                      fetch<Methods_15kczvl['post']['resBody'], BasicHeaders, Methods_15kczvl['post']['status']>(prefix, `${prefix3}${PATH46}`, POST, option).json().then(r => r.body),
                    $path: () => `${prefix}${prefix3}${PATH46}`,
                  },
                  bulk_delete: {
                    delete: (option: { body: Methods_15hymo6['delete']['reqBody'], config?: T | undefined }) =>
                      fetch<void, BasicHeaders, Methods_15hymo6['delete']['status']>(prefix, `${prefix3}${PATH47}`, DELETE, option).send(),
                    $delete: (option: { body: Methods_15hymo6['delete']['reqBody'], config?: T | undefined }) =>
                      fetch<void, BasicHeaders, Methods_15hymo6['delete']['status']>(prefix, `${prefix3}${PATH47}`, DELETE, option).send().then(r => r.body),
                    $path: () => `${prefix}${prefix3}${PATH47}`,
                  },
                  /**
                   * @returns A list of RestaurantCourseBusinessTimes
                   */
                  get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1ynfmxz['get']['resBody'], BasicHeaders, Methods_1ynfmxz['get']['status']>(prefix, `${prefix3}${PATH45}`, GET, option).json(),
                  /**
                   * @returns A list of RestaurantCourseBusinessTimes
                   */
                  $get: (option?: { config?: T | undefined } | undefined) =>
                    fetch<Methods_1ynfmxz['get']['resBody'], BasicHeaders, Methods_1ynfmxz['get']['status']>(prefix, `${prefix3}${PATH45}`, GET, option).json().then(r => r.body),
                  $path: () => `${prefix}${prefix3}${PATH45}`,
                },
              };
            },
            /**
             * @returns コース一覧
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_dap3cx['get']['resBody'], BasicHeaders, Methods_dap3cx['get']['status']>(prefix, `${prefix1}${PATH23}`, GET, option).json(),
            /**
             * @returns コース一覧
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_dap3cx['get']['resBody'], BasicHeaders, Methods_dap3cx['get']['status']>(prefix, `${prefix1}${PATH23}`, GET, option).json().then(r => r.body),
            /**
             * @returns 作成したコース
             */
            post: (option: { body: Methods_dap3cx['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_dap3cx['post']['resBody'], BasicHeaders, Methods_dap3cx['post']['status']>(prefix, `${prefix1}${PATH23}`, POST, option).json(),
            /**
             * @returns 作成したコース
             */
            $post: (option: { body: Methods_dap3cx['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_dap3cx['post']['resBody'], BasicHeaders, Methods_dap3cx['post']['status']>(prefix, `${prefix1}${PATH23}`, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH23}`,
          },
          restaurant_setting: {
            /**
             * @returns 更新成功
             */
            patch: (option: { body: Methods_1l0xkuz['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1l0xkuz['patch']['resBody'], BasicHeaders, Methods_1l0xkuz['patch']['status']>(prefix, `${prefix1}${PATH48}`, PATCH, option).json(),
            /**
             * @returns 更新成功
             */
            $patch: (option: { body: Methods_1l0xkuz['patch']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1l0xkuz['patch']['resBody'], BasicHeaders, Methods_1l0xkuz['patch']['status']>(prefix, `${prefix1}${PATH48}`, PATCH, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH48}`,
          },
          seats: {
            /**
             * @returns seat created successfully
             */
            post: (option: { body: Methods_1p95naf['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1p95naf['post']['resBody'], BasicHeaders, Methods_1p95naf['post']['status']>(prefix, `${prefix1}${PATH35}`, POST, option).json(),
            /**
             * @returns seat created successfully
             */
            $post: (option: { body: Methods_1p95naf['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_1p95naf['post']['resBody'], BasicHeaders, Methods_1p95naf['post']['status']>(prefix, `${prefix1}${PATH35}`, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH35}`,
          },
          unread_message_count: {
            /**
             * @returns 未読メッセージ数
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1938y0s['get']['resBody'], BasicHeaders, Methods_1938y0s['get']['status']>(prefix, `${prefix1}${PATH49}`, GET, option).json(),
            /**
             * @returns 未読メッセージ数
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_1938y0s['get']['resBody'], BasicHeaders, Methods_1938y0s['get']['status']>(prefix, `${prefix1}${PATH49}`, GET, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH49}`,
          },
          visit_status_settings: {
            _visit_status_setting_id: (val3: number) => {
              const prefix3 = `${prefix1}${PATH50}/${val3}`;

              return {
                /**
                 * @returns 更新された予約ステータス
                 */
                patch: (option: { body: Methods_uf7f9d['patch']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_uf7f9d['patch']['resBody'], BasicHeaders, Methods_uf7f9d['patch']['status']>(prefix, prefix3, PATCH, option).json(),
                /**
                 * @returns 更新された予約ステータス
                 */
                $patch: (option: { body: Methods_uf7f9d['patch']['reqBody'], config?: T | undefined }) =>
                  fetch<Methods_uf7f9d['patch']['resBody'], BasicHeaders, Methods_uf7f9d['patch']['status']>(prefix, prefix3, PATCH, option).json().then(r => r.body),
                /**
                 * @returns 論理削除された予約ステータス
                 */
                delete: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_uf7f9d['delete']['resBody'], BasicHeaders, Methods_uf7f9d['delete']['status']>(prefix, prefix3, DELETE, option).json(),
                /**
                 * @returns 論理削除された予約ステータス
                 */
                $delete: (option?: { config?: T | undefined } | undefined) =>
                  fetch<Methods_uf7f9d['delete']['resBody'], BasicHeaders, Methods_uf7f9d['delete']['status']>(prefix, prefix3, DELETE, option).json().then(r => r.body),
                $path: () => `${prefix}${prefix3}`,
              };
            },
            /**
             * @returns 使用可能な予約ステータスの配列
             */
            get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_zmpk9j['get']['resBody'], BasicHeaders, Methods_zmpk9j['get']['status']>(prefix, `${prefix1}${PATH50}`, GET, option).json(),
            /**
             * @returns 使用可能な予約ステータスの配列
             */
            $get: (option?: { config?: T | undefined } | undefined) =>
              fetch<Methods_zmpk9j['get']['resBody'], BasicHeaders, Methods_zmpk9j['get']['status']>(prefix, `${prefix1}${PATH50}`, GET, option).json().then(r => r.body),
            /**
             * @returns 作成された予約ステータス
             */
            post: (option: { body: Methods_zmpk9j['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_zmpk9j['post']['resBody'], BasicHeaders, Methods_zmpk9j['post']['status']>(prefix, `${prefix1}${PATH50}`, POST, option).json(),
            /**
             * @returns 作成された予約ステータス
             */
            $post: (option: { body: Methods_zmpk9j['post']['reqBody'], config?: T | undefined }) =>
              fetch<Methods_zmpk9j['post']['resBody'], BasicHeaders, Methods_zmpk9j['post']['status']>(prefix, `${prefix1}${PATH50}`, POST, option).json().then(r => r.body),
            $path: () => `${prefix}${prefix1}${PATH50}`,
          },
        };
      },
    },
    seats: {
      _id: (val1: string) => {
        const prefix1 = `${PATH35}/${val1}`;

        return {
          /**
           * @returns seat fetched successfully
           */
          get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_eqa4uw['get']['resBody'], BasicHeaders, Methods_eqa4uw['get']['status']>(prefix, prefix1, GET, option).json(),
          /**
           * @returns seat fetched successfully
           */
          $get: (option?: { config?: T | undefined } | undefined) =>
            fetch<Methods_eqa4uw['get']['resBody'], BasicHeaders, Methods_eqa4uw['get']['status']>(prefix, prefix1, GET, option).json().then(r => r.body),
          /**
           * @returns seat updated successfully
           */
          put: (option: { body: Methods_eqa4uw['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_eqa4uw['put']['resBody'], BasicHeaders, Methods_eqa4uw['put']['status']>(prefix, prefix1, PUT, option).json(),
          /**
           * @returns seat updated successfully
           */
          $put: (option: { body: Methods_eqa4uw['put']['reqBody'], config?: T | undefined }) =>
            fetch<Methods_eqa4uw['put']['resBody'], BasicHeaders, Methods_eqa4uw['put']['status']>(prefix, prefix1, PUT, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`,
        };
      },
    },
  };
};

export type ApiInstance = ReturnType<typeof api>;
export default api;
