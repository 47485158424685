import type React from 'react'

import { View, Text, StyleSheet } from '@react-pdf/renderer'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerLeft: {
    flexDirection: 'column',
  },
  headerCenter: {
    alignItems: 'center',
  },
  headerRight: {
    alignItems: 'flex-end',
  },
  text: {
    fontSize: 8,
    color: Colors.black60,
    fontFamily: 'NotoSansJPRegular',
  },
  dateText: {
    fontSize: 10,
    color: Colors.black,
    fontFamily: 'NotoSansJPBold',
  },
})

interface PrintWrapProps {
  children: React.ReactNode
  orientation: 'landscape' | 'portrait'
  currentPage: number
  totalPages: number
  lastUpdatedAt: string
  restaurantName: string
  reservationDate: dayjs.Dayjs
}

export function PrintWrap({
  children,
  orientation,
  currentPage,
  totalPages,
  lastUpdatedAt,
  restaurantName,
  reservationDate,
}: PrintWrapProps) {
  const now = dayjs().format('YYYY/MM/DD HH:mm')
  return (
    <View>
      <View
        style={[
          styles.header,
          { marginBottom: orientation === 'landscape' ? 12 : 20 },
        ]}
      >
        <View style={styles.headerLeft}>
          <Text style={styles.text}>
            {t('店舗：{{name}}', { name: restaurantName })}
          </Text>
          <Text style={styles.text}>
            {t('{{current}}ページ目/{{total}}ページ', {
              current: currentPage,
              total: totalPages,
            })}
          </Text>
        </View>

        <View style={styles.headerCenter}>
          <Text style={styles.dateText}>
            {reservationDate.format(t('YYYY年MM月DD日（ddd）'))}
          </Text>
        </View>

        <View style={styles.headerRight}>
          <Text style={styles.text}>
            {t('印刷日時：{{date}}', { date: now })}
          </Text>
          <Text style={styles.text}>
            {t('最終更新：{{date}}', { date: lastUpdatedAt })}
          </Text>
        </View>
      </View>

      {children}
    </View>
  )
}
