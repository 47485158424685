import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faRemove } from '@fortawesome/pro-solid-svg-icons/faRemove'
import {
  Animated,
  SafeAreaView,
  StyleProp,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

interface ToastMessage {
  id: string
  message: string
  duration?: number
  type: 'info' | 'success' | 'error' | 'confirm'
  onPress?: () => void
  style?: StyleProp<ViewStyle>
  excludeFromConfirmRemoval?: boolean
}

interface ToastMethods {
  displayToastInfo: (
    message: string,
    duration?: number,
    style?: StyleProp<ViewStyle>
  ) => void
  displayToastSuccess: (
    message: string,
    duration?: number,
    style?: StyleProp<ViewStyle>
  ) => void
  displayToastError: (
    message: string,
    duration?: number,
    style?: StyleProp<ViewStyle>
  ) => void
  displayToastConfirm: (
    message: string,
    onPress: () => void,
    options?: { excludeFromConfirmRemoval?: boolean }
  ) => void
  removeToast: (id: string) => void
  removeConfirmToast: () => void
  removeAllConfirmToasts: () => void
}

const ToastContext = React.createContext<
  [state: ToastMessage[], setState: (value: ToastMessage[]) => void] | undefined
>(undefined)

export function ToastProvider({ children }: { children?: React.ReactNode }) {
  const [state, setState] = useState<ToastMessage[]>([])
  return (
    <ToastContext.Provider value={[state, setState]}>
      {children}
    </ToastContext.Provider>
  )
}

let toastRef: ToastMethods | null = null
export function setToastRef(ref: ToastMethods | null) {
  toastRef = ref
}

export function displayToastError(
  message: string,
  duration?: number,
  style?: StyleProp<ViewStyle>
) {
  toastRef?.displayToastError(message, duration, style)
}

export function displayToastInfo(
  message: string,
  duration?: number,
  style?: StyleProp<ViewStyle>
) {
  toastRef?.displayToastInfo(message, duration, style)
}

export function displayToastSuccess(
  message: string,
  duration?: number,
  style?: StyleProp<ViewStyle>
) {
  toastRef?.displayToastSuccess(message, duration, style)
}

export function displayToastConfirm(
  message: string,
  onPress: () => void,
  options?: { excludeFromConfirmRemoval?: boolean }
) {
  toastRef?.displayToastConfirm(message, onPress, options)
}

export function displayToastSequence(toasts: Array<() => void>, delay = 0) {
  if (toasts.length === 0) return

  const [firstToast, ...remainingToasts] = toasts
  firstToast()

  remainingToasts.reduce((promise, toast) => {
    return promise.then(() => {
      return new Promise((resolve) => {
        setTimeout(() => {
          toast()
          resolve(undefined)
        }, delay)
      })
    })
  }, Promise.resolve())
}

export function removeToast(id: string) {
  toastRef?.removeToast(id)
}

export function removeConfirmToast() {
  toastRef?.removeConfirmToast()
}

export function removeAllConfirmToasts() {
  toastRef?.removeAllConfirmToasts()
}

export const Toast = React.forwardRef<ToastMethods, {}>((_props, ref) => {
  const context = useContext(ToastContext)
  if (context === undefined) {
    throw new Error('<Toast /> can only be used inside <ToastProvider />')
  }
  const [state, setState] = context

  useImperativeHandle(ref, () => {
    function displayToast(
      type: 'info' | 'success' | 'error' | 'confirm',
      message: string,
      duration?: number,
      onPress?: () => void,
      style?: StyleProp<ViewStyle>,
      options?: { excludeFromConfirmRemoval?: boolean }
    ) {
      if (
        state.find((state) => state.type === type && state.message === message)
      )
        return
      const newState = [
        ...state,
        {
          id: `${Date.now()}`,
          message,
          duration,
          style,
          type,
          onPress,
          excludeFromConfirmRemoval: options?.excludeFromConfirmRemoval,
        },
      ]
      setState(newState)
    }

    function removeToast(id: string) {
      setState(state.filter((toast) => toast.id !== id))
    }

    return {
      displayToastInfo: (message, duration = 3000, style) => {
        displayToast('info', message, duration, undefined, style)
      },
      displayToastSuccess: (message, duration = 3000, style) => {
        displayToast('success', message, duration, undefined, style)
      },
      displayToastError: (message, duration = 3000, style) => {
        displayToast('error', message, duration, undefined, style)
      },
      displayToastConfirm: (message, onPress, options) => {
        displayToast('confirm', message, undefined, onPress, undefined, options)
      },
      removeToast,
      removeConfirmToast: () => {
        setState(
          state.filter(
            (toast) =>
              toast.type !== 'confirm' || toast.excludeFromConfirmRemoval
          )
        )
      },
      removeAllConfirmToasts: () => {
        setState(state.filter((toast) => toast.type !== 'confirm'))
      },
    }
  }, [state, setState])
  if (state.length === 0) return <></>

  return (
    <>
      <ToastItem
        key={state[0].id}
        id={state[0].id}
        message={state[0].message}
        duration={state[0].duration}
        type={state[0].type}
        onPress={state[0].onPress}
        style={state[0].style}
        removeToast={removeToast}
        index={0}
      />
    </>
  )
})

function ToastItem({
  id,
  message,
  duration,
  type,
  onPress,
  style,
  removeToast,
  index,
}: Omit<ToastMessage, 'excludeFromConfirmRemoval'> & {
  removeToast: (id: string) => void
  index: number
}) {
  const fadeAnimation = useRef(new Animated.Value(0)).current
  const { width, sm } = useResponsive()

  useEffect(() => {
    Animated.timing(fadeAnimation, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
    }).start()

    if (duration !== undefined) {
      const timer = setTimeout(() => {
        Animated.timing(fadeAnimation, {
          toValue: 0,
          duration: 200,
          useNativeDriver: true,
        }).start(() => {
          removeToast(id)
        })
      }, duration)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [duration, fadeAnimation, id, removeToast])

  let backgroundColor: ViewStyle['backgroundColor']
  switch (type) {
    case 'info':
      backgroundColor = Colors.black
      break
    case 'success':
      backgroundColor = Colors.success
      break
    case 'error':
      backgroundColor = Colors.caution90
      break
    case 'confirm':
      backgroundColor = Colors.white
      break
  }
  const messageStyles: Animated.WithAnimatedValue<ViewStyle>[] = [
    {
      padding: 16,
      borderRadius: 8,
      shadowRadius: 8,
      elevation: 4,
      shadowColor: '#000',
      shadowOpacity: 0.1,
      shadowOffset: { width: 0, height: 2 },
      width: width < sm ? 360 : 480,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor,
    },
    {
      opacity: fadeAnimation,
    },
  ]
  const messageTextStyles: (TextStyle | false)[] = [
    {
      flex: 1,
      fontWeight: '300',
      fontSize: 18,
      lineHeight: 27,
      color: 'white',
    },
    type === 'confirm' && {
      color: Colors.black,
      fontWeight: '600',
      flexWrap: 'wrap',
      overflow: 'hidden',
    },
  ]

  function Icon() {
    let icon: IconDefinition | undefined
    let color: string | undefined
    switch (type) {
      case 'success':
        icon = faCheckCircle
        color = Colors.white
        break
      case 'error':
        icon = faExclamationCircle
        color = Colors.white
        break
      case 'confirm':
        icon = faBell
        color = Colors.black40
        break
    }

    if (!icon) return null

    return (
      <FontAwesomeIcon
        icon={icon}
        size={width < sm ? 16 : 24}
        color={color}
        style={{ marginRight: 4 }}
      />
    )
  }

  function ActionText() {
    switch (type) {
      case 'confirm':
        return <Text style={{ color: Colors.primary }}>{t('確認する')}</Text>
      default:
        return (
          <FontAwesomeIcon
            icon={faRemove}
            size={width < sm ? 16 : 20}
            color={Colors.white}
          />
        )
    }
  }

  return (
    <View
      pointerEvents={'box-none'}
      style={[
        {
          position: 'absolute',
          alignItems: 'center',
        },
        width < sm
          ? {
              left: 0,
              right: 0,
              bottom: 0 + index * 100,
            }
          : {
              left: 0,
              right: 0,
              bottom: 20 + index * 70,
            },
      ]}
    >
      <Animated.View style={[messageStyles, style]}>
        {type !== 'info' && <Icon />}
        <Text
          style={messageTextStyles}
          numberOfLines={type === 'confirm' ? 1 : undefined}
          ellipsizeMode={type === 'confirm' ? 'tail' : undefined}
        >
          {message}
        </Text>
        <TouchableOpacity
          onPress={() => {
            onPress && onPress()
            Animated.timing(fadeAnimation, {
              toValue: 0,
              duration: 200,
              useNativeDriver: true,
            }).start(() => {
              removeToast(id)
            })
          }}
        >
          <ActionText />
        </TouchableOpacity>
      </Animated.View>
      <SafeAreaView />
    </View>
  )
}
