import { Colors } from '../../constants/Colors'
import { t } from '../../modules/i18n'
import { TextStyle, StyleProp } from 'react-native'

export const GOURMET_SITE_PROVIDER_SOURCE = {
  tabelog: {
    authenticateLabel: t('食べログ'),
    label: t('食べログ'),
    simplifiedLabel: t('食べ'),
    adminUrl: 'https://owner.tabelog.com/owner_yoyaku/config/edit_mail_store',
  },
  tabelog_note: {
    authenticateLabel: t('食べログ'),
    label: t('食べログ'),
    simplifiedLabel: t('食べ'),
    adminUrl: 'https://owner.tabelog.com/tn/config/index',
  },
  hotpepper: {
    authenticateLabel: t('レストランボード'),
    label: t('ホットペッパー'),
    simplifiedLabel: t('HP'),
    adminUrl: 'https://restaurant-board.com/CLP/view/top/index',
  },
  gnavi: {
    authenticateLabel: t('ぐるなび'),
    label: t('ぐるなび'),
    simplifiedLabel: t('ぐる'),
    adminUrl: 'https://manage.gnavi.co.jp/rsv/notification-setting',
  },
  ikkyu: {
    authenticateLabel: t('一休'),
    label: t('一休'),
    simplifiedLabel: t('一休'),
    adminUrl: 'https://restaurant.ikyu.com/rsOwner/login',
  },
} as const

export type GourmetSiteProvider = keyof typeof GOURMET_SITE_PROVIDER_SOURCE

export const GOURMET_SITE_PROVIDER_TEXT_STYLE: Record<
  GourmetSiteProvider,
  StyleProp<TextStyle>
> = {
  tabelog: {
    color: Colors.white,
    backgroundColor: '#FF9601',
  },
  tabelog_note: {
    color: Colors.white,
    backgroundColor: '#FF9601',
  },
  hotpepper: {
    color: Colors.white,
    backgroundColor: '#6D9BC5',
  },
  gnavi: {
    color: Colors.white,
    backgroundColor: '#E32F2F',
  },
  ikkyu: {
    color: Colors.white,
    backgroundColor: '#1A4473',
  },
}

export interface ImportMailSetting {
  source: GourmetSiteProvider
  set_at: string | null
  last_received_at: string | null
}

// グルメサイト連携の一覧で取得するAPIの型
export interface ImportMailSettingsIndex {
  agreed_at: string | null
  site_controller_enabled: boolean | null
  settings: ImportMailSetting[]
}

export interface ImportMailAddress {
  mail_address: string
}

export interface LastImportedMail {
  body: { text: string }
}

interface TabelogAuthentications {
  site: 'tabelog'
  signin_id: string
  password: string
}
interface TabelogNoteAuthentications {
  site: 'tabelog_note'
  signin_id: string
  password: string
}
interface IkkyuAuthentications {
  site: 'ikkyu'
  site_restaurant_id: string
  signin_id: string
  password: string
}

interface HotpepperAuthentications {
  site: 'hotpepper'
  signin_id: string
  password: string
}

interface GnaviAuthentications {
  site: 'gnavi'
  signin_id: string
  password: string
}

export type AuthenticationForSite =
  | TabelogAuthentications
  | TabelogNoteAuthentications
  | IkkyuAuthentications
  | HotpepperAuthentications
  | GnaviAuthentications
